import React from 'react';
import Grid from '@material-ui/core/Grid';

import './pageheading/PageHeading.scss';

interface Props {
    title: string;
    description?: string;
    className?: string;
}

export default function PageHeading({ className = '', title, description }: Props): React.ReactElement {
    return (
        <Grid item md={7} className={`o-pageheading ${className}`}>
            <h1>{title}</h1>
            {description && <p>{description}</p>}
        </Grid>
    );
}
