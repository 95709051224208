import { IUser } from 'typings/User';

export const SAVE_USER_STATE = 'SAVE_USER_STATE';
export const UPDATE_USER_STATE = 'UPDATE_USER_STATE';
export const DELETE_USER_STATE = 'DELETE_USER_STATE';
export const GET_USER_STATE = 'GET_USER_STATE';
export const SAVE_USER_INTRODUCTIONS = 'SAVE_USER_INTRODUCTIONS';

export const saveUserState = (data: IUser): Record<string, unknown> => ({
    type: SAVE_USER_STATE,
    payload: { data },
});

export const saveUserIntroductions = (data: number[]): Record<string, unknown> => ({
    type: SAVE_USER_INTRODUCTIONS,
    payload: { data },
});

export const updateUserState = (data: IUser): Record<string, unknown> => ({
    type: UPDATE_USER_STATE,
    payload: { data },
});

export const deleteUserState = (id: number): Record<string, unknown> => ({
    type: DELETE_USER_STATE,
    payload: { id },
});

export const getUserState = (id: number): Record<string, unknown> => ({
    type: GET_USER_STATE,
    payload: { id },
});
