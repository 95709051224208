import React from 'react';
import ganttState from '../ganttState';
import { TaskModel } from '../Data';
import './GanttTTBody.scss';

interface GanttItemProps extends TaskModel {
    index: number;
    template?: number;
}

const GanttTTBody = ({ ...props }: GanttItemProps): React.ReactElement => {
    const { Gantt } = ganttState;
    const rangCnt = Gantt.getBusinessDayCount(props.start, props.end);
    return (
        <div className={['ttbody', rangCnt && 'wide'].join(' ')}>
            <h4>{props.title}</h4>
            {!!rangCnt ? (
                <div className="space-between">
                    <div className="title">Date Range</div>
                    <div className="datePill">{`${Gantt.formatDate(props.start)} - ${Gantt.formatDate(
                        props.end
                    )}`}</div>
                </div>
            ) : (
                <div className="space-between">
                    <div>Due Date</div>
                    <div className="datePill">{Gantt.formatDate(props.start)}</div>
                </div>
            )}{' '}
            {!!rangCnt && <div>{rangCnt} Business Days</div>}
        </div>
    );
};

export default GanttTTBody;
