/* eslint no-underscore-dangle: "off" */
import React, { useState, useEffect, useRef } from 'react';
import { Button, Icon, IconButton, MenuItem } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import CustomSelect from 'components/CustomSelect';
import { IUser } from 'typings/User';
import CustomInput from 'components/CustomInput';
import Offcanvas from 'components/OffCanvas';
import AlertToast from 'components/AlertToast';
import { qUsers, qInvitees } from 'api/queries';
import { GetUserUserExtended } from 'typings/_graphql';
import ConfirmButton from 'components/ConfirmButton';
import DeletePersonModal from './DeletePersonModal';
import { Statuses, PhoneTypes } from './Enums';
import { offCanvasProps } from '../../PlanContext';

interface OnSaveProps {
    email: undefined;
    name: undefined;
    organization: undefined;
    permission: undefined;
    phone: string;
    phoneType: number;
    projectRole: string;
}

interface IdentifyPeopleFormsProps {
    selectedUser: GetUserUserExtended | any;
    setOffCanvasTaskGroup: (val: offCanvasProps) => void;
    setResetDefaultData: (val: boolean) => void;
    offCanvasTaskGroup: offCanvasProps;
    resetDefaultData: boolean;
    planId: string;
    isCurrentUser: boolean;
    isCreator: boolean;
    planName: string;
    status?: number;
    userData: IUser;
}

function phoneMask(str = ''): string {
    if (!str) return '';
    const cleaned = str.replace(/\D/g, '').slice(0, 10);

    return cleaned.replace(/.?(\d{3}).?.?(\d{3}).?(\d{4})/g, '($1) $2-$3');
}

function getStatusText(num: number): string {
    switch (num) {
        case 2:
            return 'User rejected the invitation';
        case 3:
            return 'Inactive: User left the project';
        default:
            return Statuses[num];
    }
}

function checkStatus(num: number) {
    return num === 0 || num === 3;
}

function emptyUser(email?: string) {
    return {
        emailAddress: email,
        phone: '',
        organization: '',
        projectRole: '',
        permission: '',
        name: '',
        phoneType: '',
        status: 1,
    };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default ({
    selectedUser,
    setOffCanvasTaskGroup,
    setResetDefaultData,
    offCanvasTaskGroup,
    resetDefaultData,
    planId,
    isCurrentUser,
    isCreator,
    planName,
    userData,
    status = 1,
}: IdentifyPeopleFormsProps) => {
    const ref = useRef();
    const [updateTeamMember] = useMutation(qUsers.UPDATE_USER);
    const [reinvteStakeholder] = useMutation(qInvitees.REINVITE);
    const _selectedUser = { ...(selectedUser?.user ? selectedUser.user : emptyUser(selectedUser?.email)) };
    const userPermission = selectedUser?.permission || '';

    const {
        register,
        errors,
        reset,
        handleSubmit,
        control,
        watch,
        getValues,
        setValue,
        formState: { isDirty },
    } = useForm({
        mode: 'onSubmit',
        defaultValues: {
            email: _selectedUser.emailAddress,
            phone: phoneMask(_selectedUser.phone),
            organization: _selectedUser?.organization?.name,
            projectRole: _selectedUser.dutyTitle,
            permission: userPermission,
            name: _selectedUser.name,
            phoneType: _selectedUser.phoneType,
            status: Statuses[status],
        },
    });
    const [title, setTitle] = useState('');
    const [readOnly, setReadOnly] = useState(true);

    const close = () => {
        setReadOnly(true);
        setOffCanvasTaskGroup({ canvas: false, editTaskGroup: false });
        setResetDefaultData(!resetDefaultData);
    };

    const resendInvite = () => {
        reinvteStakeholder({
            refetchQueries: [
                {
                    query: qInvitees.GET_INVITEES_BY_PLAN,
                    variables: { planId: +planId },
                },
            ],
            variables: {
                planId: +planId,
                email: selectedUser.email,
            },
            fetchPolicy: 'no-cache',
        }).then(() => {
            _selectedUser.status = 1;
            toast(<AlertToast severity="success" message="Member reinvited." />);
            close();
        });
    };

    const onSave = (data: OnSaveProps) => {
        updateTeamMember({
            refetchQueries: [
                {
                    query: qUsers.GET_USERS_BY_PLAN,
                    variables: { planId: +planId },
                },
            ],
            variables: {
                id: _selectedUser.id,
                data: {
                    phone: getValues('phone'),
                    phoneType: getValues('phoneType'),
                    dutyTitle: getValues('projectRole'),
                },
            },
            fetchPolicy: 'no-cache',
        }).then(() => {
            _selectedUser.phoneType = data.phoneType;
            _selectedUser.phone = phoneMask(_selectedUser.phone);
            toast(<AlertToast severity="success" message="Member updated." />);
        });

        close();
    };

    const onCancel = () => {
        close();
    };

    useEffect(() => {
        if (!!userData && !!selectedUser) {
            setReadOnly(!(userData?.id === selectedUser?.user?.id));
        }
    }, [userData, selectedUser]);

    useEffect(() => {
        setTitle(isCurrentUser ? 'My Details' : 'Person Details');
    }, [isCurrentUser]);

    useEffect(() => {
        reset({
            email: _selectedUser.emailAddress,
            phone: phoneMask(_selectedUser.phone),
            organization: _selectedUser?.organization?.name,
            projectRole: _selectedUser.dutyTitle,
            permission: userPermission,
            name: _selectedUser.name,
            phoneType: _selectedUser.phoneType,
            status: Statuses[status],
        });
    }, [selectedUser, planId, isCurrentUser, isCreator]);

    return (
        <Offcanvas state={offCanvasTaskGroup.canvas} className="o-indentify-people-forms">
            <form onSubmit={handleSubmit(onSave)} className="o-indentify-people-forms__body">
                <div className="m-forms-header">
                    <h2>{title}</h2>
                    <div>
                        {selectedUser?.permission !== 'Admin' && !isCurrentUser && (
                            <DeletePersonModal
                                leave={isCurrentUser}
                                ref={ref}
                                onDelete={close}
                                user={_selectedUser}
                                planId={planId}
                                status={status}
                            >
                                <Button>
                                    <Icon color="error">delete</Icon>
                                </Button>
                            </DeletePersonModal>
                        )}
                        <IconButton onClick={close}>
                            <Icon>close</Icon>
                        </IconButton>
                    </div>
                </div>

                <div className="m-forms-sections">
                    {(isCurrentUser || checkStatus(status)) && (
                        <div className="a-forms-section __no-border">
                            <CustomInput
                                className={!readOnly ? '-nudge' : ''}
                                displayOnly
                                errors={errors}
                                name="name"
                                inputLabel="Name"
                                inputRef={register()}
                                disabled
                                defaultValue={_selectedUser.name}
                                fullWidth
                            />
                            {!readOnly && <div>Edit name in your Account Settings</div>}
                        </div>
                    )}
                    <div className="m-forms-header__child">
                        <h2>Contact Information</h2>
                    </div>
                    <div className="a-forms-section __no-border">
                        <CustomInput
                            displayOnly
                            className={!readOnly ? '-nudge' : ''}
                            errors={errors}
                            name="email"
                            inputLabel="Email"
                            inputRef={register()}
                            disabled
                            defaultValue={_selectedUser.emailAddress}
                            fullWidth
                        />
                        {!readOnly && <div>Edit email in your Account Settings</div>}
                        {(isCurrentUser || checkStatus(status)) && (
                            <>
                                <CustomInput
                                    displayOnly
                                    errors={errors}
                                    name="phone"
                                    placeholder="Not specified"
                                    inputLabel="Phone Number"
                                    inputRef={register()}
                                    disabled={readOnly}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setValue('phone', phoneMask(e.target.value))
                                    }
                                    defaultValue={phoneMask(_selectedUser.phone)}
                                    fullWidth
                                />
                                {readOnly ? (
                                    <span className="a-phoneType">{PhoneTypes[_selectedUser.phoneType]}</span>
                                ) : (
                                    <CustomSelect
                                        errors={errors}
                                        inputLabel="Type"
                                        name="phoneType"
                                        control={control}
                                        placeholder="Select Type"
                                        defaultValue={_selectedUser.phoneType}
                                        disabled={readOnly}
                                        watch={watch('phoneType')}
                                    >
                                        {new Array(3).fill(0).map((a: number, i: number) => (
                                            <MenuItem key={i} value={i}>
                                                <div className="o-taskform__taskGroupOption">{PhoneTypes[i]}</div>
                                            </MenuItem>
                                        ))}
                                    </CustomSelect>
                                )}
                            </>
                        )}
                    </div>

                    <div className="a-forms-section">
                        {(isCurrentUser || checkStatus(status)) && (
                            <>
                                <CustomInput
                                    displayOnly
                                    errors={errors}
                                    name="organization"
                                    inputLabel="Organization/Company"
                                    inputRef={register()}
                                    disabled
                                    defaultValue={_selectedUser.organization}
                                    fullWidth
                                />
                                <CustomInput
                                    displayOnly
                                    errors={errors}
                                    name="projectRole"
                                    inputLabel="Project Role"
                                    maxLength={30}
                                    placeholder="Not specified"
                                    inputRef={register()}
                                    disabled={readOnly}
                                    showCharacterCount
                                    defaultValue={_selectedUser.dutyTitle}
                                    fullWidth
                                />
                            </>
                        )}
                        <CustomInput
                            displayOnly
                            errors={errors}
                            name="permission"
                            inputLabel="Permission"
                            inputRef={register()}
                            disabled
                            defaultValue={userPermission}
                            fullWidth
                        />
                        <div className="a-entry">
                            <div className="a-entry__label">Status</div>
                            <div className="a-entry__data">
                                {status > 1 && <Icon>error_outline</Icon>}
                                <div>{getStatusText(status)}</div>
                            </div>
                        </div>
                        {!isCurrentUser && status !== 0 && (
                            <Button className="a-resend-button" color="primary" onClick={() => resendInvite()}>
                                {status === 1 || (status === 2 && 'Resend Invitation')}
                                {status === 3 && 'Send Invitation'}
                            </Button>
                        )}
                        {!isCreator && isCurrentUser && (
                            <DeletePersonModal
                                leave={isCurrentUser}
                                ref={ref}
                                onDelete={close}
                                user={_selectedUser}
                                planId={planId}
                                planName={planName}
                            >
                                <Button className="a-resend-button" color="primary" onClick={resendInvite}>
                                    <div style={{ marginBottom: 20 }}>Leave Project</div>
                                </Button>
                            </DeletePersonModal>
                        )}
                    </div>
                </div>
                {!readOnly && (
                    <div className="o-FormButtons">
                        <Button type="button" onClick={onCancel} color="secondary" variant="outlined">
                            Cancel
                        </Button>
                        <ConfirmButton
                            onClick={(e: any) => {
                                onSave(e);
                                close();
                            }}
                            variant="contained"
                            valuesHaveChanged
                            disable={!isDirty}
                            watch={watch}
                        >
                            Save
                        </ConfirmButton>
                    </div>
                )}
            </form>
        </Offcanvas>
    );
};
