/* eslint-disable no-use-before-define */
import React from 'react';
import HoverTooltip from 'components/HoverTooltip';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import './autocompleteDropdown/AutocompleteDropdown.scss';

interface AutocompleteDropdownProps {
    label?: string;
    defaultValue?: string;
    value?: string;
    options?: any;
    dropdownOptions: string[];
    disabled?: boolean;
    toolTip?: React.ReactFragment;
    onChange?: (e: any, value: any) => void;
}

const optionParser = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: string) => option,
});

export default function Filter({
    label,
    defaultValue,
    value,
    dropdownOptions,
    disabled = false,
    toolTip = '',
    onChange,
}: AutocompleteDropdownProps): React.ReactElement {
    return (
        <HoverTooltip disableTooltip={!disabled} message={toolTip}>
            <Autocomplete
                id="autocomplete-dropdown"
                defaultValue={defaultValue}
                value={value}
                options={dropdownOptions}
                getOptionLabel={(option) => option}
                filterOptions={optionParser}
                style={{ width: 340 }}
                disabled={disabled}
                onChange={onChange}
                renderInput={(params) => {
                    // eslint-disable-next-line
                    const { InputLabelProps, ...rest } = params;
                    return (
                        <div className="a-autocompleteDropdown">
                            <label className="a-autocompleteDropdown__label" htmlFor={label}>
                                {label}
                            </label>
                            <TextField InputLabelProps={{ shrink: false }} {...rest} variant="outlined" />
                        </div>
                    );
                }}
            />
        </HoverTooltip>
    );
}
