import toDate from 'date-fns/toDate';
import addBusinessDays from 'date-fns/addBusinessDays';
import differenceInBusinessDays from 'date-fns/differenceInBusinessDays';

const getDurationMinusWeekends = (startDate: Date, endDate: Date): number =>
    differenceInBusinessDays(endDate, startDate) + 1;

const getDateRangeByDuration = (startDate: Date, durationCount: number, isReverse = false): Date => {
    let safeEndDate = toDate(startDate);
    safeEndDate = addBusinessDays(safeEndDate, durationCount - 1);
    return safeEndDate;
};

export { getDurationMinusWeekends, getDateRangeByDuration };
