import React, { ReactElement } from 'react';
import NumberFormat from 'react-number-format';
import { Table, TableHead, TableRow, TableCell, TableBody, Icon } from '@material-ui/core';
import { FundingTableData } from './AlignResourceExpandableRow';

import './tables/Tables.scss';

interface FundingTableProps {
    fundingTableData: FundingTableData[];
}

const FundingTable = ({ fundingTableData }: FundingTableProps): ReactElement => (
    <section className="m-fundingTable">
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="left">Funding</TableCell>
                    <TableCell align="left">Category</TableCell>
                    <TableCell align="left">Amount</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {fundingTableData?.map((resource: FundingTableData) => {
                    const overAllocationAmount = Math.abs(resource?.totalAllocation - (resource?.amount || 0));
                    return (
                        <>
                            <TableRow>
                                <TableCell className="m-fundingTable__semiBold">
                                    <div className="m-fundingTable__label">
                                        {resource.totalAllocation > resource.amount && (
                                            <Icon style={{ color: '#F44336', marginRight: 10 }}>error_outlined</Icon>
                                        )}
                                        <p>{resource.name}</p>
                                    </div>
                                </TableCell>
                                <TableCell className="m-fundingTable__semiBold">
                                    {resource.fundTypeName || 'N/A'}
                                </TableCell>
                                <TableCell>
                                    <div className="o-resourceTable__numberColumn">
                                        <b>$</b>
                                        <NumberFormat
                                            displayType="text"
                                            thousandSeparator
                                            value={resource.totalAllocation}
                                        />
                                    </div>
                                </TableCell>
                            </TableRow>
                            {resource.totalAllocation > resource.amount && (
                                <TableRow>
                                    <TableCell colSpan={3} style={{ backgroundColor: '#FDECEB' }}>
                                        <div className="o-resourceTable__overAllocationInfoRow">
                                            <Icon style={{ color: '#F44336', marginRight: 10 }}>error_outlined</Icon>
                                            <p>{`${resource.name} is over allocated by $ `}</p>
                                            <NumberFormat
                                                displayType="text"
                                                thousandSeparator
                                                value={overAllocationAmount}
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )}
                        </>
                    );
                })}
            </TableBody>
        </Table>
    </section>
);

export default FundingTable;
