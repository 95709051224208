export const getTaskStatusIcon = (status: string): string => {
    switch (status) {
        case 'In Progress':
            return 'schedule';
        case 'Not Started':
            return 'radio_button_unchecked';
        case 'Complete':
            return 'check_circle_outline';
        default:
            return 'radio_button_unchecked';
    }
};

// Custom icons here
export const getResourceStatusIcon = (status: string): string => {
    switch (status) {
        case 'In Progress':
            return 'in-progress';
        case 'Applied':
            return 'applied';
        case 'Approved':
            return 'approved';
        case 'Available':
            return 'available';
        case 'Rejected':
            return 'rejected';
        default:
            return 'not-started';
    }
};
