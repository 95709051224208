import React, { useState } from 'react';
import { Grid, Icon, IconButton } from '@material-ui/core';
import { sortBy } from 'lodash-es';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Store } from 'store/reducers';
import { IUser } from 'typings/User';
import { useQuery } from '@apollo/client';
import { qPlans, qResources } from 'api/queries';
import { GetResources, PlanSummary, PlanSummaryVariables } from 'typings/_graphql';

import Layouts from 'components/layouts/Layouts';
import PageHeading from 'components/PageHeading';
import AutocompleteDropdown from 'components/AutocompleteDropdown';
import { sortByAssignee } from 'components/utilities/sortTaskGroupData';
import Offcanvas from 'components/OffCanvas';
import { IPlanId } from 'typings/Plan';
import ResourceBreakdownTable from './ResourceBreakdownTable';
import Accordion from './justification/Accordion';

import ResourceTable, { TASK_GROUP, ASSIGNEE } from './alignresources/ResourceTable';
import TaskFundingForm from './TaskFundingForm';
import { PlanConsumer, PlanProvider } from './PlanContext';

import './alignresources/AlignResources.scss';

const { Content, Sidebar, Subheader, Navigation } = Layouts;

export default function AlignResources(): React.ReactElement {
    const { planId } = useParams<IPlanId>();
    const [filterType, setFilterType] = useState<string>(TASK_GROUP);
    const userData: IUser = useSelector((storeState: Store) => storeState?.User);

    const { data: { planSummary } = {}, error } = useQuery<PlanSummary, PlanSummaryVariables>(
        qPlans.QUERY_PLAN_SUMMARY,
        {
            variables: { planId: parseFloat(planId) },
            fetchPolicy: 'network-only',
        }
    );

    if (error) throw new Error('Could not fetch project summary');

    const { data: { resources } = {} } = useQuery<GetResources>(qResources.QUERY_RESOURCES, {
        variables: { planId: parseFloat(planId) },
        fetchPolicy: 'network-only',
    });

    return (
        <PlanProvider>
            <PlanConsumer>
                {({ taskGroupData, offCanvasTask, setOffCanvasTask }) => {
                    const taskAssigneeData = filterType === ASSIGNEE ? sortByAssignee(taskGroupData, userData) : null;
                    let tasksCount = 0;
                    taskGroupData?.forEach((taskGroup) => {
                        tasksCount += taskGroup?.tasks?.length || 0;
                    });

                    return (
                        <Layouts className="prevent-col-wrap">
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Sidebar />
                                <Content>
                                    <Navigation />
                                    <Subheader>
                                        <Grid container spacing={2}>
                                            <Grid item xs={9}>
                                                <PageHeading title="Allocate Funding" />
                                            </Grid>
                                        </Grid>
                                    </Subheader>
                                    <Accordion heading="Funding Overview">
                                        <div className="o-alignResources__resourceBreakdownTableContainer">
                                            <ResourceBreakdownTable resourceSummary={planSummary?.resources} />
                                        </div>
                                    </Accordion>
                                    <section className="o-alignResources__filter">
                                        <p>
                                            In this table you will finish estimating your tasks and allocate your
                                            funding.
                                        </p>
                                        <AutocompleteDropdown
                                            label="Sort By"
                                            defaultValue={TASK_GROUP}
                                            dropdownOptions={[TASK_GROUP, ASSIGNEE]}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>, value: string) =>
                                                setFilterType(value)
                                            }
                                        />
                                    </section>
                                    {tasksCount < 1 && <h2>There are currently no tasks in the plan.</h2>}

                                    {tasksCount > 0 &&
                                        filterType === TASK_GROUP &&
                                        sortBy(taskGroupData, [(taskGroup) => taskGroup.id]).map((taskGroup) => (
                                            <ResourceTable
                                                key={taskGroup.id}
                                                tasks={taskGroup.tasks}
                                                taskGroup={taskGroup}
                                                label={taskGroup.name}
                                                filterType={filterType}
                                                resources={resources}
                                            />
                                        ))}
                                    {tasksCount > 0 &&
                                        filterType === ASSIGNEE &&
                                        taskAssigneeData?.map((assignee) => (
                                            <ResourceTable
                                                key={assignee.name}
                                                tasks={assignee.tasks}
                                                label={assignee.name}
                                                filterType={filterType}
                                                resources={resources}
                                            />
                                        ))}
                                    <Offcanvas state={offCanvasTask.canvas}>
                                        <IconButton
                                            onClick={() => {
                                                setOffCanvasTask({ canvas: false, editFundingTask: false });
                                            }}
                                            className="close-icon"
                                        >
                                            <div className="o-alignResources__closeIcon">
                                                <Icon>close</Icon>
                                            </div>
                                        </IconButton>
                                        <TaskFundingForm />
                                    </Offcanvas>
                                </Content>
                            </div>
                        </Layouts>
                    );
                }}
            </PlanConsumer>
        </PlanProvider>
    );
}
