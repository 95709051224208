/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react';
import {
    GetTaskGroups,
    GetTaskGroupsTaskGroupsByPlanIdTasks,
    GetTaskGroupsTaskGroupsByPlanId,
    GetFundTypes,
    GetFundTypesFundTypes,
    GetTaskGroupTaskGroup,
    GetTasksByPlanIdFundTypeAndUserGetTasksByPlanIdFundTypeAndUser,
} from 'typings/_graphql';
import { useQuery } from '@apollo/client';
import { qTaskGroups, qFundTypes } from 'api/queries';
import { useLocation, useParams } from 'react-router-dom';
import { isEmpty, isEqual, filter, get } from 'lodash-es';

import { IUser } from 'typings/User';
import { Store } from 'store/reducers';
import { useSelector } from 'react-redux';

export interface offCanvasProps {
    canvas: boolean;
    editTaskGroup?: GetTaskGroupsTaskGroupsByPlanId | false;
    editTask?: GetTaskGroupsTaskGroupsByPlanIdTasks | false;
    editFundingTask?: GetTasksByPlanIdFundTypeAndUserGetTasksByPlanIdFundTypeAndUser | false;
    readonly?: boolean;
    taskGroupId?: number;
}

export interface PlanContextProps {
    offCanvasTaskGroup: offCanvasProps;
    offCanvasTask: offCanvasProps;
    setOffCanvasTaskGroup: (val: offCanvasProps) => void;
    setOffCanvasTask: (val: offCanvasProps) => void;
    getTaskGroupById: (id: number) => GetTaskGroupsTaskGroupsByPlanId | false;
    taskGroupData: readonly GetTaskGroupsTaskGroupsByPlanId[];
    fundTypes: readonly GetFundTypesFundTypes[];
    activeTaskGroupTask: number;
    updateCallBack: (id: number) => void;
    addTaskCallBack: (taskGroupId: number) => void;
    planId: number;
    hasTaskGroups: boolean;
    taskGroupList: TaskGroupListProps[];
    allTaskGroupsHaveTasks: boolean;
    allTasksAreEstimated: boolean;
    taskGroupDetails?: GetTaskGroupTaskGroup | null;
    selectedTaskIds: number[];
    setSelectedTaskIds: (taskIds: number[]) => void;
}

interface TaskGroupListProps {
    id: number;
    name: string;
    description: string | null;
    tasksEstimated: number;
    totalTaskGroupEstimate: number;
    totalMyTaskGroupEstimate: number;
    color: string;
}

export const PlanContext = React.createContext<PlanContextProps>({
    offCanvasTaskGroup: { canvas: false, editTaskGroup: false },
    offCanvasTask: { canvas: false, editTask: false, taskGroupId: 0, editFundingTask: false },
    taskGroupData: [],
    fundTypes: [],
    activeTaskGroupTask: 0,
    getTaskGroupById: () => false,
    setOffCanvasTaskGroup: () => {},
    setOffCanvasTask: () => {},
    updateCallBack: () => {},
    addTaskCallBack: () => {},
    planId: 0,
    hasTaskGroups: false,
    taskGroupList: [],
    allTaskGroupsHaveTasks: false,
    allTasksAreEstimated: false,
    taskGroupDetails: null,
    selectedTaskIds: [],
    setSelectedTaskIds: () => {},
});

export const PlanConsumer = PlanContext.Consumer;

export const PlanProvider = (props: any): React.ReactElement => {
    const [offCanvasTaskGroup, setOffCanvasTaskGroup] = useState<offCanvasProps>({
        canvas: false,
        editTaskGroup: false,
    });
    const [offCanvasTask, setOffCanvasTask] = useState<offCanvasProps>({
        canvas: false,
        editTask: false,
        taskGroupId: 0,
        editFundingTask: false,
    });

    const [activeTaskGroupTask, setActiveTaskGroupTask] = useState(0);
    const [taskGroupDetails, setTaskGroupDetails] = useState<GetTaskGroupTaskGroup | null | undefined>(null);
    const [allTaskGroupsHaveTasks, setAllTaskGroupsHaveTasks] = useState(false);
    const [allTasksAreEstimated, setAllTasksAreEstimated] = useState(false);
    const planId = parseFloat(useParams<{ planId: string }>().planId);
    const [taskGroupList, setTaskGroupList] = useState<TaskGroupListProps[]>([]);
    const userData: IUser = useSelector((storeState: Store) => storeState?.User);
    const [selectedTaskIds, setSelectedTaskIds] = useState<number[]>([]);
    const location = useLocation();

    const {
        data: taskGroupData,
        startPolling,
        stopPolling,
    } = useQuery<GetTaskGroups>(qTaskGroups.QUERY_TASK_GROUPS_BY_PLAN, {
        variables: { id: planId },
    });

    const { data: fundTypes } = useQuery<GetFundTypes>(qFundTypes.QUERY_ALL);

    useEffect(() => {
        const currentTaskGroup = taskGroupData?.taskGroupsByPlanId.find(
            (taskGroup) => taskGroup.id === offCanvasTask.taskGroupId
        );
        setTaskGroupDetails(currentTaskGroup);
    }, [offCanvasTask?.taskGroupId]);

    useEffect(() => {
        startPolling(1000);
        return () => {
            stopPolling();
        };
    }, []);

    useEffect(() => {
        if (taskGroupData && !isEmpty(taskGroupData?.taskGroupsByPlanId)) {
            let newAllTaskGroupsHaveTasks = true;
            let allTasksHaveEstimates = true;

            const newTaskGroupList = taskGroupData.taskGroupsByPlanId.map((item) => {
                const tasksEstimated = filter(
                    item.tasks,
                    (o) => o.estimate !== null && o.estimate !== 0 && o.fundType !== null
                ).length;

                let totalTaskGroupEstimate = 0;
                let totalMyTaskGroupEstimate = 0;

                if (item.tasks) {
                    if (item.tasks.length < 1) {
                        newAllTaskGroupsHaveTasks = false;
                    }

                    for (let i = 0; i < item?.tasks.length; i++) {
                        if (!item.tasks[i]?.estimate) allTasksHaveEstimates = false;
                        if (item.tasks[i]?.fundType === null) allTasksHaveEstimates = false;
                        totalTaskGroupEstimate += item.tasks[i]?.estimate || 0;
                        if (get(item, `tasks[${i}].users[0].id`, null) === userData.id) {
                            totalMyTaskGroupEstimate += item.tasks[i]?.estimate || 0;
                        }
                    }
                }

                return {
                    id: item.id,
                    name: item.name,
                    color: item.color,
                    description: item.description,
                    tasksEstimated,
                    totalTaskGroupEstimate,
                    totalMyTaskGroupEstimate,
                };
            });
            setAllTaskGroupsHaveTasks(newAllTaskGroupsHaveTasks);
            setAllTasksAreEstimated(allTasksHaveEstimates);
            if (!isEqual(newTaskGroupList, taskGroupList)) {
                setTaskGroupList(newTaskGroupList);
            }
        }
    }, [taskGroupData]);

    useEffect(() => {
        setOffCanvasTask({
            canvas: false,
            editTask: false,
            taskGroupId: 0,
            editFundingTask: false,
        });
    }, [location.pathname]);

    const updateCallBack = (id: number) => {
        if (!taskGroupData || taskGroupData.taskGroupsByPlanId?.length < 1) {
            return;
        }
        const taskGroupToEdit = taskGroupData.taskGroupsByPlanId.find((e) => e.id === id);
        setOffCanvasTaskGroup({ canvas: true, editTaskGroup: taskGroupToEdit || false });
    };

    const addTaskCallBack = (id: number) => {
        setActiveTaskGroupTask(id);
        if (id === 0) setOffCanvasTask({ canvas: true, editTask: false, taskGroupId: 0, editFundingTask: false });
        else setOffCanvasTask({ canvas: true, editTask: false, taskGroupId: id, editFundingTask: false });
    };

    const getTaskGroupById = (id: number) => {
        if (!taskGroupData || taskGroupData.taskGroupsByPlanId?.length < 1) {
            return false;
        }
        const taskGroup = taskGroupData.taskGroupsByPlanId.find((e) => e.id === id);
        if (taskGroup) {
            return taskGroup;
        }
        return false;
    };

    const hasTaskGroups = taskGroupData?.taskGroupsByPlanId.length === 0;

    return (
        <PlanContext.Provider
            value={{
                updateCallBack,
                addTaskCallBack,
                setOffCanvasTaskGroup,
                setOffCanvasTask,
                getTaskGroupById,
                offCanvasTaskGroup,
                offCanvasTask,
                activeTaskGroupTask,
                taskGroupData: taskGroupData?.taskGroupsByPlanId || [],
                fundTypes: fundTypes?.fundTypes || [],
                planId,
                hasTaskGroups,
                taskGroupList,
                allTaskGroupsHaveTasks,
                allTasksAreEstimated,
                taskGroupDetails,
                selectedTaskIds,
                setSelectedTaskIds,
            }}
        >
            {props.children}
        </PlanContext.Provider>
    );
};

export default PlanContext;
