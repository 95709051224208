import React, { ReactElement } from 'react';
import { Button } from '@material-ui/core';

import Permission from 'components/Permission';
import ConfirmButton from 'components/ConfirmButton';

import './formButton/FormButton.scss';

interface FormButtonsProps {
    readOnly: boolean;
    isDisabled?: boolean;
    isNotEditMode: boolean;
    areValuesChanged: boolean;
    watch?: any;
    onEditHandler: () => void;
    onCancelHandler: () => void;
    onSubmitHandler?: (e: any) => void;
    setIsCanvasClosed: (val: boolean) => void;
    scrollToTop?: () => void;
}

const FormButtons = ({
    readOnly,
    isDisabled,
    isNotEditMode,
    areValuesChanged,
    watch,
    onEditHandler,
    onCancelHandler,
    onSubmitHandler,
    setIsCanvasClosed,
    scrollToTop,
}: FormButtonsProps): ReactElement => (
    <Permission
        auth={`${readOnly} === false`}
        showFail={
            <div className="o-FormButtons">
                <ConfirmButton color="primary" variant="outlined" onClick={onEditHandler}>
                    Edit
                </ConfirmButton>
            </div>
        }
    >
        <div className="o-FormButtons">
            <Button type="button" onClick={onCancelHandler} color="secondary" variant="outlined">
                Cancel
            </Button>
            <ConfirmButton
                onClick={(e) => {
                    setIsCanvasClosed(false);
                    if (onSubmitHandler) onSubmitHandler(e);
                }}
                variant="contained"
                valuesHaveChanged={areValuesChanged}
                disable={isDisabled}
                watch={watch}
            >
                {isNotEditMode ? 'Add' : 'Save'}
            </ConfirmButton>
            {isNotEditMode && (
                <ConfirmButton
                    onClick={(e) => {
                        setIsCanvasClosed(true);
                        if (onSubmitHandler) onSubmitHandler(e);
                        if (scrollToTop) scrollToTop();
                    }}
                    color="secondary"
                    variant="text"
                    valuesHaveChanged={areValuesChanged}
                    disable={isDisabled}
                    watch={watch}
                >
                    Add &amp; Create Another
                </ConfirmButton>
            )}
        </div>
    </Permission>
);

export default FormButtons;
