import React, { ReactElement, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { SubMenuProps } from './SubMenu';

export interface MenuItemProps extends React.LiHTMLAttributes<HTMLLIElement> {
    link?: string;
}

function splitSubMenuFromChildren(children: React.ReactNode) {
    const display: React.ReactNode[] = [];
    const subMenu: SubMenuProps[] = [];
    if (children) {
        React.Children.map(children, (child: any) => {
            if (child?.type?.name === 'SubMenu') {
                subMenu.push(child);
            } else {
                display.push(child);
            }
        });
        return { display, subMenu };
    }
    return { display: [], subMenu: [] };
}

const Item = ({ link = '', ...shared }: MenuItemProps): ReactElement => {
    const WithLink = useCallback(
        ({ children }: React.PropsWithChildren<React.ReactNode>) =>
            !!link ? <Link to={link}>{children}</Link> : <>{children}</>,
        [link]
    );
    const splitChildren = splitSubMenuFromChildren(shared.children);
    const className = React.Children.count(splitChildren.subMenu) ? 'hasSubMenu' : '';

    return (
        <li className={`${shared.className || ''} ${className}`} {...shared}>
            <WithLink>{splitChildren.display}</WithLink>
            {splitChildren.subMenu}
        </li>
    );
};

export default Item;
