import React from 'react';
import { Icon } from '@material-ui/core';
import find from 'lodash-es/find';
import ganttState from '../ganttState';
import Enums from '../utils/enums';

export interface TaskModel {
    start: string;
    end: string;
    title: string;
    assignee?: string;
    status: number;
    progress?: string;
    id: string;
    parents: string[];
    taskGroupId?: string;
    index?: number;
}

export interface TaskGroupDataModel {
    id: string;
    title: string;
    description?: string;
    children: TaskModel[];
    color?: string;
    index?: number;
}

export interface DataProps {
    getNewDataModel: (key: string | number) => TaskGroupDataModel[];
    getTabularizeTaskGroupDataModelArr: () => [];
}

const getTabularizeTaskGroupDataModelArr = (): any => {
    const { tasks } = ganttState.Gantt;
    const out: any = [];
    // Switching from a Date String to a number reduces memory consumption by 60%
    const formatDigit = (date: string) => new Date(date).getTime();
    tasks.forEach((task: TaskModel) =>
        out.push([
            task.id,
            task.taskGroupId,
            task.assignee,
            formatDigit(task.start),
            formatDigit(task.end),
            task.progress,
        ])
    );
    return out;
};

export const getNewDataModel = (key: string | number): TaskGroupDataModel[] => {
    const { tabularData, tasks, taskGroups } = ganttState.Gantt;
    const out: { [index: string]: any } = {};
    const k = ganttState.Gantt.columnIndexObj[key];
    let cnt = 0;

    if (tabularData === undefined || tabularData.length === 0) return [];
    /*
        Grab unique items by which we'll group crap.
    */
    const uniqueKeys = tabularData.reduce(
        (a: (string | number)[], b: (string | number)[]) => (!a.includes(b[k]) ? [...a, b[k]] : a),
        [tabularData[0][k]]
    );

    const getId = (uniqueKey: string | number) =>
        key === 'start' || key === 'end' ? new Date(uniqueKey).toLocaleDateString() : uniqueKey;

    const getTitle = (uniqueKey: string | number) => {
        switch (key) {
            case 'start':
            case 'end':
                return new Date(uniqueKey).toLocaleDateString();
            case 'taskId':
                return tasks.filter((t: TaskModel) => t.id === uniqueKey)[0]?.title;
            case 'taskGroupId':
                return taskGroups.filter((g: TaskGroupDataModel) => g.id === uniqueKey)[0]?.title;
            case 'progress':
                return (
                    <div className="hor-align-left">
                        {uniqueKey}
                        <Icon fontSize="small">{Enums.StatusCodesIcons[uniqueKey]}</Icon>
                    </div>
                );
            case 'assignee':
            default:
                return uniqueKey;
        }
    };

    const getChildren = () => {
        if (key === 'taskGroupId') {
            return taskGroups.filter((g: TaskGroupDataModel) => g.id === k)[0];
        }
        return {};
    };

    uniqueKeys.forEach((uniqueKey: any) => {
        out[uniqueKey] = {
            children: [],
            ...getChildren(),
            id: getId(uniqueKey),
            title: getTitle(uniqueKey),
        };
    });

    tabularData.forEach((tabularDataRow: (string | number)[]) => {
        out[tabularDataRow[k]].isOpen = true;
        out[tabularDataRow[k]].color = find(taskGroups, { id: Number(tabularDataRow[k]) })?.color;
        out[tabularDataRow[k]].index = cnt++;
        out[tabularDataRow[k]].children = tasks.filter((t: TaskModel) => tabularDataRow[k] === { ...t }[key]);
        console.log(
            'data_TabularData',
            { tabularDataRow },
            Number(tabularDataRow[k]),
            { out },
            { taskGroups },
            taskGroups[Number(tabularDataRow[k]) - 1]?.color
        );
    });

    return Object.values(out);
};

export default { getNewDataModel, getTabularizeTaskGroupDataModelArr };
