import React, { useCallback } from 'react';
import { Icon, Button } from '@material-ui/core';
// import TScoutLogo from 'assets/images/TechScout-Favi.png';
// import SNextLogo from 'assets/images/SBIRNext-Favi.png';
import { IUser } from 'typings/User';
import { isEmpty } from 'lodash-es';
import { kcLogout } from '../../providers/Keycloak';
import SubMenu from './menu/SubMenu';
import Item from './menu/Item';

const ProfileMenu = (user: IUser): React.ReactElement => {
    const { emailAddress, phone, name, orgSymbol } = user;
    const Exists = useCallback(
        (value: any): React.ReactElement => (!isEmpty(value.value) ? <>{value.children}</> : <></>),
        [emailAddress, phone, name, orgSymbol]
    );

    return (
        <SubMenu className="m-menu__subMenuList m-profileMenu">
            <Item className="m-menu__subMenuItem m-profileMenu__details">
                <Exists value={name}>
                    <p className="m-profileMenu__name">{name}</p>
                </Exists>
                <Exists value={orgSymbol}>
                    <p className="m-profileMenu__company">{orgSymbol}</p>
                </Exists>

                <Exists value={emailAddress}>
                    <p className="m-profileMenu__email">
                        <Icon>email</Icon>
                        {emailAddress}
                    </p>
                </Exists>
                {/* <Exists value={phone}>
                    <p className="m-profileMenu__phone">
                        <Icon>phone</Icon>
                        {phone}
                    </p>
                </Exists> */}
            </Item>
            {/* <Item className="m-menu__subMenuItem" link="/">
                <span className="m-profileMenu__techScout">
                    <img alt="tech scout logo" src={TScoutLogo} />
                </span>
                TechScout
            </Item>
            <Item className="m-menu__subMenuItem" link="/">
                <span className="m-profileMenu__sbirNext">
                    <img alt="sbir next logo" src={SNextLogo} />
                </span>
                SBIRNext
            </Item>
            <Item className="m-menu__subMenuItem" link="/">
                Account Settings
            </Item> */}
            <Button className="m-menu__subMenuItem -logout" onClick={kcLogout}>
                Log Out
            </Button>
        </SubMenu>
    );
};
export default ProfileMenu;
