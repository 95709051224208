import { gql } from 'apollo-boost';

const QUERY_ALL = gql`
    query getFundTypes {
        fundTypes {
            id
            name
        }
    }
`;

const QUERY_WITH_ESTIMATE = gql`
    query getFundTypesWithEstimates($planId: Float!, $fundTypeName: [String!]) {
        getFundTypesWithEstimates(planId: $planId, fundTypeName: $fundTypeName) {
            id
            name
            estimate
        }
    }
`;

export { QUERY_ALL, QUERY_WITH_ESTIMATE };
