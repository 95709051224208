import React from 'react';
import { PlanSummaryPlanSummaryScopeScopeRoles as ScopeRoles } from 'typings/_graphql';
import Stakeholder from './stakeholderlist/Stakeholder';

import './stakeholderlist/StakeholderList.scss';

interface StakeholderListProps {
    stakeHolders?: readonly ScopeRoles[];
}

const StakeholderList = ({ stakeHolders }: StakeholderListProps): React.ReactElement => (
    <ul className="o-section m-stakeholder">
        {stakeHolders?.map(({ users }) => (
            <Stakeholder key={users.emailAddress} user={users} />
        ))}
    </ul>
);

export default StakeholderList;
