import format from 'date-fns/format';
import isDate from 'date-fns/isDate';

export default function getDateFormat(timestamp: number | string | Date | undefined, form = ''): string {
    const timestampOut =
        typeof timestamp === 'number' || typeof timestamp === 'string' ? new Date(timestamp) : timestamp;
    if (timestampOut === undefined || timestampOut === null) {
        return '';
    }
    if (!isDate(timestampOut)) {
        return `${timestamp}`;
    }
    if (form) {
        return format(timestampOut, form);
    }
    return format(timestampOut, 'd MMM uuuu');
}
