import React, { useContext } from 'react';
import { Icon, Button } from '@material-ui/core';
import { find } from 'lodash-es';

import { removeItemProp } from 'pages/plans/TaskForm';
import Permission from 'components/Permission';
import { PlanContext } from 'pages/plans/PlanContext';
import { SearchTaskNameByPlanSearchTaskNameByPlan } from 'typings/_graphql';

interface Props {
    items: SearchTaskNameByPlanSearchTaskNameByPlan[];
    message?: string;
    type: 'parent' | 'child';
    removeTask: ({ type, taskId }: removeItemProp) => void;
    readOnly?: boolean;
    dependencyArrayLength?: number;
}

const TaskDependencyList = ({
    items,
    message,
    type = 'parent',
    dependencyArrayLength = 0,
    removeTask,
    readOnly = false,
}: Props): React.ReactElement => {
    const { setOffCanvasTask, taskGroupData } = useContext(PlanContext);
    const switchToNewTask = (taskId: number, taskGroupId: number) => {
        const taskGroup = find(taskGroupData, (o) => o.id === taskGroupId);
        const task = find(taskGroup?.tasks, (o) => o.id === taskId);
        setOffCanvasTask({ canvas: true, editTask: task, taskGroupId });
    };

    if (items.length < 1) {
        return (
            <Permission
                log
                auth={`${readOnly} === false`}
                showFail={
                    type === 'parent' && dependencyArrayLength < 1 ? (
                        <div className="o-dependencies__tasklist">
                            <p>No dependencies </p>
                        </div>
                    ) : (
                        ''
                    )
                }
            />
        );
    }

    return (
        <>
            {items.length > 0 && (
                <div className="o-dependencies__taskListContainer">
                    <ul className="o-dependencies__tasklist">
                        {items.map((item) => (
                            <li key={item.id}>
                                <Permission
                                    log
                                    auth={`${readOnly} === false`}
                                    showFail={
                                        <div>
                                            <Button
                                                onClick={() => switchToNewTask(item.id, item.taskGroup.id)}
                                                key={`perm_${item.id}`}
                                            >
                                                <span className="o-dependencies__dependencyNames">{item.name}</span>
                                            </Button>
                                        </div>
                                    }
                                >
                                    <Icon
                                        className="o-dependencies__closeIcon"
                                        key={`notperm_${item.id}`}
                                        onClick={() => removeTask({ type, taskId: item.id })}
                                    >
                                        close
                                    </Icon>
                                    {item.name}
                                </Permission>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
};

export default TaskDependencyList;
