import React, { useState, ReactElement, useContext, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import { Icon, TableCell, TableRow, Chip, IconButton, Button } from '@material-ui/core';
import { useMutation } from '@apollo/client';

import UserAvatar from 'components/UserAvatar';
import AlertToast from 'components/AlertToast';
import PlanContext from 'pages/plans/PlanContext';

import {
    GetResourcesResources,
    DeleteTaskResourcesVariables,
    GetTaskGroupsTaskGroupsByPlanIdTasksTaskResourceAssoc as TaskResourceAssocType,
    GetTasksByPlanIdFundTypeAndUserGetTasksByPlanIdFundTypeAndUserTaskResourceAssoc as TaskResourceAssocTypeByUser,
} from 'typings/_graphql';
import { qTaskResourcesAssoc, qResources } from 'api/queries';
import CustomFundingChip from './customFundingChip/CustomFundingChip';

import { ASSIGNEE } from '../ResourceTable';
import AlignResourceExpandableRow, { SummaryTableData } from './expandablerow/AlignResourceExpandableRow';

interface AlignResourceListItemProps {
    task: any;
    filterType: string;
    resources?: readonly GetResourcesResources[];
}

const PARTLY_ALLOCATED = 'partly_allocated';
const FULLY_ALLOCATED = 'fully_allocated';
export const OVER_ALLOCATED = 'over_allocated';

const AlignResourceListItem = ({ task, filterType, resources }: AlignResourceListItemProps): ReactElement => {
    const { setOffCanvasTask, planId } = useContext(PlanContext);
    const [open, setOpen] = useState(false);
    const [allocationStatus, setAllocationStatus] = useState<string>(PARTLY_ALLOCATED);
    const [totalAllocated, setTotalAllocated] = useState<number>(0);

    useEffect(() => {
        let totalAllocation = 0;
        const resourcesOverAllocated: number[] = [];
        task?.taskResourceAssoc?.forEach((tr: TaskResourceAssocType | TaskResourceAssocTypeByUser) => {
            totalAllocation += tr?.taskAmount || 0;
            if (tr?.taskAmount > (tr?.resource?.amount || 0)) resourcesOverAllocated.push(tr.id);
        });

        let status = PARTLY_ALLOCATED;
        if (totalAllocation > (task?.estimate || 0) || resourcesOverAllocated.length > 0) status = OVER_ALLOCATED;
        else if (totalAllocation === task.estimate) status = FULLY_ALLOCATED;

        setTotalAllocated(totalAllocation);
        setAllocationStatus(status);
    }, [task]);

    const [deleteTaskResources] = useMutation<DeleteTaskResourcesVariables>(qTaskResourcesAssoc.DELETE_TASK_RESOURCES);

    const summaryTableData: SummaryTableData = {
        estimate: task.estimate || 0,
        totalAllocated,
    };

    const fundingTableData = task?.taskResourceAssoc?.map((tr: any) => {
        const foundResource: GetResourcesResources | undefined = resources?.find(
            (res: GetResourcesResources) => res.id === tr.resource.id
        );
        return {
            name: tr.resource?.name,
            fundTypeName: tr.resource?.fundType?.name || null,
            amount: foundResource?.amount,
            totalAllocation:
                foundResource?.taskResourceAssoc?.reduce(
                    (accumulator: number, currentValue: any) => accumulator + (currentValue?.taskAmount || 0),
                    0
                ) || 0,
        };
    });

    const readOnlyHandler = () => {
        setOffCanvasTask({ readonly: true, canvas: true, editFundingTask: task });
    };

    const editHandler = () => {
        setOffCanvasTask({ readonly: false, canvas: true, editFundingTask: task });
    };

    const onDeleteHandler = async (taskResource: any) => {
        deleteTaskResources({
            refetchQueries: [
                {
                    query: qResources.QUERY_RESOURCES,
                    variables: {
                        planId,
                    },
                },
            ],
            variables: { taskResourceIds: [taskResource.id] },
        })
            .then(() => {
                toast(<AlertToast severity="success" message={'Successfully removed resource from task.'} />);
            })
            .catch((e) => {
                toast(<AlertToast severity="error" message={`Error removing resource from task: ${e.message} `} />);
            });
    };

    const addButton = (
        <Button color="secondary" onClick={editHandler}>
            <Icon>add_circle_outline</Icon>&nbsp;&nbsp;
            <span>Add</span>
        </Button>
    );

    const getIconPerTaskStatus = () => {
        switch (allocationStatus) {
            case OVER_ALLOCATED:
                return 'error_outlined';
            case FULLY_ALLOCATED:
                return 'check_circle';
            default:
                return 'check_circle_outlined';
        }
    };

    const bkgColor = allocationStatus === OVER_ALLOCATED ? '#FDECEB' : '';

    return (
        <>
            <TableRow className="o-resourceTable__tableRow" key={task.id}>
                <TableCell colSpan={1} component="th" scope="row" align="left">
                    <button className="o-resourceTable__taskName" onClick={readOnlyHandler}>
                        <Icon
                            className={`o-resourceTable__taskAlignedIcon
                                ${allocationStatus === PARTLY_ALLOCATED && 'notAligned'}
                                ${allocationStatus === OVER_ALLOCATED && 'overAligned'}`}
                        >
                            {getIconPerTaskStatus()}
                        </Icon>
                        {task.name}
                    </button>
                </TableCell>
                <TableCell colSpan={1} align="center">
                    {filterType === ASSIGNEE ? (
                        <Chip
                            label={task?.taskGroup?.name}
                            style={{ backgroundColor: task?.taskGroup?.color, fontWeight: 'bold' }}
                        />
                    ) : (
                        <UserAvatar name={(task && task.users && task?.users[0].name) || undefined} />
                    )}
                </TableCell>
                <TableCell colSpan={1} align="right">
                    <div className="o-resourceTable__numberColumn">
                        <b>$</b>
                        <NumberFormat displayType="text" thousandSeparator value={task.estimate} />
                    </div>
                </TableCell>
                <TableCell colSpan={1} align="center">
                    {task?.fundType?.name ? task?.fundType?.name : addButton}
                </TableCell>
                <TableCell colSpan={1} align="center">
                    {task?.taskResourceAssoc?.length > 0 ? (
                        <CustomFundingChip
                            data={task?.taskResourceAssoc}
                            onDeleteHandler={onDeleteHandler}
                            onEditHandler={editHandler}
                        />
                    ) : (
                        addButton
                    )}
                </TableCell>
                <TableCell colSpan={1} align="center" style={{ backgroundColor: bkgColor }}>
                    <div
                        className={`o-resourceTable__numberColumn ${
                            allocationStatus === OVER_ALLOCATED && 'overAligned'
                        }`}
                    >
                        <b>$</b>
                        <NumberFormat displayType="text" thousandSeparator value={totalAllocated} />
                    </div>
                </TableCell>
                <TableCell style={{ backgroundColor: bkgColor }}>
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        <Icon fontSize="large" color="secondary">
                            {open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                        </Icon>
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <AlignResourceExpandableRow
                        open={open}
                        summaryTableData={summaryTableData}
                        fundingTableData={fundingTableData}
                        allocationStatus={allocationStatus}
                    />
                </TableCell>
            </TableRow>
        </>
    );
};

export default AlignResourceListItem;
