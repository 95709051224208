import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { isEmpty, has } from 'lodash-es';

interface ConfirmButtonProps extends React.PropsWithChildren<any> {
    form?: string;
    onClick?: (e: React.SyntheticEvent) => void;
    type?: 'submit' | 'button';
    valuesHaveChanged?: boolean;
    watch?: any;
    color?: 'primary' | 'secondary';
    variant?: 'outlined' | 'contained' | 'text';
    ignore?: string[];
    errors?: any;
    disable?: boolean;
}

export default function ConfirmButton({
    children,
    form,
    onClick,
    type = 'submit',
    valuesHaveChanged = true,
    watch,
    color,
    variant,
    disable,
    errors = {},
    ...rest
}: ConfirmButtonProps): React.ReactElement {
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (watch) {
            let isDisabled = false;
            for (const [key, value] of Object.entries(watch)) {
                if (has(errors, `${key}`)) {
                    isDisabled = true;
                }
                if (isEmpty(value)) {
                    isDisabled = true;
                }
            }
            setDisabled(isDisabled);
        } else {
            setDisabled(false);
        }

        if (!valuesHaveChanged) {
            setDisabled(true);
        }
    }, [watch, valuesHaveChanged]);

    return (
        <Button
            disabled={disabled || disable}
            form={form}
            onClick={onClick}
            type={type}
            color={color || 'primary'}
            variant={variant || 'contained'}
            {...rest}
        >
            {children}
        </Button>
    );
}
