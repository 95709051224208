import { combineReducers } from 'redux';
import PlanStep, { State as PlanStepState } from './PlanStep';
import Modal, { State as ModalState } from './Modal';
import Header, { State as HeaderState } from './Header';
import Plan, { State as PlanState } from './Plan';
import User, { State as UserState } from './User';

export interface Store {
    Modal: ModalState;
    Header: HeaderState;
    Plan: PlanState;
    User: UserState;
    PlanStep: PlanStepState;
}

export default combineReducers({ Modal, Header, Plan, User, PlanStep });
