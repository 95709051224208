import React, { ReactElement } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { FormControl, InputLabel, StandardTextFieldProps, FormHelperText, TextField } from '@material-ui/core';
import { Controller, Control, DeepMap, FieldError } from 'react-hook-form';

import './custominput/CustomInput.scss';

interface CustomNumberInputProps extends StandardTextFieldProps {
    inputLabel?: string;
    control: Control;
    name: string;
    errormessage?: string;
    errors?: DeepMap<Record<string, any>, FieldError>;
    required?: boolean;
    fullWidth?: boolean;
    defaultValue?: string;
    helperText?: string;
    customError?: boolean;
}

const CustomNumberInput = ({
    inputLabel,
    control,
    name,
    errormessage,
    errors,
    required,
    placeholder,
    fullWidth,
    defaultValue,
    helperText,
    customError,
}: CustomNumberInputProps): ReactElement => {
    const flagError = (errors && errors[name]) || customError;

    return (
        <section>
            <FormControl fullWidth={fullWidth}>
                {inputLabel && (
                    <div className="o-customInput__inputLabel" data-testid="input-label">
                        <InputLabel htmlFor={name} error={!!flagError}>
                            {required && '*'}
                            {inputLabel}
                        </InputLabel>
                    </div>
                )}
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
                <div className="o-customInput__amount">
                    <span>$</span>
                    <Controller
                        control={control}
                        name={name}
                        defaultValue={defaultValue}
                        rules={{ required }}
                        render={({ onChange, value, ref }) => {
                            let val;

                            if (value || value === 0) {
                                val = typeof value === 'number' ? value : value?.value;
                            } else {
                                val = '';
                            }

                            return (
                                <NumberFormat
                                    error={!!flagError}
                                    defaultValue={val}
                                    value={val}
                                    getInputRef={ref}
                                    onValueChange={(v: NumberFormatValues) => {
                                        if (v.floatValue === undefined) {
                                            const result = {
                                                floatValue: 0,
                                                formattedValue: '',
                                                value: '',
                                            };
                                            onChange(result);
                                        } else {
                                            onChange(v);
                                        }
                                    }}
                                    placeholder={placeholder}
                                    thousandSeparator
                                    isNumericString
                                    customInput={TextField}
                                    variant="outlined"
                                    allowEmptyFormatting
                                    allowNegative={false}
                                    allowLeadingZeros={false}
                                />
                            );
                        }}
                    />
                </div>
                {flagError && <FormHelperText error>{errormessage}</FormHelperText>}
            </FormControl>
        </section>
    );
};

export default CustomNumberInput;
