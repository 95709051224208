export interface AnswerKeyProps {
    [key: string]: {
        inputText: string;
        questionNum: number;
    };
}
export interface QuestionAnswerProps {
    [key: string]: AnswerKeyProps;
}
export interface EnvironmentInputProps {
    title: string | undefined;
    inputText: string;
}

export interface ReadinessResultsProps {
    trlScore?: number;
    trlDefinition?: string;
    mrlScore?: number;
    mrlDefinition?: string;
    dateCompleted?: Date | null;
    currentActive?: string;
    trlQuestionsAnswered?: QuestionAnswerProps;
    mrlQuestionsAnswered?: QuestionAnswerProps;
    trlNextSteps?: string[];
    mrlNextSteps?: string[];
    environmentInputs?: EnvironmentInputProps[];
}

export interface pdfInfoProps {
    firstName: string;
    lastName: string;
    companyName: string;
    technologyName: string;
}

// Pages
export const WELCOME = 'welcome';
export const QUESTIONS = 'questions';
export const RESULTS = 'results';

// Modal messages
export const assessmentRestartMsg = 'If you restart the assessment, all of the progress you have made will be lost.';
export const resultsRestartMsg =
    "If you restart the assessment, all of the progress you have made will be lost. Make sure you download your results before you continue if you'd like to keep them.";

// Homepage custom text
export const homepageText = {
    title: 'There is currently no record of an assessment for this technology.',
    text: 'To get started, click the "Start Assessment" button.',
};
