import React, { ReactElement } from 'react';
import ClassNameFactory from 'components/utilities/ClassNameFactory';
import Item, { MenuItemProps } from './Item';
import SubMenu, { SubMenuProps } from './SubMenu';
import './Menu.scss';

interface MenuProps {
    className?: string;
    direction?: 'horizontal' | 'vertical';
    Item?: MenuItemProps;
    SubMenu?: SubMenuProps;
    children?: React.ReactNode;
}

const Menu = ({ direction = 'horizontal', className = '', ...shared }: MenuProps): ReactElement => {
    const classNames = [
        { condition: true, class: 'menu_component' },
        { condition: direction === 'horizontal', class: 'horizontal_menu' },
        { condition: direction === 'vertical', class: 'vertical_menu' },
        { condition: className, class: className },
    ];

    return <ul className={ClassNameFactory(classNames)}>{shared.children}</ul>;
};

Menu.Item = Item;
Menu.SubMenu = SubMenu;

export default Menu;
