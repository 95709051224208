import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Store } from 'store/reducers';
import { IPlanId } from 'typings/Plan';
import { IPlanSteps } from 'typings/PlanStep';
import Menu from './header/menu';
import './Navigation.scss';

const NavigationWrapper = (): React.ReactElement => {
    const [currentRoute, setCurrentRoute] = useState('');
    const location = useLocation();
    const { planId }: IPlanId = useParams();
    const planSteps: IPlanSteps = useSelector((storeState: Store) => storeState.PlanStep || []);

    useEffect(() => {
        setCurrentRoute(location.pathname || '');
    }, [location.pathname]);

    const getInitialPage = (navSection: string) => {
        let currentStep = '';
        const parentSteps = planSteps.find((planStep) => planStep.name === navSection);
        if (parentSteps && parentSteps.subSteps) {
            const step = parentSteps.subSteps.find((subStep) => subStep.order === 0);
            if (step) {
                currentStep = step.link;
            }
        }
        return currentStep;
    };

    const getLink = (navSection: string) => {
        let newLink = '';

        const currentPath = `/plan/${planId}`;
        const initialPage = getInitialPage(navSection);

        if (navSection === 'Identify Details') newLink = `${currentPath}/details${initialPage}`;
        else if (navSection === 'Manage Tasks') newLink = `${currentPath}/tasks${initialPage}`;
        else if (navSection === 'Manage Resources') newLink = `${currentPath}/resources${initialPage}`;
        else newLink = `${currentPath}/report${initialPage}`;
        return newLink;
    };

    return (
        <nav className="o-navigation" role="navigation" aria-label="Main">
            <div className="o-nav">
                <Menu className="o-nav__list">
                    <Menu.Item className="o-nav__item" link={getLink('Identify Details')}>
                        <div className={`o-nav__link ${currentRoute.includes('details') ? '-active' : ''}`}>
                            Project Details
                        </div>
                    </Menu.Item>
                    <Menu.Item className="o-nav__item" link={getLink('Manage Tasks')}>
                        <div className={`o-nav__link ${currentRoute.includes('tasks') ? '-active' : ''}`}>Tasks</div>
                    </Menu.Item>
                    <Menu.Item className="o-nav__item" link={getLink('Manage Resources')}>
                        <div className={`o-nav__link ${currentRoute.includes('resources') ? '-active' : ''}`}>
                            Resources
                        </div>
                    </Menu.Item>
                    <Menu.Item className="o-nav__item" link={getLink('View Project Report')}>
                        <div className={`o-nav__link ${currentRoute.includes('report') ? '-active' : ''}`}>
                            Project Report
                        </div>
                    </Menu.Item>
                </Menu>
            </div>
        </nav>
    );
};

const defaultProps = {};

NavigationWrapper.defaultProps = defaultProps;

export default NavigationWrapper;
