/* eslint prefer-destructuring: 'off' */
import React, { ReactElement } from 'react';
import Keycloak from 'keycloak-js';
import { KeycloakProvider } from '@react-keycloak/web';

interface Props {
    children: ReactElement;
}

const auth = Keycloak('/keycloak.json');
export const kcLogout = auth.logout;

export default function Providers({ children }: Props): ReactElement {
    return <KeycloakProvider keycloak={auth}>{children}</KeycloakProvider>;
}
