import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import HoverTooltip from 'components/HoverTooltip';
import { GetTaskGroupsTaskGroupsByPlanIdTasksUsers } from 'typings/_graphql';

import './useravatar/UserAvatar.scss';
import Permission from './Permission';

interface Props {
    name?: string;
    user?: GetTaskGroupsTaskGroupsByPlanIdTasksUsers;
    hideTooltip?: boolean;
    bypassUserTooltipMessage?: boolean;
    message?: React.ReactElement;
    isCurrentUser?: boolean;
    style?: { [key: string]: string | number };
}

const UserAvatar = ({
    name = 'No Name',
    hideTooltip = false,
    bypassUserTooltipMessage = false,
    message,
    user,
    style = {},
    isCurrentUser = false,
}: Props): React.ReactElement => (
    <div className={`a-userAvatar ${isCurrentUser && 'a-userAvatar__selected'}`}>
        <Permission
            auth={`${hideTooltip} === false`}
            showFail={<Avatar>{user && user.name ? user.name.createInitials() : name.createInitials()}</Avatar>}
        >
            <HoverTooltip
                data-testid="UserAvatar"
                message={
                    <>
                        {!bypassUserTooltipMessage && (user ? <div>{user?.name}</div> : name)}
                        {message && message}
                    </>
                }
            >
                <Avatar style={style}>{user?.name ? user.name.createInitials() : name.createInitials()}</Avatar>
            </HoverTooltip>
        </Permission>
    </div>
);

export default UserAvatar;
