import { gql } from 'apollo-boost';

const QUERY_ASSESS_TECHS = gql`
    query GetAssessTechs($planId: Float!) {
        assessTechs(planId: $planId) {
            id
            techReadinessLevel
            mfgReadinessLevel
            dateCompletedReadinessLevel
            currentReadinessResults
            user {
                id
                name
                organization {
                    id
                    name
                }
            }
        }
    }
`;

const CREATE_ASSESS_TECH = gql`
    mutation CreateAssessTech($planId: Float!, $data: CreateAssessTechInput!) {
        createAssessTech(planId: $planId, data: $data) {
            techReadinessLevel
            mfgReadinessLevel
            dateCompletedReadinessLevel
            currentReadinessResults
            user {
                id
                name
                organization {
                    id
                    name
                }
            }
        }
    }
`;

export { QUERY_ASSESS_TECHS, CREATE_ASSESS_TECH };
