import React, { ReactElement } from 'react';
import { Button, Grid } from '@material-ui/core';

import './ProjectReportIntroduction.scss';

interface Props {
    closeModal: () => void;
}

const ProjectReportIntroduction = ({ closeModal }: Props): ReactElement => (
    <div className="m-projectReportIntroduction">
        <Grid container direction="row">
            <Grid item xs={12} sm={6}>
                <div className="m-projectReportIntroduction__head">Justification</div>
                <div className="m-projectReportIntroduction__headSmall">of the plan to leadership</div>
                <div className="m-projectReportIntroduction__sub">You&#39;re in the home stretch</div>
                <p>
                    Review how the planning effort is coming together. All of the information gathered so is
                    consolidated into a report that can be exported and shared out externally.
                </p>
                <p>
                    The last step in the planning effort is to gain mutual stakeholder concurrence with the plan, which
                    naturally leads to the start of the execution phase.
                </p>
            </Grid>
        </Grid>
        <Button
            onClick={closeModal}
            color="primary"
            variant="contained"
            className="m-projectReportIntroduction__button"
        >
            View Project Summary
        </Button>
    </div>
);
export default ProjectReportIntroduction;
