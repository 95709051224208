/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect, useCallback } from 'react';
import ganttState from '../ganttState';
import Enums from '../utils/enums';
import './TimelineScaleScrollbar.scss';

interface TimelineScaleScrollbarProps {
    sliderValueHandler: (value: number) => void;
    sliderValue: number;
}

const TimelineScaleScrollbar = ({
    sliderValueHandler,
    sliderValue,
}: TimelineScaleScrollbarProps): React.ReactElement => {
    const { references, displayWidth, sideBarWidth, setTimeScaleString } = ganttState.Gantt;
    const [scrollBoxTop, setScrollBoxTop] = useState(0);
    const [lastScrollThumbPosition, setLastScrollThumbPosition] = useState(10);
    const [isDragging, setIsDragging] = useState(false);
    const [scrollThumbHandleRightDrag, setScrollThumbHandleRightDrag] = useState(false);
    const [scrollThumbHandleLeftDrag, setScrollThumbHandleLeftDrag] = useState(false);
    const [scrollThumbWidth, setScrollThumbWidth] = useState(ganttState.Gantt.displayWidth);

    const handleDocumentMouseUp = useCallback(
        (e: MouseEvent) => {
            if (isDragging) {
                e.preventDefault();
                setIsDragging(false);
            }
            if (scrollThumbHandleRightDrag) {
                e.preventDefault();
                setScrollThumbHandleRightDrag(false);
            }
            if (scrollThumbHandleLeftDrag) {
                e.preventDefault();
                setScrollThumbHandleLeftDrag(false);
            }
        },
        [isDragging, scrollThumbHandleRightDrag, scrollThumbHandleLeftDrag]
    );

    const scrollThumbWidthChange = useCallback(() => {
        if (!references?.refCanvas?.current) return;
        const scrollSlaveElement: HTMLElement = references.refCanvas.current;
        if (!scrollThumbWidth || !displayWidth || !scrollSlaveElement) {
            sliderValueHandler(9);
            return;
        }
        let scaleValue = (100 - Math.round((scrollThumbWidth / (displayWidth - sideBarWidth - 15)) * 100)) / 10 + 1;
        if (scaleValue < 1) {
            scaleValue = 1;
        }
        sliderValueHandler(scaleValue);

        if (scaleValue <= 1.5) {
            setTimeScaleString(Enums.TimeBuckets[3]);
        } else if (scaleValue <= 4.5) {
            setTimeScaleString(Enums.TimeBuckets[2]);
        } else if (scaleValue <= 8) {
            setTimeScaleString(Enums.TimeBuckets[1]);
        } else if (scaleValue > 8) {
            setTimeScaleString(Enums.TimeBuckets[0]);
        }

        const scrollLeftMax = scrollSlaveElement.scrollWidth - scrollSlaveElement.clientWidth;

        const percentage = scrollBoxTop / (displayWidth - scrollThumbWidth - sideBarWidth - 15);
        scrollSlaveElement.scrollLeft = scrollLeftMax * Math.min(percentage, 1);
        references.ganttHeaderMonths.current.scrollLeft = scrollSlaveElement.scrollLeft;
    }, [scrollThumbWidth, displayWidth]);

    const handleDocumentMouseMove = useCallback(
        (e: MouseEvent) => {
            if (isDragging) {
                e.preventDefault();
                e.stopPropagation();
                if (!references?.refCanvas?.current) return;
                const scrollSlaveElement: HTMLElement = references.refCanvas.current;
                if (!scrollSlaveElement) return;
                const scrollLeftMax = scrollSlaveElement.scrollWidth - scrollSlaveElement.clientWidth;
                const deltaX = e.clientX - lastScrollThumbPosition;
                const scrollThumbOffset = Math.min(
                    Math.max(0, scrollBoxTop + deltaX),
                    displayWidth - scrollThumbWidth - sideBarWidth
                );
                const percentage = scrollThumbOffset / (displayWidth - scrollThumbWidth - sideBarWidth);
                setLastScrollThumbPosition(e.clientX);
                setScrollBoxTop(scrollThumbOffset);
                scrollSlaveElement.scrollLeft = scrollLeftMax * Math.min(percentage, 1);
                references.ganttHeaderMonths.current.scrollLeft = scrollLeftMax * Math.min(percentage, 1);
            }
            if (scrollThumbHandleRightDrag) {
                e.preventDefault();
                e.stopPropagation();
                const deltaX = e.clientX - lastScrollThumbPosition;
                setScrollThumbWidth(
                    Math.min(Math.max(scrollThumbWidth + deltaX, 100), displayWidth - scrollBoxTop - sideBarWidth)
                );
                setLastScrollThumbPosition(e.clientX);
                scrollThumbWidthChange();
            }
            if (scrollThumbHandleLeftDrag) {
                e.preventDefault();
                e.stopPropagation();
                const deltaX = e.clientX - lastScrollThumbPosition;
                const newScrollThumbWidth = Math.min(
                    Math.max(scrollThumbWidth + deltaX * -1, 100),
                    scrollBoxTop + scrollThumbWidth
                );

                setScrollThumbWidth(newScrollThumbWidth);
                setScrollBoxTop(Math.max(scrollBoxTop - (newScrollThumbWidth - scrollThumbWidth), 0));
                setLastScrollThumbPosition(e.clientX);
                scrollThumbWidthChange();
            }
        },
        [
            isDragging,
            lastScrollThumbPosition,
            scrollBoxTop,
            scrollThumbHandleRightDrag,
            scrollThumbHandleLeftDrag,
            references?.refCanvas?.current?.scrollLeft,
        ]
    );

    const shrinkThumbIfTooLarge = () => {
        setTimeout(() => {
            setScrollThumbWidth(Math.min(Math.max(scrollThumbWidth, 100), displayWidth - scrollBoxTop - sideBarWidth));
            scrollThumbWidthChange();
        }, 100);
    };

    useEffect(() => {
        shrinkThumbIfTooLarge();
    }, [displayWidth, sideBarWidth]);

    const handleTimelineMouseDown = useCallback((e) => {
        const { timelineWidth } = ganttState.Gantt;
        const dw = ganttState.Gantt;
        /* Percentage of scrollbar times canvas width relative to sliderValue */
        const sliderPercentage = (e.nativeEvent.offsetX + scrollThumbWidth) / dw;
        const newThumbPos = Math.max(e.nativeEvent.offsetX - scrollThumbWidth, 0);
        let newCanvasPosition = timelineWidth * sliderValue * sliderPercentage;
        if (newThumbPos < 3) {
            newCanvasPosition = 0;
        } else if (sliderPercentage > 0.86) {
            /*
                Trying to scroll to the very end of the Canvas when the scroller hits 86%.
                I can get the headers but not the Canvas. They instantly
                fall out of sync.

                I think this is due to how the widths of headers and the canvas vary.
                I'll revist this later.
            */
            // ganttState.Gantt.references.refCanvas.current.scrollLeft = ?;
            // ganttState.Gantt.references.ganttHeaderMonths.current.scrollLeft = ?;
        }
        setLastScrollThumbPosition(newThumbPos);
        setScrollBoxTop(newThumbPos);
        ganttState.Gantt.references.refCanvas.current.scrollLeft = newCanvasPosition; // Set canvas pos
        ganttState.Gantt.references.ganttHeaderMonths.current.scrollLeft = newCanvasPosition; // Set canvas pos
    }, []);

    const handleScrollThumbMouseDown = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setLastScrollThumbPosition(e.clientX);
        setIsDragging(true);
    }, []);

    const handleRightHandleBarMouseDown = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setLastScrollThumbPosition(e.clientX);
        setScrollThumbHandleRightDrag(true);
    }, []);

    const handleLeftHandleBarMouseDown = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setLastScrollThumbPosition(e.clientX);
        setScrollThumbHandleLeftDrag(true);
    }, []);

    useEffect(() => {
        // this is handle the dragging on scroll-thumb
        // console.log('should we be adding and removing listeners this often???');
        document.addEventListener('mousemove', handleDocumentMouseMove);
        document.addEventListener('mouseup', handleDocumentMouseUp);
        document.addEventListener('mouseleave', handleDocumentMouseUp);
        return function cleanup() {
            document.removeEventListener('mousemove', handleDocumentMouseMove);
            document.removeEventListener('mouseup', handleDocumentMouseUp);
            document.removeEventListener('mouseleave', handleDocumentMouseUp);
        };
    }, [handleDocumentMouseMove, handleDocumentMouseUp]);

    useEffect(() => {
        sliderValueHandler(9);
    }, []);

    useEffect(() => {
        /*
            Default zoom now set to full-zoom out
        */
        setTimeout(() => {
            setScrollThumbWidth(ganttState.Gantt.displayWidth);
            setLastScrollThumbPosition(10);
        }, 100);
    }, [ganttState.Gantt.isZeroState]);

    return (
        <button
            className="o-timelineScaleScrollbar"
            style={{ width: displayWidth }}
            ref={references.ganttSliderRef}
            onMouseDown={handleTimelineMouseDown}
        >
            <div className={'o-timelineScaleScrollbar__scrollBbar'}>
                <div
                    className={'o-timelineScaleScrollbar__scrollThumbContainer'}
                    style={{ width: `${scrollThumbWidth - 2}px`, left: scrollBoxTop }}
                >
                    <button
                        className={'o-timelineScaleScrollbar__scrollThumbHandleLeft'}
                        onMouseDown={handleLeftHandleBarMouseDown}
                    />
                    <button
                        className={'o-timelineScaleScrollbar__scrollThumb'}
                        onMouseDown={handleScrollThumbMouseDown}
                    />
                    <button
                        className={'o-timelineScaleScrollbar__scrollThumbHandleRight'}
                        onMouseDown={handleRightHandleBarMouseDown}
                    />
                </div>
            </div>
        </button>
    );
};

export default TimelineScaleScrollbar;
