import React, { ReactElement, useCallback, useRef, useState, useEffect, useMemo } from 'react';
import addBusinessDays from 'date-fns/addBusinessDays';
import isWeekend from 'date-fns/isWeekend';
import { debounce } from 'lodash-es';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Popover from '@material-ui/core/Popover';
import {
    DesktopDatePicker,
    LocalizationProvider,
    StaticDateRangePicker,
    DateRangeDelimiter,
    StaticDatePicker,
} from '@material-ui/pickers';

import CustomInput from 'components/CustomInput';
import CustomSwitch from 'components/CustomSwitch';
import getDateFormat from 'components/utilities/GetDateFormat';
import { getDurationMinusWeekends, getDateRangeByDuration } from 'components/utilities/DateUtils';
import './datepicker/DoubleDatePicker.scss';
import { RangeInput } from '@material-ui/pickers/DateRangePicker/RangeTypes';

interface DoubleDatePickerProps {
    label?: string | number | ReactElement;
    disabled?: boolean;
    startLabel?: string;
    endLabel?: string;
    formHooks: any;
    variant?: false | 'button';
    triggerSave?: false | (() => void);
}

const DoubleDatePicker = ({
    label,
    disabled = false,
    startLabel = 'Start Date',
    endLabel = 'End Date',
    variant = false,
    formHooks,
    triggerSave = false,
}: DoubleDatePickerProps): ReactElement => {
    const { watch, setValue } = formHooks;
    const [open, setOpen] = useState<boolean>(false);
    const disableWeekends = (date: any) => date.getDay() === 0 || date.getDay() === 6;
    const [pickerDateRangeValues, setPickerDateRangeValues] = useState<RangeInput<any>>([
        watch('startDate') || new Date(),
        watch('dueDate') || new Date(),
    ]);
    const [duration, setDuration] = useState(watch('duration') || 0);
    const [pickerIsDateRange, setPickerIsDateRange] = useState(watch('isDateRange') || false);
    const anchorEl = useRef(null);

    useEffect(() => {
        setPickerDateRangeValues([watch('startDate'), watch('dueDate')]);
        setDuration(watch('duration'));
        setPickerIsDateRange(watch('isDateRange'));
    }, [open]);

    const handleOpen = () => {
        if (!disabled) setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const saveEntries = () => {
        setValue('isDateRange', pickerIsDateRange, { shouldDirty: true });
        setValue('startDate', pickerDateRangeValues[0], { shouldDirty: true });
        setValue('dueDate', pickerDateRangeValues[1], { shouldDirty: true });
        setValue('duration', duration, { shouldDirty: true });
        if (triggerSave && 'handleSubmit' in formHooks) {
            formHooks.handleSubmit(triggerSave)();
        }
        setOpen(false);
    };

    const changeDuration = (e: any) => {
        if (pickerDateRangeValues[0] === null) return;
        if (e.target.value === '') {
            setDuration('');
            return;
        }
        const newDuration = e.target.value < 1 ? 1 : e.target.value;
        const newEndDate = getDateRangeByDuration(pickerDateRangeValues[0], newDuration, duration > newDuration);
        setDuration(newDuration);
        setPickerDateRangeValues([pickerDateRangeValues[0], newEndDate]);
    };

    const manualChangeDate = (date1: Date, date2: Date) => {
        let newDate1 = isWeekend(date1) ? addBusinessDays(date1, 1) : date1;
        let newDate2 = isWeekend(date2) ? addBusinessDays(date2, 1) : date2;
        if (newDate1 >= newDate2) {
            newDate2 = addBusinessDays(newDate1, 1);
        }
        if (newDate2 <= newDate1) {
            newDate1 = addBusinessDays(newDate2, -1);
        }
        setPickerDateRangeValues([newDate1, newDate2]);
        setDuration(getDurationMinusWeekends(newDate1, newDate2));
    };

    const debouncedManualChangeDate = useMemo(() => debounce(manualChangeDate, 500), []);

    const RenderVariant = () => {
        const dueText = getDateFormat(watch('startDate')) ? 'Due Date' : '';
        if (variant === 'button') {
            let hasDateText = '';
            if (watch('isDateRange')) {
                hasDateText = `${getDateFormat(watch('startDate'))} - ${getDateFormat(watch('dueDate'))}`;
            } else hasDateText = `${getDateFormat(watch('startDate'))}`;
            return (
                <>
                    {!watch('startDate') ? (
                        <button onClick={handleOpen}>
                            <strong>Set Date</strong>
                        </button>
                    ) : (
                        <>
                            <strong>{hasDateText}</strong>
                            <div className="o-taskListItem__milestone">
                                {watch('isDateRange') &&
                                    watch('dueDate') &&
                                    `${getDurationMinusWeekends(watch('startDate'), watch('dueDate'))} Business Days`}
                            </div>
                        </>
                    )}
                </>
            );
        }
        return (
            <>
                <section className="dateInput">
                    <TextField
                        label={watch('isDateRange') ? startLabel : dueText}
                        value={getDateFormat(watch('startDate')) || 'Set Date'}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </section>
                {watch('isDateRange') && (
                    <section className="dateInput">
                        <TextField
                            label={endLabel}
                            value={getDateFormat(watch('dueDate')) || 'Set Date'}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </section>
                )}
            </>
        );
    };

    const PopHOC = useCallback(
        (props: any) =>
            variant === 'button' ? (
                <Popover
                    open={open}
                    onClose={handleClose}
                    className="DoubleDatePicker__container DoubleDatePicker__popContainer"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    anchorEl={anchorEl.current}
                >
                    {props.children}
                </Popover>
            ) : (
                <Modal open={open} onClose={handleClose} className="DoubleDatePicker__container">
                    {props.children}
                </Modal>
            ),
        [open]
    );

    return (
        <LocalizationProvider dateAdapter={DateFnsAdapter}>
            <button type="button" onClick={handleOpen} ref={anchorEl}>
                <RenderVariant />
            </button>
            <PopHOC>
                <div className={`DoubleDatePicker ${!pickerIsDateRange && 'DoubleDatePicker__milestoneOption'}`}>
                    <h3 className="DoubleDatePicker__header">{label}</h3>
                    <div className="DoubleDatePicker__body" style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="DoubleDatePicker__calendar">
                            {pickerIsDateRange ? (
                                <StaticDateRangePicker
                                    displayStaticWrapperAs="desktop"
                                    value={pickerDateRangeValues}
                                    shouldDisableDate={disableWeekends}
                                    disableHighlightToday
                                    onChange={(newValue) => {
                                        setPickerDateRangeValues([newValue[0], newValue[1]]);
                                        setDuration(getDurationMinusWeekends(newValue[0], newValue[1]));
                                    }}
                                    renderInput={(startProps, endProps) => (
                                        <div className="o-customInput">
                                            <TextField {...startProps} />
                                            <DateRangeDelimiter> to </DateRangeDelimiter>
                                            <TextField {...endProps} />
                                        </div>
                                    )}
                                />
                            ) : (
                                <StaticDatePicker
                                    minDate={new Date(`01/01/${new Date().getFullYear() - 20}`)}
                                    maxDate={new Date(`01/01/${new Date().getFullYear() + 20}`)}
                                    displayStaticWrapperAs="desktop"
                                    value={pickerDateRangeValues[0]}
                                    shouldDisableDate={disableWeekends}
                                    disableHighlightToday
                                    onChange={(newValue: Date | null) => {
                                        setPickerDateRangeValues([newValue, newValue]);
                                    }}
                                    renderInput={(props) => <TextField {...props} />}
                                />
                            )}
                        </div>
                        <div>
                            <CustomSwitch
                                leftLabel="Milestone"
                                rightLabel="Date Range"
                                checkedValue={pickerIsDateRange}
                                handleChange={() => {
                                    setPickerIsDateRange(!pickerIsDateRange);
                                }}
                            />
                        </div>
                        <div className="DoubleDatePicker__inputsContainer">
                            <DesktopDatePicker
                                label={pickerIsDateRange ? startLabel : 'Due Date'}
                                toolbarTitle="Set Date"
                                disableOpenPicker
                                value={pickerDateRangeValues[0]}
                                inputFormat="MM/dd/yyyy"
                                shouldDisableDate={disableWeekends}
                                onChange={(val) => debouncedManualChangeDate(val, pickerDateRangeValues[1])}
                                renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
                            />

                            {pickerIsDateRange && (
                                <>
                                    <DesktopDatePicker
                                        label={endLabel}
                                        value={pickerDateRangeValues[1]}
                                        inputFormat="MM/dd/yyyy"
                                        shouldDisableDate={disableWeekends}
                                        onChange={(val) => debouncedManualChangeDate(pickerDateRangeValues[0], val)}
                                        renderInput={(params) => (
                                            <TextField {...params} InputLabelProps={{ shrink: true }} />
                                        )}
                                    />

                                    <div className="DoubleDatePicker__duration">
                                        <CustomInput
                                            inputLabel="Duration"
                                            name="duration"
                                            type="number"
                                            onChange={changeDuration}
                                            value={duration}
                                            disabled={!pickerDateRangeValues[0]}
                                        />
                                        <div className="DoubleDatePicker__duration__businessDays">Business Days</div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="DoubleDatePicker__buttons">
                            <Button variant="contained" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="contained" color="primary" onClick={saveEntries}>
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </PopHOC>
        </LocalizationProvider>
    );
};

export default DoubleDatePicker;
