import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import './offcanvas/Offcanvas.scss';

interface Props {
    children: ReactNode | ReactNode[];
    state: boolean;
    className?: string;
}

export default function Offcanvas({ children, state, className = '' }: Props): ReactElement {
    const [offsetTop, setOffsetTop] = useState(65);
    const [isOpen, setIsOpen] = useState('--closed');

    useEffect(() => {
        if (state) {
            setIsOpen('--open');
        } else {
            setIsOpen('--closed');
        }
    }, [state]);

    useEffect(() => {
        function onScroll() {
            const offset = 65 - window.pageYOffset;
            if (offset <= 0) {
                setOffsetTop(0);
            } else {
                setOffsetTop(offset);
            }
        }
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [offsetTop]);

    return (
        <div
            id={`off-canvas ${isOpen}`}
            className={`o-offcanvas ${isOpen} ${className}`}
            style={{ marginTop: `${offsetTop}px` }}
        >
            {children}
        </div>
    );
}
