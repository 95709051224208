import React, { useState, useEffect } from 'react';
import {
    InputLabel,
    FormHelperText,
    OutlinedInput,
    StandardTextFieldProps,
    FormControl,
    InputAdornment,
    Icon,
} from '@material-ui/core';
import { DeepMap, FieldError } from 'react-hook-form';

import './custominput/CustomInput.scss';

export interface CustomInputProps extends StandardTextFieldProps {
    inputLabel?: string;
    helperText?: string | React.ReactNode;
    name: string;
    maxLength?: number;
    errormessage?: string;
    errors?: DeepMap<Record<string, any>, FieldError>;
    required?: boolean;
    startAdornment?: string;
    multiline?: boolean;
    showCharacterCount?: boolean;
    fullWidth?: boolean;
    className?: string;
    inputSpacing?: boolean;
    onChange?: any;
    value?: string | number;
    defaultValue?: string | number;
    displayOnly?: boolean;
}

function CustomInput({
    inputLabel,
    helperText,
    errors = {},
    required,
    startAdornment,
    name,
    maxLength,
    showCharacterCount,
    errormessage,
    fullWidth,
    multiline,
    placeholder,
    onChange,
    onBlur,
    inputSpacing,
    className = '',
    value,
    displayOnly = false,
    defaultValue,
    ...props
}: CustomInputProps): React.ReactElement {
    const [inputLength, setInputLength] = useState(0);
    const [flagError, setFlagError] = useState(errors[name] || (maxLength && inputLength > maxLength));

    const updateCount = (val: number) => setInputLength(val);

    function isDisplayOnly() {
        return props.disabled && displayOnly ? ' -displayOnly' : '';
    }

    useEffect(() => {
        if (defaultValue) {
            const { length } = String(defaultValue);
            setInputLength(length);
            setFlagError(errors[name] || (maxLength && length > maxLength));
        } else {
            setFlagError(false);
            setInputLength(0);
        }
    }, [defaultValue]);

    return (
        <section
            className={`o-customInput${isDisplayOnly()} ${className} ${!multiline ? '-notTextbox' : ''} ${
                inputSpacing ? '-inputSpacing' : ''
            }`}
        >
            <FormControl fullWidth={fullWidth}>
                {inputLabel && (
                    <div className={`o-customInput__inputLabel`} data-testid="input-label">
                        <InputLabel htmlFor={name} error={!!flagError}>
                            {required && '*'}
                            {inputLabel}
                        </InputLabel>
                    </div>
                )}
                {helperText && <FormHelperText data-testid="helper-text">{helperText}</FormHelperText>}
                <OutlinedInput
                    error={!!flagError}
                    name={name}
                    startAdornment={
                        startAdornment ? (
                            <InputAdornment position="start">
                                <Icon>{startAdornment}</Icon>
                            </InputAdornment>
                        ) : null
                    }
                    id={name}
                    disabled={props.disabled}
                    placeholder={placeholder || 'Enter Text'}
                    inputRef={props.inputRef}
                    fullWidth={fullWidth}
                    onFocus={props.onFocus}
                    required={required}
                    multiline={multiline}
                    inputProps={maxLength ? { maxLength } : {}}
                    onChange={(e) => {
                        if (onChange) onChange(e);
                        updateCount(e.target.value.length);
                    }}
                    type={props.type || 'text'}
                    onBlur={(e) => {
                        if (onBlur) onBlur(e);
                        updateCount(e.target.value.length);
                    }}
                    value={value}
                    defaultValue={defaultValue}
                />
                {flagError && (
                    <FormHelperText error data-testid="flag-error">
                        {errormessage}
                    </FormHelperText>
                )}
                {maxLength && showCharacterCount && (
                    <p
                        className={`o-customInput__characterCount ${flagError ? '-charactersExceeded' : ''}`}
                        data-testid="max-length"
                    >{`${inputLength} / ${maxLength} Characters`}</p>
                )}
            </FormControl>
        </section>
    );
}

export default CustomInput;
