/* eslint no-unneeded-ternary: "off" */
/* eslint no-bitwise: "off" */
import React, { useState, useEffect, useCallback } from 'react';
import { Icon } from '@material-ui/core';
import { PlanSummaryPlanSummaryResourcesTaskResourceAssoc, PlanSummaryPlanSummaryResources } from 'typings/_graphql';
import { ThreeBarOverlay, GraphHeader, ColumnLines } from 'components/charts/threebaroverlay/ThreeBarOverlay';

import './resourcebreakdowntable/ResourceBreakdownTable.scss';

interface ResourceBreakdownTableProps {
    resourceSummary?: readonly PlanSummaryPlanSummaryResources[] | null;
    isReport?: boolean;
}

interface PlanSummaryPlanSummaryResourcesExtended extends PlanSummaryPlanSummaryResources {
    amount: number;
    amountUsed: number;
    unallocated: number;
}

interface PlanSummaryPlanSummaryResourcesExtendedRow {
    row: PlanSummaryPlanSummaryResourcesExtended;
}

interface PlanSummaryPlanSummaryResourcesFundingOverviewTableRow {
    row: PlanSummaryPlanSummaryResourcesExtended[];
    index: number;
}

interface FundingOverviewTableHeaderProps {
    row: (string | number)[];
}

interface DollarCellProps {
    amount?: number | null;
    isOverAllocated?: boolean;
    emptyCell?: boolean;
}

type FundingOverviewData = PlanSummaryPlanSummaryResourcesExtended[];

export default ({ resourceSummary, isReport = false }: ResourceBreakdownTableProps): React.ReactElement => {
    const [fundingOverviewData, setFundingOverviewData] = useState<FundingOverviewData[]>([]);
    const [fundingOverviewDataHeader, setFundingOverviewDataHeader] = useState<(string | number)[]>([]);
    const [max, setMax] = useState(10);

    function grabAmount(posNum: number | null): number {
        return posNum ? posNum : 0;
    }

    const isDataEmpty = () => {
        let isEmpty = true;
        fundingOverviewData.forEach((categoryArray) => {
            if (categoryArray.length > 0) isEmpty = false;
        });
        return isEmpty;
    };

    useEffect(() => {
        if (resourceSummary) {
            const localMax: (number | undefined | null)[] = [];
            const moneyResources = resourceSummary.filter((resource) => resource.resourceTypeId !== 3);
            const acceptableResources = moneyResources.filter((resource) => resource.status?.status !== 'Rejected');

            const clumpedResources = acceptableResources.reduce(
                (acc: FundingOverviewData[], cur: PlanSummaryPlanSummaryResources) => {
                    let amountUsed = 0;
                    const curCopy: PlanSummaryPlanSummaryResourcesExtended = {
                        ...cur,
                        amountUsed: 0,
                        unallocated: 0,
                        amount: 0,
                    };
                    curCopy?.taskResourceAssoc?.forEach((tr: PlanSummaryPlanSummaryResourcesTaskResourceAssoc) => {
                        amountUsed += tr.taskAmount || 0;
                    });
                    curCopy.amount = cur.amount ? cur.amount : 0;
                    curCopy.amountUsed = isReport ? grabAmount(curCopy?.amount) : amountUsed;
                    curCopy.unallocated = isReport
                        ? grabAmount(curCopy?.amount)
                        : curCopy.amountUsed - (curCopy.amount ? curCopy.amount : 0);
                    localMax.push(curCopy.amountUsed, curCopy.unallocated, curCopy.amount);
                    switch (cur!.resourceTypeId) {
                        case 1:
                            return [[...acc[0], curCopy], acc[1]];
                        case 2:
                        default:
                            return [acc[0], [...acc[1], curCopy]];
                    }
                },
                [[], []]
            );
            const maxAmount = Math.max(...(localMax.filter((a: number | undefined | null) => a) as number[]));
            setMax(Math.floor(maxAmount * 1.1));
            setFundingOverviewDataHeader([
                'Funding Type',
                'Funding',
                maxAmount,
                'Total Amount',
                'Allocated',
                'Unallocated',
            ]);
            setFundingOverviewData([...clumpedResources]);
        }
    }, [resourceSummary, isReport]);

    const GrabIcon = ({ row }: PlanSummaryPlanSummaryResourcesExtendedRow): React.ReactElement | null => {
        if (row.unallocated > 0) return <Icon color="error">error_outline</Icon>;
        if (!row.unallocated || !row.amount) return <Icon color="primary">check_circle</Icon>;
        if (row.unallocated < 0) return <Icon color="disabled">check_circle_outline</Icon>;
        return null;
    };

    const GraphRow = ({ row }: PlanSummaryPlanSummaryResourcesExtendedRow) => (
        <div className="a-cell fr-fs">
            <div className="a-cell__chartBackdrop">
                <ColumnLines />
                <div className="a-cell__chartBackdrop__container">
                    <ThreeBarOverlay
                        data={[row.amount ? row.amount : 0, row.amountUsed]}
                        scaleMax={max}
                        icon={() => (!isReport ? <GrabIcon row={row} /> : <span />)}
                    />
                </div>
            </div>
        </div>
    );

    const FundingOverviewTableHeader = ({ row }: FundingOverviewTableHeaderProps) => (
        <div className="a-table__row -header">
            <strong className="-pd a-cell__terminus -initial">{row[0]}</strong>
            <strong className="-pd a-cell a-funding">{row[1]}</strong>
            <div className="-pd a-cell fr-sb">
                <GraphHeader scaleMax={max} />
            </div>
            <strong className="-pd a-cell" style={{ minWidth: 120, maxWidth: 120 }}>
                {row[3]}
            </strong>
            {!isReport && (
                <>
                    <strong className="-pd a-cell" style={{ minWidth: 120, maxWidth: 120 }}>
                        {row[4]}
                    </strong>
                    <strong className="-pd a-cell" style={{ minWidth: 120, maxWidth: 120 }}>
                        {row[5]}
                    </strong>
                </>
            )}
        </div>
    );

    const FundingCell = ({ row }: PlanSummaryPlanSummaryResourcesExtendedRow) => (
        <div className="-pd a-cell fc-fs a-funding">
            <strong>{row.name}</strong>
            {row.fundType && <div>{row.fundType.name}</div>}
        </div>
    );

    const DollarCell = ({ amount, emptyCell = false, isOverAllocated = false }: DollarCellProps) => {
        function isOverAllocatedClass() {
            return isOverAllocated ? '-overAllocated' : '';
        }

        return (
            <div className={`-pd a-cell -length120 -dollar ${isOverAllocatedClass()}`}>
                {isOverAllocated && <span className="a-cell__overAllocatedSpan">Over Allocated</span>}
                <div className="a-cell__dollarFormat -length120">
                    <span>$</span>
                    <span className="a-cell__dollarAmount">
                        {!emptyCell && (amount ? amount.toLocaleString() : 0)}
                        {emptyCell && null}
                    </span>
                </div>
            </div>
        );
    };

    function grabClusterName(index: number): string {
        switch (index) {
            case 0:
                return "Gov't";
            case 1:
                return "Non-Gov't";
            case 2:
            default:
                return 'In-Kind';
        }
    }

    const FundingOverviewTableRow = ({ row, index }: PlanSummaryPlanSummaryResourcesFundingOverviewTableRow) => (
        <div className={`a-table__row ${index === 0 ? '-body' : '-regular'}`}>
            <strong className="-pd a-cell__terminus -initial">{grabClusterName(index)}</strong>
            <div className="fc-fs -fullWidth">
                {row.length > 0 ? (
                    row.map((r: PlanSummaryPlanSummaryResourcesExtended, j: number) => (
                        <div className="fr-sb" key={j} id={`FundingOverviewTableRow-${j}`}>
                            <FundingCell row={r} />
                            <GraphRow row={r} />
                            <DollarCell emptyCell={!r.amount} amount={r.amount} />
                            {!isReport && (
                                <>
                                    <DollarCell amount={r.amountUsed} />
                                    <DollarCell
                                        emptyCell={!r.amount}
                                        amount={Math.abs(r.unallocated)}
                                        isOverAllocated={!!(r?.amount && r.amountUsed > r.amount)}
                                    />
                                </>
                            )}
                        </div>
                    ))
                ) : (
                    <div className="a-cell__empty">{`No ${grabClusterName(index)} Resources have been identified`}</div>
                )}
            </div>
        </div>
    );

    const FundingOverviewTable = useCallback(
        () => (
            <div className="a-table">
                {!isDataEmpty() && <FundingOverviewTableHeader row={fundingOverviewDataHeader} />}
                {fundingOverviewData.map((row: FundingOverviewData, i: number) => (
                    <FundingOverviewTableRow
                        row={row.sort(
                            (a: PlanSummaryPlanSummaryResourcesExtended, b: PlanSummaryPlanSummaryResourcesExtended) =>
                                a.amount >= b.amount ? -1 : 1
                        )}
                        index={i}
                        key={i}
                    />
                ))}
            </div>
        ),
        [fundingOverviewData, isReport]
    );

    return (
        <div className="o-resourceBreakdownTable" id="ResourceBreakdownTable">
            <FundingOverviewTable />
        </div>
    );
};
