import React, { useState, ReactElement } from 'react';

import { toast } from 'react-toastify';
import { Button, IconButton, Icon, Grid, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import AlertToast from 'components/AlertToast';
import CustomInput from 'components/CustomInput';
import { GetPlansPlans as PlanType, UpdatePlan, UpdatePlanVariables } from 'typings/_graphql';

import './editprojectmodal/EditProjectModal.scss';
import ConfirmButton from 'components/ConfirmButton';
import { qPlans } from 'api/queries';

interface EditProjectModalProps {
    plan: PlanType;
    trigger: (openFunction: (val: boolean) => void) => ReactElement;
}

interface PlanFormInputs {
    name: string;
    description: string;
}

const EditProjectModal = React.forwardRef(({ trigger, plan }: EditProjectModalProps, ref: any) => {
    const { errors, register, watch, handleSubmit, formState } = useForm({
        mode: 'onSubmit',
    });
    const isDirtyAlt = !!Object.keys(formState.dirtyFields).length;
    const [openPopup, setOpenPopup] = useState(false);
    const [updatePlan] = useMutation<UpdatePlan, UpdatePlanVariables>(qPlans.UPDATE_PLAN, {
        refetchQueries: [
            {
                query: qPlans.QUERY_PLAN,
                variables: {
                    id: plan.id,
                },
            },
        ],
    });

    const closeModal = () => {
        setOpenPopup(false);
        if (ref) {
            ref?.current?.close();
        }
    };
    const onSubmit = (data: PlanFormInputs) => {
        updatePlan({
            variables: {
                planId: plan.id,
                data,
            },
        })
            .then(() => {
                closeModal();
            })
            .catch((error) => {
                toast(
                    <AlertToast
                        severity="error"
                        message={`Plan could not be updated at this time. Please try again! ${error.message}`}
                    />
                );
            });
    };

    return (
        <>
            {trigger(setOpenPopup)}
            <Dialog fullWidth maxWidth="sm" className={`a-editProjectModal}`} open={openPopup}>
                <form id="createPlanForm" onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle className="a-editProjectModal__title">
                        <span className="a-editProjectModal__titleHeader">Edit Project Information</span>
                    </DialogTitle>
                    <DialogContent className="a-editProjectModal__content">
                        <Grid spacing={3} container direction="column">
                            <Grid className="a-editProjectModal__label" container direction="column" item>
                                <span>Project ID</span>
                                <p>{plan.code}</p>
                            </Grid>
                            <Grid item>
                                <CustomInput
                                    placeholder={'Enter Text'}
                                    inputLabel="Project Name"
                                    showCharacterCount
                                    errors={errors}
                                    defaultValue={plan.name}
                                    maxLength={50}
                                    required
                                    errormessage="Project name cannot exceed 50 characters in length"
                                    name="name"
                                    inputRef={register({ required: true })}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item>
                                <CustomInput
                                    placeholder={'Enter Text'}
                                    inputLabel="Project Description"
                                    errors={errors}
                                    required
                                    showCharacterCount
                                    defaultValue={plan.description}
                                    maxLength={160}
                                    errormessage="Project description cannot exceed 160 characters in length"
                                    name="description"
                                    inputRef={register({ required: true })}
                                    fullWidth
                                    multiline
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className="a-editProjectModal__actions">
                        <Button color="secondary" variant="outlined" onClick={() => closeModal()}>
                            Cancel
                        </Button>
                        <ConfirmButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            valuesHaveChanged={isDirtyAlt}
                            watch={watch(['name', 'description'])}
                        >
                            Save
                        </ConfirmButton>
                    </DialogActions>
                    <div className="a-editProjectModal__closeButton">
                        <IconButton onClick={() => closeModal()}>
                            <Icon>close</Icon>
                        </IconButton>
                    </div>
                </form>
            </Dialog>
        </>
    );
});

export default EditProjectModal;
