/* eslint no-extend-native: "off" */
/* eslint no-bitwise: "off" */
declare global {
    interface Number {
        dollarValueTruncate: () => string;
    }
}

Number.prototype.dollarValueTruncate = function (): string {
    const val = ~~this.toString();
    if (Math.abs(val) > 999999999) return `$${(Math.abs(val) / 1000000000).toFixed(0)}b`;
    if (Math.abs(val) > 999999) return `$${(Math.abs(val) / 1000000).toFixed(0)}m`;
    if (Math.abs(val) > 999) return `$${(Math.abs(val) / 1000).toFixed(0)}k`;
    return `$${Math.abs(val)}`;
};

export {};
