import React, { useState, useEffect, useContext, useCallback, ReactElement } from 'react';
import { find, debounce } from 'lodash-es';

import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { GetTaskGroupsTaskGroupsByPlanIdTasks, GetTaskGroupsTaskGroupsByPlanIdTasksUsers } from 'typings/_graphql';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { qFundTypes, qTasks } from 'api/queries';
import { preventEnterSubmit } from 'components/utilities/preventEnterSubmit';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import UserAvatar from 'components/UserAvatar';

import PlanContext from 'pages/plans/PlanContext';
import { toast } from 'react-toastify';
import AlertToast from 'components/AlertToast';

import Permission from 'components/Permission';
import { useParams } from 'react-router-dom';
import { IPlanId } from 'typings/Plan';

interface Props {
    task: GetTaskGroupsTaskGroupsByPlanIdTasks;
    readOnly: boolean;
    showStakeholder?: boolean;
}

const TaskGroupTableItem = ({ task, readOnly, showStakeholder = true }: Props): ReactElement => {
    const { planId }: IPlanId = useParams();
    const { id, name, fundType, users, estimate } = task;
    const [allDone, setAllDone] = useState(() => false);
    const [updateTask] = useMutation(qTasks.UPDATE_TASK_ESTIMATE);
    const { fundTypes } = useContext(PlanContext);
    const { watch, control, reset } = useForm({ mode: 'onChange' });
    const defaultFundTypeId = find(fundTypes, { name: fundType?.name })?.id || 0;
    const [, setSaved] = useState(`${task.estimate}${task.fundType?.name}`);
    const defaultEstimate = estimate ?? 0;
    const [stateEstimate, setStateEstimate] = useState<NumberFormatValues>({
        formattedValue: defaultEstimate.toString(),
        floatValue: defaultEstimate,
        value: defaultEstimate.toString(),
    });

    const renderUser = (user: GetTaskGroupsTaskGroupsByPlanIdTasksUsers) => (
        <>
            <UserAvatar user={user} />
        </>
    );

    useEffect(() => {
        reset({
            fundType: defaultFundTypeId,
            estimate: estimate || 0,
        });
    }, []);

    useEffect(() => {
        if (estimate && estimate > 0 && fundType) {
            setAllDone(true);
        } else {
            setAllDone(false);
        }
    }, [estimate, fundType]);

    const debounceCall = useCallback(
        debounce(
            (newEstimate, newFundType) =>
                updateTask({
                    variables: { id, data: { fundTypeId: newFundType, estimate: newEstimate.floatValue } },
                    refetchQueries: [
                        {
                            query: qFundTypes.QUERY_WITH_ESTIMATE,
                            variables: { planId: parseFloat(planId), fundTypeName: 'Commercial' },
                        },
                        {
                            query: qTasks.GET_BY_PLAN_FUND_TYPE_AND_USER,
                            variables: { planId: parseFloat(planId), getByUser: true },
                        },
                    ],
                })
                    .then(() => {
                        setSaved(`${newEstimate.floatValue}${newFundType}`);
                    })
                    .catch((e) => {
                        toast(<AlertToast severity="error" title={`Error updating Task:`} message={e.message} />);
                    }),
            400
        ),
        []
    );

    const changeItem = () => {
        const newEstimate = watch('estimate');
        const newFundType = watch('fundType');

        debounceCall(newEstimate, newFundType);
    };

    return (
        /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */
        <form autoComplete="off" onKeyPress={preventEnterSubmit}>
            <Grid
                container
                spacing={3}
                className="m-estimateTasksTable__item"
                alignItems="center"
                justify="space-between"
            >
                <Grid className="m-estimateTasksTable__item__name" xs={6} item container>
                    <Grid className="m-estimateTasksTable__item__check" item xs={1}>
                        {allDone && <CheckCircleIcon color="primary" />}
                    </Grid>
                    <Grid item xs={11}>
                        {name}
                    </Grid>
                </Grid>
                <Permission auth={`${showStakeholder} === true`}>
                    <Grid className="m-estimateTasksTable__item__user cell" item xs={1}>
                        {users && renderUser(users[0])}
                    </Grid>
                </Permission>
                <Grid
                    className={`m-estimateTasksTable__item__amount cell ${watch('estimate') ? '' : '--grayed-out'}`}
                    item
                    xs={showStakeholder ? 2 : 3}
                >
                    <span>$</span>
                    <Permission
                        auth={`${readOnly} === false`}
                        showFail={<NumberFormat thousandSeparator displayType="text" value={watch('estimate')} />}
                    >
                        <Controller
                            control={control}
                            name="estimate"
                            defaultValue={
                                estimate || {
                                    floatValue: 0.0,
                                    formattedValue: '0',
                                    value: '0',
                                }
                            }
                            render={({ onChange, onBlur, value, ref }) => (
                                <NumberFormat
                                    defaultValue={value}
                                    onBlur={(): void => {
                                        if (value.floatValue === 0) {
                                            const result = {
                                                floatValue: 0,
                                                formattedValue: '0',
                                                value: '0',
                                            };
                                            setStateEstimate(result);
                                            onChange(result);
                                        }
                                        onBlur();
                                    }}
                                    value={stateEstimate.value}
                                    customInput={TextField}
                                    allowEmptyFormatting
                                    thousandSeparator
                                    allowNegative={false}
                                    inputRef={ref}
                                    allowLeadingZeros={false}
                                    variant="outlined"
                                    onValueChange={(v: NumberFormatValues) => {
                                        if (v.floatValue === undefined) {
                                            const result = {
                                                floatValue: 0,
                                                formattedValue: '',
                                                value: '',
                                            };
                                            setStateEstimate(result);
                                            onChange(result);
                                            changeItem();
                                        } else {
                                            onChange(v);
                                            setStateEstimate(v);
                                            changeItem();
                                        }
                                    }}
                                />
                            )}
                        />
                    </Permission>
                </Grid>
                <Grid
                    item
                    className={`m-estimateTasksTable__item__category ${watch('fundType') ? '' : '--grayed-out'}`}
                    xs={3}
                >
                    <Permission
                        auth={`${readOnly} === false`}
                        showFail={
                            <span>
                                {find(fundTypes, (o) => o.id === watch('fundType'))?.name || 'No Option Selected'}
                            </span>
                        }
                    >
                        <Controller
                            control={control}
                            name="fundType"
                            id="formatted-type-input"
                            disabled={readOnly}
                            defaultValue={defaultFundTypeId}
                            onBlur={() => changeItem()}
                            render={({ onChange }) => (
                                <Select
                                    onChange={(e) => {
                                        onChange(e);
                                        changeItem();
                                    }}
                                    variant="outlined"
                                    defaultValue={defaultFundTypeId}
                                >
                                    <MenuItem value={0}>Select Option</MenuItem>
                                    {fundTypes.map((fundTypeItem) => (
                                        <MenuItem key={fundTypeItem.id} value={fundTypeItem.id}>
                                            {fundTypeItem.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                    </Permission>
                </Grid>
            </Grid>
        </form>
    );
};

export default TaskGroupTableItem;
