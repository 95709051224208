/* eslint prefer-object-spread: "off" */
import React from 'react';
import { GetUserUserExtended } from 'typings/_graphql';
import Row from './IdentifyPeopleRow';
import { offCanvasProps } from '../../PlanContext';

interface IdentifyPeopleBodyProps {
    setSelectedUser: (value: GetUserUserExtended) => void;
    users: GetUserUserExtended[];
    setOffCanvasTaskGroup: (arg0: offCanvasProps) => void;
    createdBy: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default ({ setSelectedUser, users, setOffCanvasTaskGroup, createdBy }: IdentifyPeopleBodyProps) => (
    <div className="m-identify-people-table-body">
        {users && users.length > 0 ? (
            [...users]
                .sort((inviteeA: GetUserUserExtended, inviteeB: GetUserUserExtended) =>
                    inviteeA.status <= inviteeB.status ? -1 : 1
                )
                .map((invitee: GetUserUserExtended) => (
                    <Row
                        permission={createdBy === invitee?.user?.id ? 'Admin' : 'Contributor'}
                        setSelectedUser={() =>
                            setSelectedUser(
                                Object.assign(
                                    { ...invitee },
                                    { permission: createdBy === invitee?.user?.id ? 'Admin' : 'Contributor' }
                                )
                            )
                        }
                        invitee={invitee}
                        setOffCanvasTaskGroup={setOffCanvasTaskGroup}
                        status={invitee.status}
                        key={invitee.id}
                    />
                ))
        ) : (
            <div className="m-identify-people-table-body-row">
                <div className="a-cell">No users have been invited to this plan.</div>
            </div>
        )}
    </div>
);
