import React, { ReactElement } from 'react';
import { ThreeBarOverlay, GraphHeader, ColumnLines } from 'components/charts/threebaroverlay/ThreeBarOverlay';

export interface SummaryTableData {
    estimate: number;
    totalAllocated: number;
}

interface GraphProps {
    summaryTableData: SummaryTableData;
}

interface GraphBarProps {
    data: number[];
    isOverAllocated: boolean;
    scaleMax: number;
}

export default ({ summaryTableData }: GraphProps) => {
    const scaleMax = Object.values(summaryTableData).sort((a: number, b: number) => (a < b ? 1 : -1))[0] * 1.15;

    return (
        <div>
            <div className="a-graph-header-offset">
                <GraphHeader scaleMax={scaleMax} />
            </div>
            <div className="a-graph-guts">
                <ColumnLines />
                <div className="a-graph-guts__bars">
                    <ThreeBarOverlay
                        data={[summaryTableData.estimate, summaryTableData.totalAllocated]}
                        scaleMax={scaleMax}
                    />
                </div>
            </div>
        </div>
    );
};
