import React, { useState } from 'react';
import './estimatetasks/EstimateTasks.scss';
import Layouts from 'components/layouts/Layouts';
import PageHeading from 'components/PageHeading';
import { Grid, Card, Switch } from '@material-ui/core';
import Tabs from 'components/Tabs';
import { filter, get } from 'lodash-es';

import { IUser } from 'typings/User';
import { Store } from 'store/reducers';
import { useSelector } from 'react-redux';
import { GetTaskGroupsTaskGroupsByPlanId, GetTaskGroupsTaskGroupsByPlanIdTasks } from 'typings/_graphql';

import EstimatesByCategory from './estimatetasks/EstimatesByCategory';
import WhatAreCategories from './estimatetasks/WhatAreCategories';
import TaskGroupTableContainer from './estimatetasks/TaskGroupTableContainer';
import { PlanConsumer, PlanProvider } from './PlanContext';

const { Content, Subheader, Sidebar, Navigation } = Layouts;

const EstimateTasks = (): React.ReactElement => {
    const userData: IUser = useSelector((storeState: Store) => storeState?.User);
    const [viewAllTasks, setViewAllTasks] = useState(false);
    const [noTasksWithCategory, setNoTasksWithCategory] = useState(false);

    const emptyTasksMessage = (taskGroupData: readonly GetTaskGroupsTaskGroupsByPlanId[]) => {
        setNoTasksWithCategory(false);
        let emptyTaskData = true;
        let tasksCategorized = false;

        taskGroupData.forEach((taskGroup) => {
            let { tasks } = taskGroup;
            if (tasks && tasks.length > 0) {
                tasks.forEach((task) => {
                    if (task.fundType != null) tasksCategorized = true;
                });
                if (!viewAllTasks)
                    tasks = filter(
                        taskGroup.tasks,
                        (o: GetTaskGroupsTaskGroupsByPlanIdTasks) => get(o, `users[0].id`) === userData.id
                    );

                if (tasks && tasks.length > 0) emptyTaskData = false;
            }
        });

        if (!tasksCategorized) {
            setNoTasksWithCategory(true);
        }

        if (taskGroupData && emptyTaskData && !viewAllTasks) {
            return <h2>There are currently no tasks assigned to you.</h2>;
        }
        if (taskGroupData && emptyTaskData && viewAllTasks) {
            return <h2>There are currently no tasks in the plan.</h2>;
        }
        return <></>;
    };

    return (
        <PlanProvider>
            <PlanConsumer>
                {({ taskGroupData }) => (
                    <Layouts id="page-wrap" className="prevent-col-wrap">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Sidebar />
                            <Content>
                                <Navigation />
                                <Subheader>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <PageHeading
                                                title="Estimate Cost of Tasks"
                                                description="For each defined task within your task groups, determine the total estimated cost of work and the type of funding the task will need."
                                            />
                                        </Grid>
                                    </Grid>
                                </Subheader>
                                <Grid container spacing={2}>
                                    <Grid item xs={8} className="o-estimateTasks__taskGroup-list">
                                        <span className={`${!viewAllTasks && '--bold'}`}>View my tasks</span>
                                        <Switch
                                            checked={viewAllTasks}
                                            onChange={(e) => setViewAllTasks(e.target.checked)}
                                            color="primary"
                                        />
                                        <span className={`${!!viewAllTasks && '--bold'}`}>View all tasks</span>
                                        {emptyTasksMessage(taskGroupData)}
                                        <TaskGroupTableContainer
                                            taskGroupData={taskGroupData}
                                            userData={userData}
                                            viewAllTasks={viewAllTasks}
                                        />
                                    </Grid>

                                    <Grid item xs={4} className="o-estimateTasks__estimateForm">
                                        <Card>
                                            <Tabs
                                                items={[
                                                    {
                                                        label: (
                                                            <span>
                                                                Estimates By <br /> Category
                                                            </span>
                                                        ),
                                                        content: (
                                                            <EstimatesByCategory emptyTasks={noTasksWithCategory} />
                                                        ),
                                                    },
                                                    {
                                                        label: (
                                                            <span>
                                                                What Are <br /> Categories?
                                                            </span>
                                                        ),
                                                        content: <WhatAreCategories />,
                                                    },
                                                ]}
                                                textColor="secondary"
                                                variant="fullWidth"
                                            />
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Content>
                        </div>
                    </Layouts>
                )}
            </PlanConsumer>
        </PlanProvider>
    );
};

export default EstimateTasks;
