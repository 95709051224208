import React, { ReactElement } from 'react';
import { Icon } from '@material-ui/core';
import usePalette from 'components/utilities/usePalette';
import Avatar from './avatar/Avatar';
import Tooltip from './Tooltip';
import GanttTTBody from './GanttTTBody';
import { TaskModel } from '../Data';
import Enums from '../utils/enums';
import './Milestone.scss';

interface MilestoneProps extends TaskModel {
    index: number;
    groupIndex: number;
    draw: boolean;
    callback?: (taskId: number, status: any) => void;
}

export default ({ index, groupIndex, draw, callback, ...props }: MilestoneProps): ReactElement => {
    const color = usePalette();
    const pos = {
        x: 20,
        y: 0,
        width: 200,
        height: 30,
    };

    return draw ? (
        <button
            className="Milestone__container"
            id={`${props.id}-gantt-container`}
            onClick={() =>
                callback ? callback(+props.id, props.progress) : console.log('No callback function provided.')
            }
        >
            <Tooltip body={<GanttTTBody index={index} {...props} />}>
                <svg height={32}>
                    <polygon
                        fill={!!props.color ? props.color : color(groupIndex)}
                        strokeWidth="2"
                        className="Milestone"
                        points={`
                            0,15
                            10,0
                            20,15
                            10,30
                        `}
                    />
                    <polygon
                        fill="transparent"
                        strokeWidth="2"
                        className="Milestone"
                        points={`
                            2,15
                            10,2
                            18,15
                            10,28
                        `}
                    />
                    <foreignObject className="ganttBarForeignObject" {...pos}>
                        <div className="fo_wrapper" style={{ marginLeft: 4 }}>
                            <div className="fo_wrapper__textwrapper">
                                <Icon>{Enums.StatusCodesIcons[props.progress]}</Icon>
                                <div className="fo_wrapper">{props.progress}</div>
                                {!!props.users && props.users.length > 0 && (
                                    <div style={{ top: -4, position: 'relative' }}>
                                        <Avatar users={props.users} hideTooltip={false} offsetRight={-36} absolute />
                                    </div>
                                )}
                            </div>
                        </div>
                    </foreignObject>
                </svg>
            </Tooltip>
        </button>
    ) : (
        <></>
    );
};
