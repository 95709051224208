import React, { ReactElement, ReactNode } from 'react';
import Popup from 'reactjs-popup';
import { Button } from '@material-ui/core';

import Modal from 'components/Modal';

import './confirmmodal/ConfirmModal.scss';

interface Props {
    children: ReactElement;
    title: string;
    message?: ReactNode;
    closeText: string;
    confirmText: string;
    onConfirm?: () => void;
}

const ConfirmModal = React.forwardRef(
    ({ children, title, message, closeText, confirmText, onConfirm }: Props, ref: any) => {
        const closeModal = (close: { (): void }) => {
            if (ref) {
                ref?.current?.close();
            }
            close();
        };

        return (
            <Popup trigger={children} modal nested contentStyle={{ width: '390px' }}>
                {(close: () => void) => (
                    <Modal
                        closeFunction={() => {
                            closeModal(close);
                        }}
                    >
                        <h3>{title}</h3>

                        <p>{message}</p>

                        <div className="button-group">
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                    closeModal(close);
                                }}
                            >
                                {closeText}
                            </Button>
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        if (onConfirm) onConfirm();
                                        closeModal(close);
                                    }}
                                >
                                    {confirmText}
                                </Button>
                            </div>
                        </div>
                    </Modal>
                )}
            </Popup>
        );
    }
);

export default ConfirmModal;
