import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Button, Select, MenuItem, InputBase } from '@material-ui/core';
import { find } from 'lodash-es';
import { Store } from 'store/reducers';
import { useQuery, useMutation } from '@apollo/client';
import { qUsers, qTasks } from 'api/queries';
import { GetTaskGroupsTaskGroupsByPlanIdTasks as Task } from 'typings/_graphql';
import { IPlanId } from 'typings/Plan';
import { IUser } from 'typings/User';

import ConfirmModal from 'components/ConfirmModal';
import getTasks from 'components/utilities/getTasks';
import Modal from 'components/Modal';
import UpdateTaskModalTable from './UpdateTaskModalTable';
import PlanContext from '../../PlanContext';

import './updatetaskmodal/UpdateTaskModal.scss';

interface Props {
    tasks: number[] | undefined;
    open?: boolean;
    afterClick: () => void;
}

const UpdateTaskAssigneeModal = React.forwardRef(({ tasks = [], open = false, afterClick }: Props, ref: any) => {
    const { taskGroupData, selectedTaskIds, setSelectedTaskIds } = useContext(PlanContext);
    const { planId }: IPlanId = useParams();
    const currentUser: IUser = useSelector((storeState: Store) => storeState?.User);
    const [selectedTasks, setSelectedTasks] = useState<Task[]>([]);
    const [selectedUserId, setSelectedUserId] = useState<string>('');
    const [updateTasks] = useMutation(qTasks.UPDATE_TASKS_ASSIGNEES);

    const { data: users } = useQuery(qUsers.GET_USERS_BY_PLAN, {
        variables: { planId: parseFloat(planId) },
    });

    const updateAssignee = () => {
        if (users) {
            updateTasks({
                variables: { taskIds: selectedTaskIds, userId: selectedUserId },
            });
            setSelectedTaskIds([]);
        }
    };

    const onFilterChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        const userId = `${e.target.value}`;
        if (userId) {
            setSelectedUserId(userId);
        }
    };

    const closeModal = (close: { (): void }) => {
        close();
        afterClick();
        if (ref) {
            ref?.current?.close();
        }
    };

    useEffect(() => {
        if (currentUser) setSelectedUserId(currentUser.id);
        setSelectedTasks(getTasks(tasks, taskGroupData));
    }, [tasks, users, taskGroupData]);

    const getSelectedUsersName = () => find(users?.usersByPlan, (o) => o.id === selectedUserId)?.name;

    return (
        <Popup
            open={open && selectedTasks.length > 0}
            closeOnDocumentClick
            onClose={afterClick}
            modal
            nested
            contentStyle={{ width: '657px' }}
        >
            {(close: () => void) => (
                <div className="a-updateTaskModal">
                    <Modal closeFunction={() => closeModal(close)}>
                        <h3>Update Assignee</h3>
                        <p>Update the assignee of all selected tasks to:</p>
                        <div className="a-updateTaskModal__selectContainer">
                            <span className="a-updateTaskModal__selectLabel">Assignee</span>
                            <Select
                                input={<InputBase />}
                                label="Assignee"
                                defaultValue={currentUser.id}
                                onChange={onFilterChange}
                            >
                                {users &&
                                    users?.usersByPlan.map((user: IUser) => (
                                        <MenuItem id={user.id} value={user.id}>
                                            {user.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </div>
                        <UpdateTaskModalTable assignee tasks={selectedTasks} />
                        <div className="button-group">
                            <Button color="secondary" variant="outlined" onClick={() => closeModal(close)}>
                                Cancel
                            </Button>
                            <ConfirmModal
                                closeText="Cancel"
                                onConfirm={() => {
                                    updateAssignee();
                                    closeModal(close);
                                }}
                                confirmText="Update"
                                message={
                                    <>
                                        Are you sure you want to update the assignees on the selected tasks to &quot;
                                        {getSelectedUsersName()}&quot;?
                                    </>
                                }
                                title="Update Assignee"
                            >
                                <Button color="primary" variant="contained">
                                    Save
                                </Button>
                            </ConfirmModal>
                        </div>
                    </Modal>
                </div>
            )}
        </Popup>
    );
});

export default UpdateTaskAssigneeModal;
