import React from 'react';
import { GetTaskGroupsTaskGroupsByPlanId, GetTaskGroupsTaskGroupsByPlanIdTasks } from 'typings/_graphql';
import { IUser } from 'typings/User';
import { get, filter } from 'lodash-es';
import { Grid } from '@material-ui/core';
import { AnimateGroup } from 'react-animation';
import TaskGroupTable from '../TaskGroupTable';
import TaskGroupTableItem from './TaskGroupTableItem';

interface Props {
    taskGroupData: readonly GetTaskGroupsTaskGroupsByPlanId[];
    userData: IUser;
    viewAllTasks: boolean;
}

const TaskGroupTableContainer = ({ taskGroupData, userData, viewAllTasks }: Props): React.ReactElement => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    if (taskGroupData.length === 0) {
        return <h2>Loading...</h2>;
    }
    return (
        <ul>
            <AnimateGroup durationOut="200">
                {taskGroupData.map((taskGroup) => {
                    let { tasks } = taskGroup;
                    if (!viewAllTasks) {
                        tasks = filter(
                            taskGroup.tasks,
                            (o: GetTaskGroupsTaskGroupsByPlanIdTasks) => get(o, `users[0].id`) === userData.id
                        );
                    }
                    if ((tasks?.length || 0) < 1) {
                        return <div />;
                    }
                    if (tasks?.length) {
                        const tasksEstimated = filter(tasks, (o) => o.estimate && o.fundType?.name);
                        return (
                            <li key={`${taskGroup.id}`}>
                                <TaskGroupTable
                                    taskGroup={taskGroup}
                                    index={taskGroup.id}
                                    counterMessage={`${tasksEstimated.length}/${tasks?.length || 0} Tasks Estimated`}
                                >
                                    <AnimateGroup
                                        durationOut="200"
                                        className="MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-3 --noPadding --noMargin"
                                    >
                                        <li
                                            key={'header'}
                                            className="m-estimateTasksTable__tableHeader MuiGrid-item MuiGrid-grid-xs-12"
                                        >
                                            <Grid container spacing={3} alignItems="center" justify="space-between">
                                                <Grid xs={6} item container>
                                                    <Grid item xs={1} />
                                                    <Grid item xs={11}>
                                                        Task Name
                                                    </Grid>
                                                </Grid>
                                                {viewAllTasks && (
                                                    <Grid item xs={1} className="m-estimateTasksTable__tableHeader__sh">
                                                        SH
                                                    </Grid>
                                                )}
                                                <Grid item xs={viewAllTasks ? 2 : 3}>
                                                    Estimate
                                                </Grid>
                                                <Grid item xs={3}>
                                                    Resource Category
                                                </Grid>
                                            </Grid>
                                        </li>
                                        {tasks?.map((task) => (
                                            <li key={task.id} className={'MuiGrid-item MuiGrid-grid-xs-12'}>
                                                <TaskGroupTableItem
                                                    key={`${task.id}`}
                                                    task={task}
                                                    readOnly={get(task, `users[0].id`) !== userData.id}
                                                    showStakeholder={viewAllTasks}
                                                />
                                            </li>
                                        ))}
                                    </AnimateGroup>
                                </TaskGroupTable>
                            </li>
                        );
                    }
                    return <div />;
                })}
            </AnimateGroup>
        </ul>
    );
};
export default TaskGroupTableContainer;
