import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Layouts from 'components/layouts/Layouts';
import PageHeading from 'components/PageHeading';
import { useQuery, useLazyQuery } from '@apollo/client';
import { qPlans, qUsers, qInvitees } from 'api/queries';
import { IPlanId } from 'typings/Plan';
import { GetUserUserExtended } from 'typings/_graphql';
import { IUser } from 'typings/User';
import { Store } from 'store/reducers';
import IdentifyPeopleForms from './identifyPeople/IdentifyPeopleForms';
import { PlanConsumer, PlanProvider } from '../PlanContext';
import Body from './identifyPeople/IdentifyPeopleBody';
import Footer from './identifyPeople/IdentifyPeopleFooter';
import './identifyPeople/IdentifyPeople.scss';

const { Subheader } = Layouts;

export default function IdentifyProblem(): React.ReactElement {
    const [resetDefaultData, setResetDefaultData] = useState(false);
    const [selectedUser, setSelectedUser] = useState<GetUserUserExtended>();
    const { planId } = useParams<IPlanId>();

    const [getUser] = useLazyQuery(qUsers.GET_USERS_BY_PLAN);
    const {
        data: invitesByPlan,
        startPolling,
        stopPolling,
    } = useQuery(qInvitees.GET_INVITEES_BY_PLAN, {
        variables: {
            planId: +planId,
        },
    });

    const userData: IUser = useSelector((storeState: Store) => storeState?.User);

    useEffect(() => {
        getUser({ variables: { planId: +planId } });
    }, []);

    const { data: planCreator } = useQuery(qPlans.QUERY_PLAN_CREATOR, {
        variables: { planId: +planId },
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        startPolling(2000);
        return () => {
            stopPolling();
        };
    }, []);

    return (
        <PlanProvider>
            <PlanConsumer>
                {({ setOffCanvasTaskGroup, offCanvasTaskGroup }) => (
                    <>
                        <div className="m-fundamentalsForm">
                            <Subheader>
                                <PageHeading title="Manage Team" />
                            </Subheader>
                            <div className="o-identify-people-table">
                                <div className="m-identify-people-table-header">
                                    {['Name', 'Organization/Company', 'Project Role', 'Permission', 'Status'].map(
                                        (key: string) => (
                                            <div>{key}</div>
                                        )
                                    )}
                                </div>
                                <Body
                                    createdBy={planCreator?.planSummary?.createdBy.id}
                                    setSelectedUser={setSelectedUser}
                                    users={invitesByPlan?.getInviteesByPlan ? invitesByPlan?.getInviteesByPlan : []}
                                    setOffCanvasTaskGroup={setOffCanvasTaskGroup}
                                />
                                <Footer
                                    planId={planId}
                                    users={invitesByPlan?.getInviteesByPlan ? invitesByPlan?.getInviteesByPlan : []}
                                />
                            </div>
                        </div>
                        <IdentifyPeopleForms
                            planId={planId}
                            userData={userData}
                            status={selectedUser ? selectedUser.status : 1}
                            isCreator={userData && planCreator?.planSummary?.createdBy.id === userData?.id}
                            planName={planCreator?.planSummary?.name}
                            selectedUser={selectedUser}
                            isCurrentUser={userData?.id === selectedUser?.user?.id}
                            setOffCanvasTaskGroup={setOffCanvasTaskGroup}
                            setResetDefaultData={setResetDefaultData}
                            offCanvasTaskGroup={offCanvasTaskGroup}
                            resetDefaultData={resetDefaultData}
                        />
                    </>
                )}
            </PlanConsumer>
        </PlanProvider>
    );
}
