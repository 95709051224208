import React, { ReactElement } from 'react';
import Layouts from 'components/layouts/Layouts';
import 'assets/sass/grid.css';
import '../node_modules/reactjs-popup/dist/index.css';
import '../node_modules/uikit/dist/uikit.css';
import './App.scss';

const { Header } = Layouts;

export default (): ReactElement => (
    <div className="App">
        <Header />
    </div>
);
