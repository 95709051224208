import { gql } from 'apollo-boost';

const CREACT_PAGE_INTRODUCTION = gql`
    mutation CreatePageIntroduction($id: Float!) {
        createPageIntroduction(id: $id)
    }
`;

// eslint-disable-next-line import/prefer-default-export
export { CREACT_PAGE_INTRODUCTION };
