import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

import { qTasks } from 'api/queries';
import { debounce, get } from 'lodash-es';
import { SearchTaskNameByPlanSearchTaskNameByPlan } from 'typings/_graphql';

import InputAdornment from '@material-ui/core/InputAdornment';
import UserAvatar from 'components/UserAvatar';

interface Props {
    addTask: (item: addItemProp) => void;
    excludeIds: number[];
    taskType?: 'parent' | 'child';
    taskId: number;
}

export interface addItemProp {
    type: 'parent' | 'child';
    task: SearchTaskNameByPlanSearchTaskNameByPlan;
}

const AddDependency = ({ excludeIds, taskId = 0, addTask, taskType }: Props): React.ReactElement => {
    const { planId } = useParams<{ planId: string }>();
    const textField = useRef<HTMLInputElement>(null);
    let timer: ReturnType<typeof setTimeout>;

    const [getTasks, { data: searchResults }] = useLazyQuery(qTasks.SEARCH_TASK_NAME_BY_PLAN, {
        fetchPolicy: 'no-cache',
    });
    const [parentChild] = useState<'parent' | 'child'>(taskType || 'parent');
    const [showResults, setShowResults] = useState(false);
    const [queryResults, setQueryResults] = useState([]);

    useEffect(() => {
        setQueryResults(searchResults?.searchTaskNameByPlan || []);
    }, [searchResults]);

    const debounceQuery = useCallback(
        debounce(
            (searchString, exclude) =>
                getTasks({ variables: { searchString, planId: parseFloat(planId), exclude, limit: 5 } }),
            400
        ),
        [searchResults, excludeIds]
    );

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const exclude = [...excludeIds, taskId];
        debounceQuery(event.target.value, exclude);
    };

    const searchFocus = () => {
        clearTimeout(timer);
        setShowResults(true);
    };

    const searchBlur = () => {
        timer = setTimeout(() => setShowResults(false), 250);
    };

    const addTaskDependency = (task: SearchTaskNameByPlanSearchTaskNameByPlan) => {
        const type = parentChild;
        addTask({ type, task });
        setQueryResults([]);
        if (textField.current !== null) {
            textField.current.value = '';
        }
    };

    return (
        <div className="m-adddependencies__container">
            <TextField
                autoComplete="off"
                className="m-search"
                fullWidth
                inputRef={textField}
                onChange={handleChange}
                onFocus={() => {
                    searchFocus();
                }}
                onBlur={() => {
                    searchBlur();
                }}
                placeholder="Search Existing Tasks..."
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon color="primary" fontSize="large" />
                        </InputAdornment>
                    ),
                }}
                variant="outlined"
            />

            <ul className={`m-adddependencies ${showResults ? '' : 'hidden'}`}>
                {queryResults.map((task: SearchTaskNameByPlanSearchTaskNameByPlan) => (
                    <li className="m-adddependencies__itemcontainer" key={task.id}>
                        <button
                            onClick={() => {
                                addTaskDependency(task);
                            }}
                            className="m-adddependencies__itembutton"
                        >
                            <div className="m-adddependencies__name">{task.name}</div>
                            <div className="m-adddependencies__avatar">
                                <UserAvatar name={(task && task.users && task.users[0].name) || undefined} />
                            </div>
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AddDependency;
