/* eslint @typescript-eslint/no-shadow: "off" */
import React from 'react';
import { Button } from '@material-ui/core';
import { GetUserUserExtended } from 'typings/_graphql';
import { Statuses } from './Enums';
import { offCanvasProps } from '../../PlanContext';
import { StatusIcon, checkStatus } from './utils/Utils';

interface IdentifyPeopleRowProps {
    invitee: GetUserUserExtended;
    status?: number;
    setOffCanvasTaskGroup?: (arg0: offCanvasProps) => void;
    setSelectedUser?: () => void;
    truncated?: boolean;
    permission?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default ({
    invitee,
    status = 0,
    setOffCanvasTaskGroup,
    setSelectedUser,
    truncated,
    permission,
}: IdentifyPeopleRowProps) => (
    <div className="m-identify-people-table-body-row">
        <div>
            <StatusIcon invitee={invitee?.user} status={status} />
            <div>
                <Button
                    className="a-cell --name"
                    onClick={() => {
                        if (!!setOffCanvasTaskGroup) {
                            setOffCanvasTaskGroup({ canvas: true });
                        }
                        if (!!setSelectedUser) {
                            setSelectedUser();
                        }
                    }}
                >
                    {checkStatus(status) ? invitee.user?.name : 'Pending User'}
                </Button>
                <div className="a-cell">{invitee?.user ? invitee.user.emailAddress : invitee.email}</div>
            </div>
        </div>
        <div className="a-cell">{checkStatus(status) ? invitee?.user?.organization?.name : ''}</div>
        <div className="a-cell">{checkStatus(status) ? invitee?.user?.dutyTitle : ''}</div>
        {!truncated && (
            <>
                {<div className="a-cell">{permission}</div>}
                <div className="a-cell">{Statuses[status]}</div>
            </>
        )}
    </div>
);
