import React, { useRef } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Icon, Grid, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Layouts from 'components/layouts/Layouts';
import AlertToast from 'components/AlertToast';
import PageHeading from 'components/PageHeading';
import ConfirmModal from 'components/ConfirmModal';
import CustomInput from 'components/CustomInput';
import ConfirmButton from 'components/ConfirmButton';

import { useMutation } from '@apollo/client';
import { qPlans } from 'api/queries';
import { CreatePlan, CreatePlanVariables } from 'typings/_graphql';

import { preventEnterSubmit } from 'components/utilities/preventEnterSubmit';
import './createplan/createPlan.scss';

const { Content, Subheader } = Layouts;

type Stakeholder = {
    emailAddress: string;
};

interface PlanFormInputs {
    name: string;
    description: string;
    role: string;
    stakeholders: Stakeholder[];
    search?: string;
}

const PlanForm = (): React.ReactElement => {
    const {
        control,
        handleSubmit,
        register,
        setValue,
        watch,
        formState,
        formState: { errors },
        getValues,
        reset,
    } = useForm({
        mode: 'onChange',
    });
    const history = useHistory();
    const ref: any = useRef();
    const [createPlan] = useMutation<CreatePlan, CreatePlanVariables>(qPlans.CREATE_PLAN);
    const { fields, prepend, remove } = useFieldArray({ control, name: 'stakeholders' });
    const { isSubmitSuccessful } = formState;

    const hasSearchError = errors.search;

    const onSubmit = (formData: PlanFormInputs) => {
        if (isSubmitSuccessful) return;

        // search isn't used to remove it from form data
        const { stakeholders, ...form } = formData;
        delete form.search;

        createPlan({
            refetchQueries: [
                {
                    query: qPlans.QUERY_PLANS,
                },
            ],
            variables: { data: { stakeholders, ...form } },
        })
            .then((result) => {
                toast(
                    <AlertToast
                        severity="success"
                        message="New project was successfully created and is now available in TechPlan."
                    />
                );
                history.push(`/plan/${result.data?.createPlan.id}/details/identifypeople`);
            })
            .catch((error) => {
                toast(
                    <AlertToast
                        severity="error"
                        message={`Project could not be created at this time. Please try again! ${error.message}`}
                    />
                );
                reset(formData);
            });
    };

    return (
        <Layouts>
            <Content>
                <Subheader>
                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            <PageHeading
                                title="Create a New Project"
                                description="Please complete the following form fields to create a new project and receive your Project ID number."
                            />
                        </Grid>
                        <Grid item xs={3} className="m-createPlan__buttons">
                            <ConfirmModal
                                ref={ref}
                                closeText="Back"
                                confirmText="Discard"
                                onConfirm={() => history.push('/')}
                                title="Cancel Project?"
                                message="Are you sure you want to leave? You will lose all progress and the new project will not be saved."
                            >
                                <Button color="secondary" variant="outlined">
                                    Cancel
                                </Button>
                            </ConfirmModal>
                            <ConfirmButton
                                form="createPlanForm"
                                onSubmit={onSubmit}
                                watch={watch(['name', 'description', 'role', 'stakeholders'])}
                            >
                                Create Project
                            </ConfirmButton>
                        </Grid>
                    </Grid>
                </Subheader>
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <form id="createPlanForm" onSubmit={handleSubmit(onSubmit)} onKeyPress={preventEnterSubmit}>
                    <Grid container spacing={3} direction="row">
                        <Grid container direction="column" spacing={2} item md={6}>
                            <Grid item>
                                <CustomInput
                                    className="m-createPlan__inputs"
                                    inputLabel="Project Name"
                                    errors={errors}
                                    showCharacterCount
                                    name="name"
                                    inputRef={register({ required: true })}
                                    maxLength={50}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <CustomInput
                                    className="m-createPlan__inputs"
                                    inputLabel="Project Description"
                                    helperText="A brief overview of the project."
                                    errors={errors}
                                    name="description"
                                    inputRef={register({ required: true })}
                                    maxLength={160}
                                    showCharacterCount
                                    fullWidth
                                    multiline
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <CustomInput
                                    inputLabel="Your Project Role"
                                    helperText="A title describing your overall role or contribution to the project (Problem Holder, Technology
                            Owner, Subject Matter Expert, etc.)."
                                    errors={errors}
                                    name="role"
                                    inputRef={register({ required: true })}
                                    maxLength={32}
                                    showCharacterCount
                                    fullWidth
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="column" item md={6}>
                            <Grid container spacing={2} item>
                                <Grid item md={9}>
                                    <CustomInput
                                        inputLabel="*Add People"
                                        helperText={
                                            <>
                                                Please enter the email for <b>at least</b> one or more users that would
                                                be needed to contribute to the project. This can include Problem
                                                Holders, Technology Owners, Subject Matter Experts, Investors, etc. You
                                                can add or remove people to the project at any point.
                                            </>
                                        }
                                        errors={errors}
                                        name="search"
                                        startAdornment="email"
                                        inputRef={register({
                                            pattern: {
                                                value: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/i,
                                                message: 'Must be an email address.',
                                            },
                                        })}
                                        inputSpacing
                                        errormessage="Must be an email address."
                                        type="email"
                                        placeholder="Enter Email"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid
                                    className={`m-createPlan__stakeholderButton ${
                                        hasSearchError ? '-hasSearchError' : ''
                                    }`}
                                    container
                                    justify="flex-start"
                                    item
                                    alignContent="flex-end"
                                    md={3}
                                >
                                    <ConfirmButton
                                        onClick={() => {
                                            prepend({ emailAddress: getValues('search') }, false);
                                            setValue('search', '');
                                        }}
                                        type="button"
                                        watch={watch(['search'])}
                                        errors={errors}
                                    >
                                        Add Person
                                    </ConfirmButton>
                                </Grid>
                                <Grid item md={12}>
                                    <span className="m-createPlan__stakeholderNote">
                                        Note: You do not need to add yourself to this list.
                                    </span>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <ul className="m-createPlan__stakeholderList">
                                    {fields.map((item, index) => (
                                        <li className="m-createPlan__stakeholderItem" key={item.id}>
                                            <Icon className="m-createPlan__email">email</Icon>
                                            <input
                                                className="m-createPlan__stakeholderInput"
                                                name={`stakeholders[${index}].emailAddress`}
                                                defaultValue={`${item.emailAddress}`}
                                                tabIndex={-1}
                                                readOnly
                                                ref={register({ required: true })}
                                            />
                                            <IconButton onClick={() => remove(index)}>
                                                <Icon className="m-createPlan__deleteButton">delete</Icon>
                                            </IconButton>
                                        </li>
                                    ))}
                                </ul>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Content>
        </Layouts>
    );
};

export default PlanForm;
