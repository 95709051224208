import React, { useState, useEffect, ReactElement } from 'react';
import { TrlMrlCalculator, TrlMrlAPI } from 'techsuite-trl-mrl-calc';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import AlertToast from 'components/AlertToast';
import Layouts from 'components/layouts/Layouts';

import { qUsers, qPlans, qAssessTechs } from 'api/queries';
import { IPlanId } from 'typings/Plan';
import {
    GetPlan as PlanData,
    GetPlanVariables as PlanVariables,
    GetAssessTechs,
    CreateAssessTech,
} from 'typings/_graphql';

import { ReadinessResultsProps, pdfInfoProps, WELCOME, homepageText } from './assesstech/AssessTechProps';
import AssessTechHeader from './assesstech/AssessTechHeader';

import './assesstech/AssessTech.scss';

const { Content, Subheader, Sidebar, Navigation } = Layouts;

const AssessTech = (): ReactElement | null => {
    const { planId }: IPlanId = useParams();
    const location = useLocation();
    const castedPlanId = parseFloat(planId);

    const [currentPage, setCurrentPagePage] = useState(WELCOME);
    const [storeData, setStoreData] = useState<ReadinessResultsProps>();
    const [takeNewAssess, setTakeNewAssess] = useState(false);
    const [pdfInformation, setPdfInformation] = useState<pdfInfoProps>();

    const { data: userData, loading: userDataLoading } = useQuery(qUsers.GET_USER);
    const { data: planData, loading: planDataLoading } = useQuery<PlanData, PlanVariables>(qPlans.QUERY_PLAN, {
        variables: { id: parseFloat(planId) },
    });
    const { data: assessTechsData, loading: assessTechsLoading } = useQuery<GetAssessTechs>(
        qAssessTechs.QUERY_ASSESS_TECHS,
        {
            variables: { planId: parseFloat(planId) },
        }
    );

    const [createAssessTech] = useMutation<CreateAssessTech>(qAssessTechs.CREATE_ASSESS_TECH);

    useEffect(() => {
        const path = location.pathname.split('/');
        const pathParam = path[path.length - 1];

        switch (pathParam) {
            case 'readinessresults':
                TrlMrlAPI.changePage('Readiness Results');
                break;
            case 'answersoverview':
                TrlMrlAPI.changePage('Answers Overview');
                break;
            case 'testingenvironments':
                TrlMrlAPI.changePage('Defined Testing Environments');
                break;
            case 'suggestednextsteps':
                TrlMrlAPI.changePage('Suggested Next Steps');
                break;
            default:
                TrlMrlAPI.changePage('');
                break;
        }
    }, [location.pathname]);

    useEffect(() => {
        if (!assessTechsLoading && !userDataLoading && !planDataLoading) {
            const technologyName = planData?.plan?.name || '';

            if (assessTechsData && assessTechsData.assessTechs.length > 0) {
                // build pdf info based on what was stored from assessment in db
                const assessmentUserNameArr = assessTechsData?.assessTechs[0]?.user?.name?.split(' ');
                const pdfInfo = {
                    firstName:
                        assessmentUserNameArr && assessmentUserNameArr?.length > 0 ? assessmentUserNameArr[0] : '',
                    lastName:
                        assessmentUserNameArr && assessmentUserNameArr?.length > 1 ? assessmentUserNameArr[1] : '',
                    companyName: assessTechsData?.assessTechs[0]?.user?.organization || '',
                    technologyName,
                };

                setStoreData({ ...assessTechsData.assessTechs[0].currentReadinessResults, ...pdfInfo });
            } else {
                // build pdf info with current user taking assessment
                const currentUserNameArr = userData?.user?.name?.split(' ');
                const pdfInfo = {
                    firstName: currentUserNameArr?.length > 0 ? currentUserNameArr[0] : '',
                    lastName: currentUserNameArr?.length > 1 ? currentUserNameArr[1] : '',
                    companyName: userData?.user?.organization || '',
                    technologyName,
                };
                setStoreData({});
                setTakeNewAssess(true);
                setPdfInformation(pdfInfo);
            }
        }
    }, [assessTechsData, assessTechsLoading, userDataLoading, userData, planDataLoading, planData]);

    useEffect(() => {
        if (storeData === undefined && takeNewAssess) setStoreData({});
    }, [storeData, takeNewAssess]);

    const returnPageName = (value: string) => setCurrentPagePage(value);

    const restartData = () => {
        setStoreData(undefined);
        setTakeNewAssess(true);
    };

    const valueReturned = (value: ReadinessResultsProps) => setStoreData(value);

    const saveNewAssessment = async () => {
        createAssessTech({
            refetchQueries: [
                {
                    query: qAssessTechs.QUERY_ASSESS_TECHS,
                    variables: {
                        planId: castedPlanId,
                    },
                },
            ],
            variables: {
                planId: castedPlanId,
                data: {
                    techReadinessLevel: storeData?.mrlScore,
                    mfgReadinessLevel: storeData?.trlScore,
                    dateCompletedReadinessLevel: storeData?.dateCompleted,
                    currentReadinessResults: storeData,
                    userId: userData.user.id,
                },
            },
        })
            .then(() => {
                toast(<AlertToast severity="success" message={`A Technology Assessment has been Created`} />);
            })
            .catch((e) => {
                toast(<AlertToast severity="error" message={`Error saving Technology Assessment: ${e}`} />);
            });

        setTakeNewAssess(false);
    };

    if (assessTechsLoading || storeData === undefined) return <div>Loading...</div>;

    return (
        <Layouts className="prevent-col-wrap">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Sidebar />
                <Content>
                    <Navigation />
                    <Subheader>
                        <AssessTechHeader
                            currentPage={currentPage}
                            takeNewAssess={takeNewAssess}
                            restartData={restartData}
                            saveNewAssessment={saveNewAssessment}
                        />
                    </Subheader>
                    <TrlMrlCalculator
                        displayPdf
                        homepageText={homepageText}
                        returnPageName={returnPageName}
                        storeData={storeData}
                        pdfInformation={pdfInformation}
                        returnData={valueReturned}
                    />
                </Content>
            </div>
        </Layouts>
    );
};

export default AssessTech;
