import { createStore, compose, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers/index';
import { loadState, Sanitizer } from './middlewares';

const persistedState = loadState(); // get localStorage state
export const initialState = persistedState || {};

const stateSanitizer: any = Sanitizer;

export interface Action {
    type: string;
    payload: Record<string, unknown>;
}

const composeEnhancers =
    process.env.NODE_ENV !== 'development'
        ? compose()
        : composeWithDevTools({ trace: true, traceLimit: 10, stateSanitizer });

export default createStore(rootReducer, initialState, composeEnhancers(applyMiddleware()));
