import { UNLOCK_PLANSTEP, SET_PLANSTEP, SET_PLANSTEPS } from 'store/actions/PlanStep';

import { IPlanSteps } from 'typings/PlanStep';

const initialState: IPlanSteps = [];
export type State = IPlanSteps;

interface Request {
    type: string;
    payload?: any;
}

export default (state = initialState, { type, payload }: Request) => {
    switch (type) {
        case SET_PLANSTEPS: {
            return payload;
        }

        case UNLOCK_PLANSTEP: {
            return {
                ...state,
                isLocked: false,
            };
        }
        case SET_PLANSTEP: {
            return {
                ...state,
                ...payload,
            };
        }
        default: {
            return state;
        }
    }
};
