import React, { ReactElement } from 'react';
import './ScoreRing.scss';

export interface ScoreRingProps {
    score: number | undefined;
    type: string;
    definition: string;
}

const ScoreRing = ({ score, type, definition }: ScoreRingProps): ReactElement | null => {
    const scoreRingText = (yAxis: string, fontsize: string, content: string): ReactElement => (
        <text
            x="50%"
            y={`${yAxis}%`}
            textAnchor="middle"
            fill="#364d6c"
            fontSize={`${fontsize}px`}
            fontFamily="Montserrat"
            fontWeight="600"
            dy=".3em"
        >
            {content}
        </text>
    );

    if (score)
        return (
            <section className="m-scoreRingWrapper">
                <svg className="m-scoreRingWrapper__scoreRing" viewBox="0 0 40 40">
                    <g className="m-scoreRingWrapper__ring">
                        <circle
                            className="m-scoreRingWrapper__background"
                            cx="50%"
                            cy="50%"
                            strokeWidth="3"
                            r="15.915"
                        />
                        <circle
                            className="m-scoreRingWrapper__completed"
                            cx="50%"
                            cy="50%"
                            strokeWidth="3"
                            r="15.915"
                            transform="rotate(-90 20 20)"
                            style={{
                                strokeDasharray: `${type === 'TRL' ? score * 10 + 10 : score * 10} 100`,
                                strokeLinecap: 'round',
                            }}
                        />
                    </g>
                    {scoreRingText('45', '7', `${type} ${score}`)}
                    {scoreRingText('65', '5', `of ${type === 'TRL' ? '9' : '10'}`)}
                </svg>
                <p className="m-scoreRingWrapper__definition">{definition}</p>
            </section>
        );
    return null;
};

export default ScoreRing;
