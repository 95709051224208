import React, { ReactElement, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Button } from '@material-ui/core';
import AlertToast from 'components/AlertToast';
import Modal from 'components/Modal';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { qTasks, qResources, qResourceTypes, qUsers } from 'api/queries';
import { IPlanId } from 'typings/Plan';

import DeleteTaskCard from 'pages/plans/managetasks/managetaskscard/deletetaskgroupmodal/DeleteTaskCard';
import ResourceCard from '../resourceForm/ResourceCard';
import PlanContext from '../PlanContext';

interface Props {
    objectToDelete: any;
    children: ReactElement;
    taskGroupId?: number;
    afterClick: () => void;
}

const DeleteModal = React.forwardRef(
    ({ objectToDelete, children, afterClick, taskGroupId }: Props, ref: any): React.ReactElement => {
        const { planId }: IPlanId = useParams();
        const castedPlanId = parseFloat(planId);
        const { taskGroupData } = useContext(PlanContext);

        const objToDeleteStr = taskGroupId ? 'Task' : 'Resource';

        const [deleteTask] = useMutation(qTasks.DELETE_TASK);

        const [deleteResource] = useMutation(qResources.DELETE_RESOURCE, {
            refetchQueries: [
                {
                    query: qResourceTypes.QUERY_WITH_RESOURCES,
                    variables: {
                        planId: castedPlanId,
                    },
                },
                {
                    query: qUsers.GET_USERS_AND_RESOURCES,
                    variables: { planId: castedPlanId },
                },
            ],
        });

        const deleteTaskOrResource = async (id: number) => {
            if (taskGroupId) await deleteTask({ variables: { taskId: id } });
            else await deleteResource({ variables: { resourceId: parseFloat(id.toString()) } });
        };

        const onDelete = async (id: number, close: { (): void }) => {
            await deleteTaskOrResource(id)
                .then(() => {
                    toast(
                        <AlertToast
                            severity="success"
                            message={`${objToDeleteStr} Deleted: ${
                                taskGroupId ? objectToDelete.name : objectToDelete.label
                            }`}
                        />
                    );
                    afterClick();
                    close();
                    if (ref) {
                        ref?.current?.close();
                    }
                })
                .catch((e) => {
                    toast(<AlertToast severity="error" message={`Error deleting ${objToDeleteStr}: ${e.message}`} />);
                });
        };

        const closeModal = (close: { (): void }) => {
            close();
            if (ref) {
                ref?.current?.close();
            }
        };

        const taskGroupOfSelectedTask =
            taskGroupId && taskGroupData
                ? taskGroupData.find((taskGroupItem) => taskGroupItem.id === taskGroupId)
                : null;

        return (
            <Popup trigger={children} modal nested contentStyle={{ width: '390px' }}>
                {(close: () => void) => (
                    <Modal closeFunction={() => closeModal(close)}>
                        <h3>{`Delete ${objToDeleteStr}`}</h3>
                        <p>{`Are you sure you want to delete this resource? ${objToDeleteStr}`}</p>
                        {taskGroupId && taskGroupOfSelectedTask ? (
                            <DeleteTaskCard color={taskGroupOfSelectedTask?.color || ''} task={objectToDelete} />
                        ) : (
                            <ResourceCard resource={objectToDelete} />
                        )}
                        <div className="button-group">
                            <Button color="secondary" variant="outlined" onClick={() => closeModal(close)}>
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    onDelete(objectToDelete.id, close);
                                }}
                            >
                                Delete
                            </Button>
                        </div>
                    </Modal>
                )}
            </Popup>
        );
    }
);

export default DeleteModal;
