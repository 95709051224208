import { PlanSummaryPlanSummaryPlanParameters as Parameters } from 'typings/_graphql';

export interface ParameterArrayObjProps {
    [key: string]: Parameters;
}

export const convertArrayToObject = (
    planParameters: readonly Parameters[] | null | undefined
): ParameterArrayObjProps => {
    const reducer = (obj: any, item: Parameters) => ({
        ...obj,
        [item.label]: item,
    });
    const paramObj = {};
    return planParameters?.reduce(reducer, paramObj);
};

export const capitalize = (value: string): string => {
    if (value && value !== '') return value.charAt(0).toUpperCase() + value.slice(1);
    return '';
};

export const CUSTOMER_PRIORITY_REASONING = 'Customer Priority Reasoning';
export const CUSTOMER_PRIORITY_LEVEL = 'Customer Priority Level';
export const SOLUTION_HOLDER = 'Solution Holder';

export const PROBLEM_SUBHEADINGS = ['Capability Gap', 'Desired End State', 'Customer Priority Reasoning'];
export const PROBLEM_STAKEHOLDERS = ['Customer', 'End User'];

export const SOLUTION_SUBHEADINGS = [
    'Description of Technology',
    'Current State of Technology Relative to the Problem',
    'Risk Mitigation Strategy',
    'Commercial Value',
];
