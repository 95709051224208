import React, { useEffect, useState } from 'react';
import './plans/plans.scss';
import { useQuery } from '@apollo/client';
import { qPlans, qPlanSteps } from 'api/queries';
import { set, get } from 'lodash-es';
import {
    GetPlanSteps as PlanStepsData,
    GetPlanStepsVariables as PlanStepsVariables,
    GetPlan as PlanData,
    GetPlanVariables as PlanVariables,
} from 'typings/_graphql';
import { IPlanSteps } from 'typings/PlanStep';

import { useDispatch } from 'react-redux';
import { setPlan } from 'store/actions/Plan';
import { setSteps } from 'store/actions/PlanStep';
import PrivateRoute from 'PrivateRoute';
import { Switch, Route, useParams } from 'react-router-dom';
import { IPlanId } from 'typings/Plan';

// PAGES
import NoPage from 'pages/404';
import ProjectDashboard from 'pages/plans/ProjectDashboard';
import Details from 'pages/plans/Details';
import ManageTasks from './plans/ManageTasks';
import IdentifyResources from './plans/IdentifyResources';
import Justification from './plans/Justification';
import AlignResources from './plans/AlignResources';
import EstimateTasks from './plans/EstimateTasks';
import AssessTech from './plans/AssessTech';

export const Plans = (): React.ReactElement => {
    const [planStepIds, setPlanStepIds] = useState({});
    const { planId }: IPlanId = useParams();
    const dispatch = useDispatch();

    const { data: planData, loading: planLoading } = useQuery<PlanData, PlanVariables>(qPlans.QUERY_PLAN, {
        variables: { id: parseFloat(planId) },
    });

    useEffect(() => {
        if (!planLoading && planData?.plan) {
            dispatch(setPlan(planData?.plan));
        }
    }, [planData, planLoading]);

    let parentSteps: IPlanSteps = [];
    const { data: planStepData, loading: planStepLoading } = useQuery<PlanStepsData, PlanStepsVariables>(
        qPlanSteps.QUERY_PLANSTEPS,
        {
            variables: {
                id: parseFloat(planId),
            },
        }
    );

    useEffect(() => {
        if (!planStepLoading && planStepData?.planSteps) {
            parentSteps = planStepData?.planSteps.filter((planStep) => planStep.parentStep === null) || [];
            dispatch(setSteps(parentSteps));
            const newPlanStepIds = {};
            for (let i = 0; i < planStepData.planSteps.length; i++) {
                const item = planStepData.planSteps[i];
                set(newPlanStepIds, item.link, item.id);
            }
            setPlanStepIds(newPlanStepIds);
        }
    }, [planStepData, planStepLoading]);

    return (
        <Switch>
            <PrivateRoute path="/plan/:planId/project/dashboard" component={ProjectDashboard} exact />
            <PrivateRoute path="/plan/:planId/details/readinessresults" component={AssessTech} exact />
            <PrivateRoute path="/plan/:planId/details/answersoverview" component={AssessTech} exact />
            <PrivateRoute path="/plan/:planId/details/testingenvironments" component={AssessTech} exact />
            <PrivateRoute path="/plan/:planId/details/suggestednextsteps" component={AssessTech} exact />
            <PrivateRoute path="/plan/:planId/details" component={Details} />
            <PrivateRoute path="/plan/:planId/tasks" component={ManageTasks} />
            <PrivateRoute path="/plan/:planId/tasks" component={ManageTasks} />
            <PrivateRoute
                path="/plan/:planId/tasks/roadmap"
                component={ManageTasks}
                stepId={get(planStepIds, '/roadmap', 0)}
                exact
            />
            <PrivateRoute
                path="/plan/:planId/resources/estimatetasks"
                component={EstimateTasks}
                stepId={get(planStepIds, '/estimatetasks', 0)}
                exact
            />
            <PrivateRoute
                path="/plan/:planId/resources/identifyresources"
                component={IdentifyResources}
                stepId={get(planStepIds, '/identifyresources', 0)}
                exact
            />
            <PrivateRoute
                path="/plan/:planId/resources/allocatefunding"
                component={AlignResources}
                stepId={get(planStepIds, '/allocatefunding', 0)}
                exact
            />
            <PrivateRoute
                path="/plan/:planId/resources/monitorresources"
                component={AlignResources}
                stepId={get(planStepIds, '/alignresources', 0)}
                exact
            />
            <PrivateRoute
                path="/plan/:planId/report/projectsummary"
                component={Justification}
                stepId={get(planStepIds, '/plansummary', 0)}
                exact
            />
            <PrivateRoute
                path="/plan/:planId/report/projectroadmap"
                component={Justification}
                stepId={get(planStepIds, '/plansummary', 0)}
                exact
            />
            <Route component={NoPage} />
        </Switch>
    );
};

export default Plans;
