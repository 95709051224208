export const statusIcons: { [index: string]: any } = {
    applied: {
        id: 'applied',
        d: 'M3.714,2A1.714,1.714,0,0,0,2,3.714V17.429a1.714,1.714,0,0,0,1.714,1.714h7.209A5.994,5.994,0,1,0,15.714,8.257V7.143L10.571,2H3.714m0,1.714h6V8H14a6,6,0,0,0-4.92,9.429H3.714V3.714M14,9.714A4.286,4.286,0,1,1,9.714,14,4.286,4.286,0,0,1,14,9.714m-.857.857v4.286l3.094,1.851.643-1.046-2.451-1.449V10.571Z',
        transform: 'scale(1.23), translate(-1.25, -1.25)',
    },
    'in-progress': {
        id: 'in-progress',
        d: 'M11.92,2.03V3.851a7.211,7.211,0,0,1,0,14.313v1.8a9.014,9.014,0,0,0,0-17.937m-1.8.027a8.858,8.858,0,0,0-4.8,1.983L6.6,5.374A7.207,7.207,0,0,1,10.117,3.86v-1.8M4.042,5.311a8.91,8.91,0,0,0-1.992,4.8h1.8A7.226,7.226,0,0,1,5.331,6.6L4.042,5.311M2.059,11.918a9.049,9.049,0,0,0,1.992,4.8l1.28-1.289a7.213,7.213,0,0,1-1.469-3.515h-1.8M6.6,16.758,5.313,17.993a9.008,9.008,0,0,0,4.8,2.037v-1.8A7.213,7.213,0,0,1,6.6,16.758M11.469,6.51v4.732l4.056,2.407-.676,1.109-4.732-2.839V6.51Z',
        transform: 'scale(1.23), translate(-1.25, -1.25)',
    },
    approved: {
        id: 'approved',
        d: 'M13,2l5.4,5.4V18.2A1.8,1.8,0,0,1,16.6,20H5.8A1.8,1.8,0,0,1,4,18.2V3.8A1.8,1.8,0,0,1,5.8,2H13m3.6,16.2V8.3H12.1V3.8H5.8V18.2H16.6m-6.12-1.386L8,14.114,9.049,13.07l1.431,1.422L13.7,11.27l1.053,1.269Z',
        transform: 'scale(1.23), translate(-1.25, -1.25)',
    },
    'not-started': {
        id: 'not-started',
        d: 'M9,2a7,7,0,1,0,7,7A7.008,7.008,0,0,0,9,2M9,0A9,9,0,1,1,0,9,9,9,0,0,1,9,0Z',
        transform: 'scale(1.23), translate(0.5, 0.5)',
    },
    available: {
        id: 'available',
        d: 'M3,6V16.784h9.274a5.733,5.733,0,0,1-.288-1.8H6.595a1.8,1.8,0,0,0-1.8-1.8V9.595a1.791,1.791,0,0,0,1.8-1.8h8.987a1.8,1.8,0,0,0,1.8,1.8v.054a5.677,5.677,0,0,1,1.8.306V6H3m8.088,2.7a2.7,2.7,0,1,0,0,5.392,2.743,2.743,0,0,0,1.024-.207,5.311,5.311,0,0,1,1.645-2.84A2.684,2.684,0,0,0,11.088,8.7m8.654,2.939-3.478,3.5-1.213-1.231-1.267,1.276,2.471,2.5L21,12.9Z',
        transform: 'scale(1.23), translate(-1.25, -1.25)',
    },
    rejected: {
        id: 'rejected',
        d: 'M3,4.27L4.28,3L21,19.72L19.73,21L16.06,17.33C15.44,18 14.54,18.55 13.5,18.82V21H10.5V18.82C8.47,18.31 7,16.79 7,15H9C9,16.08 10.37,17 12,17C13.13,17 14.14,16.56 14.65,15.92L11.68,12.95C9.58,12.42 7,11.75 7,9C7,8.77 7,8.55 7.07,8.34L3,4.27M10.5,5.18V3H13.5V5.18C15.53,5.69 17,7.21 17,9H15C15,7.92 13.63,7 12,7C11.63,7 11.28,7.05 10.95,7.13L9.4,5.58L10.5,5.18Z',
        transform: 'scale(1.23), translate(-1.25, -1.25)',
    },
};
