import { gql } from 'apollo-boost';

const QUERY_RESOURCE_BY_ID = gql`
    query GetResourceById($id: Float!) {
        resource(resourceId: $id) {
            id
            amount
            description
            name
            startDate
            dueDate
            duration
            isDateRange
            contracts {
                id
                title
                isActive
                contractNumber
                TpocFirstName
                TpocLastName
            }
            fundType {
                id
                name
            }
            resourceType {
                id
                name
            }
            status {
                id
                status
            }
            user {
                id
                name
                avatar
            }
            taskResourceAssoc {
                id
                taskAmount
                task {
                    id
                    name
                    estimate
                    fundType {
                        id
                        name
                    }
                    users {
                        id
                        name
                        avatar
                    }
                    taskGroup {
                        id
                        name
                        color
                    }
                }
                resource {
                    id
                    amount
                }
            }
        }
    }
`;

const QUERY_RESOURCES = gql`
    query GetResources($planId: Float!) {
        resources(planId: $planId) {
            id
            amount
            name
            startDate
            dueDate
            duration
            isDateRange
            fundType {
                id
                name
            }
            resourceType {
                id
                name
            }
            status {
                id
                status
            }
            user {
                id
                name
                avatar
            }
            taskResourceAssoc {
                id
                taskAmount
                task {
                    id
                    name
                    estimate
                    fundType {
                        id
                        name
                    }
                    users {
                        id
                        name
                        avatar
                    }
                    taskGroup {
                        id
                        name
                        color
                    }
                }
                resource {
                    id
                    amount
                }
            }
        }
    }
`;

const CREATE_RESOURCE = gql`
    mutation CreateResource($planId: Float!, $data: CreateResourceInput!) {
        createResource(planId: $planId, data: $data) {
            id
            name
            amount
            description
            startDate
            dueDate
            duration
            isDateRange
            fundType {
                id
                name
            }
            resourceType {
                id
                name
            }
            status {
                id
                status
            }
            user {
                id
                name
                avatar
            }
        }
    }
`;

const DELETE_RESOURCE = gql`
    mutation DeleteResource($resourceId: Float!) {
        deleteResource(resourceId: $resourceId)
    }
`;

const UPDATE_RESOURCE = gql`
    mutation UpdateResource($planId: Float!, $resourceId: Float!, $data: UpdateResourceInput!) {
        updateResource(planId: $planId, resourceId: $resourceId, data: $data) {
            id
            name
            amount
            description
            startDate
            dueDate
            duration
            isDateRange
            fundType {
                id
                name
            }
            resourceType {
                id
                name
            }
            status {
                id
                status
            }
            user {
                id
                name
                avatar
            }
        }
    }
`;

const UPDATE_RESOURCE_DATES = gql`
    mutation UpdateResourceDates($id: Float!, $data: UpdateResourceDatesInput!) {
        updateResourceDates(id: $id, data: $data)
    }
`;

const QUERY_BY_FUND_TYPE = gql`
    query GetResourcesByFundType($planId: Float!) {
        getResourcesByFundType(planId: $planId) {
            id
            amount
            name
            fundType {
                id
                name
            }
            resourceType {
                id
                name
            }
            status {
                id
                status
            }
            user {
                id
                name
                avatar
            }
            taskResourceAssoc {
                id
                taskAmount
                task {
                    id
                    name
                    estimate
                    fundType {
                        id
                        name
                    }
                    users {
                        id
                        name
                        avatar
                    }
                    taskGroup {
                        id
                        name
                        color
                    }
                }
                resource {
                    id
                    amount
                }
            }
        }
    }
`;

export {
    QUERY_RESOURCE_BY_ID,
    QUERY_RESOURCES,
    CREATE_RESOURCE,
    DELETE_RESOURCE,
    UPDATE_RESOURCE,
    QUERY_BY_FUND_TYPE,
    UPDATE_RESOURCE_DATES,
};
