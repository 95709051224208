/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactElement, useState } from 'react';
import './taskgrouptable/TaskGroupTable.scss';

import { GetTaskGroupsTaskGroupsByPlanId } from 'typings/_graphql';
import { Icon, Grid, Collapse } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface Props {
    taskGroup: GetTaskGroupsTaskGroupsByPlanId;
    index: number;
    counterMessage: string;
    children?: ReactElement | ReactElement[];
    viewAllTasks?: boolean;
}

export default function TaskGroupTable({
    taskGroup,
    counterMessage,
    ...rest
}: React.PropsWithChildren<Props>): React.ReactElement {
    const { name, color, description } = taskGroup;
    const [descriptionExpanded, setDescriptionExpanded] = useState<boolean>(false);

    const expand = () => {
        setDescriptionExpanded(!descriptionExpanded);
    };

    const InfoIcon = withStyles(() => ({
        root: {
            fontSize: '24px',
        },
    }))(Icon);

    const ShowIcon = () =>
        description ? (
            <InfoIcon className="m-taskGroupTable__infoIcon" onClick={expand}>
                {descriptionExpanded ? 'info' : 'info_outline'}
            </InfoIcon>
        ) : (
            <></>
        );

    return (
        <>
            <div className="m-taskGroupTable">
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="additional-actions1-content"
                        style={{ backgroundColor: `${color}` }}
                    >
                        <div className={`m-taskGroupTable__header`}>
                            <span
                                className={`header-title`}
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                            >
                                {name} <ShowIcon />
                            </span>
                            <Collapse in={descriptionExpanded} className={`m-taskGroupTable__description`}>
                                <p
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                >
                                    {description}
                                </p>
                            </Collapse>
                            <div className="header-tasksmapped">
                                <span>{counterMessage}</span>
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="m-taskGroupTable__content">
                        <Grid container spacing={3}>
                            {rest.children}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
}
