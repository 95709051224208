import { gql } from 'apollo-boost';

const QUERY_TASK_BY_ID = gql`
    query GetTaskById($id: Float!) {
        task(taskId: $id) {
            id
            name
            description
            duration
            estimate
            flagged
            flaggedMessage
            status
            dueDate
            startDate
            isDateRange
            fundType {
                id
                name
            }
            taskGroup {
                id
                color
            }
            dependencies {
                id
                name
                taskGroup {
                    id
                }
            }
            dependees {
                id
                name
                taskGroup {
                    id
                }
            }
            users {
                id
                emailAddress
                name
                phone
                planRoles {
                    planRole
                }
                avatar
            }
            taskResourceAssoc {
                id
                taskAmount
                resource {
                    id
                    name
                    amount
                }
            }
        }
    }
`;

const SEARCH_TASK_NAME_BY_PLAN = gql`
    query SearchTaskNameByPlan($planId: Float!, $searchString: String!, $exclude: [Float!]!, $limit: Float!) {
        searchTaskNameByPlan(planId: $planId, search: $searchString, exclude: $exclude, limit: $limit) {
            id
            name
            taskGroup {
                id
            }
            dependencies {
                id
                name
            }
            dependees {
                id
                name
            }
            users {
                name
                avatar
            }
        }
    }
`;

const CREATE_TASK = gql`
    mutation CreateTask($data: CreateTaskInput!) {
        createTask(data: $data) {
            name
            description
            duration
            estimate
            flagged
            flaggedMessage
            status
            dueDate
            startDate
            taskGroupId
            isDateRange
        }
    }
`;

const UPDATE_TASK = gql`
    mutation UpdateTask($data: UpdateTaskInput!) {
        updateTask(data: $data) {
            name
            description
            duration
            estimate
            flagged
            flaggedMessage
            status
            dueDate
            startDate
            taskGroupId
            isDateRange
        }
    }
`;

const UPDATE_TASKS_ASSIGNEES = gql`
    mutation UpdateTasksAssignees($taskIds: [Float!]!, $userId: String!) {
        updateTasksAssignees(taskIds: $taskIds, userId: $userId)
    }
`;

const UPDATE_TASKS_STATUS = gql`
    mutation UpdateTasksStatus($taskIds: [Float!]!, $status: String!) {
        updateTasksStatus(taskIds: $taskIds, status: $status)
    }
`;

const UPDATE_TASKS_SHIFT_DATES = gql`
    mutation UpdateTasksShiftDates($taskIds: [Float!]!, $days: Float!) {
        updateTasksShiftDates(taskIds: $taskIds, days: $days)
    }
`;

const UPDATE_TASK_DATES = gql`
    mutation UpdateTaskDates($id: Float!, $data: UpdateTaskDatesInput!) {
        updateTaskDates(id: $id, data: $data)
    }
`;

const UPDATE_TASK_ESTIMATE = gql`
    mutation UpdateTaskEstimate($id: Float!, $data: UpdateTaskEstimateInput!) {
        updateTaskEstimate(id: $id, data: $data)
    }
`;

const DELETE_TASK = gql`
    mutation DeleteTask($taskId: Float!) {
        deleteTask(taskId: $taskId)
    }
`;

const GET_BY_PLAN_FUND_TYPE_AND_USER = gql`
    query GetTasksByPlanIdFundTypeAndUser($planId: Float!, $getByUser: Boolean!) {
        getTasksByPlanIdFundTypeAndUser(planId: $planId, getByUser: $getByUser) {
            id
            name
            description
            duration
            estimate
            fundType {
                name
                id
            }
            dependencies {
                id
                name
            }
            dependees {
                id
                name
            }
            users {
                id
                emailAddress
                name
                phone
                planRoles {
                    planRole
                }
                avatar
            }
            taskResourceAssoc {
                id
                taskAmount
                resource {
                    id
                    name
                    amount
                }
            }
        }
    }
`;

export {
    QUERY_TASK_BY_ID,
    SEARCH_TASK_NAME_BY_PLAN,
    CREATE_TASK,
    UPDATE_TASK,
    DELETE_TASK,
    UPDATE_TASK_ESTIMATE,
    UPDATE_TASKS_ASSIGNEES,
    UPDATE_TASKS_STATUS,
    UPDATE_TASKS_SHIFT_DATES,
    UPDATE_TASK_DATES,
    GET_BY_PLAN_FUND_TYPE_AND_USER,
};
