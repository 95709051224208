import React from 'react';
import { PlanContextProps } from 'pages/plans/PlanContext';
import { update, get } from 'lodash-es';

interface ReturnType {
    estimatesByFundType: (userId: string) => any;
}

const usePlanFunctions = (context: PlanContextProps): ReturnType => {
    const estimatesByFundType = (userId: string) => {
        const { taskGroupData } = context;
        const estimateTable = {};
        for (let i = 0; i < taskGroupData.length; i++) {
            const taskGroup = taskGroupData[i];
            if (taskGroup.tasks) {
                for (let t = 0; t < taskGroup.tasks?.length; t++) {
                    const task = taskGroup.tasks[t];
                    if (!task.fundType?.name) {
                        continue;
                    }
                    update(estimateTable, `${task.fundType.name}.total`, (n) =>
                        n ? n + task.estimate : task.estimate
                    );
                    if (get(task, `users[0].id`, '') === userId) {
                        update(estimateTable, `${task.fundType.name}.userTotal`, (n) =>
                            n ? n + task.estimate : task.estimate
                        );
                    }
                }
            }
        }
        return estimateTable;
    };

    return { estimatesByFundType };
};

export default usePlanFunctions;
