import React from 'react';
import { Table, TableHead, TableBody, TableCell } from '@material-ui/core';
import { GetTaskGroupsTaskGroupsByPlanIdTasks as Task } from 'typings/_graphql';

import UpdateTaskModalListItem from './UpdateTaskModalListItem';

interface Props {
    tasks?: readonly Task[] | undefined;
    assignee?: boolean;
    status?: boolean;
}

const UpdateTaskModalTable = ({ tasks, assignee, status }: Props): React.ReactElement => {
    const ASSIGNEE_PRIVILEGES = assignee || status;

    return (
        <div>
            <Table>
                <TableHead>
                    <TableCell width="50%" align="left">
                        Selected Tasks
                    </TableCell>
                    {ASSIGNEE_PRIVILEGES && (
                        <TableCell width="auto" align="left">
                            {assignee ? 'Current Assignee' : 'Assignee'}
                        </TableCell>
                    )}
                    {status && (
                        <TableCell width="auto" align="left">
                            Current Status
                        </TableCell>
                    )}
                </TableHead>
                <TableBody>
                    {tasks?.map((task: Task) => (
                        <UpdateTaskModalListItem statusList={status} assigneeList={assignee} task={task} />
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};
export default UpdateTaskModalTable;
