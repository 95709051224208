/* eslint consistent-return: "off" */
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GetPlans, GetPlansPlans } from 'typings/_graphql';
import { qPlans } from 'api/queries';

import Layouts from 'components/layouts/Layouts';
import PlanCard from './homepage/PlanCard';
import HomePageHeader from './homepage/HomePageHeader';

import './homepage/HomePage.scss';

const { Content, Subheader } = Layouts;

const HomePage = (): React.ReactElement => {
    const [seeArchived, setSeeArchived] = useState(false);
    const { data, loading, startPolling, stopPolling } = useQuery<GetPlans>(qPlans.QUERY_PLANS);

    const activeProjects = data?.plans.filter((plan) => plan.archived === false);
    const archivedProjects =
        data?.plans
            .filter((plan) => plan.archived === true)
            .sort((a: GetPlansPlans, b: GetPlansPlans) => (a.archivedDate > b.archivedDate ? -1 : 1)) || [];
    const Projects = seeArchived ? archivedProjects : activeProjects;
    const filteredProjects = Projects?.filter((plan) => plan.archived === false);
    const shortenCards = (Projects && Projects.length > 2) || false;

    useEffect(() => {
        startPolling(1000);
        return () => {
            stopPolling();
        };
    }, []);

    return (
        <Layouts>
            <Content>
                <Subheader>
                    <HomePageHeader
                        setSeeArchived={setSeeArchived}
                        seeArchived={seeArchived}
                        hasArchivedProjects={archivedProjects.length > 0}
                        hasActiveProjects={(filteredProjects && filteredProjects.length > 0) || false}
                    />
                </Subheader>
                {loading && 'Loading projects...'}
                <div className={`o-homePage__cardContainer ${shortenCards ? '-short' : '-long'}`}>
                    {Projects?.sort((a: GetPlansPlans) => (a.invitation.status === 0 ? 1 : -1)).map((project) => (
                        <PlanCard
                            seeArchived={seeArchived}
                            shortenCard={shortenCards}
                            key={project.id}
                            plan={project}
                        />
                    ))}
                </div>
            </Content>
        </Layouts>
    );
};

export default HomePage;
