import React, { ReactElement } from 'react';
import { Provider } from 'react-redux';
import store from '../../store/index';

interface Props {
    children: ReactElement[] | ReactElement;
}

export default function Redux({ children }: Props): ReactElement {
    return <Provider store={store}>{children}</Provider>;
}
