import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { qResources } from 'api/queries';
import { IPlanId } from 'typings/Plan';

import { GetResources, PlanSummaryPlanSummaryResources } from 'typings/_graphql';
import ResourceBreakdownTable from '../ResourceBreakdownTable';
import ResourceList from './financialstrategy/ResourceList';

import './financialstrategy/FinancialStrategy.scss';

interface FinancialStrategyProps {
    resourceSummary?: readonly PlanSummaryPlanSummaryResources[] | null;
}

const FinancialStrategy = ({ resourceSummary }: FinancialStrategyProps): React.ReactElement => {
    const { planId }: IPlanId = useParams();
    const [totalResources, setTotalResources] = useState(0);

    const {
        data: { resources } = {},
        startPolling,
        stopPolling,
        loading: resourcesLoading,
    } = useQuery<GetResources>(qResources.QUERY_RESOURCES, {
        variables: { planId: parseFloat(planId) },
    });

    useEffect(() => {
        startPolling(1000);
        return () => {
            stopPolling();
        };
    }, []);

    useEffect(() => {
        let total = 0;
        resourceSummary?.forEach((resource) => {
            if (resource?.status?.status !== 'Rejected') total += resource.amount || 0;
        });
        setTotalResources(total);
    }, [resourceSummary]);

    if (resourcesLoading) return <div>Loading...</div>;

    return (
        <div className="o-financialStrategy">
            {resources && resources.length > 0 ? (
                <>
                    <h2>Funding Overview</h2>
                    <ResourceBreakdownTable resourceSummary={resourceSummary} isReport />
                    <h2>Resource List</h2>
                    <ResourceList totalResources={totalResources} resources={resources} />
                </>
            ) : (
                <div>No resources have been identified.</div>
            )}
        </div>
    );
};

export default FinancialStrategy;
