import React, { useState, useEffect } from 'react';
import { Grid, Icon, IconButton } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { sortBy } from 'lodash-es';
import { IUser } from 'typings/User';
import { useSelector } from 'react-redux';
import { Store } from 'store/reducers';
import { useQuery } from '@apollo/client';
import {
    GetUsersAndResourcesUsersAndResources as userType,
    GetResourceTypesWithResourcesGetResourceTypesWithResources as resType,
} from 'typings/_graphql';
import { IPlanId } from 'typings/Plan';
import { qResourceTypes, qUsers } from 'api/queries';

import Layouts from 'components/layouts/Layouts';
import AutocompleteDropdown from 'components/AutocompleteDropdown';
import PageHeading from 'components/PageHeading';
import Offcanvas from 'components/OffCanvas';
import { ResourceConsumer, ResourceProvider } from './ResourceContext';
import IdentifyTable, { TYPE, ASSIGNEE } from './identifyresources/IdentifyTable';
import ResourceForm from './ResourceForm';

import './identifyresources/IdentifyResources.scss';

const { Content, Sidebar, Subheader, Navigation } = Layouts;

export default function DefineResources(): React.ReactElement {
    const { planId }: IPlanId = useParams();
    const userData: IUser = useSelector((storeState: Store) => storeState?.User);
    const castedPlanId = parseFloat(planId);
    const [sortedBy, setSortedBy] = useState<string>(TYPE);

    const {
        data: resourceTypesRes,
        startPolling,
        stopPolling,
        loading: resourceTypesLoading,
    } = useQuery(qResourceTypes.QUERY_WITH_RESOURCES, {
        variables: { planId: castedPlanId },
        fetchPolicy: 'no-cache',
    });

    const { data: usersRes, loading: usersResLoading } = useQuery(qUsers.GET_USERS_AND_RESOURCES, {
        variables: { planId: castedPlanId },
        fetchPolicy: 'no-cache',
    });

    function renderSortedData() {
        switch (sortedBy) {
            case TYPE:
                return resourceTypesRes?.getResourceTypesWithResources?.map((resourceType: resType) => (
                    <IdentifyTable rowInfo={resourceType} sortedBy={sortedBy} />
                ));
            case ASSIGNEE:
                return sortBy(usersRes.usersAndResources, ({ name }) => (name === userData.name ? -1 : 1)).map(
                    (user: userType) => <IdentifyTable rowInfo={user} sortedBy={sortedBy} />
                );
            default:
                return null;
        }
    }

    useEffect(() => {
        startPolling(1000);
        renderSortedData();
        return () => {
            stopPolling();
        };
    }, []);

    if (resourceTypesLoading && usersResLoading) return <div>Loading...</div>;

    return (
        <Layouts className="prevent-col-wrap">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Sidebar />
                <Content>
                    <Navigation />
                    <ResourceProvider>
                        <ResourceConsumer>
                            {({ setOffCanvasResource, offCanvasResource }) => (
                                <>
                                    <Subheader>
                                        <Grid container spacing={2}>
                                            <Grid item xs={10}>
                                                <PageHeading title="Identify Resources" />
                                            </Grid>
                                        </Grid>
                                    </Subheader>
                                    <AutocompleteDropdown
                                        label="Sort By"
                                        defaultValue={'Type'}
                                        dropdownOptions={['Type', 'Assignee']}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>, value: string) =>
                                            setSortedBy(value)
                                        }
                                    />
                                    {renderSortedData()}
                                    <Offcanvas state={offCanvasResource.canvas}>
                                        <IconButton
                                            onClick={() => {
                                                setOffCanvasResource({ canvas: false, editResource: false });
                                            }}
                                            className="close-icon"
                                        >
                                            <div className="o-identifyResources__closeIcon">
                                                <Icon>close</Icon>
                                            </div>
                                        </IconButton>
                                        <ResourceForm planId={parseFloat(planId)} />
                                    </Offcanvas>
                                </>
                            )}
                        </ResourceConsumer>
                    </ResourceProvider>
                </Content>
            </div>
        </Layouts>
    );
}
