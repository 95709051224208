/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { ReactElement, useEffect } from 'react';
import { LocalizationProvider } from '@material-ui/pickers';
import DoubleDatePicker from 'components/DoubleDatePicker';
import { useMutation } from '@apollo/client';
import { qTasks, qResources } from 'api/queries';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import { Input } from '@material-ui/core';
import { preventEnterSubmit } from 'components/utilities/preventEnterSubmit';
import { useForm, Controller } from 'react-hook-form';

interface RenderDoubleDatePickerProps {
    data: {
        id: string | number;
        startDate: Date | null;
        dueDate: Date | null;
        duration: number | null | undefined;
        isDateRange: boolean;
    };
    update?: 'task' | 'resource';
    label?: string | number | ReactElement;
}

const RenderDoubleDatePicker = ({
    data,
    label = 'Set Date',
    update = 'task',
}: RenderDoubleDatePickerProps): ReactElement => {
    const [updateTask] = useMutation(qTasks.UPDATE_TASK_DATES);
    const [updateResource] = useMutation(qResources.UPDATE_RESOURCE_DATES);
    const { control, register, watch, setValue, getValues, reset, handleSubmit } = useForm({
        defaultValues: {
            startDate: data.startDate || null,
            dueDate: data.dueDate || null,
            duration: data.duration || 0,
            isDateRange: data.isDateRange || false,
        },
        mode: 'onChange',
    });

    useEffect(() => {
        reset({
            startDate: data.startDate || null,
            dueDate: data.dueDate || null,
            duration: data.duration || 0,
            isDateRange: data.isDateRange || false,
        });
    }, [data]);

    const formHooks = { getValues, watch, setValue, handleSubmit };

    const saveNewDates = handleSubmit((formData) => {
        const newData = {
            startDate: formData.startDate,
            dueDate: formData.dueDate,
            duration: formData.duration,
            isDateRange: formData.isDateRange,
        };
        if (update === 'task') {
            updateTask({
                variables: {
                    id: data.id,
                    data: { ...newData },
                },
            });
        } else {
            const resourceId = typeof data.id === 'string' ? parseInt(data.id, 10) : data.id;
            updateResource({
                variables: {
                    id: resourceId,
                    data: { ...newData },
                },
            });
        }
    });

    return (
        <LocalizationProvider dateAdapter={DateFnsAdapter}>
            <form autoComplete="off" onKeyPress={preventEnterSubmit} onSubmit={saveNewDates}>
                <div className="-hidden">
                    <Controller as={Input} name="startDate" control={control} />
                    <Controller as={Input} name="dueDate" control={control} />
                    <Controller as={Input} name="duration" control={control} />
                    <input ref={register} name="isDateRange" type="checkbox" />
                </div>
                <DoubleDatePicker variant="button" label={label} formHooks={formHooks} triggerSave={saveNewDates} />
            </form>
        </LocalizationProvider>
    );
};

export default RenderDoubleDatePicker;
