import React from 'react';
import Layouts from 'components/layouts/Layouts';

const { Content } = Layouts;

const NoPage = (): React.ReactElement => (
    <Layouts>
        <Content>
            <h1>404 Page Not found</h1>
        </Content>
    </Layouts>
);

export default NoPage;
