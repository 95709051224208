import { defaults } from 'lodash-es';
import { GET_PLAN, SET_PLAN } from 'store/actions/Plan';
import { Plan } from 'typings/Plan';

const initialState = { id: 0, code: '', description: '', name: '', scope: {} };
interface Request {
    type: string;
    payload: any;
}

export interface State {
    id: number;
    code: string;
    description: string;
    name: string;
    scope: Record<string, unknown>;
}

export default (state = initialState, { type, payload }: Request): Plan => {
    switch (type) {
        case GET_PLAN: {
            return { ...state, ...payload };
        }
        case SET_PLAN: {
            const { data } = payload;
            const newState = defaults(data, initialState);
            return newState;
        }
        default:
            return state;
    }
};
