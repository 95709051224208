import React, { ReactElement } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import './assesstechintroduction/AssessTechIntroduction.scss';

interface Props {
    closeModal: () => void;
}

const AssessTechIntroduction = ({ closeModal }: Props): ReactElement => {
    const history = useHistory();
    const currentPlan: any = useParams();

    const modalNavigation = () => {
        history.push(`/plan/${currentPlan.planId}/details/readinessresults`);
        closeModal();
    };
    return (
        <div className="m-assessTechIntroduction">
            <Grid container direction="row">
                <Grid item xs={12} sm={6}>
                    <div className="m-assessTechIntroduction__head">Assess</div>
                    <div className="m-assessTechIntroduction__headSmall">The Maturity</div>
                    <p>
                        Understanding the <b>current maturity</b> of the technology solution,{' '}
                        <b>relative to the problem</b> or desired capability, is critical to establishing the tasks
                        necessary to mature and implement the solution.
                    </p>
                    <p>
                        <b>TRL</b> (technology readiness level) and <b>MRL</b> (manufacturing readiness level) are
                        scales that the Air Force and other organizations use to gauge the maturity of technologies. The
                        values in the scales are intended to show how far along a technology is on the path from concept
                        to a commercially available product. These values provide a good starting point when identifying
                        what needs to be done to mature the technology.
                    </p>
                </Grid>
            </Grid>
            <Button
                onClick={modalNavigation}
                color="primary"
                variant="contained"
                className="m-assessTechIntroduction__button"
            >
                Assess The Maturity!
            </Button>
        </div>
    );
};
export default AssessTechIntroduction;
