/* eslint-disable guard-for-in */
import React, { useCallback, useEffect, useState } from 'react';

import { debounce } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { useParams, useLocation } from 'react-router-dom';
import { qFundamentals } from 'api/queries';
import { IPlanId } from 'typings/Plan';
import { GetPlanParametersVariables, GetPlanParameters, UpdatePlanParametersByLabelVariables } from 'typings/_graphql';
import PrivateRoute from 'PrivateRoute';
import { useForm } from 'react-hook-form';
import { get, set, has } from 'lodash-es';

import Layouts from 'components/layouts/Layouts';
import AlertToast from 'components/AlertToast';
import IdentifyProblem from './details/IdentifyProblem';
import IdentifySolution from './details/IdentifySolution';
import IdentifyPeople from './details/IdentifyPeople';

import './details/Details.scss';

const { Sidebar, Content, Navigation } = Layouts;

export const Details = (): React.ReactElement => {
    const { planId }: IPlanId = useParams();
    const castedPlanId = parseFloat(planId);
    const [focusedField, setFocusedField] = useState<{ [key: string]: boolean }>({});
    const [timeouts, setTimeouts] = useState<{ [key: string]: string }>({});
    const [dataLoadedIntoForm, setDataLoadedIntoForm] = useState(false);
    const [formController] = useState(
        useForm({
            mode: 'onChange',
        })
    );
    const { watch, setValue, unregister } = formController;
    const fields = watch();
    const [updateParameters] = useMutation<UpdatePlanParametersByLabelVariables>(qFundamentals.UPDATE_PLAN_PARAMETERS);
    const location = useLocation();
    const { data: parameterData, loading: parametersLoading } = useQuery<GetPlanParameters, GetPlanParametersVariables>(
        qFundamentals.QUERY_PLAN_PARAMETERS,
        {
            variables: {
                id: castedPlanId,
            },
        }
    );

    useEffect(() => {
        const unregisterArray = [];
        for (const key in fields) {
            unregisterArray.push(key);
        }
        unregister(unregisterArray);
        setFocusedField({});
        setTimeouts({});
        setDataLoadedIntoForm(false);
    }, [location.pathname]);

    const saveField = useCallback(
        debounce((item: { name: string; value: string }) => {
            updateParameters({
                variables: { id: castedPlanId, data: { label: item.name, details: item.value } },
                refetchQueries: [
                    {
                        query: qFundamentals.QUERY_PLAN_PARAMETERS,
                        variables: { id: castedPlanId },
                    },
                ],
            }).catch((e) => {
                toast(<AlertToast severity="error" message={`Error with parameter: ${e.message}`} />);
            });
        }, 200),
        []
    );

    const loadFieldsWithData = () => {
        const arrayLength = parameterData?.plan.planParameters?.length || 0;
        for (let i = 0; i < arrayLength; i++) {
            const label = get(parameterData, `plan.planParameters[${i}].label`);
            const value = get(parameterData, `plan.planParameters[${i}].details`);
            if (label && !has(focusedField, `${label}`) && value !== watch(`${label}`)) {
                setValue(label, value);
            }
        }
        if (!dataLoadedIntoForm) {
            setDataLoadedIntoForm(true);
        }
    };

    useEffect(() => {
        if (!dataLoadedIntoForm) {
            return;
        }

        for (const key in fields) {
            if (
                fields[key] !==
                parameterData?.plan.planParameters?.find((parameter) => parameter.label === key)?.details
            ) {
                const item = { name: key, value: fields[key] };
                saveField(item);
            }
        }
    }, [fields]);

    useEffect(() => {
        loadFieldsWithData();
    }, [parameterData]);

    useEffect(() => {
        loadFieldsWithData();
    }, [dataLoadedIntoForm]);

    const setFocus = (e: { target: HTMLInputElement }) => {
        const label = e.target.name;
        const newFocus = focusedField;
        newFocus[`${label}`] = true;
        setFocusedField(newFocus);
    };

    const setBlur = (e: { target: HTMLInputElement }) => {
        const newTimeouts = timeouts;
        set(newTimeouts, `${e.target.name}`, () =>
            setTimeout(() => {
                const newFocus = focusedField;
                delete newFocus[`${e.target.name}`];
                setFocusedField(newFocus);
            }, 500)
        );
        setTimeouts(newTimeouts);
    };

    const detailsProps = { parameterData, formController, setFocus, setBlur };

    if (parametersLoading || !formController) return <span>Loading...</span>;
    if (!parameterData) {
        return <div>Ooops...</div>;
    }

    return (
        <Layouts>
            <Sidebar />
            <Content>
                <Navigation />
                <form>
                    <PrivateRoute
                        path="/plan/:planId/details/identifypeople"
                        component={IdentifyPeople}
                        {...detailsProps}
                        exact
                    />
                    <PrivateRoute
                        path="/plan/:planId/details/identifyproblem"
                        component={IdentifyProblem}
                        {...detailsProps}
                        exact
                    />
                    <PrivateRoute
                        path="/plan/:planId/details/identifysolution"
                        component={IdentifySolution}
                        {...detailsProps}
                        exact
                    />
                </form>
            </Content>
        </Layouts>
    );
};

export default Details;
