import { PlanStep, IPlanSteps } from 'typings/PlanStep';

export const UNLOCK_PLANSTEP = 'UNLOCK_PLANSTEP';
export const SET_PLANSTEP = 'SET_PLANSTEP';
export const SET_PLANSTEPS = 'SET_PLANSTEPS';

export const GO_TO_NEXT_STEP = 'GO_TO_NEXT_STEP';

export const setSteps = (data: IPlanSteps) => ({
    type: SET_PLANSTEPS,
    payload: data,
});

export const setStep = (data: PlanStep) => ({
    type: SET_PLANSTEP,
    payload: { data },
});

export const gotoNextStep = (url: string, planId: number) => ({
    type: GO_TO_NEXT_STEP,
    payload: { url, planId },
});
