import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { statusIcons } from './statusIcons/svgIcons';

interface SvgIconProps {
    children: string;
    style?: { [key: string]: string | number };
    [props: string]: any;
}

export default ({ children, style = {}, ...props }: SvgIconProps): React.ReactElement | null =>
    statusIcons[children]
        ? React.createElement(SvgIcon, { style: { ...style }, ...props }, [
              React.createElement('path', { ...statusIcons[children], key: children }, []),
          ])
        : null;
