import React from 'react';

const usePalette = () => (int: number) => {
    const colors = [
        [328, 63.9, 69.6] /* #e380b5 */,
        [312, 72.2, 84.5] /* #f3bce8 */,
        [71, 90.2, 67.8] /* #dcf763 */,
        [48, 100, 64.1] /* #ffd948 */,
        [279, 96.1, 69.6] /* #c767fc */,
        [205, 82.7, 59.2] /* #41a5ed */,
        [37, 88.4, 62.9] /* #f3b54d */,
        [14, 100, 86.3] /* #ffc9b9 */,
        [121, 26.4, 60.6] /* #7fb681 */,
        [169, 50, 70.2] /* #8dd9cb */,
        [53, 90.1, 68.2] /* #f6e664 */,
        [145, 62.4, 64.5] /* #6bdd9a */,
        [226, 100, 83.7] /* #adbfff */,
        [338, 100, 75.1] /* #ff80af */,
        [254, 95.7, 81.8] /* #b9a5fd */,
        [94, 82.7, 66.1] /* #9ff061 */,
        [296, 72.8, 66.9] /* #df6ce8 */,
        [224, 100, 73.7] /* #799dff */,
        [281, 75, 82.7] /* #dfb2f4 */,
        [281, 60.8, 72] /* #c88ce3 */,
        [180, 2.4, 75.9] /* #c0c3c3 */,
    ];
    const cycle = Math.min(10, Math.floor(int / colors.length));
    return `hsl(${colors[int % colors.length]
        .map((a: number, i: number) => (i === 2 ? a - cycle * 3 : a))
        .map((a: string | number, i: number) => (i > 0 ? `${a}%` : a))
        .join(' ')})`;
};

export default usePalette;
