import React, { ReactElement } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

type TooltipProps = {
    body: any; // really. Anything can go here. This isn't just laziness
    children: ReactElement;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tooltip: {
            backgroundColor: theme.palette.grey[700],
            color: theme.palette.common.white,
            fontSize: 12,
        },
        arrow: {
            '&::before': {
                backgroundColor: theme.palette.grey[700],
            },
        },
    })
);

const ToolTip: React.FC<TooltipProps> = ({ body, children }) => {
    const tooltipClass = useStyles();

    return (
        <Tooltip title={body} classes={tooltipClass} arrow>
            {children}
        </Tooltip>
    );
};

export default ToolTip;
