import React, { ReactNode } from 'react';
import { Alert } from '@material-ui/lab';

interface AlertProps {
    closeToast?: any;
    severity?: 'success' | 'error' | 'info' | 'warning';
    message: string | string[] | ReactNode;
    title?: string;
}

export default function AlertToast({
    closeToast,
    title = '',
    message,
    severity = 'success',
}: AlertProps): React.ReactElement {
    return (
        <div>
            <Alert variant={'filled'} severity={severity} onClick={closeToast} role="alert">
                {title && <b>{title}</b>}
                {message}
            </Alert>
        </div>
    );
}
