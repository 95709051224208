import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from 'PrivateRoute';
import { cloneDeep } from 'lodash-es';

import { useDispatch } from 'react-redux';
import { saveUserState } from 'store/actions/User';

import { useKeycloak } from '@react-keycloak/web';
import { useLazyQuery } from '@apollo/client';
import { qUsers } from 'api/queries';

import { FormatScopes, FormatPageIntroductions } from 'components/utilities/FormatState';

import HomePage from 'pages/HomePage';
import Plans from 'pages/Plans';
import NoPage from 'pages/404';
import CreatePlan from 'pages/CreatePlan';

const getUserProfile = async (keycloak: Keycloak.KeycloakInstance): Promise<Keycloak.KeycloakProfile> =>
    keycloak.loadUserProfile();

export default function ReactRouter(): React.ReactElement {
    const [foundUser, setFoundUser] = useState(true);
    const { keycloak, initialized } = useKeycloak();

    const dispatch = useDispatch();
    const [sendQuery] = useLazyQuery(qUsers.GET_USER, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            const currentUser = cloneDeep(data.user);
            currentUser.scopeRoles = FormatScopes(currentUser.scopeRoles);
            currentUser.pageIntroductions = FormatPageIntroductions(currentUser.pageIntroductions);
            dispatch(saveUserState(currentUser));
        },
        onError: () => {
            setFoundUser(false);
        },
    });

    useEffect(() => {
        sendQuery();
    }, []);

    useEffect(() => {
        if (initialized && keycloak?.token) {
            getUserProfile(keycloak);
        }
    }, [initialized, keycloak]);

    if (!foundUser) {
        if (!keycloak?.authenticated) {
            keycloak?.login({ redirectUri: window.location.href });
        }
        return <h2>Issue fetching authenticated user.</h2>;
    }
    return (
        <Switch>
            <PrivateRoute path="/" component={HomePage} exact />
            <PrivateRoute path="/createPlan" component={CreatePlan} exact />
            <PrivateRoute path="/plan/:planId" component={Plans} />
            <Route component={NoPage} />
        </Switch>
    );
}
