/* eslint no-extend-native: "off" */
declare global {
    interface Date {
        getWeekCountMonth(): number;
        getWeekOfYear(): number;
        getWeek(): number;
        addDays(days: number): Date;
    }
}

Date.prototype.getWeekCountMonth = function (): number {
    const [year, monthNumber] = [this.getFullYear(), this.getMonth() + 1];

    const firstOfMonth = new Date(year, monthNumber - 1, 1);
    const lastOfMonth = new Date(year, monthNumber, 0);
    const used = firstOfMonth.getDay() + 6 + lastOfMonth.getDate();
    return Math.ceil(used / 7);
};

Date.prototype.getWeekOfYear = function (): number {
    const onejan = new Date(this.getFullYear(), 0, 1).getDate();
    const dayOfYear = (this.getDate() - onejan + 86400000) / 86400000;
    return Math.ceil(dayOfYear / 7);
};

Date.prototype.getWeek = function () {
    const startOfMonth = new Date(
        this.toLocaleDateString()
            .split('/')
            .map((a, i) => (i === 1 ? 1 : a))
            .join('/')
    );
    return this.getWeekOfYear() - new Date(startOfMonth).getWeekOfYear();
};

Date.prototype.addDays = function (days: number): Date {
    this.setDate(this.getDate() + days);
    return this;
};

export {};
