import React, { ReactElement } from 'react';

const WhatAreCategories = (): ReactElement => (
    <div className="m-whatAreCategories">
        <div className="m-whatAreCategories__heading">Government Resource Categories</div>
        <ul>
            <li>
                <span className="m-whatAreCategories__item__title">RDT&E</span>
                <span className="m-whatAreCategories__item__subTitle">Research, development, test, and evaluation</span>
                <p>
                    This category represents tasks like: Research and development of equipment, materials, computer
                    software and their testing and evaluation.
                </p>
            </li>
            <li>
                <span className="m-whatAreCategories__item__title">PROC (SCN)</span>
                <span className="m-whatAreCategories__item__subTitle">Procurement</span>
                <p>
                    This category represents tasks that are used for the purchase of goods and non construction related
                    costs. It is used for approved acquisition programs and all costs integral and necessary to deliver
                    a useful end item intended for operational use or inventory upon delivery.
                </p>
            </li>
            <li>
                <span className="m-whatAreCategories__item__title">O&M</span>
                <span className="m-whatAreCategories__item__subTitle">Operation and Maintenance</span>
                <p>
                    This category represents tasks like: Civilian salaries, travel, training, and education, facility
                    maintenance, and base operations support.
                </p>
            </li>
            <li>
                <span className="m-whatAreCategories__item__title">MILPERS</span>
                <span className="m-whatAreCategories__item__subTitle">Military Personnel</span>
                <p>
                    This category represents tasks related to the salaries and other compensation for active and retired
                    military personnel as well as reserve forces.
                </p>
            </li>
            <li>
                <span className="m-whatAreCategories__item__title">MILCON</span>
                <span className="m-whatAreCategories__item__subTitle">Military Construction</span>
                <p>
                    This category represents tasks that relate to major construction projects such as bases, schools,
                    missile storage facilities and maintenance facilities, medical clinics, libraries, and military
                    housing.
                </p>
            </li>
            <div className="m-whatAreCategories__divider">Non-Government Resource Categories</div>
            <li>
                <span className="m-whatAreCategories__item__subTitle">Commercial</span>
                <p>A category that is not necessarily restricted with what type of tasks it could fund.</p>
            </li>
        </ul>
    </div>
);

export default WhatAreCategories;
