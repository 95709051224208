import React from 'react';
import { GetTaskGroupsTaskGroupsByPlanIdTasksUsers } from 'typings/_graphql';

import AvatarGroups from '@material-ui/lab/AvatarGroup';
import UserAvatar from 'components/UserAvatar';

import './avatargroup/AvatarGroup.scss';
import { IUser } from 'typings/User';
import { useSelector } from 'react-redux';
import { Store } from 'store/reducers';

const MAX_VISIBLE = 3;

interface AvatarGroupProps {
    users: GetTaskGroupsTaskGroupsByPlanIdTasksUsers[];
}

interface UserListProps {
    users: Array<GetTaskGroupsTaskGroupsByPlanIdTasksUsers>;
}

interface UsersGrouped {
    shown: GetTaskGroupsTaskGroupsByPlanIdTasksUsers[];
    hidden: GetTaskGroupsTaskGroupsByPlanIdTasksUsers[];
}

const UserList = ({ users }: UserListProps) => (
    <div>
        {users.map((user: GetTaskGroupsTaskGroupsByPlanIdTasksUsers) => (
            <div>{user.name}</div>
        ))}
    </div>
);

export default function AvatarGroup({ users = [] }: AvatarGroupProps): React.ReactElement {
    const userData: IUser = useSelector((storeState: Store) => storeState?.User);
    const isOneOff = users.length - MAX_VISIBLE - 1 === 1;

    const usersGrouped = users
        .filter((userItem) => userItem.id !== userData.id)
        .reduce(
            (acc: UsersGrouped, userItem, i: number) =>
                i < MAX_VISIBLE || isOneOff
                    ? { shown: [...acc.shown, userItem], hidden: acc.hidden }
                    : { shown: acc.shown, hidden: [...acc.hidden, userItem] },
            { shown: [], hidden: [] }
        );

    return (
        <div className="a-avatarGroup">
            <AvatarGroups data-testid="AvatarGroup" spacing="medium" max={MAX_VISIBLE + 1}>
                {usersGrouped.shown.map((user: GetTaskGroupsTaskGroupsByPlanIdTasksUsers) => (
                    <div key={user.id} className="a-avatarGroup__avatarContainer">
                        <UserAvatar key={user.id} user={user} />
                    </div>
                ))}
                {usersGrouped.hidden.length > 0 && (
                    <div className="a-avatarGroup__avatarContainer">
                        <UserAvatar
                            name={`+ ${usersGrouped.hidden.length}`}
                            message={<UserList users={usersGrouped.hidden} />}
                            bypassUserTooltipMessage
                        />
                    </div>
                )}
            </AvatarGroups>
        </div>
    );
}
