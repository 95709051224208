import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';

import UserAvatar from 'components/UserAvatar';

interface Resource {
    [index: string]: any;
}

export default ({ title, ...props }: Resource): React.ReactElement => {
    let totalAmount = 0;
    Object.keys(props).map((key: string) =>
        props[key].forEach((item: any) => {
            totalAmount += item.amount;
        })
    );
    let titleCopy = title;
    if (title.includes('Government Funding:')) titleCopy = title.slice(20);
    if (title.includes('Non-Government')) titleCopy = 'Non-Government';

    return (
        <>
            <div className="o-resourceList__header">{title}</div>
            <Table>
                <TableBody>
                    {Object.keys(props).length === 0 ? (
                        <TableRow>
                            <TableCell>
                                <span className="o-resourceList__tableText">
                                    No {titleCopy} Resources have been identified
                                </span>
                            </TableCell>
                        </TableRow>
                    ) : (
                        <>
                            {Object.keys(props).map((key: string) => (
                                <>
                                    {props[key].map((item: any) => (
                                        <>
                                            <TableRow className="o-resourceList__tableRow">
                                                <TableCell className="test" width="50%">
                                                    <span className="o-resourceList__resourceName">{item.name}</span>
                                                </TableCell>
                                                <TableCell width="35%">
                                                    <div className="o-resourceList__resourceUser">
                                                        <UserAvatar
                                                            hideTooltip
                                                            name={item.user ? item.user.name : 'No User'}
                                                        />
                                                        &nbsp;&nbsp;
                                                        <span>{item.user ? item.user.name : 'No User'}</span>
                                                    </div>
                                                </TableCell>
                                                <TableCell width="15%">
                                                    <div className="o-resourceList__resourceAmount">
                                                        <strong>$</strong>
                                                        {item.amount ? item.amount.toLocaleString() : 0}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    ))}
                                </>
                            ))}
                            {titleCopy !== 'In-Kind' && (
                                <TableRow className="o-resourceList__tableRow -total">
                                    <TableCell width="50%" />
                                    <TableCell width="35%">
                                        <div className="o-resourceList__resourceTotal">{titleCopy} Total:</div>
                                    </TableCell>
                                    <TableCell width="15%">
                                        <div className="o-resourceList__resourceAmount">
                                            <strong>$</strong>
                                            {totalAmount.toLocaleString()}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )}
                        </>
                    )}
                </TableBody>
            </Table>
        </>
    );
};
