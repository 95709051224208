import { createTheme } from '@material-ui/core/styles';

/*

  I couldn't wait around for Material to support CSS Variables. I did it myself.
  This chunk binds Material UI components to CSS Variables. The catch is that
  the variables have to be HSL params. I've included translucency CSS variables
  for opacity adjustment.

  -- DO NOT MAKE A NEW createTheme PER THEME! --

  Theming is now handled with root.css files per usual.

*/

export const getColor = (colorName: string, transparency?: number | undefined): string => {
    if (colorName === '--white-hsl') return `hsl(var(${colorName}))`;
    return `hsl(var(${colorName})${transparency ? `, ${transparency}` : ``})`;
};

const theme = createTheme({
    palette: {
        common: {
            black: getColor('--black-hsl'),
            white: getColor('--white-hsl'),
        },
        type: 'light',
        primary: {
            main: getColor('--primary-main'),
            light: getColor('--primary-light'),
            dark: getColor('--primary-dark'),
            contrastText: getColor('--white-hsl'),
        },
        secondary: {
            main: getColor('--secondary-main'),
            light: getColor('--secondary-light'),
            dark: getColor('--secondary-dark'),
            contrastText: getColor('--white-hsl'),
        },
        error: {
            main: getColor('--error-main'),
            light: getColor('--error-light'),
            dark: getColor('--error-dark'),
            contrastText: getColor('--white-hsl'),
        },
        warning: {
            main: getColor('--warning-main'),
            light: getColor('--warning-light'),
            dark: getColor('--warning-dark'),
            contrastText: getColor('--warning-contrast-text'),
        },
        success: {
            main: getColor('--success-main'),
            light: getColor('--success-light'),
            dark: getColor('--success-dark'),
            contrastText: getColor('--success-contrast-text'),
        },
        grey: {
            50: getColor('--gray-050'),
            100: getColor('--gray-100'),
            200: getColor('--gray-200'),
            300: getColor('--gray-300'),
            400: getColor('--gray-400'),
            500: getColor('--gray-500'),
            600: getColor('--gray-600'),
            700: getColor('--gray-700'),
            800: getColor('--gray-800'),
            900: getColor('--gray-900'),
        },

        contrastThreshold: 3,
        tonalOffset: 0.2,
        text: {
            primary: getColor('--text-primary'),
            secondary: getColor('--text-secondary'),
            disabled: getColor('--text-disabled'),
        },
        divider: getColor('--divider'),
        background: {
            paper: getColor('--paper'),
            default: getColor('--default'),
        },
    },
    typography: {
        fontFamily: 'Montserrat',
        fontSize: 14,
    },
});

theme.overrides = {
    MuiAlert: {
        root: {
            alignItems: 'center',
        },
    },
    MuiTableCell: {
        root: {
            padding: 10,
        },
        head: {
            backgroundColor: getColor('--divider'),
            color: getColor('--deep-gray'),
            fontSize: 16,
            fontWeight: 'bold',
        },
        body: {
            fontSize: 14,
            '&:first-child': {
                paddingLeft: 30,
            },
        },
    },
    MuiTableRow: {
        root: {
            '&:last-child': {
                borderBottom: 0,
            },
        },
    },
    MuiInputLabel: {
        root: {
            alignItems: 'center',
            paddingBottom: 5,
        },
    },
    MuiSwitch: {
        track: {
            backgroundColor: getColor('--gray-800'),
        },
    },
    MuiCheckbox: {
        root: {
            color: getColor('--secondary-main'),
        },
    },
    MuiBadge: {
        badge: {
            fontWeight: 600,
            fontSize: 12,
        },
    },
    MuiChip: {
        outlinedSecondary: {
            borderSize: 2,
            borderColor: getColor('--error-main', 0.26),
            backgroundColor: getColor('--error-light', 0.1),
            color: getColor('--error-main'),
        },
    },
    MuiButton: {
        root: {
            textTransform: 'none',
            fontWeight: 600,
            fontSize: 16,
            height: 'fit-content',
        },
        containedPrimary: {
            color: getColor('--white-hsl'),
            backgroundColor: getColor('--secondary-main'),
            '&:hover': {
                backgroundColor: getColor('--secondary-dark'),
            },
            '&:focus': {
                backgroundColor: getColor('--secondary-main'),
            },
        },
        containedSecondary: {
            color: getColor('--text-primary'),
            backgroundColor: getColor('--text-primary', 0.04),
            '&:hover': {
                backgroundColor: getColor('--text-primary', 0.1),
            },
            '&:focus': {
                backgroundColor: getColor('--text-primary', 0.1),
            },
        },
        outlinedPrimary: {
            borderColor: getColor('--secondary-main'),
            color: getColor('--secondary-main'),
            '&:hover': {
                borderColor: getColor('--secondary-main'),
                backgroundColor: getColor('--secondary-main', 0.1),
            },
            '&:focus': {
                borderColor: getColor('--secondary-main'),
                backgroundColor: getColor('--secondary-main', 0.1),
            },
        },
        outlinedSecondary: {
            color: getColor('--text-primary'),
            borderColor: getColor('--text-primary', 0.26),
            '&:hover': {
                backgroundColor: getColor('--text-primary', 0.04),
                borderColor: getColor('--text-primary', 0.5),
            },
            '&:focus': {
                backgroundColor: getColor('--text-primary', 0.04),
                borderColor: getColor('--text-primary', 0.5),
            },
        },

        textPrimary: {
            color: getColor('--secondary-main'),
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent',
                color: getColor('--secondary-dark'),
            },
            '&:focus': {
                backgroundColor: 'transparent',
            },
            '&:disabled': {
                backgroundColor: 'transparent',
            },
        },
        textSecondary: {
            '&:hover': {
                backgroundColor: getColor('--secondary-main', 0.04),
            },
            '&:focus': {
                backgroundColor: getColor('--secondary-main', 0.1),
            },
            '&:disabled': {
                backgroundColor: 'transparent',
            },
        },
    },
    MuiTooltip: {
        arrow: {
            color: '#666',
        },
        tooltip: {
            color: theme.palette.common.white,
            fontWeight: 'bold',
            backgroundColor: '#666',
            fontSize: theme.typography.pxToRem(12),
        },
    },
    MuiPaper: {
        root: {
            borderColor: getColor('--gray-border'),
            border: '1px solid',
        },
        rounded: {
            borderRadius: 4,
        },
        elevation1: {
            boxShadow: '0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05)',
        },
    },
    MuiCardHeader: {
        root: {
            borderBottom: '1px solid #DADEDE',
        },
        title: {
            fontSize: 18,
            fontWeight: 600,
            color: getColor('--black-hsl'),
        },
        action: {
            margin: 0,
        },
    },
    MuiCardContent: {
        // @ts-expect-error ts doesn't recognize scss style nesting
        padding: 19,
        '&:last-child': {
            paddingBottom: 20,
        },
    },
    MuiTabs: {
        root: {
            justifyContent: 'space-between',
        },
        flexContainer: {
            justifyContent: 'space-between',
        },
    },
    MuiTab: {
        wrapper: {
            fontSize: 14,
            fontWeight: 600,
        },
    },
    MuiList: {
        root: {
            backgroundColor: '#ffffff',
        },
    },
    MuiInputBase: {
        input: {
            height: 18,
            padding: '10px 14px',
            '&:focus': {
                borderSize: 1,
            },
        },
        root: {
            width: '100%',
        },
    },
    MuiPickersDay: {
        root: {
            borderRadius: 0,
            '&.Mui-active': {
                backgroundColor: getColor('--secondary-main'),
            },
            '&.Mui-selected': {
                borderRadius: '50%',
                backgroundColor: getColor('--secondary-main'),
            },
        },
    },
    MuiPickersDateRangeDay: {
        rangeIntervalDayHighlightStart: {
            marginTop: 0,
            marginRight: 0,
            marginBottom: 0,
            marginLeft: 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            backgroundColor: 'transparent',
        },
        rangeIntervalDayHighlightEnd: {
            marginTop: 0,
            marginRight: 0,
            marginBottom: 0,
            marginLeft: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor: 'transparent',
        },
        rangeIntervalDayHighlight: {
            backgroundColor: getColor('--picker-range-highlight'),
            '&:first-child': {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            },
            '&:last-child': {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
            },
        },
    },
    MuiButtonBase: {
        root: {
            '&.Mui-disabled': {
                backgroundColor: 'lightgray',
            },
        },
    },
    MuiPickersDesktopDateRangeCalendar: {
        root: {
            justifyContent: 'space-evenly',
        },
        rangeCalendarContainer: {
            '&:not(:last-child)': {
                borderRight: 0,
            },
        },
        calendar: {
            minHeight: 254,
        },
    },
    MuiPickersCalendar: {
        root: {
            minHeight: 224,
        },
    },
};

export default theme;
