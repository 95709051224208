import React, { ReactElement } from 'react';
import NumberFormat from 'react-number-format';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import UserAvatar from 'components/UserAvatar';
import {
    GetTaskGroupsTaskGroupsByPlanIdTasks,
    GetTaskGroupsTaskGroupsByPlanId,
    GetResourcesResources,
} from 'typings/_graphql';

import { TaskWithTaskGroup } from 'components/utilities/sortTaskGroupData';
import './resourcetable/ResourceTable.scss';
import AlignResourceListItem from './resourcetable/AlignResourceListItem';

interface ResourceTableProps {
    label: string;
    tasks: readonly GetTaskGroupsTaskGroupsByPlanIdTasks[] | TaskWithTaskGroup[] | null;
    taskGroup?: GetTaskGroupsTaskGroupsByPlanId;
    filterType: string;
    resources?: readonly GetResourcesResources[];
}

export interface ErrorState {
    label: string;
    amount: number;
}

export const TASK_GROUP = 'Task Group';
export const ASSIGNEE = 'Assignee';

export default function ResourceTable({
    label,
    tasks,
    taskGroup,
    filterType,
    resources = [],
}: ResourceTableProps): ReactElement {
    let totalAmountEstimated = 0;
    let fundingAllocated = 0;
    let totalTasksAllocated = 0;

    tasks?.forEach((task) => {
        totalAmountEstimated += task.estimate || 0;
        task.taskResourceAssoc?.forEach((tr) => {
            fundingAllocated += tr.taskAmount || 0;
        });
        if (task && task.estimate) {
            if (task.estimate <= fundingAllocated) totalTasksAllocated++;
        }
    });

    return (
        <div className="o-resourceTable">
            <div className="o-resourceTable__tableHeader" style={{ backgroundColor: `${taskGroup?.color}` }}>
                <section>
                    <div
                        className={`o-resourceTable__tableHeader-${filterType === ASSIGNEE ? 'assignee' : 'taskGroup'}`}
                    >
                        {filterType === ASSIGNEE && <UserAvatar name={label} hideTooltip />}
                        <h1>{label}</h1>
                    </div>
                    <p className="o-resourceTable__tasksAllocated">{`${totalTasksAllocated}/ ${
                        tasks?.length || '0'
                    } Tasks are allocated`}</p>
                </section>
                <section className="o-resourceTable__tableHeader-rightSide">
                    <p>Funding Allocated:</p>
                    <b>$</b>
                    <NumberFormat displayType="text" thousandSeparator value={fundingAllocated} />
                    <p>Total Estimated:</p>
                    <b>$</b>
                    <NumberFormat displayType="text" thousandSeparator value={totalAmountEstimated} />
                </section>
            </div>
            <div>
                <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '35%' }} align="left">
                                    Task Name
                                </TableCell>
                                <TableCell style={{ width: '10%' }} align="center">
                                    {filterType === ASSIGNEE ? 'Task Group' : 'Assignee'}
                                </TableCell>
                                <TableCell style={{ width: '10%' }} align="center">
                                    Estimate
                                </TableCell>
                                <TableCell align="center">{`Gov't Auth. Category`}</TableCell>
                                <TableCell align="center">Allocate Funding</TableCell>
                                <TableCell align="center">Total Allocated</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tasks?.map((task: any) => (
                                <AlignResourceListItem task={task} filterType={filterType} resources={resources} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}
