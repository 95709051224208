import { RangeProps } from './dates';
import { TaskGroupDataModel } from './data';
import ganttState from '../ganttState';

export interface UtilsProps {
    getRandomInt: (value: number) => number;
    getCandleWidth: (candlerange: RangeProps) => number;
    getCandleWidthAsPercentage: (candlerange: RangeProps) => number;
    getRelationshipOrientation: (
        x1: number,
        y1: number,
        width1: number,
        x2: number,
        y2: number,
        width2: number
    ) => boolean[];
    getVectorOfTaskGroups: (rawData: TaskGroupDataModel[]) => TaskGroupDataModel[];
}

export const getRandomInt = (max: number): number => Math.floor(Math.random() * max);

const getCandleWidth = (candlerange: RangeProps): number =>
    ((candlerange.end.getTime() - candlerange.start.getTime()) / ganttState.Gantt.milisecondWidth) * 100;

const getCandleWidthAsPercentage = (candlerange: RangeProps): number =>
    candlerange.end.getTime() - (candlerange.start.getTime() / ganttState.Gantt.milisecondWidth) * 100;

export const getRelationshipOrientation = (
    x1: number,
    y1: number,
    width1: number,
    x2: number,
    y2: number
): boolean[] => {
    // index 0 is related to start
    // index 1 is related to end
    const out = [false, false];
    if (x1 + width1 < x2) out[0] = true; // if start point horizontally preceeds end
    if (y1 < y2) out[1] = true; // if start point virtically preceeds end
    // if(y1 < y2) out[0] = true

    return out;
};

const getVectorOfTaskGroups = (rawData: TaskGroupDataModel[]): TaskGroupDataModel[] => {
    const out: TaskGroupDataModel[] = [];
    rawData.forEach((d: TaskGroupDataModel) => {
        out.push({ ...d, children: [] });
    });
    return out;
};

export default {
    getRandomInt,
    getCandleWidth,
    getCandleWidthAsPercentage,
    getRelationshipOrientation,
    getVectorOfTaskGroups,
};
