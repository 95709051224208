import React, { ReactElement } from 'react';
import { Collapse, Box, Typography } from '@material-ui/core';
import SummaryTable from './SummaryTable';
import FundingTable from './FundingTable';
import SummaryTableGraph from './SummaryTableGraph';
import './AlignResourceExpandableRow.scss';

export interface SummaryTableData {
    estimate: number;
    totalAllocated: number;
}

export interface FundingTableData {
    name: string;
    fundTypeName?: string;
    amount: number;
    totalAllocation: number;
}

interface AlignResourceExpandableRowProps {
    open: boolean;
    summaryTableData: SummaryTableData;
    fundingTableData: FundingTableData[];
    allocationStatus: string;
}

const AlignResourceExpandableRow = ({
    open,
    summaryTableData,
    fundingTableData,
    allocationStatus,
}: AlignResourceExpandableRowProps): ReactElement => (
    <Collapse in={open} timeout="auto" unmountOnExit>
        <Box margin={1} width={1} className="o-align-resource-expandable-row">
            <Typography variant="subtitle1" gutterBottom component="div">
                <b>Allocated Funding Overview</b>
            </Typography>
            <div className="a-graph__container">
                <SummaryTableGraph summaryTableData={summaryTableData} />
                <div className="summaryTable">
                    <SummaryTable summaryTableData={summaryTableData} allocationStatus={allocationStatus} />
                </div>
            </div>
            <div className="fundingTable__container">
                <FundingTable fundingTableData={fundingTableData} />
            </div>
        </Box>
    </Collapse>
);

export default AlignResourceExpandableRow;
