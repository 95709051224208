import React, { ReactNode } from 'react';
import { Icon } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import './accordion/Accordion.scss';

interface AccordionProps {
    children: ReactNode;
    heading: string;
    quantity?: number;
    icon?: string;
}

const Accordion = ({ children, heading, icon, quantity }: AccordionProps): React.ReactElement => {
    const section = heading.split(' ').join('-').toLowerCase();
    return (
        <div className="o-accordion">
            <MuiAccordion defaultExpanded>
                <MuiAccordionSummary
                    expandIcon={
                        <div className="o-accordion__expandIcon">
                            <Icon>expand_more</Icon>
                        </div>
                    }
                    aria-controls={`${section}-content`}
                    id={`${section}-header`}
                >
                    <div className="o-accordion__heading">
                        {icon && <Icon>{icon}</Icon>}
                        <h3 className="o-accordion__title">
                            {heading}
                            {quantity && ` (${quantity})`}
                        </h3>
                    </div>
                </MuiAccordionSummary>
                <MuiAccordionDetails>{children}</MuiAccordionDetails>
            </MuiAccordion>
        </div>
    );
};

export default Accordion;
