import React from 'react';
import FundamentalsIntroduction from 'pages/plans/details/FundamentalsIntroduction';
import PlanOfActionIntroduction from 'pages/plans/managetasks/PlanOfActionIntroduction';
import IdentifyResourcesIntroduction from 'pages/plans/identifyresources/IdentifyResourcesIntroduction';
import AssessTechIntroduction from 'pages/plans/assesstech/AssessTechIntroduction';
import ProjectReportIntroduction from 'pages/plans/projectreport/ProjectReportIntroduction';

export default function GetIntroPage(name: string, callback: () => void): React.ReactElement {
    switch (name) {
        case 'Identify Details':
            return <FundamentalsIntroduction closeModal={callback} />;
        case 'Manage Tasks':
            return <PlanOfActionIntroduction closeModal={callback} />;
        case 'Manage Resources':
            return <IdentifyResourcesIntroduction closeModal={callback} />;
        case 'Assess TRL / MRL':
            return <AssessTechIntroduction closeModal={callback} />;
        case 'View Project Report':
            return <ProjectReportIntroduction closeModal={callback} />;
        default:
            return <FundamentalsIntroduction closeModal={callback} />;
    }
}
