/* eslint no-bitwise: "off" */
import { FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import React from 'react';
import getDateFormat from 'components/utilities/GetDateFormat';
import { TaskGroupDataModel, TaskModel } from '../Data';
import Enums from '../utils/enums';
import ganttState from '../ganttState';
import Utils from './utils';

export interface LineProps {
    start: number;
    end: number;
    index: number;
    id: string;
    borderRadius?: string | number;
    component?: () => React.ReactNode;
    milestoneWidth?: number | null;
}

export interface ViewsProps {
    drawRectangleRounded: (values: LineProps) => React.ReactNode | null;
    getStartPosition: (start: number | string | Date) => number;
    drawToday: () => JSX.Element;
    drawCalendarLines: (
        index: number,
        h: number,
        xPos: number,
        widthPercent: number,
        scaleIndex: number
    ) => React.ReactNode;
    drawDivisionLine: (index: number) => React.ReactNode;
    drawDivisionBox: (index: number, id: string, isHeader: boolean) => React.ReactNode;
    drawRelationship: (startId: string, endId: string, sliderValue: number) => React.ReactNode | null;
    DataGroupingSelector: () => void;
    TimeViewSelector: () => void;
}

interface TaskModelReconciliationProps extends TaskModel, TaskGroupDataModel {
    name: string;
    tasks: TaskGroupDataModel[];
}

const getChildren = (taskGroup: TaskModelReconciliationProps): TaskModel[] =>
    taskGroup.tasks.map((task: any, j: number) => {
        let isMilestone: number | boolean = false;
        if (typeof task.isDateRange === 'boolean') {
            isMilestone = !task.isDateRange;
        } else {
            isMilestone = ~!task.startDate ^ ~!task.dueDate;
        }

        return {
            start: task.startDate,
            end: isMilestone ? task.startDate : task.dueDate,
            title: task.name,
            progress: task.status,
            isMilestone: !!isMilestone,
            assignee: !!task.users && task.users.length > 0 ? task.users[0].name : null,
            users: task.users,
            status: 0,
            id: task.id,
            description: task.description,
            fundType: task.fundType,
            parents: !!task.dependencies ? task.dependencies.map((d: any) => d.id) : [],
            taskGroupId: taskGroup.id,
            index: j,
            color: taskGroup.color,
        };
    });

// TODO: extract children anom function
export const techPlanDataConversion = (arr: any[]): TaskGroupDataModel[] =>
    arr.map((taskGroup: TaskModelReconciliationProps, i: number) => ({
        id: taskGroup.id,
        title: taskGroup.name,
        description: taskGroup.description,
        index: i,
        color: taskGroup.color,
        children: getChildren(taskGroup),
        isOpen: true,
    }));

export const formatDate = (date: string): string => getDateFormat(new Date(date).getTime());

export const getStartPosition = (start: number | string | Date): number =>
    // Get the LEFT position of candle
    // startBoundCheck was used to ensure the scale of
    // the board was always set to the the data coming in.
    Math.abs(
        ganttState.Gantt.displayMonthRange.start.getTime() -
            ganttState.Gantt.dayInMiliseconds / 2.35 -
            new Date(start).getTime()
    ) / ganttState.Gantt.scaleData;

export const drawRectangleRounded = ({ index, ...item }: LineProps): React.ReactNode | null => {
    if (!item.start || !item.end) return null;
    const startPos = getStartPosition(item.start);
    if (startPos === Infinity) {
        return null;
    }
    const props = {
        x: `${Math.floor(startPos) + 0.1}%`,
        y: index * 42 + 6,
        width: !!item?.milestoneWidth
            ? item.milestoneWidth
            : `${Math.max(3, Utils.getCandleWidth({ start: new Date(item.start), end: new Date(item.end) })) + 0.1}%`,
        height: 30,
    };

    return (
        <svg>
            <rect id={item.id} className="ganttRectangle" ry="0" rx="0" {...props} />
            <foreignObject className="ganttBarForeignObject" {...props}>
                {!!item.component && item.component()}
            </foreignObject>
        </svg>
    );
};

export const drawToday = (): JSX.Element => {
    const startPos = getStartPosition(new Date());
    const props = {
        x: '11',
        y: '0',
        height: '100%',
        width: '4',
    };
    return (
        <svg x={`${startPos}%`} y="0">
            <g id="#todayMarker">
                <rect className="ganttToday verticalLine" {...props} />
            </g>
            <foreignObject x="22" y="4" height="18" width="46">
                <div style={{ fontSize: 14 }}>Today</div>
            </foreignObject>
        </svg>
    );
};

export const drawDivisionLine = (index: number): React.ReactNode => (
    <line className="gantt-graphline" x1="0" y1={index * 42} x2="100%" y2={index * 42} stroke="rgba(0, 0, 0, .03)" />
);

export const drawDivisionBox = (index: number, id: string, isHeader: boolean): React.ReactNode => {
    const props = {
        x: 0,
        y: (index - 1) * 42,
        width: '100%',
        height: 42,
    };
    const fill = ganttState.Gantt.isZeroState || !isHeader ? 'rgba(0, 0, 0, .0)' : 'rgba(0, 0, 0, .04)';
    return <rect id={id} className="gantt-graphline" fill={fill} {...props} />;
};

export const drawCalendarLines = (
    index: number,
    h: number,
    xPos: number,
    widthPercent: number,
    scaleIndex: number
): React.ReactElement => (
    <>
        <line className="gantt-graphline" x1={`${xPos}%`} y1="0" x2={`${xPos}%`} y2={'100%'} />
        {(index + 1) % 2 === 0 && (
            <>
                <svg x={`${xPos}%`}>
                    <rect width={`${widthPercent}%`} height="100%" fill={'rgba(0, 0, 0, .01)'} />
                </svg>

                {scaleIndex === 0 && (index + 4) % 7 === 0 && (
                    <svg x={`${xPos}%`}>
                        <rect width={`${widthPercent * 7}%`} height="100%" fill={'rgba(0, 0, 0, .02)'} />
                    </svg>
                )}
            </>
        )}
    </>
);

function bustOutPath(arr: any, isPortOrientationAbove: boolean, xOffset: number): any {
    /*
      X value is percentage
      Y value is NOT percentage
    */
    const out = [arr[0]];
    const m = (arr[1][1] - arr[0][1]) / (arr[1][0] - arr[0][0]);
    const isNegativeSlope = m < 0;
    switch (~isPortOrientationAbove - ~isNegativeSlope) {
        case 0:
            out.push([out[0][0] + 0.5, out[0][1]]);
            out.push([arr[1][0] - xOffset, out[1][1]]);
            out.push([out[2][0], arr[1][1]]);
            break;
        case -1:
        case 1:
        default:
            out.push([out[0][0] + 0.5, out[0][1]]);
            out.push([out[1][0], arr[1][1] - 21]);
            out.push([arr[1][0] - xOffset, out[2][1]]);
            out.push([out[3][0], arr[1][1]]);
    }
    out.push(arr[1]);

    return out;
}

export const drawRelationship = (startId: string, endId: string, sliderValue: number): React.ReactNode | null => {
    const start: HTMLElement | null | any = document.getElementById(startId);
    const end: HTMLElement | null | any = document.getElementById(endId);
    if (!!start && !!end) {
        const xOffset = (0.5 * 1) / sliderValue;
        const x1 = parseFloat(start.x.baseVal.valueAsString.replace('%', ''));
        const y1 = parseFloat(start.y.baseVal.valueAsString) + 15;
        const width1 = parseFloat(start.width.baseVal.valueAsString.replace('%', ''));
        const x2 = parseFloat(end.x.baseVal.valueAsString.replace('%', ''));
        const y2 = parseFloat(end.y.baseVal.valueAsString) + 15;
        const isMilestone = width1 > 100;

        /*
          isMilestone is being computed by item width.
          Since all non-milestone items' widths are percentages, it stands to reason
          that every incoming item greater than 100 is a milestone.

          And because milestones have a set-width BUT the relationship path is RELATIVE,
          I'm converting the absolute width of the milestone into a percentage and adding
          the x1; otherwise, xPos1 is simply width1 + x1
        */

        const xPos1 = isMilestone ? width1 / 100 + x1 : width1 + x1;

        const isPortOrientationAbove = y1 > y2; // is parent above
        const portOut = [xPos1, y1];
        const portIn = [x2 - 1.25 / sliderValue, y2];

        const outPath = [portOut, portIn];

        const b = bustOutPath(outPath, isPortOrientationAbove, xOffset);

        return (
            <g>
                <defs>
                    <marker id="arrowhead" markerWidth="12" markerHeight="11" refX="5" refY="3.5" orient="0">
                        <polyline
                            points="0 0.5, 5 3.5, 0 6.5"
                            fill="transparent"
                            stroke="rgba(0, 0, 0, .78)"
                            strokeWidth="1"
                        />
                    </marker>
                </defs>
                {b
                    .filter((a: any, i: number) => i < b.length - 1)
                    .map((point: any, i: number) => (
                        <line
                            className="gantt-relationship"
                            data-parent={startId}
                            data-child={endId}
                            strokeLinecap="round"
                            x1={`${point[0]}%`}
                            y1={`${point[1]}`}
                            x2={`${b[i + 1][0]}%`}
                            y2={`${b[i + 1][1]}`}
                        />
                    ))}
                <line
                    className="gantt-relationship"
                    data-parent={startId}
                    data-child={endId}
                    x1={`${portIn[0]}%`}
                    y1={`${portIn[1]}`}
                    x2={`${portIn[0] + 1 / sliderValue}%`}
                    y2={`${portIn[1]}`}
                    markerEnd="url(#arrowhead)"
                />
            </g>
        );
    }
    return null;
};

const DataGroupingSelector = (): React.ReactElement => (
    <FormControl variant="filled" className="Gantt__groupBy__select">
        <InputLabel id="Gantt__groupBy__label">Sort By</InputLabel>
        <Select
            value={ganttState.Gantt.groupBy}
            labelId="Gantt__groupBy__label"
            id="Gantt__groupBy"
            disabled={ganttState.Gantt.isZeroState}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                ganttState.Gantt.groupByHandler(event.target.value as string);
            }}
        >
            <MenuItem value={'taskGroupId'}>Task Group</MenuItem>
            <MenuItem value={'assignee'}>Assignee</MenuItem>
            <MenuItem value={'progress'}>Status</MenuItem>
        </Select>
    </FormControl>
);

const TimeViewSelector = (): React.ReactElement => (
    <FormControl variant="filled" className="Gantt__groupBy__select">
        <Select
            value={`${ganttState.Gantt.timeScaleString}s`}
            id="Gantt__groupBy"
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                ganttState.Gantt.setTimeBy(event.target.value as string);
                if (event.target.value) {
                    ganttState.Gantt.setTimeScaleString(String(event.target.value).slice(0, -1));
                }
            }}
        >
            <MenuItem value={'days'}>Days</MenuItem>
            <MenuItem value={'weeks'}>Weeks</MenuItem>
            <MenuItem value={'months'}>Months</MenuItem>
            <MenuItem value={'years'}>Years</MenuItem>
        </Select>
    </FormControl>
);

export default {
    drawRectangleRounded,
    getStartPosition,
    drawToday,
    formatDate,
    drawDivisionLine,
    drawDivisionBox,
    drawCalendarLines,
    drawRelationship,
    techPlanDataConversion,
    DataGroupingSelector,
    TimeViewSelector,
};
