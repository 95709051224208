import React from 'react';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { qPlans, qUsers, qInvitees } from 'api/queries';

import AlertToast from 'components/AlertToast';
import Modal from 'components/Modal';
import { GetUserUser } from 'typings/_graphql';
import { StatusIcon, checkStatus } from './utils/Utils';

interface DeletePersonModalProps {
    children: React.ReactElement;
    ref: any;
    onDelete: () => void;
    user: GetUserUser;
    planId: string;
    leave: boolean;
    status?: number;
    planName?: string;
}

interface CurrentUserLeaveProjectProps {
    planName?: string;
}

interface RemovePendingRejectedProps {
    emailAddress: string;
}

interface RemoveActiveInactiveProps {
    status?: number;
    user: GetUserUser;
}

const CurrentUserLeaveProject = ({ planName }: CurrentUserLeaveProjectProps) => (
    <div>
        <h3>Leave Project</h3>
        <p>
            Are you sure you want to leave the <strong>{planName}</strong> project? You will no longer be able to access
            or contribute to the project.
        </p>
    </div>
);

const RemovePendingRejected = ({ emailAddress }: RemovePendingRejectedProps) => (
    <div>
        <h3>Remove Person</h3>
        <p>Are you sure you want to remove this person from the project?</p>
        <div className="email-row">
            <Icon>email</Icon>
            {emailAddress}
        </div>
    </div>
);

const RemoveActiveInactive = ({ user, status }: RemoveActiveInactiveProps) => (
    <div style={{ width: '100%' }}>
        <h3>Remove Person</h3>
        <p>
            Are you sure that you want to remove <strong>{user.name}</strong> from the project? This person will no
            longer be able to contribute or access the project. If they have any tasks and/or resources, there will be
            no assignee.
        </p>
        <div className="m-fundamentalsForm">
            <div className="o-identify-people-table">
                <div className="m-identify-people-table-header">
                    {['Name', 'Organization/Company', 'Project Role'].map((key: string) => (
                        <div>{key}</div>
                    ))}
                </div>
                <div className="m-identify-people-table-body">
                    <div className="m-identify-people-table-body-row">
                        <div>
                            <StatusIcon invitee={user} status={status} />
                            <div>
                                {checkStatus(status) ? user.name : 'Pending User'}
                                <div className="a-cell">{user.emailAddress}</div>
                            </div>
                        </div>
                        <div className="a-cell">{checkStatus(status) ? user.organization?.name || 'N/A' : ''}</div>
                        <div className="a-cell">{checkStatus(status) ? user.dutyTitle : ''}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

function removeMemberNomenclatureHandler(leave: boolean, user: GetUserUser, planName?: string, status?: number) {
    if (leave) {
        return <CurrentUserLeaveProject planName={planName} />;
    }
    if (status === 1 || status === 2) {
        return <RemovePendingRejected emailAddress={user.emailAddress} />;
    }
    return <RemoveActiveInactive user={user} status={status} />;
}

function popupWidth(leave: boolean, status?: number) {
    if (leave || status === 1 || status === 2) {
        return '500px';
    }
    return '886px';
}

export default React.forwardRef(
    ({ children, ref, onDelete, user, planId, leave = false, status, planName }: DeletePersonModalProps) => {
        const [removeTeamMember] = useMutation(qInvitees.DELETE_INVITEE);

        const history = useHistory();

        const closeModal = (close: { (): void }) => {
            if (ref) {
                ref?.current?.close();
            }
            close();
        };

        const removeUserFromProject = (close: { (): void }) => {
            removeTeamMember({
                refetchQueries: [
                    {
                        query: qInvitees.GET_INVITEES_BY_PLAN,
                        variables: { planId: +planId },
                    },
                    {
                        query: qPlans.QUERY_PLANS,
                    },
                    {
                        query: qUsers.GET_USERS_BY_PLAN,
                        variables: { planId: +planId },
                    },
                ],
                fetchPolicy: 'no-cache',
                variables: { planId: +planId, email: user.emailAddress },
            })
                .then(() => {
                    toast(<AlertToast severity="success" message={`${user.name} removed from Project.`} />);
                    if (leave) {
                        history.push(`/`);
                    }
                })
                .catch((e) => {
                    toast(<AlertToast severity="error" message={`Error removing User: ${e.message}`} />);
                });
            onDelete();
            closeModal(close);
        };

        return (
            <Popup contentStyle={{ width: popupWidth(leave, status) }} trigger={children} modal nested>
                {(close: () => void) => (
                    <Modal
                        className="o-add-people-modal"
                        closeFunction={() => {
                            closeModal(close);
                        }}
                    >
                        <div>{removeMemberNomenclatureHandler(leave, user, planName, status)}</div>
                        <div className="o-add-people-modal__buttons">
                            <Button color="secondary" variant="outlined" onClick={() => closeModal(close)}>
                                Cancel
                            </Button>
                            <Button color="primary" variant="contained" onClick={() => removeUserFromProject(close)}>
                                {!leave ? 'Remove' : 'Leave'}
                            </Button>
                        </div>
                    </Modal>
                )}
            </Popup>
        );
    }
);
