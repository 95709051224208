import React from 'react';
import UserAvatar from 'components/UserAvatar';
import { GetResourcesResourcesResourceType as ResourceType } from 'typings/_graphql';
import { IUser } from 'typings/User';
import './resourceCard/ResourceCard.scss';

interface ResourceProps {
    name: string;
    user: IUser;
    resourceType: ResourceType;
}

interface Props {
    resource: ResourceProps;
}

const ResourceCard = ({ resource }: Props): React.ReactElement => (
    <div className="o-resourceCard">
        <h4 className="o-resourceCard__resourceLabel">{resource?.name}</h4>
        <div className="o-resourceCard__resourceType">{resource?.resourceType?.name}</div>
        {resource && resource?.user && (
            <div className="o-resourceCard__resourceAssignee">
                <UserAvatar key={resource?.user?.id} name={resource?.user?.name} />
                <div>{resource.user?.name}</div>
            </div>
        )}
    </div>
);

export default ResourceCard;
