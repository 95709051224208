import React, { ReactElement } from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, LabelList } from 'recharts';

interface HorizontalBarchartProps {
    dataset: BarchartData[];
}

export interface BarchartData {
    label: string;
    value: number;
}

const HorizontalBarchart = ({ dataset }: HorizontalBarchartProps): ReactElement => {
    const renderCustomizedLabel = (props: any) => {
        const { x, y, width, height, value } = props;
        return (
            <text
                x={width + x + 10}
                y={y + height / 2}
                fill="hsl(var(--secondary-main))"
                textAnchor="start"
                style={{ fontWeight: 'bold' }}
            >
                {String(value).simplifyMoneyTag()}
            </text>
        );
    };

    return (
        <ResponsiveContainer width={519} height={280}>
            <BarChart
                layout="vertical"
                data={dataset}
                width={519}
                height={380}
                margin={{
                    top: 0,
                    right: 80,
                    bottom: 0,
                    left: 10,
                }}
            >
                <XAxis
                    tickLine={false}
                    type="number"
                    tickFormatter={(label) => `${String(label).simplifyMoneyTag().replace(/\$/g, '')}`}
                />
                <YAxis type="category" dataKey="label" tickLine={false} />
                <CartesianGrid stroke="#E8EAED" />
                <Bar
                    dataKey="value"
                    fill="hsl(var(--secondary-main))"
                    barSize={36}
                    layout="horizontal"
                    maxBarSize={100}
                    radius={[0, 5, 5, 0]}
                >
                    <LabelList dataKey="value" content={renderCustomizedLabel} />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default HorizontalBarchart;
