import React, { ComponentClass } from 'react';
import HeaderWrapper from './HeaderWrapper';
import NavigationWrapper from './NavigationWrapper';
import BuildSideBarC from './BuildSideBar';
import { LayoutProps } from './interface';
import './Layouts.scss';

function LayoutMaker({ tagName, displayName, className }: LayoutProps) {
    return (BasicComponent: any) =>
        class Adapter extends React.Component {
            // eslint-disable-next-line react/static-property-placement
            static displayName: string = displayName;

            static Header: ComponentClass;

            static Footer: ComponentClass;

            static Content: ComponentClass;

            static Sidebar: ComponentClass;

            static Navigation: ComponentClass;

            render() {
                return <BasicComponent tagName={tagName} className={className} {...this.props} />;
            }
        };
}

const Basic = (props: LayoutProps) => {
    const { children, tagName, ...others } = props;
    if (tagName === 'header') {
        return <HeaderWrapper />;
    }
    if (tagName === 'sidebar') {
        return <BuildSideBarC />;
    }
    if (tagName === 'nav') {
        return <NavigationWrapper />;
    }
    return React.createElement(tagName, { ...others }, children);
};

const Layout: any & {
    Header: React.ComponentClass<ComponentClass>;
    Footer: React.ComponentClass<ComponentClass>;
    Content: React.ComponentClass<ComponentClass>;
    Sidebar: React.ComponentClass<ComponentClass>;
    Subheader: React.ComponentClass<ComponentClass>;
    Navigation: React.ComponentClass<ComponentClass>;
} = LayoutMaker({
    tagName: 'div',
    displayName: 'Layout',
    className: 'MuiGrid-container layout',
})(Basic);

const Header = LayoutMaker({
    tagName: 'header',
    displayName: 'Header',
    className: 'header',
})(Basic);

const Footer = LayoutMaker({
    tagName: 'footer',
    displayName: 'Footer',
    className: '',
})(Basic);

const Content = LayoutMaker({
    tagName: 'main',
    displayName: 'Content',
    className: 'MuiGrid-item MuiGrid-grid-md-true content',
})(Basic);

const Sidebar = LayoutMaker({
    tagName: 'sidebar',
    displayName: 'Sidebar',
    className: 'sidebar',
})(Basic);

const Subheader = LayoutMaker({
    tagName: 'div',
    displayName: 'Subheader',
    className:
        ' MuiGrid-container MuiGrid-item MuiGrid-grid-md-12 MuiGrid-align-items-xs-center MuiGrid-justify-xs-space-between subheader',
})(Basic);

const Navigation = LayoutMaker({
    tagName: 'nav',
    displayName: 'Navigation',
    className: 'navigation',
})(Basic);

Layout.Header = Header;
Layout.Footer = Footer;
Layout.Content = Content;
Layout.Sidebar = Sidebar;
Layout.Subheader = Subheader;
Layout.Navigation = Navigation;

export default Layout;
