import React, { useState, useEffect, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { qTasks } from 'api/queries';
import { toast } from 'react-toastify';
import AlertToast from 'components/AlertToast';
import { TaskGroupDataModel, TaskModel } from '../Data';
import ganttState from '../ganttState';
import GanttItem from './GanttItem';
import Milestone from './Milestone';
import Enums from '../utils/enums';
import './Canvas.scss';

interface CanvasProps {
    sliderValue: number;
}

interface DrawItemProps {
    item: TaskModel;
    index: number;
    groupIndex: number;
    draw: boolean;
    callback: (taskId: number, status: any) => void;
}

const DrawTodayMarker = () => ganttState.Gantt.drawToday();

const DrawItem = ({ index, item, groupIndex, draw, callback }: DrawItemProps) => {
    const indexGroup = ganttState.Gantt.groupBy === 'assignee' ? index : groupIndex;
    return ganttState.Gantt.drawRectangleRounded({
        index,
        start: item.start,
        end: item.end,
        id: item.id,
        milestoneWidth: (() => {
            if (item.isMilestone) {
                if (item?.progress?.length < 6) return 120;
                return 130;
            }
            return null;
        })(),
        component: () =>
            item.isMilestone ? (
                <Milestone index={index} groupIndex={indexGroup} draw={draw} callback={callback} {...item} />
            ) : (
                <GanttItem index={index} groupIndex={indexGroup} draw={draw} callback={callback} {...item} />
            ),
    });
};

const Canvas = ({ sliderValue }: CanvasProps): React.ReactElement => {
    const { Gantt } = ganttState;
    const { references } = Gantt;
    const [didItemsLoad, setDidItemsLoad] = useState(false);
    const [canvasHeight, canvasHeightHandler] = useState(0);
    const [updateTasks] = useMutation(qTasks.UPDATE_TASKS_STATUS);

    const updateStatus = (taskId: number, status: string) => {
        updateTasks({
            variables: {
                taskIds: [taskId],
                status: Enums.StatusCodes[(Object.values(Enums.StatusCodes).indexOf(status) + 1) % 3],
            },
        }).then(() => {
            toast(<AlertToast severity="success" message={`Task successfully updated!`} />);
        });
    };

    const makeCanvasHeight = () =>
        ganttState.Gantt.data.reduce((a: number, b: TaskGroupDataModel) => {
            if (b.isOpen && !!b.children) return a + b.children.length + 1;
            return a + 1;
        }, 0) * 42;

    const DrawVerticleLines = useCallback(() => {
        const paths: React.ReactNode[] = [];

        // small-scale calendar column lines + zebra striping
        const microTimeBucket: string[] = Gantt.getDateList();
        const xOffsetPercentageMicroTimeBucket = (i: number) => (i / microTimeBucket.length) * 100;
        const widthPercentMicroTimeBucket = 100 / microTimeBucket.length;
        microTimeBucket.forEach((i: string, j: number) => {
            paths.push(
                Gantt.drawCalendarLines(
                    j,
                    canvasHeight,
                    xOffsetPercentageMicroTimeBucket(j),
                    widthPercentMicroTimeBucket,
                    Enums.TimeBuckets[ganttState.Gantt.timeScaleString]
                )
            );
        });
        return <>{paths}</>;
    }, [canvasHeight, Gantt]);

    const DrawItems = useCallback(() => {
        let masterRowCounter = 0; // master row counter
        setDidItemsLoad(false);
        return (
            <>
                {Gantt.data
                    .sort((itemA: TaskGroupDataModel, itemB: TaskGroupDataModel) => (itemA.id > itemB.id ? -1 : 1))
                    .map((item: TaskGroupDataModel, i: number) => (
                        <>
                            {Gantt.drawDivisionBox(++masterRowCounter, `gantt-row-${i}`, true)}
                            {item.children &&
                                item.children.length > 0 &&
                                item.isOpen &&
                                item.children.map(
                                    (task: TaskModel, j: number) =>
                                        item.index !== undefined && (
                                            <>
                                                {masterRowCounter++}
                                                <DrawItem
                                                    index={masterRowCounter - 1}
                                                    item={task}
                                                    callback={updateStatus}
                                                    groupIndex={!!item.index ? item.index : 0}
                                                    draw={
                                                        masterRowCounter >=
                                                            ganttState.Gantt.cacheCeiling -
                                                                ganttState.Gantt.cacheBufferSize &&
                                                        masterRowCounter <= ganttState.Gantt.cacheCeiling
                                                    }
                                                />
                                                {Gantt.drawDivisionLine(masterRowCounter, false)}
                                            </>
                                        )
                                )}
                        </>
                    ))}
                {setDidItemsLoad(true)}
            </>
        );
    }, [ganttState.Gantt.data, ganttState.Gantt.cacheRange]);

    const DrawRelationships = useCallback(
        () => (
            <>
                {Gantt.data.map(
                    (item: TaskGroupDataModel) =>
                        item.children &&
                        item.children.map((task: TaskModel) =>
                            task.parents.map((parentId: string) =>
                                Gantt.drawRelationship(parentId, task.id, sliderValue)
                            )
                        )
                )}
            </>
        ),
        [didItemsLoad, Gantt.data, Gantt.cacheRange, sliderValue]
    );

    useEffect(() => {
        if (!!Gantt.data) {
            canvasHeightHandler(makeCanvasHeight());
        }
    }, [ganttState.Gantt.data]);

    return (
        <div
            className={`body_canvas__container${Gantt.isZeroState ? '_zero' : ''}`}
            id="canvas-container"
            style={{ width: Gantt.displayWidth }}
            ref={references.refCanvas}
        >
            <div
                id="ganttCanvas"
                style={{ width: Gantt.timelineWidth * sliderValue }}
                className={`body_canvas__container_ganttCanvas${Gantt.isZeroState ? '_zero' : ''}`}
            >
                <svg
                    width="100%"
                    height="100%"
                    viewBox={`0 0 ${Gantt.timelineWidth * sliderValue} ${canvasHeight}`}
                    className="Canvas"
                    ref={references.ganttSvgRef}
                >
                    {!!Gantt.data && (
                        <>
                            <DrawVerticleLines />
                            <DrawRelationships />
                            <DrawItems />
                            <DrawTodayMarker />
                        </>
                    )}
                </svg>
            </div>
        </div>
    );
};

export default Canvas;
