import { gql } from 'apollo-boost';

const CREATE_TASK_RESOURCE_ASSOC = gql`
    mutation CreateTaskResource($data: CreateTaskResourcesInput!) {
        createTaskResource(data: $data) {
            taskId
            resourceId
            taskAmount
            task {
                id
                name
            }
        }
    }
`;

const UPDATE_TASK_RESOURCE = gql`
    mutation UpdateTaskResources($data: UpdateTaskResourcesInput!) {
        updateTaskResources(data: $data) {
            id
            taskId
            resourceId
            taskAmount
        }
    }
`;

const DELETE_TASK_RESOURCES = gql`
    mutation DeleteTaskResources($taskResourceIds: [Float!]!) {
        deleteTaskResources(taskResourceIds: $taskResourceIds)
    }
`;

export { CREATE_TASK_RESOURCE_ASSOC, UPDATE_TASK_RESOURCE, DELETE_TASK_RESOURCES };
