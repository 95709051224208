import React from 'react';
import { Control, UseFormMethods } from 'react-hook-form';
import { IUser } from 'typings/User';
import MenuItem from '@material-ui/core/MenuItem';

import CustomSelect from 'components/CustomSelect';
import UserAvatar from 'components/UserAvatar';

import './userdropdown/UserDropDown.scss';

export interface UserDropdownProps extends Partial<Pick<UseFormMethods, 'register' | 'errors'>> {
    users: IUser[];
    control: Control;
    name: string;
    required?: boolean;
    label: string;
    className?: string;
    defaultValue?: string | number;
    readOnly?: boolean;
}

const UserDropdown = ({
    readOnly = false,
    control,
    users,
    className,
    name,
    label,
    required = false,
    defaultValue,
}: UserDropdownProps): React.ReactElement => {
    if (readOnly) {
        return (
            <>
                {users.map((item) => {
                    if (item.id === defaultValue)
                        return (
                            <div key={item.id} className="o-taskform__readOnlyContainer">
                                <span className="o-taskform__readOnlyLabel">Assignee</span>
                                <div className={'a-userDropdown'}>
                                    <UserAvatar hideTooltip name={item.name} />
                                    <span key={item.id}>&nbsp;&nbsp;{item.name}</span>
                                </div>
                            </div>
                        );
                    return <></>;
                })}
            </>
        );
    }

    return (
        <CustomSelect
            required={required}
            inputLabel={label}
            name={name}
            control={control}
            watch={defaultValue}
            className={className}
        >
            {defaultValue === 0 ? (
                <MenuItem disabled value={0}>
                    Select a Person
                </MenuItem>
            ) : null}
            {users.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                    <div className={'a-userDropdown'}>
                        <UserAvatar hideTooltip name={item.name} />
                        &nbsp;&nbsp;
                        {item.name}
                    </div>
                </MenuItem>
            ))}
        </CustomSelect>
    );
};

export default UserDropdown;
