/* eslint @typescript-eslint/no-unused-vars: 0 */

import { Action } from '..';

export interface State {
    test?: string;
}

const initialState: State = {
    test: 'This is my test',
};

export default (state = initialState, action: Action): State => {
    const { type, payload } = action;
    switch (type) {
        default:
            return { ...state };
    }
};
