import { gql } from 'apollo-boost';

const QUERY_ALL = gql`
    query getResourceTypes {
        resourceTypes {
            id
            name
        }
    }
`;

const QUERY_WITH_RESOURCES = gql`
    query getResourceTypesWithResources($planId: Float!) {
        getResourceTypesWithResources(planId: $planId) {
            id
            name
            resources {
                id
                name
                amount
                description
                startDate
                dueDate
                isDateRange
                duration
                fundType {
                    id
                    name
                }
                plan {
                    id
                }
                status {
                    id
                    status
                }
                user {
                    id
                    name
                    avatar
                }
                contracts {
                    id
                    title
                    isActive
                    contractNumber
                    TpocFirstName
                    TpocLastName
                }
            }
        }
    }
`;

export { QUERY_ALL, QUERY_WITH_RESOURCES };
