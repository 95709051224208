import React from 'react';
import ProblemSolutionSection from './problemandsolution/ProblemSolutionSection';
import ReadinessLevels from './ReadinessLevels';
import { ReadinessResultsProps } from '../assesstech/AssessTechProps';

import {
    capitalize,
    CUSTOMER_PRIORITY_REASONING,
    CUSTOMER_PRIORITY_LEVEL,
    SOLUTION_HOLDER,
} from './JustificationUtils';

interface IdentifiedDetailsProps {
    planParametersObj: any;
    subheadingList: string[];
    stakeholderList: string[];
    assessTechData?: ReadinessResultsProps;
}

const IdentifiedDetails = ({
    planParametersObj,
    subheadingList,
    stakeholderList,
    assessTechData,
}: IdentifiedDetailsProps): React.ReactElement => (
    <div className="m-planFundamentals">
        <section className="m-planFundamentals__stakeholderSection">
            {stakeholderList.map((stakeholderItem) => {
                let subheading = stakeholderItem;
                if (stakeholderItem !== SOLUTION_HOLDER) subheading = `Air Force ${stakeholderItem}`;

                const stakeholderInfo = {
                    name: planParametersObj[`${stakeholderItem} First Name`]?.details,
                    lastName: planParametersObj[`${stakeholderItem} Last Name`]?.details,
                    company:
                        planParametersObj[`${stakeholderItem} Organization`]?.details ||
                        planParametersObj[`${stakeholderItem} Company`]?.details,
                };

                return <ProblemSolutionSection subheading={subheading} stakeholderInfo={stakeholderInfo} />;
            })}
        </section>
        <div className="m-planFundamentals__separator" />
        {subheadingList.map((subheadingItem) => {
            let subheading = subheadingItem;
            if (subheadingItem === CUSTOMER_PRIORITY_REASONING)
                subheading = `${CUSTOMER_PRIORITY_LEVEL} : ${capitalize(
                    planParametersObj[CUSTOMER_PRIORITY_LEVEL]?.details
                )}`;
            return (
                <ProblemSolutionSection subheading={subheading} content={planParametersObj[subheadingItem]?.details} />
            );
        })}
        {assessTechData && <ReadinessLevels assessTechData={assessTechData} />}
    </div>
);

export default IdentifiedDetails;
