import React, { useEffect, useState, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import randomColor from 'randomcolor';
import { useForm } from 'react-hook-form';
import { Button } from '@material-ui/core';
import { debounce } from 'lodash-es';
import { CreateTaskGroup, GetTaskGroupsTaskGroupsByPlanId, UpdateTaskGroup } from 'typings/_graphql';
import { useMutation } from '@apollo/client';
import { qTaskGroups } from 'api/queries';

import ToolTipMenu from 'components/ToolTipMenu';
import AlertToast from 'components/AlertToast';
import CustomInput from 'components/CustomInput';
import ConfirmButton from 'components/ConfirmButton';
import { preventEnterSubmit } from 'components/utilities/preventEnterSubmit';
import ColorSelector from './taskgroupform/ColorSelector';

import './taskgroupform/TaskGroupForm.scss';

interface Props {
    editTaskGroup?: false | GetTaskGroupsTaskGroupsByPlanId;
    closeForm?: (action: boolean) => void;
    resetDefaultData: boolean;
}

const TaskGroupForm = ({ editTaskGroup = false, closeForm, resetDefaultData }: Props): React.ReactElement => {
    let title;
    const { planId } = useParams<{ planId: string }>();
    const defaultFormData = {
        planId,
        color: randomColor({ luminosity: 'light', hue: 'random' }),
        name: '',
        description: '',
    };

    const { register, handleSubmit, errors, reset, watch, formState, setValue } = useForm({ mode: 'onSubmit' });
    const [colorItems, setColorItems] = useState(<></>);
    const [createTaskGroup] = useMutation<CreateTaskGroup>(qTaskGroups.CREATE_TASK_GROUP);
    const [updateTaskGroup] = useMutation<UpdateTaskGroup>(qTaskGroups.UPDATE_TASK_GROUP);

    if (editTaskGroup) {
        title = `Edit ${editTaskGroup.name} Task Group`;
    } else {
        title = 'Add a Task Group';
    }

    const { isDirty: valuesHaveChanged } = formState;

    useEffect(() => {
        if (editTaskGroup) {
            setValue('color', editTaskGroup.color, { shouldDirty: true });
            reset(editTaskGroup);
        }
    }, [editTaskGroup]);

    useEffect(() => {
        reset(defaultFormData);
    }, [resetDefaultData]);

    const cancelClicked = () => {
        reset(defaultFormData);
        if (closeForm) {
            closeForm(false);
        }
    };

    const debounceTaskGroup = useCallback(
        debounce(
            (data, taskGroup) =>
                !taskGroup
                    ? createTaskGroup({ variables: { data } })
                          .then(() => {
                              toast(
                                  <AlertToast severity="success" message="Your task group was successfully created." />
                              );
                              reset(defaultFormData);
                              setValue('color', randomColor({ luminosity: 'light', hue: 'random' }), {
                                  shouldDirty: true,
                              });
                              if (closeForm) {
                                  closeForm(false);
                              }
                          })
                          .catch((e) => {
                              toast(<AlertToast severity="error" message={`Error creating Task Group: ${e}`} />);
                          })
                    : updateTaskGroup({ variables: { taskGroupId: taskGroup.id, data: { ...data } } })
                          .then(() => {
                              toast(
                                  <AlertToast severity="success" message="Your task group was successfully updated." />
                              );
                              cancelClicked();
                              setValue('color', randomColor({ luminosity: 'light', hue: 'random' }), {
                                  shouldDirty: true,
                              });
                          })
                          .catch((e) => {
                              toast(<AlertToast severity="error" message={`Error editing Task Group: ${e}`} />);
                          }),
            600
        ),
        []
    );

    const onSubmit = async (data: CreateTaskGroup | UpdateTaskGroup) => {
        debounceTaskGroup(data, editTaskGroup);
    };

    const ref: any = useRef();

    const colorClickHandler = (color: string) => {
        setValue('color', color, { shouldDirty: true });
        if (ref) ref?.current?.close();
    };

    const regenerateColors = () => {
        const colors: Array<string> = [];
        for (let i = 0; i < 6; i++) {
            colors[i] = randomColor({ luminosity: 'light', hue: 'random' });
        }
        return (
            <>
                {colors.map((color) => (
                    <ColorSelector key={color} onClick={() => colorClickHandler(color)} color={color} />
                ))}
            </>
        );
    };

    const setColors = () => {
        setColorItems(regenerateColors());
    };

    useEffect(() => {
        setColorItems(regenerateColors());
    }, []);

    return (
        <div className="o-taskGroupForm">
            <h2 className="o-taskGroupForm__title">{title}</h2>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <form onSubmit={handleSubmit(onSubmit)} onKeyPress={preventEnterSubmit}>
                <CustomInput
                    inputLabel="Task Group Name"
                    errors={errors}
                    name="name"
                    inputRef={register({ required: true })}
                    defaultValue={watch('name')}
                    maxLength={24}
                    fullWidth
                    showCharacterCount
                    required
                />
                <CustomInput
                    inputLabel="Task Group Description"
                    errors={errors}
                    name="description"
                    inputRef={register()}
                    defaultValue={watch('description')}
                    fullWidth
                    multiline
                />
                <span className="o-taskGroupForm__colorSpan">*Task Group Color</span>
                <div className="o-taskGroupForm__colorSelectorContainer">
                    <ToolTipMenu
                        ref={ref}
                        className="o-randomcolorcontainer__tooltip"
                        onClose={setColors}
                        position={['bottom center', 'bottom right', 'bottom left']}
                        trigger={<ColorSelector color={watch('color')} />}
                    >
                        {colorItems}
                    </ToolTipMenu>
                </div>
                <input name="planId" hidden value={planId} ref={register} />
                <input name="color" required hidden value={watch('color')} ref={register({ required: true })} />
                <div className="o-taskGroupForm__hr" />
                <div className="o-taskGroupForm__buttons">
                    <Button onClick={cancelClicked} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <ConfirmButton valuesHaveChanged={valuesHaveChanged} type="submit" watch={watch(['name', 'color'])}>
                        Save
                    </ConfirmButton>
                </div>
            </form>
        </div>
    );
};

export default TaskGroupForm;
