import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { Button, Icon, IconButton } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { qPlans, qInvitees } from 'api/queries';
import AlertToast from 'components/AlertToast';
import { useForm } from 'react-hook-form';
import CustomInput from 'components/CustomInput';
import ConfirmButton from 'components/ConfirmButton';
import Modal from 'components/Modal';
import { AddStakeHolders, AddStakeHoldersVariables, GetUserUser } from 'typings/_graphql';

interface EmailRowProps {
    email: string;
    index: number;
    setEmails: (emails: string[]) => void;
    emails: string[];
    emailExists: boolean;
}

interface FilteredUser {
    id: string;
    email: string;
    status?: number;
    user: GetUserUser;
}

interface AddPersonModalProps {
    children: React.ReactElement;
    ref: any;
    planId: string | number;
    users: FilteredUser[];
}

const EmailRow = ({ email, index, setEmails, emails, emailExists }: EmailRowProps) => (
    <div className="email-row">
        <IconButton onClick={() => setEmails(emails.filter((e: string, i: number) => i !== index))}>
            <Icon>close</Icon>
        </IconButton>
        <div className={emailExists ? '-errorMessage' : ''}>
            <div>
                {emailExists && <Icon>error_outline</Icon>}
                <div>{email}</div>
            </div>
            {emailExists && <div className="__small">This person is already a part of or invited to the project.</div>}
        </div>
    </div>
);

export default React.forwardRef(({ children, ref, planId, users }: AddPersonModalProps) => {
    const [emails, setEmails] = useState<string[]>([]);
    const [existingEmails, setExistingEmails] = useState<string[]>([]);
    const [updateTeamMembers] = useMutation<AddStakeHolders, AddStakeHoldersVariables>(qPlans.ADD_STAKEHOLDERS);
    const hasNewEmails = emails.filter((email: string) => existingEmails.indexOf(email) === -1).length > 0;
    const {
        handleSubmit,
        register,
        setValue,
        watch,
        formState: { errors },
        getValues,
    } = useForm({
        mode: 'onChange',
    });

    const closeModal = (close: { (): void }) => {
        setEmails([]);
        if (ref) {
            ref?.current?.close();
        }
        close();
    };

    const inviteUsers = (close: { (): void }) => {
        updateTeamMembers({
            refetchQueries: [
                {
                    query: qInvitees.GET_INVITEES_BY_PLAN,
                    variables: { planId: +planId },
                },
            ],
            variables: {
                planId: +planId,
                data: {
                    stakeholders: emails
                        .filter((email: string) => existingEmails.indexOf(email) === -1)
                        .map((email: string) => ({ emailAddress: email })),
                },
            },
            fetchPolicy: 'no-cache',
        })
            .then(() => {
                toast(<AlertToast severity="success" message="Members added to Project." />);
            })
            .catch((e) => {
                toast(<AlertToast severity="error" message={`Error adding: ${e.message}`} />);
            });
        setEmails([]);
        closeModal(close);
    };

    const onSubmit = () => {
        if (getValues('email') !== '' && emails.indexOf(getValues('email')) === -1) {
            setEmails([...emails, getValues('email')]);
        }
        setValue('email', null);
    };

    useEffect(() => {
        if (users) {
            setExistingEmails(users.map((user: FilteredUser) => user.email));
        }
    }, [users, emails]);

    return (
        <Popup trigger={children} modal nested contentStyle={{ width: 600 }}>
            {(close: () => void) => (
                <Modal
                    className="o-add-people-modal"
                    closeFunction={() => {
                        closeModal(close);
                    }}
                >
                    <div>
                        <h3>Add Person</h3>
                        <div>Invite other people to contribute to this project.</div>
                    </div>
                    <form className="o-add-people-modal" id="createPlanForm" onSubmit={handleSubmit(onSubmit)}>
                        <div className="o-add-people-modal__forms">
                            <CustomInput
                                errors={errors}
                                name="email"
                                startAdornment="email"
                                inputRef={register({
                                    pattern: {
                                        value: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/i,
                                        message: 'Must be an email address.',
                                    },
                                })}
                                inputSpacing
                                errormessage="Must be an email address."
                                type="email"
                                placeholder="Enter Email"
                                fullWidth
                            />
                            <ConfirmButton
                                onClick={onSubmit}
                                type="button"
                                watch={watch(['email'])}
                                disable={!getValues('email')}
                                errors={errors}
                            >
                                Add
                            </ConfirmButton>
                        </div>
                        <div>
                            <strong>*Emails added</strong>
                            {emails.length === 0 ? (
                                <div>
                                    <em>One or more emails are required to move forward</em>
                                </div>
                            ) : (
                                emails.map((email: string, i: number) => (
                                    <EmailRow
                                        emailExists={existingEmails.indexOf(email) !== -1}
                                        email={email}
                                        index={i}
                                        setEmails={setEmails}
                                        emails={emails}
                                    />
                                ))
                            )}
                        </div>
                        <div className="o-add-people-modal__buttons">
                            <Button color="secondary" variant="outlined" onClick={() => closeModal(close)}>
                                Cancel
                            </Button>
                            <Button
                                disabled={!hasNewEmails}
                                color="primary"
                                variant="contained"
                                onClick={() => inviteUsers(close)}
                            >
                                Invite
                            </Button>
                        </div>
                    </form>
                </Modal>
            )}
        </Popup>
    );
});
