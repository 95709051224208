import React, { useContext, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Icon, Grid } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Collapse from '@material-ui/core/Collapse';

import ToolTipMenu from 'components/ToolTipMenu';
import { GetTaskGroupsTaskGroupsByPlanId, GetTaskGroupsTaskGroupsByPlanIdTasks } from 'typings/_graphql';
import { getTaskStatusIcon } from 'components/utilities/getStatusIcon';
import AddRowButton from 'components/table/AddRowButton/AddRowButton';
import UserAvatar from 'components/UserAvatar';
import PlanContext from '../PlanContext';
import DeleteTaskGroupModal from './DeleteTaskGroupModal';
import './managetaskscard/ManageTasksCard.scss';
import TaskCard from './managetaskscard/TaskCard';
import TaskGroupTable from './listview/TaskGroupTable';

interface Props {
    readOnly: boolean;
    label: string;
    tasks: readonly GetTaskGroupsTaskGroupsByPlanIdTasks[] | null;
    taskGroup?: GetTaskGroupsTaskGroupsByPlanId;
    showMenu?: boolean;
    filterType: string;
    onTaskSelection?: any;
}

const ManageTasksCard = ({
    readOnly,
    onTaskSelection,
    label,
    tasks,
    taskGroup,
    showMenu = true,
    filterType,
}: Props): React.ReactElement => {
    const ref: any = useRef();
    const { updateCallBack, addTaskCallBack } = useContext(PlanContext);
    const taskCount = tasks?.length || 0;
    const [descriptionExpanded, setDescriptionExpanded] = useState<boolean>(false);

    const location = useLocation();
    const [currentPath, setCurrentPath] = useState(location.pathname);

    const expand = () => {
        setDescriptionExpanded(!descriptionExpanded);
    };

    const ShowIcon = () => {
        if (taskGroup?.description) {
            return (
                <Icon className="o-manageTasksCard__infoIcon" onClick={expand}>
                    {descriptionExpanded ? 'info' : 'info_outline'}
                </Icon>
            );
        }
        return <></>;
    };

    const editClick = () => {
        if (taskGroup) {
            updateCallBack(taskGroup.id);
            if (ref) {
                ref?.current?.close();
            }
        }
    };

    useEffect(() => {
        setCurrentPath(location.pathname);
    }, [location.pathname]);

    return (
        <>
            {taskGroup ? (
                <div className="o-manageTasksCard" id={`taskGroup-${taskGroup.id}`}>
                    <Accordion>
                        <AccordionSummary
                            aria-controls="additional-actions1-content"
                            style={{ backgroundColor: `${taskGroup.color}` }}
                        >
                            <div className={`o-manageTasksCard__header ${descriptionExpanded ? '-expanded' : ''}`}>
                                <Grid container>
                                    <Grid item xs={11}>
                                        <div className="o-manageTasksCard__taskGroupTitle taskGroup">
                                            <span className={`header-title`}>{label}</span>
                                            <ShowIcon />
                                        </div>
                                    </Grid>
                                    <Grid container justify="flex-end" item xs={1}>
                                        {showMenu && (
                                            <ToolTipMenu
                                                ref={ref}
                                                position={['right center', 'right top', 'right bottom']}
                                            >
                                                <li>
                                                    <button onClick={editClick} className="ally-button">
                                                        Edit Task Group
                                                    </button>
                                                </li>
                                                <DeleteTaskGroupModal taskGroup={taskGroup} ref={ref}>
                                                    <li className="alert">
                                                        <button>Delete Task Group</button>
                                                    </li>
                                                </DeleteTaskGroupModal>
                                            </ToolTipMenu>
                                        )}
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={12}>
                                        <Collapse in={descriptionExpanded} className={`o-manageTasksCard__description`}>
                                            <p>{taskGroup.description}</p>
                                        </Collapse>
                                    </Grid>
                                </Grid>
                            </div>
                        </AccordionSummary>
                    </Accordion>

                    {currentPath.includes('/boardview') ? (
                        <>
                            <div className="o-manageTasksCard__taskCount">
                                <span>{taskCount} Tasks</span>
                            </div>
                            <div className="o-manageTasksCard__taskList">
                                {tasks?.map((task) => (
                                    <TaskCard
                                        filterType={filterType}
                                        task={task}
                                        key={task.id}
                                        taskGroupId={taskGroup.id}
                                    />
                                ))}
                            </div>
                        </>
                    ) : (
                        <TaskGroupTable onTaskSelection={onTaskSelection} tasks={tasks} filterType={filterType} />
                    )}

                    {!readOnly && (
                        <AddRowButton
                            onClickHandle={() => addTaskCallBack(taskGroup.id)}
                            className={`${currentPath.includes('/boardview') ? '' : '-alignLeft'}`}
                            itemToAdd="Task"
                        />
                    )}
                </div>
            ) : (
                <div className="o-manageTasksCard cell">
                    <Accordion>
                        <AccordionSummary
                            aria-controls="additional-actions1-content"
                            className="o-manageTasksCard__accordion-header"
                        >
                            <div
                                className={`o-manageTasksCard__header ${filterType === 'Assignee' ? '-assignee' : ''}`}
                            >
                                <Grid container>
                                    <Grid item xs={11}>
                                        <div className="o-manageTasksCard__taskGroupTitle status">
                                            {filterType === 'Status' && <Icon>{getTaskStatusIcon(label)}</Icon>}
                                            {filterType === 'Assignee' && (
                                                <>
                                                    <UserAvatar hideTooltip name={label} />
                                                    &nbsp;
                                                </>
                                            )}
                                            <span className={`header-title`}>{label}</span>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </AccordionSummary>
                    </Accordion>

                    {currentPath.includes('/boardview') ? (
                        <>
                            <div className="o-manageTasksCard__taskCount">
                                <span>{taskCount} Tasks</span>
                            </div>
                            <div className="o-manageTasksCard__taskList">
                                {tasks?.map((task) => (
                                    <TaskCard filterType={filterType} task={task} key={task.id} />
                                ))}
                            </div>
                        </>
                    ) : (
                        <TaskGroupTable onTaskSelection={onTaskSelection} tasks={tasks} filterType={filterType} />
                    )}
                    {!readOnly && (
                        <AddRowButton
                            onClickHandle={() => addTaskCallBack(0)}
                            className={`${currentPath.includes('/boardview') ? '' : '-alignLeft'}`}
                            itemToAdd="Task"
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default ManageTasksCard;
