import React, { ReactElement } from 'react';
import NumberFormat from 'react-number-format';
import { Icon, IconButton } from '@material-ui/core';
import CustomNumberInput from 'components/CustomNumberInput';
import { TaskFundingResourceProps } from '../TaskFundingForm';

interface Props {
    taskFundingResources: TaskFundingResourceProps[];
    isTaskOverAllocated: boolean;
    control: any;
    register: any;
    watch: any;
    onRemove: (resourceId: string, index: number) => void;
}
interface ReadOnlyProps {
    taskFundingResources: TaskFundingResourceProps[];
}

export const TaskFundingResource = ({
    taskFundingResources,
    isTaskOverAllocated,
    control,
    register,
    watch,
    onRemove,
}: Props): ReactElement => (
    <div className="o-taskFundingForm__fundingResources">
        {taskFundingResources.map((taskFundingResource, index) => {
            const { resourceName, allocationAmount, resourceAmount, resourceId, totalAllocation } = taskFundingResource;
            const resourceOverAllocated = totalAllocation > resourceAmount;
            let isOverAllocated = resourceOverAllocated || isTaskOverAllocated;
            if (watch(`taskFundingResources[${index}]`)) {
                const currentValue = watch(`taskFundingResources[${index}]`);
                isOverAllocated = currentValue.floatValue > resourceAmount;
            }
            return (
                <div
                    className={`m-fundingResourceItem ${isOverAllocated && 'm-fundingResourceItem__overAllocated'}`}
                    key={index}
                >
                    <div className="m-fundingResourceItem__label">
                        <IconButton onClick={() => onRemove(resourceId, index)}>
                            <Icon>close</Icon>
                        </IconButton>
                        <p>{resourceName}</p>
                    </div>
                    <div className="m-fundingResourceItem__input">
                        <CustomNumberInput
                            inputLabel="Amount Covered"
                            placeholder={allocationAmount ? allocationAmount.toLocaleString() : '0'}
                            name={`taskFundingResources[${index}]`}
                            control={control}
                            ref={register.ref}
                            fullWidth
                            value={watch(`taskFundingResources[${index}]`)}
                        />
                    </div>
                </div>
            );
        })}
    </div>
);

export const TaskFundingResourceReadOnly = ({ taskFundingResources }: ReadOnlyProps): ReactElement => (
    <div className="o-taskFundingForm__fundingResources">
        {taskFundingResources.map((taskFundingResource, index) => {
            const { resourceName, allocationAmount } = taskFundingResource;
            return (
                <div className="m-fundingResourceItem" key={index}>
                    <p className="m-fundingResourceItem__label">{resourceName}</p>
                    <p>Amount Covered</p>
                    <div className="m-fundingResourceItem__amount">
                        <b>$</b>
                        <NumberFormat displayType="text" thousandSeparator value={allocationAmount} />
                    </div>
                </div>
            );
        })}
    </div>
);
