import React, { useState, useEffect } from 'react';
import useGantt from './gantt/useGantt';
import ganttState from './gantt/ganttState';
import GanttSideBar from './gantt/GanttSideBar';
import GanttTimelineContainer from './gantt/GanttTimelineContainer';
import { techPlanDataConversion } from './gantt/usegantt/views';
import Data, { TaskGroupDataModel, TaskModel, DataProps } from './gantt/usegantt/data';
import './gantt/Gantt.scss';

interface GanttProps {
    readOnly?: boolean;
    taskGroupData: any;
}

const defaultTaskGroupData = [
    {
        description: '',
        id: 0,
        index: 1,
        isOpen: true,
        title: '',
        tasks: new Array(1).fill({}),
    },
];

const Gantt = ({ taskGroupData, readOnly }: GanttProps): React.ReactElement => {
    ganttState.Gantt = useGantt();
    const { setDisplayWidth, references, addReference, DataGroupingSelector, TimeViewSelector } = ganttState.Gantt;
    const [showSidebar, showSidebarHandler] = useState(true);
    const [size, setSize] = useState([0, 0]);

    useEffect(() => {
        if (references.ganttContainerRef?.current) {
            /*
              Event Listener for DOM elements must use:
                  new ResizeObserver(callBackFunction).observe(document.getElementById('item'))
              instead of:
                  domElement.addEventListener('resize', function)
            */
            new ResizeObserver(() => setSize([window.innerWidth, window.innerHeight])).observe(
                references.ganttContainerRef.current
            );
        }
    }, [references.ganttContainerRef]); // <= here

    useEffect(() => {
        if (Number.isInteger(references.ganttContainerRef?.current.offsetWidth)) {
            setDisplayWidth(references.ganttContainerRef.current.offsetWidth);
        }
    }, [size]);

    const sideBarCollapse = (e: boolean) => {
        showSidebarHandler(e);
    };

    useEffect(() => {
        addReference([
            'ganttSidebarRef',
            'refCanvas',
            'ganttContainerRef',
            'ganttSliderRef',
            'ganttHeaderMonths',
            'ganttTimelineContainerRef',
        ]);
    }, []);

    useEffect(() => {
        // Initialize
        // Check for zero state
        setTimeout(() => {
            const isZeroState = taskGroupData?.length === 0;
            ganttState.Gantt.setIsZeroState(isZeroState);
            const taskGroupDataConverted = techPlanDataConversion(isZeroState ? defaultTaskGroupData : taskGroupData);
            ganttState.Gantt.setRawData(taskGroupDataConverted);
            const ranges = ganttState.Gantt.boundingDates(taskGroupDataConverted);
            ganttState.Gantt.setRange(ranges);
            ganttState.Gantt.ganttSetup(100, ranges);
            if (!references.ganttContainerRef?.current?.offsetWidth) return;
            setDisplayWidth(references.ganttContainerRef.current.offsetWidth);
        }, 100);
    }, [taskGroupData, references.ganttContainerRef]);

    useEffect(() => {
        if (!references.ganttSidebarRef?.current) return;
        const ganttSideBar = references.ganttSidebarRef.current;
        setTimeout(() => {
            ganttState.Gantt.setSideBarWidth(ganttSideBar.offsetWidth);
        }, 300);
    }, [showSidebar]);

    useEffect(() => {
        // Gantt Cache Listener
        function x(e: any) {
            const ceiling = Math.floor((e.target.scrollTop + e.target.clientHeight) / 42) + 30;
            ganttState.Gantt.setCacheCeiling(ceiling);
        }
        if (!!references.refCanvas) {
            const item = document.getElementById('Gantt');
            item?.addEventListener('scroll', x);
        }
        return () => {
            const item = document.getElementById('Gantt');
            item?.removeEventListener('scroll', x);
        };
    }, [references.ganttContainerRef]);

    return (
        <>
            <div className="Gantt__filters">
                <DataGroupingSelector />
                <TimeViewSelector />
            </div>

            <div
                className={`Gantt${ganttState.Gantt.isZeroState ? ' zero' : ''}`}
                id="Gantt"
                ref={references.ganttContainerRef}
            >
                <GanttSideBar isOpen={showSidebar} openHandler={sideBarCollapse} readOnly={readOnly} />
                <GanttTimelineContainer />
            </div>
        </>
    );
};

export default Gantt;
