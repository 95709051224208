import React, { useEffect, useCallback, useState, useContext } from 'react';

import { GetTaskGroupsTaskGroupsByPlanIdTasks as Task, GetTaskGroupsTaskGroupsByPlanId } from 'typings/_graphql';
import { Chip, Grid } from '@material-ui/core';
import { find } from 'lodash-es';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ScheduleIcon from '@material-ui/icons/Schedule';

import renderDependencies from 'components/utilities/renderDependencies';
import UserAvatar from 'components/UserAvatar';
import getDateFormat from 'components/utilities/GetDateFormat';
import PlanContext from 'pages/plans/PlanContext';

import '../taskcard/TaskCard.scss';

interface DeleteTaskCardProps {
    task: Task;
    color?: string;
}

const DeleteTaskCard = ({ task, color }: DeleteTaskCardProps): React.ReactElement => {
    const { taskGroupData } = useContext(PlanContext);
    const [taskTaskGroup, setTaskTaskGroup] = useState<GetTaskGroupsTaskGroupsByPlanId>();
    const [hasDeps, setHasDeps] = useState(false);
    const [isDateRange, setIsDateRange] = useState(task.startDate && task.dueDate);
    const [isMilestone, setIsMilestone] = useState(task.startDate && task.dueDate === null);

    const renderStatusIcon = (status: string) => {
        switch (status) {
            case 'Not Started':
                return <RadioButtonUncheckedIcon />;
            case 'In Progress':
                return <ScheduleIcon />;
            case 'Complete':
                return <CheckCircleOutlineIcon />;
            default:
                return <RadioButtonUncheckedIcon />;
        }
    };

    const fetchDependencies = useCallback(
        () => renderDependencies(task.dependencies, task.dependees),
        [task.dependencies, task.dependees]
    );

    const getTaskTaskGroup = (currentTask: Task) => find(taskGroupData, (o) => o.tasks?.find((t) => t === currentTask));

    useEffect(() => {
        const foundTaskGroup: any = getTaskTaskGroup(task);
        if (foundTaskGroup) setTaskTaskGroup(foundTaskGroup);

        if (task.dependees && task.dependencies && task.dependees.length + task.dependencies.length > 0) {
            setHasDeps(true);
        } else setHasDeps(false);

        setIsDateRange(task.startDate && task.dueDate);
        setIsMilestone(task.startDate && task.dueDate === null);
    }, [task]);

    return (
        <div className="o-taskCard" style={{ borderTop: `0.75em solid ${color || taskTaskGroup?.color}` }}>
            <Grid container direction="column">
                <Grid className="o-taskCard__titleContainer" item xs>
                    <h3 className="o-taskCard__name">{task.name}</h3>
                </Grid>

                <Grid className="o-taskCard__listItem" container alignItems="center" direction="row">
                    <Grid item justify="flex-end">
                        <span>Task Group</span>
                    </Grid>
                    <Grid xs item>
                        <div className="o-taskCard__separatorLine" />
                    </Grid>
                    <Grid item>
                        <div
                            className="o-taskCard__taskGroupPill"
                            style={{ backgroundColor: color || taskTaskGroup?.color }}
                        >
                            {taskTaskGroup?.name}
                        </div>
                    </Grid>
                </Grid>

                <Grid className="o-taskCard__listItem" container alignItems="center" direction="row">
                    <Grid item justify="flex-end">
                        <span>Assignee</span>
                    </Grid>
                    <Grid xs item>
                        <div className="o-taskCard__separatorLine" />
                    </Grid>
                    <Grid item>
                        <span>
                            {task.users?.map((user) => (
                                <UserAvatar user={user} />
                            ))}
                        </span>
                    </Grid>
                </Grid>

                <Grid className="o-taskCard__listItem" container alignItems="center" direction="row">
                    <Grid item justify="flex-start">
                        <span>Status</span>
                    </Grid>
                    <Grid xs item>
                        <div className="o-taskCard__separatorLine" />
                    </Grid>
                    <Grid item justify="flex-end">
                        <Chip variant="outlined" icon={renderStatusIcon(task.status || '')} label={task.status} />
                    </Grid>
                </Grid>

                {(isDateRange || isMilestone) && (
                    <Grid className="o-taskCard__listItem" container alignItems="center" direction="row">
                        <Grid item justify="flex-start">
                            <span>{isDateRange ? 'Date Range' : 'Milestone'}</span>
                        </Grid>
                        <Grid xs item>
                            <div className="o-taskCard__separatorLine" />
                        </Grid>
                        <Grid item justify="flex-end">
                            <Chip
                                variant="outlined"
                                label={`${getDateFormat(task.startDate)} ${
                                    !isMilestone ? `- ${getDateFormat(task.dueDate)}` : ''
                                }`}
                            />
                        </Grid>
                    </Grid>
                )}

                {hasDeps && (
                    <Grid className="o-taskCard__listItem" container alignItems="center" direction="row">
                        <Grid item justify="flex-start">
                            <span>Dependencies</span>
                        </Grid>
                        <Grid xs item>
                            <div className="o-taskCard__separatorLine" />
                        </Grid>
                        <Grid item justify="flex-end">
                            {fetchDependencies()}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default DeleteTaskCard;
