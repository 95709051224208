/* eslint @typescript-eslint/no-inferrable-types: 'off' */
import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import {
    GetPlansPlans as PlanType,
    ArchivePlanArchivePlan as ArchivedPlan,
    UnarchivePlanUnarchivePlan as UnarchivedPlan,
    ArchivePlanVariables,
    GetUsersUsers,
} from 'typings/_graphql';
import { qPlans, qUsers, qInvitees } from 'api/queries';

import './plancard/PlanCard.scss';
import ClassNameFactory from 'components/utilities/ClassNameFactory';
import ToolTipMenu from 'components/ToolTipMenu';
import ConfirmModal from 'components/ConfirmModal';
import { Grid, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { IUser } from 'typings/User';
import { Store } from 'store/reducers';
import AvatarGroup from 'components/AvatarGroup';
import EditProjectModal from './plancard/EditProjectModal';

interface Props {
    plan: PlanType;
    seeArchived: boolean;
    shortenCard?: boolean;
}

interface TeamBadgesProps {
    usersByPlan: GetUsersUsers[];
    shortenCard: boolean;
    userStatus: number;
}

const TeamBadges = ({ usersByPlan = [], shortenCard, userStatus }: TeamBadgesProps) =>
    !(shortenCard && userStatus !== 0) ? (
        <Grid item>
            <div className="o-planCard__teamContainer">
                <span className="o-planCard__teamTitle">Team:</span>
                <AvatarGroup users={usersByPlan} />
            </div>
        </Grid>
    ) : null;

function invitationButtons(
    userStatus: number = 0,
    seeArchived: boolean,
    unarchivePlan: () => void,
    plan: PlanType,
    handleInvitation: (accept: boolean) => void
) {
    if (seeArchived) {
        return (
            <button
                className="o-planCard__projectButton"
                onClick={() => {
                    unarchivePlan();
                }}
            >
                Mark As Active
            </button>
        );
    }

    return userStatus === 0 ? (
        <Link to={`/plan/${plan.id}/details/identifypeople`}>
            <button className="o-planCard__projectButton">Go To Project</button>
        </Link>
    ) : (
        <>
            <Button onClick={() => handleInvitation(false)} style={{ marginRight: 15 }} variant="outlined">
                Reject Invite
            </Button>
            <Button onClick={() => handleInvitation(true)} color="primary" variant="contained">
                Accept Invite
            </Button>
        </>
    );
}

export default function PlanCard({ plan, seeArchived, shortenCard = false }: Props): React.ReactElement {
    const date = new Date(plan.archivedDate);
    const shortMonthString = date.toLocaleString('en-us', { month: 'short' });
    const dayWithLeadingZeroes = `0${date.getDate().toString()}`.slice(-2);
    const formattedDate = `${dayWithLeadingZeroes} ${shortMonthString} ${date.getFullYear()}`;
    const ref: any = useRef();

    const userData: IUser = useSelector((storeState: Store) => storeState?.User);

    const [userStatus, setUserStatus] = useState(5);

    const [acceptInvitation] = useMutation<any>(qInvitees.ACCEPT_INVITATION);

    const { data: users } = useQuery(qUsers.GET_USERS_BY_PLAN, {
        variables: { planId: plan.id },
    });

    const [archivePlan] = useMutation<ArchivedPlan, ArchivePlanVariables>(qPlans.ARCHIVE_PLAN, {
        variables: { planId: plan.id },
    });

    const [unarchivePlan] = useMutation<UnarchivedPlan, ArchivePlanVariables>(qPlans.UNARCHIVE_PLAN, {
        variables: { planId: plan.id },
    });

    const handleInvitation = (accept: boolean) => {
        acceptInvitation({
            refetchQueries: [
                {
                    query: qPlans.QUERY_PLANS,
                },
                {
                    query: qUsers.GET_USERS_BY_PLAN,
                    variables: { planId: plan.id },
                },
            ],
            fetchPolicy: 'no-cache',
            variables: {
                email: userData.emailAddress,
                planId: plan.id,
                isAccepted: accept,
            },
        });
    };

    useEffect(() => {
        if (plan?.invitation) {
            setUserStatus(plan.invitation.status);
        }
    }, [plan]);

    const ToolTipOptions = seeArchived ? (
        <li>
            <button
                onClick={() => {
                    unarchivePlan();
                }}
                className="ally-button"
            >
                Mark Project As Active
            </button>
        </li>
    ) : (
        <>
            <li>
                <EditProjectModal
                    plan={plan}
                    ref={ref}
                    trigger={(openFunction: (val: boolean) => void) => (
                        <button onClick={() => openFunction(true)}>Edit Project Information</button>
                    )}
                />
            </li>
            <li>
                <ConfirmModal
                    ref={ref}
                    closeText="Cancel"
                    onConfirm={() => {
                        archivePlan();
                    }}
                    confirmText="Confirm"
                    message={
                        <>
                            Are you sure you want to put {<b>{plan.name}</b>} on hold? All individuals on the project
                            will be notified of this change. The project will become viewable under the&nbsp;
                            <q>View Projects On Hold</q>&nbsp;list.
                        </>
                    }
                    title="Mark Project as On Hold?"
                >
                    <button>Mark Project as On Hold</button>
                </ConfirmModal>
            </li>
        </>
    );

    const classNames = [
        {
            condition: true,
            class: 'o-planCard',
        },
        { condition: plan.archived, class: '-archived' },
    ];

    return (
        <div className={ClassNameFactory(classNames)}>
            <Grid container direction="column" justify="space-between" className="o-planCard__cardContainer">
                <Grid className="o-planCard__planInformation" spacing={1} container direction="column">
                    <Grid item xs={12} sm={8}>
                        <h2 className="o-planCard__planName">{plan?.name}</h2>
                    </Grid>
                    <Grid item>
                        {!(seeArchived || userStatus !== 0) ? (
                            <Link to={`/plan/${plan.id}/details/identifypeople`}>
                                <p className="o-planCard__planCode">{plan.code}</p>
                            </Link>
                        ) : (
                            <>
                                <p className="o-planCard__planCode">{plan.code}</p>
                            </>
                        )}
                    </Grid>
                    {seeArchived && (
                        <Grid item>
                            <p className="o-planCard__archiveDate">Placed On Hold On: {formattedDate}</p>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={10}>
                        <p className="o-planCard__description">{plan?.description}</p>
                    </Grid>
                </Grid>
                <div className="o-planCard__hr" />
                <Grid
                    className="o-planCard__planInformation"
                    alignItems="center"
                    container
                    justify="space-between"
                    direction="row"
                >
                    <TeamBadges usersByPlan={users?.usersByPlan} shortenCard={shortenCard} userStatus={userStatus} />
                    <Grid item>
                        {userStatus !== 5 &&
                            invitationButtons(userStatus, seeArchived, unarchivePlan, plan, handleInvitation)}
                    </Grid>
                </Grid>
            </Grid>
            {!seeArchived && userStatus === 0 && (
                <div className="o-planCard__tooltip">
                    <ToolTipMenu ref={ref} position={['right top']}>
                        {ToolTipOptions}
                    </ToolTipMenu>
                </div>
            )}
        </div>
    );
}
