import React, { useRef, ReactElement } from 'react';

import { Grid, Button } from '@material-ui/core';
import ConfirmModal from 'components/ConfirmModal';
import PageHeading from 'components/PageHeading';

import { WELCOME, QUESTIONS, RESULTS, assessmentRestartMsg, resultsRestartMsg } from './AssessTechProps';

import './AssessTechHeader.scss';

interface AssessTechHeaderProps {
    currentPage: string;
    takeNewAssess: boolean;
    restartData: () => void;
    saveNewAssessment: () => void;
}

const AssessTechHeader = ({
    currentPage,
    takeNewAssess,
    restartData,
    saveNewAssessment,
}: AssessTechHeaderProps): ReactElement => {
    const ref: any = useRef();
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <PageHeading
                        title="Assess Technology"
                        description="Assess the technology's readiness levels relative to the problem to get an idea on how mature the technology is."
                    />
                </Grid>
                <Grid item xs={4} className={`o-assesstech__buttons`}>
                    {currentPage !== WELCOME && (
                        <ConfirmModal
                            ref={ref}
                            closeText="Cancel"
                            confirmText="Restart"
                            onConfirm={restartData}
                            title="Are you sure you want to restart the assessment?"
                            message={
                                <>
                                    <b>Note: </b>
                                    {currentPage === QUESTIONS ? assessmentRestartMsg : resultsRestartMsg}
                                </>
                            }
                        >
                            <Button style={{ width: 'fit-content' }} color="primary" variant="outlined">
                                Restart Assessment
                            </Button>
                        </ConfirmModal>
                    )}
                    {currentPage === RESULTS && takeNewAssess && (
                        <Button onClick={saveNewAssessment} color="primary" variant="contained">
                            Save Assessment
                        </Button>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default AssessTechHeader;
