import React, { useEffect, useContext, useState } from 'react';
import { find, size } from 'lodash-es';
import { Checkbox, TableCell, TableRow, Icon } from '@material-ui/core';
import RenderDoubleDatePicker from 'components/table/RenderDoubleDatePicker/RenderDoubleDatePicker';
import UserAvatar from 'components/UserAvatar';
import renderDependencies from 'components/utilities/renderDependencies';
import { getTaskStatusIcon } from 'components/utilities/getStatusIcon';
import { TaskWithTaskGroup } from 'components/utilities/sortTaskGroupData';
import { GetTaskGroupsTaskGroupsByPlanId, GetTaskGroupsTaskGroupsByPlanIdTasks as Task } from 'typings/_graphql';
import PlanContext from '../../PlanContext';

import './tasklistitem/TaskListItem.scss';

interface Props {
    task: TaskWithTaskGroup;
    taskGroup?: GetTaskGroupsTaskGroupsByPlanId;
    filterType: string;
    onTaskSelection?: any;
}

const TaskListItem = ({ task, onTaskSelection, taskGroup, filterType }: Props): React.ReactElement => {
    const { setOffCanvasTask, taskGroupData, selectedTaskIds } = useContext(PlanContext);
    const assignee = task && task.users ? task?.users[0]?.name : '';
    const [taskTaskGroup, setTaskTaskGroup] = useState<GetTaskGroupsTaskGroupsByPlanId>();
    const [hasDeps, setHasDeps] = useState(false);
    const STATUS_PRIVILEGE = ['Task Group', 'Assignee'];
    const TASK_GROUP_PRIVILEGE = ['Assignee', 'Status'];
    const { name, status, dependees, dependencies } = task;

    const getTaskTaskGroup = (currentTask: Task) => find(taskGroupData, (o) => o.tasks?.find((t) => t === currentTask));

    useEffect(() => {
        const foundTaskGroup: any = getTaskTaskGroup(task);
        if (foundTaskGroup) setTaskTaskGroup(foundTaskGroup);
        const checkHasDeps = size(task?.dependees) || size(task?.dependencies);
        setHasDeps(!!checkHasDeps);
    }, [task]);

    const editTask = () => {
        setOffCanvasTask({ canvas: true, editTask: task, taskGroupId: taskGroup?.id });
    };

    return (
        <TableRow>
            <TableCell width="38%">
                <div className="o-taskListItem__taskNameContainer">
                    <Checkbox
                        checked={selectedTaskIds.includes(task.id)}
                        onClick={() => {
                            onTaskSelection(task.id);
                        }}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        color="secondary"
                    />
                    <button onClick={editTask}>
                        <span className="o-taskListItem__taskName">{name}</span>
                    </button>
                </div>
            </TableCell>
            <TableCell width="10%" align="center">
                {STATUS_PRIVILEGE.includes(filterType) && (
                    <span className="o-taskListItem__status">
                        <button onClick={editTask}>
                            <Icon>{status && getTaskStatusIcon(status)}</Icon>
                            {status}
                        </button>
                    </span>
                )}
                {filterType === 'Status' && <UserAvatar name={assignee} />}
            </TableCell>
            <TableCell width="10%" align="center">
                {filterType === 'Task Group' && <UserAvatar name={assignee} />}
                {TASK_GROUP_PRIVILEGE.includes(filterType) && (
                    <div className="o-taskListItem__taskGroup" style={{ backgroundColor: task?.taskGroup?.color }}>
                        {task?.taskGroup?.name}
                    </div>
                )}
            </TableCell>
            <TableCell className="o-taskListItem__interactable" onClick={editTask} width="12%" align="center">
                {task.estimate ? (
                    <div className="o-taskListItem__estimate">
                        <strong>
                            <span className="o-taskListItem__dollarSign">$</span>
                        </strong>
                        <span>{task.estimate.toLocaleString()}</span>
                    </div>
                ) : (
                    ''
                )}
            </TableCell>
            <TableCell width="15%" align="center">
                <RenderDoubleDatePicker data={task} label="Set Date" />
            </TableCell>
            <TableCell width="15%" align="center">
                {hasDeps && (
                    <div className="o-taskListItem__dependency">{renderDependencies(dependencies, dependees)}</div>
                )}
            </TableCell>
        </TableRow>
    );
};

export default TaskListItem;
