import React, { useContext, useRef } from 'react';
import { GetTaskGroupsTaskGroupsByPlanId } from 'typings/_graphql';
import ToolTipMenu from 'components/ToolTipMenu';
import PlanContext from '../PlanContext';

import DeleteTaskGroupModal from './DeleteTaskGroupModal';
import './taskgroupcardshort/TaskGroupCardShort.scss';

interface Props extends GetTaskGroupsTaskGroupsByPlanId {
    showNav?: boolean;
}

const TaskGroupCardShort = ({ showNav = true, ...props }: Props): React.ReactElement => {
    const ref: any = useRef();
    const { updateCallBack } = useContext(PlanContext);
    const { id, name, color, description = 'Description' } = props;

    const editClick = () => {
        updateCallBack(id);
        if (ref) {
            ref?.current?.close();
        }
    };

    return (
        <div className="o-taskGroupCardShort cell" id={`taskGroup-${id}`} style={{ backgroundColor: color }}>
            <h2 className="o-taskGroupCardShort__title">{name}</h2>
            {showNav && (
                <div className="o-taskGroupCardShort__tooltip">
                    <ToolTipMenu
                        size={'20px'}
                        ref={ref}
                        nested
                        position={['right top', 'right center', 'right bottom']}
                    >
                        <li>
                            <button className="ally-button" onClick={editClick}>
                                Edit Task Group
                            </button>
                        </li>
                        <DeleteTaskGroupModal taskGroup={props} ref={ref}>
                            <li className="alert">
                                <button>Delete Task Group</button>
                            </li>
                        </DeleteTaskGroupModal>
                    </ToolTipMenu>
                </div>
            )}
            {description && <p>{description}</p>}
        </div>
    );
};

export default TaskGroupCardShort;
