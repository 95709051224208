import React from 'react';
import './ThreeBarOverlay.scss';

interface GraphHeaderProps {
    scaleMax: number;
}

interface GraphBarProps {
    data: number[]; // 2 element array: element 0 should usually be smaller than element 1
    scaleMax: number;
    icon?: () => React.ReactElement;
}

export const ColumnLines = (): React.ReactElement => (
    <div className="a-columnContainer">
        {new Array(5).fill(null).map((item: null, i: number) => (
            <div className="a-columnContainer__divider" key={i} style={i !== 4 ? {} : { borderLeft: 'none' }} />
        ))}
    </div>
);

export const ThreeBarOverlay = ({ data, scaleMax, icon }: GraphBarProps): React.ReactElement => {
    const midBarOffset = data[1] > data[0] ? 3 : 0;
    const overshoot = midBarOffset > 0;
    const noResourceAmountButAllocatedAmount = !data[0] && data[1];

    return (
        <div className="o-three-bars-overlay" style={{ width: '102%', position: 'absolute' }}>
            {overshoot &&
                (noResourceAmountButAllocatedAmount ? (
                    <div
                        className="o-three-bars-overlay totalAmount"
                        style={{
                            width: `${(data[1] / scaleMax) * 100}%`,
                        }}
                    />
                ) : (
                    <div
                        className="o-three-bars-overlay overAllocated"
                        style={{
                            width: `${(data[1] / scaleMax) * 100}%`,
                        }}
                    />
                ))}
            {data[1] !== data[0] && !noResourceAmountButAllocatedAmount && (
                <>
                    <div
                        className="o-three-bars-overlay amountUsed__cap"
                        style={{
                            width: `${(data[0] / scaleMax) * 100}%`,
                            left: midBarOffset,
                        }}
                    />
                    <div
                        className="o-three-bars-overlay amountUsed"
                        style={{
                            width: `${(data[0] / scaleMax) * 100}%`,
                        }}
                    />
                </>
            )}
            {data[1] > 0 && (
                <div
                    className="o-three-bars-overlay totalAmount"
                    style={{ width: `${(Math.min(data[0], data[1]) / scaleMax) * 100}%` }}
                />
            )}
            {icon && !noResourceAmountButAllocatedAmount && (
                <div
                    className="o-three-bars-overlay icon"
                    style={{ left: `${(Math.max(data[1], data[1] - data[0], data[0]) / scaleMax) * 100 + 0.6}%` }}
                >
                    {icon()}
                </div>
            )}
        </div>
    );
};

export const GraphHeader = ({ scaleMax }: GraphHeaderProps): React.ReactElement => (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        {new Array(5)
            .fill(0)
            .map((val: number, i: number) => val + i / 4)
            .map((val: number, i: number) => (
                <div style={{ marginTop: 6, height: '100%', paddingBottom: 2 }} key={i}>
                    {
                        <div style={{ marginTop: 6 }} key={i}>
                            {i !== 4 ? Number(val * scaleMax).dollarValueTruncate() : ''}
                        </div>
                    }
                </div>
            ))}
    </div>
);
