import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Button } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { qTasks } from 'api/queries';

import HoverTooltip from 'components/HoverTooltip';
import Modal from 'components/Modal';
import { GetTaskGroupsTaskGroupsByPlanIdTasks as Task } from 'typings/_graphql';
import { IPlanId } from 'typings/Plan';
import ConfirmModal from 'components/ConfirmModal';
import getTasks from 'components/utilities/getTasks';
import UpdateTaskModalTable from './UpdateTaskModalTable';
import PlanContext from '../../PlanContext';

import './updatetaskmodal/UpdateTaskModal.scss';

interface Props {
    tasks: number[] | undefined;
    open?: boolean;
    afterClick: () => void;
}

const UpdateTaskModal = React.forwardRef(({ tasks = [], open = false, afterClick }: Props, ref: any) => {
    const { planId }: IPlanId = useParams();
    const { taskGroupData, selectedTaskIds, setSelectedTaskIds } = useContext(PlanContext);
    const [selectedTasks, setSelectedTasks] = useState<Task[]>([]);
    const [shiftDays, setShiftDays] = useState<number>(1);
    const [updateTasks] = useMutation(qTasks.UPDATE_TASKS_SHIFT_DATES);

    const hasEmptyDate = () => selectedTasks.find((task) => task.startDate === null && task.dueDate === null);

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = parseFloat(e.currentTarget.value);
        setShiftDays(input);
    };

    const updateDates = () => {
        updateTasks({
            variables: { taskIds: selectedTaskIds, days: shiftDays },
            refetchQueries: [
                {
                    query: qTasks.GET_BY_PLAN_FUND_TYPE_AND_USER,
                    variables: { planId: parseFloat(planId), getByUser: false },
                },
            ],
        });
        setSelectedTaskIds([]);
    };

    const closeModal = (close: { (): void }) => {
        close();
        afterClick();
        if (ref) {
            ref?.current?.close();
        }
    };

    useEffect(() => {
        setSelectedTasks(getTasks(tasks, taskGroupData));
    }, [tasks, taskGroupData]);

    return (
        <Popup
            open={open && selectedTasks.length > 0}
            closeOnDocumentClick
            onClose={afterClick}
            modal
            nested
            contentStyle={{ width: '600px' }}
        >
            {(close: () => void) => (
                <div className="a-updateTaskModal">
                    <Modal closeFunction={() => closeModal(close)}>
                        <h3>Shift Dates</h3>
                        <p>
                            Shift selected tasks by
                            <input
                                defaultValue={1}
                                min={1}
                                className="a-updateTaskModal__dateInput"
                                type="number"
                                value={shiftDays}
                                onChange={onInputChange}
                            />
                            Business Days.
                            <br />
                            Some of the selected tasks contain dependencies. To continue
                            <br />
                            click &apos;Shift Tasks&apos; or click cancel to exit.
                        </p>

                        <UpdateTaskModalTable tasks={selectedTasks} />

                        <div className="button-group">
                            <Button color="secondary" variant="outlined" onClick={() => closeModal(close)}>
                                Cancel
                            </Button>

                            <ConfirmModal
                                closeText="Cancel"
                                onConfirm={() => {
                                    updateDates();
                                    closeModal(close);
                                }}
                                confirmText="Shift"
                                message={
                                    <>
                                        Are you sure you want to shift the selected tasks by {shiftDays} business days?
                                        All dependencies will be effected.
                                    </>
                                }
                                title="Shift Dates"
                            >
                                {hasEmptyDate() ? (
                                    <HoverTooltip message="Ensure all selected tasks have a date">
                                        <div>
                                            <Button disabled color="primary" variant="contained">
                                                Shift Tasks
                                            </Button>
                                        </div>
                                    </HoverTooltip>
                                ) : (
                                    <Button color="primary" variant="contained">
                                        Shift Tasks
                                    </Button>
                                )}
                            </ConfirmModal>
                        </div>
                    </Modal>
                </div>
            )}
        </Popup>
    );
});

export default UpdateTaskModal;
