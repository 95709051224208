import { gql } from 'apollo-boost';

const QUERY_ALL = gql`
    query getResourceStatuses {
        resourceStatuses {
            id
            status
            description
        }
    }
`;

export { QUERY_ALL };
