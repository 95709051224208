import React, { ReactElement } from 'react';
import NumberFormat from 'react-number-format';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { SummaryTableData } from './AlignResourceExpandableRow';
import { OVER_ALLOCATED } from '../AlignResourceListItem';
import './tables/Tables.scss';

interface SummaryTableProps {
    summaryTableData: SummaryTableData;
    allocationStatus: string;
}

const SummaryTable = ({ summaryTableData, allocationStatus }: SummaryTableProps): ReactElement => {
    const { estimate, totalAllocated } = summaryTableData;
    const isOverAllocated = allocationStatus === OVER_ALLOCATED && estimate - totalAllocated < 0;
    return (
        <section className="m-summaryTable">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Estimate</TableCell>
                        <TableCell align="left">Allocated</TableCell>
                        <TableCell align="left">Unallocated</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <div className="o-resourceTable__numberColumn bold">
                                <b>$</b>
                                <NumberFormat displayType="text" thousandSeparator value={estimate} />
                            </div>
                        </TableCell>
                        <TableCell>
                            <div className="o-resourceTable__numberColumn bold">
                                <b>$</b>
                                <NumberFormat displayType="text" thousandSeparator value={totalAllocated} />
                            </div>
                        </TableCell>
                        <TableCell style={{ backgroundColor: isOverAllocated ? '#FDECEB' : '' }}>
                            {isOverAllocated && (
                                <p className="o-resourceTable__overAllocatedText overAligned">Over Allocated</p>
                            )}
                            <div className={`o-resourceTable__numberColumn bold ${isOverAllocated && 'overAligned'}`}>
                                <b>$</b>
                                <NumberFormat
                                    displayType="text"
                                    thousandSeparator
                                    value={Math.abs(estimate - totalAllocated)}
                                />
                            </div>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </section>
    );
};

export default SummaryTable;
