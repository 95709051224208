import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, IconButton, Icon } from '@material-ui/core';

import Offcanvas from 'components/OffCanvas';
import Layouts from 'components/layouts/Layouts';
import PageHeading from 'components/PageHeading';
import PrivateRoute from 'PrivateRoute';
import TaskGroupForm from './TaskGroupForm';
import TaskForm from './TaskForm';
import BoardView from './managetasks/BoardView';
import Roadmap from './managetasks/Roadmap';
import ListView from './managetasks/ListView';
import { PlanConsumer, PlanProvider } from './PlanContext';

import './managetasks/ManageTasks.scss';

const { Content, Sidebar, Subheader, Navigation } = Layouts;

const getPageHeadingFromPath = (currentPath: string): string => {
    if (currentPath.includes('/boardview')) {
        return 'Board View';
    }
    if (currentPath.includes('/roadmap')) {
        return 'Project Roadmap';
    }
    return 'List View';
};

const ManageTasks = (): React.ReactElement => {
    const [resetDefaultData, setResetDefaultData] = useState(false);
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState(location.pathname);

    useEffect(() => {
        setCurrentPath(location.pathname);
    }, [location.pathname]);

    return (
        <Layouts className="prevent-col-wrap">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Sidebar />
                <Content style={{ overflowX: 'scroll' }}>
                    <Navigation />
                    <PlanProvider>
                        <PlanConsumer>
                            {({
                                planId,
                                activeTaskGroupTask,
                                setOffCanvasTaskGroup,
                                setOffCanvasTask,
                                offCanvasTaskGroup,
                                offCanvasTask,
                            }) => (
                                <>
                                    <Subheader>
                                        <Grid container justify="space-between">
                                            <Grid item xs={10}>
                                                <PageHeading title={getPageHeadingFromPath(currentPath)} />
                                            </Grid>
                                            {/* <Grid item>
                                                <Button color="primary" variant="outlined">
                                                    Export Project
                                                </Button>
                                            </Grid> */}
                                        </Grid>
                                    </Subheader>
                                    <PrivateRoute
                                        path="/plan/:planId/tasks/boardview"
                                        component={BoardView}
                                        setOffCanvasTaskGroup={setOffCanvasTaskGroup}
                                        exact
                                    />
                                    <PrivateRoute
                                        path="/plan/:planId/tasks/roadmap"
                                        component={Roadmap}
                                        setOffCanvasTaskGroup={setOffCanvasTaskGroup}
                                        exact
                                        readOnly
                                    />
                                    <PrivateRoute
                                        path="/plan/:planId/tasks/listview"
                                        component={ListView}
                                        setOffCanvasTaskGroup={setOffCanvasTaskGroup}
                                        exact
                                    />
                                    <Offcanvas state={offCanvasTaskGroup.canvas}>
                                        <IconButton
                                            onClick={() => {
                                                setOffCanvasTaskGroup({ canvas: false, editTaskGroup: false });
                                                setResetDefaultData(!resetDefaultData);
                                            }}
                                            className="close-icon"
                                        >
                                            <div className="o-manageTasks__closeIcon">
                                                <Icon>close</Icon>
                                            </div>
                                        </IconButton>
                                        <TaskGroupForm
                                            closeForm={() => {
                                                setOffCanvasTaskGroup({ canvas: false, editTaskGroup: false });
                                            }}
                                            editTaskGroup={offCanvasTaskGroup.editTaskGroup}
                                            resetDefaultData={resetDefaultData}
                                        />
                                    </Offcanvas>
                                    <Offcanvas state={offCanvasTask.canvas}>
                                        <IconButton
                                            onClick={() => {
                                                setOffCanvasTask({ canvas: false, editTask: false });
                                            }}
                                            className="close-icon"
                                        >
                                            <div className="o-manageTasks__closeIcon">
                                                <Icon>close</Icon>
                                            </div>
                                        </IconButton>
                                        <TaskForm planId={planId} taskGroupId={activeTaskGroupTask} />
                                    </Offcanvas>
                                </>
                            )}
                        </PlanConsumer>
                    </PlanProvider>
                </Content>
            </div>
        </Layouts>
    );
};

export default ManageTasks;
