import React, { ReactElement } from 'react';
import Popup from 'reactjs-popup';
import { toast } from 'react-toastify';
import { Button } from '@material-ui/core';

import Modal from 'components/Modal';
import AlertToast from 'components/AlertToast';

import { qTaskGroups } from 'api/queries';
import { DeleteTaskGroupVariables, GetTaskGroupsTaskGroupsByPlanId } from 'typings/_graphql';
import { useMutation } from '@apollo/client';
import TaskGroupCardShort from './TaskGroupCardShort';

interface Props {
    taskGroup: GetTaskGroupsTaskGroupsByPlanId;
    children: ReactElement;
    ref?: any;
}

const DeleteTaskGroupModal = React.forwardRef(({ taskGroup, children }: Props, ref: any) => {
    const [deleteTaskGroup] = useMutation<DeleteTaskGroupVariables>(qTaskGroups.DELETE_TASK_GROUP);

    const deleteTaskGroupAction = async (taskGroupId: number, close: { (): void }) => {
        await deleteTaskGroup({ variables: { taskGroupId } })
            .then(() => {
                toast(
                    <AlertToast
                        severity="success"
                        message="Your task group was successfully deleted along with associated tasks."
                    />
                );
                close();
                if (ref) {
                    ref?.current?.close();
                }
            })
            .catch((e) => {
                toast(<AlertToast severity="error" message={`Error deleting Task Group: ${e}`} />);
            });
    };

    const closeModal = (close: { (): void }) => {
        close();
        if (ref) {
            ref?.current?.close();
        }
    };

    return (
        <Popup trigger={children} modal nested contentStyle={{ width: '390px' }}>
            {(close: () => void) => (
                <Modal closeFunction={() => closeModal(close)}>
                    <h3>Delete Task Group</h3>
                    <p>
                        Are you sure you want to delete this Task Group? Anything associated with this Task Group, like
                        tasks, will be deleted as well.
                    </p>
                    <TaskGroupCardShort showNav={false} {...taskGroup} />

                    <div className="button-group">
                        <Button color="secondary" variant="outlined" onClick={() => closeModal(close)}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                deleteTaskGroupAction(taskGroup.id, close);
                            }}
                        >
                            Delete
                        </Button>
                    </div>
                </Modal>
            )}
        </Popup>
    );
});

export default DeleteTaskGroupModal;
