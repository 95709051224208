import React from 'react';
import { GetResourcesResources } from 'typings/_graphql';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';

import ResourceItemBody from './resourcelist/ResourceItemBody';

import './resourcelist/ResourceList.scss';

interface ResourcesReclustered {
    [index: string]: any;
}

interface ResourceListProps {
    totalResources: number;
    resources: readonly GetResourcesResources[] | undefined;
}

export default ({ totalResources, resources }: ResourceListProps): React.ReactElement => {
    const resourcesReclustered: ResourcesReclustered = {};
    const filteredResources = resources?.filter(
        (resource: GetResourcesResources) => resource.status?.status !== 'Rejected'
    );

    filteredResources?.forEach((nonRejectedResource: GetResourcesResources) => {
        if (
            nonRejectedResource.fundType &&
            !resourcesReclustered[`Government Funding: ${nonRejectedResource.fundType.name}`]
        )
            resourcesReclustered[`Government Funding: ${nonRejectedResource.fundType.name}`] = {};
    });

    if (filteredResources?.length === 0) resourcesReclustered['Government Funding'] = {};

    resourcesReclustered['Non-Government Funding'] = {};
    resourcesReclustered['In Kind'] = {};

    filteredResources?.forEach((resource) => {
        switch (resource.resourceType.id) {
            case 1:
                if (!resourcesReclustered[`Government Funding: ${resource?.fundType?.name}`][resource?.name]) {
                    resourcesReclustered[`Government Funding: ${resource?.fundType?.name}`][resource?.name] = [];
                }
                resourcesReclustered[`Government Funding: ${resource?.fundType?.name}`][resource.name].push(resource);
                break;
            case 2:
                if (!resourcesReclustered['Non-Government Funding'][resource.name]) {
                    resourcesReclustered['Non-Government Funding'][resource.name] = [];
                }
                resourcesReclustered['Non-Government Funding'][resource.name].push(resource);
                break;
            case 3:
            default:
                if (!resourcesReclustered['In Kind'][resource.name]) {
                    resourcesReclustered['In Kind'][resource.name] = [];
                }
                resourcesReclustered['In Kind'][resource.name].push(resource);
                break;
        }
    });

    return (
        <div className="o-resourceList">
            {Object.keys(resourcesReclustered).map((key: string) => (
                <ResourceItemBody title={key} {...resourcesReclustered[key]} />
            ))}
            <Table className="o-resourceList__footer">
                <TableBody>
                    <TableRow className="o-resourceList__tableRow -total">
                        <TableCell width="50%" />
                        <TableCell width="35%">
                            <div className="o-resourceList__resourceTotal -project -whiteText">Project Total:</div>
                        </TableCell>
                        <TableCell width="15%">
                            <div className="o-resourceList__resourceAmount -whiteText">
                                <strong>$</strong>
                                <strong>{totalResources.toLocaleString()}</strong>
                            </div>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};
