import React, { useContext } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import {
    GetUsersAndResourcesUsersAndResources as userType,
    GetResourceTypesWithResourcesGetResourceTypesWithResources as resType,
    GetResourceTypesWithResourcesGetResourceTypesWithResourcesResources as resResourceType,
    GetUsersAndResourcesUsersAndResourcesResources as userResourceType,
} from 'typings/_graphql';

import AddRowButton from 'components/table/AddRowButton/AddRowButton';
import UserAvatar from 'components/UserAvatar';
import { Store } from 'store/reducers';
import { IUser } from 'typings/User';
import ResourceListItem from './ResourceListItem';

import ResourceContext from '../ResourceContext';

interface IdentifyTableProps {
    rowInfo: resType | userType;
    sortedBy: 'Assignee' | 'Type';
}

export const TYPE = 'Type';
export const ASSIGNEE = 'Assignee';
export const GOV_FUNDING = 'Government Funding';

export default function IdentifyTable({ rowInfo, sortedBy }: IdentifyTableProps): React.ReactElement {
    const userData: IUser = useSelector((storeState: Store) => storeState?.User);
    const { setOffCanvasResource } = useContext(ResourceContext);
    let total = 0;
    rowInfo?.resources?.forEach((resource: resResourceType | userResourceType) => {
        total += resource.amount || 0;
    });

    const addResource = () => {
        const userId: string | number = sortedBy === ASSIGNEE ? rowInfo.id : userData.id;
        const resourceTypeId: string | number = sortedBy === TYPE ? rowInfo.id : 0;
        setOffCanvasResource({ canvas: true, editResource: false, resourceTypeId, resourceId: 0, userId });
    };

    return (
        <section className="o-identifyTable">
            <TableContainer className="m-resourceCategory" key={rowInfo?.id} component={Paper}>
                <div className="m-resourceCategory__header">
                    <div className="m-resourceCategory__header-name">
                        {sortedBy === ASSIGNEE && <UserAvatar name={rowInfo.name} />}
                        <h2 className={`m-resourceCategory__name${sortedBy === ASSIGNEE ? '-spaceLeft' : ''}`}>
                            {rowInfo?.name}
                        </h2>
                    </div>
                    {sortedBy === TYPE && (
                        <span className="m-resourceCategory__total">
                            <p>{`${rowInfo?.name} Total:`}</p>
                            <strong className="a-tBodyRow__dollar">$</strong>
                            <NumberFormat displayType="text" thousandSeparator value={total} />
                        </span>
                    )}
                </div>

                <Table>
                    <TableHead className="m-resourceCategory__thead">
                        <TableRow className="m-resourceCategory__theadRow">
                            <TableCell
                                style={{
                                    width: `${rowInfo?.name === GOV_FUNDING || sortedBy === ASSIGNEE ? '25%' : '40%'}`,
                                }}
                            >
                                Name
                            </TableCell>
                            {sortedBy === ASSIGNEE && (
                                <TableCell align="center" style={{ width: '15%' }}>
                                    Type
                                </TableCell>
                            )}
                            {(rowInfo?.name === GOV_FUNDING || sortedBy === ASSIGNEE) && (
                                <TableCell align="center" style={{ width: '15%' }}>{`Gov't Category`}</TableCell>
                            )}
                            {sortedBy === TYPE && (
                                <TableCell align="center" style={{ width: '10%' }}>
                                    Assignee
                                </TableCell>
                            )}
                            <TableCell align="center" style={{ width: '20%' }}>
                                Status
                            </TableCell>
                            <TableCell align="center">Amount</TableCell>
                            <TableCell align="center">Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="m-resourceCategory__tbody">
                        {rowInfo?.resources?.map((resource: any) => (
                            <ResourceListItem
                                resource={resource}
                                isGovFunding={rowInfo?.name === GOV_FUNDING}
                                sortedBy={sortedBy}
                                rowId={rowInfo.id}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <AddRowButton onClickHandle={addResource} className="-alignLeft" />
        </section>
    );
}
