import UserAvatar from 'components/UserAvatar';
import React from 'react';
import { PlanSummaryPlanSummaryScopeScopeRolesUsers as StakeholderUser } from 'typings/_graphql';
import { Icon } from '@material-ui/core';

interface StakeholderProps {
    user: StakeholderUser;
}

const Stakeholder = ({ user }: StakeholderProps): React.ReactElement => (
    <li className="m-stakeholder__item">
        <div className="m-stakeholder__nameEmailContainer">
            <div className="m-stakeholder__nameContainer">
                <UserAvatar hideTooltip name={user.name} />
                <h4>{user.name}</h4>
            </div>
            <div className="m-stakeholder__emailContainer">
                <Icon>email</Icon>
                <p className="m-stakeholder__email">{user.emailAddress}</p>
            </div>
        </div>
        <div className="m-stakeholder__titleContainer">
            <p>{user.dutyTitle}</p>
        </div>
    </li>
);

export default Stakeholder;
