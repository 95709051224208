import React, { useRef } from 'react';
import { Icon, Button } from '@material-ui/core';
import { GetUserUser } from 'typings/_graphql';
import AddPersonModal from './AddPersonModal';

interface FilteredUser {
    id: string;
    email: string;
    status?: number;
    user: GetUserUser;
}

interface IdentifyPeopleFooter {
    planId: string | number;
    users: FilteredUser[];
}

export default ({ planId, users }: IdentifyPeopleFooter): React.ReactElement => {
    const ref = useRef();

    return (
        <div className="m-identify-people-table-footer">
            <AddPersonModal ref={ref} planId={planId} users={users}>
                <div className="o-addRowButton">
                    <Button className="-alignLeft" disableRipple>
                        <Icon>add_circle_outline</Icon>&nbsp;&nbsp;
                        <span className="a-addPersonButton__text">Add Person</span>
                    </Button>
                </div>
            </AddPersonModal>
        </div>
    );
};
