enum StatusCodes {
    'Not Started' = 0,
    'In Progress',
    'Complete',
}

enum TimeBuckets {
    'day' = 0,
    'week',
    'month',
    'year',
}

const StatusCodesIcons: { [index: string]: string } = {
    'Not Started': 'radio_button_unchecked',
    'In Progress': 'access_time',
    Complete: 'check_circle_outline',
};

export default {
    StatusCodes,
    TimeBuckets,
    StatusCodesIcons,
};
