import React from 'react';

import { Grid, MenuItem, Select, InputBase, Card, CardContent, CardHeader } from '@material-ui/core';
import Layouts from 'components/layouts/Layouts';
import PageHeading from 'components/PageHeading';
import CustomInput from 'components/CustomInput';
import { Controller } from 'react-hook-form';

import FundamentalFormProps from './detailsInterface';

import './identifyproblem/IdentifyProblem.scss';

const { Subheader } = Layouts;

const priorityOptions = [
    { value: 'low', label: 'Low' },
    { value: 'medium', label: 'Medium' },
    { value: 'high', label: 'High' },
];

export default function IdentifyProblem({
    parameterData,
    formController,
    setBlur,
    setFocus,
}: FundamentalFormProps): React.ReactElement {
    const { errors, register, control } = formController;
    const readOnly = false;

    return (
        <div className="m-fundamentalsForm">
            <Subheader>
                <PageHeading title="Identify the Problem" />
            </Subheader>
            <Grid id="container" container direction="row" justify="space-between">
                <Grid id="leftColumn" container item xs={12} md={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <div className="m-fundamentalsForm__inputHeading">
                                    <div className="m-fundamentalsForm__inputTitle">Capability Gap</div>
                                    <div className="m-fundamentalsForm__inputDescription">
                                        Describe the customer and their need. If applicable, include the specific
                                        metrics.
                                    </div>
                                </div>
                                <CustomInput
                                    onBlur={setBlur}
                                    onFocus={setFocus}
                                    placeholder={!readOnly ? 'Enter Text' : 'No Content'}
                                    errors={errors}
                                    name="Capability Gap"
                                    inputRef={register()}
                                    disabled={readOnly}
                                    fullWidth
                                    multiline
                                />
                                <div className="m-fundamentalsForm__inputHeading">
                                    <div className="m-fundamentalsForm__inputTitle">Desired End State</div>
                                    <div className="m-fundamentalsForm__inputDescription">
                                        Describe the parameters and requirements that the solution should meet upon
                                        completion.
                                    </div>
                                </div>
                                <CustomInput
                                    onBlur={setBlur}
                                    onFocus={setFocus}
                                    placeholder={!readOnly ? 'Enter Text' : 'No Content'}
                                    errors={errors}
                                    name="Desired End State"
                                    inputRef={register()}
                                    disabled={readOnly}
                                    fullWidth
                                    multiline
                                />
                                <div className="m-fundamentalsForm__inputHeading">
                                    <div className="m-fundamentalsForm__inputTitle">Customer Priority Level</div>
                                </div>
                                <Grid item xs={5}>
                                    <div className="m-identifyProblem__prioritySelect">
                                        <Controller
                                            as={
                                                <Select
                                                    input={<InputBase />}
                                                    name="Customer Priority Level"
                                                    inputRef={register({ name: 'Customer Priority Level' })}
                                                    defaultValue={
                                                        parameterData?.plan.planParameters?.find(
                                                            (parameter) => parameter.label === 'Customer Priority Level'
                                                        )?.details || 'none'
                                                    }
                                                >
                                                    {!parameterData?.plan.planParameters?.find(
                                                        (parameter) => parameter.label === 'Customer Priority Level'
                                                    )?.details && (
                                                        <MenuItem disabled value="none">
                                                            Select Priority
                                                        </MenuItem>
                                                    )}
                                                    {priorityOptions.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            }
                                            name="Customer Priority Level"
                                            control={control}
                                            defaultValue={
                                                parameterData?.plan.planParameters?.find(
                                                    (parameter) => parameter.label === 'Customer Priority Level'
                                                )?.details || 'none'
                                            }
                                        />
                                    </div>
                                </Grid>
                                <div className="m-fundamentalsForm__inputHeading">
                                    <div className="m-fundamentalsForm__inputTitle">Why?</div>
                                    <div className="m-fundamentalsForm__inputDescription">
                                        Describe the reasoning behind the selected priority.
                                    </div>
                                </div>
                                <CustomInput
                                    onBlur={setBlur}
                                    onFocus={setFocus}
                                    placeholder={!readOnly ? 'Enter Text' : 'No Content'}
                                    errors={errors}
                                    name="Customer Priority Reasoning"
                                    inputRef={register()}
                                    disabled={readOnly}
                                    fullWidth
                                    multiline
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid id="rightColumn" container direction="column" spacing={2} item xs={12} md={6}>
                    <Grid id="card" item>
                        <Card>
                            <CardHeader title="Problem Holders" />
                            <CardContent>
                                <div className="m-fundamentalsForm__nameFieldLabel">Air Force Customer</div>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <div className="m-fundamentalsForm__nameContainer">
                                            <div className="m-fundamentalsForm__inputTitle">First Name</div>
                                            <CustomInput
                                                onBlur={setBlur}
                                                onFocus={setFocus}
                                                placeholder={!readOnly ? 'Enter Text' : 'No Content'}
                                                errors={errors}
                                                name="Customer First Name"
                                                inputRef={register()}
                                                disabled={readOnly}
                                                fullWidth
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="m-fundamentalsForm__nameContainer">
                                            <div className="m-fundamentalsForm__inputTitle">Last Name</div>
                                            <CustomInput
                                                onBlur={setBlur}
                                                onFocus={setFocus}
                                                placeholder={!readOnly ? 'Enter Text' : 'No Content'}
                                                errors={errors}
                                                name="Customer Last Name"
                                                inputRef={register()}
                                                disabled={readOnly}
                                                fullWidth
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className="m-fundamentalsForm__inputHeading">
                                    <div className="m-fundamentalsForm__inputTitle">Organization</div>
                                </div>
                                <CustomInput
                                    onBlur={setBlur}
                                    onFocus={setFocus}
                                    placeholder={!readOnly ? 'Enter Text' : 'No Content'}
                                    errors={errors}
                                    name="Customer Organization"
                                    inputRef={register()}
                                    disabled={readOnly}
                                    fullWidth
                                />
                                <div className="m-fundamentalsForm__hr" />
                                <div className="m-fundamentalsForm__nameFieldLabel">Air Force End User</div>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <div className="m-fundamentalsForm__nameContainer">
                                            <div className="m-fundamentalsForm__inputTitle">First Name</div>
                                            <CustomInput
                                                onBlur={setBlur}
                                                onFocus={setFocus}
                                                placeholder={!readOnly ? 'Enter Text' : 'No Content'}
                                                errors={errors}
                                                name="End User First Name"
                                                inputRef={register()}
                                                disabled={readOnly}
                                                fullWidth
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="m-fundamentalsForm__nameContainer">
                                            <div className="m-fundamentalsForm__inputTitle">Last Name</div>
                                            <CustomInput
                                                onBlur={setBlur}
                                                onFocus={setFocus}
                                                placeholder={!readOnly ? 'Enter Text' : 'No Content'}
                                                errors={errors}
                                                name="End User Last Name"
                                                inputRef={register()}
                                                disabled={readOnly}
                                                fullWidth
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className="m-fundamentalsForm__inputHeading">
                                    <div className="m-fundamentalsForm__inputTitle">Organization</div>
                                </div>
                                <CustomInput
                                    onBlur={setBlur}
                                    onFocus={setFocus}
                                    placeholder={!readOnly ? 'Enter Text' : 'No Content'}
                                    errors={errors}
                                    name="End User Organization"
                                    inputRef={register()}
                                    disabled={readOnly}
                                    fullWidth
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* <Grid id="card" item>
                        <Card>
                            <CardHeader
                                action={
                                    <Button variant="contained" color="primary">
                                        Add
                                    </Button>
                                }
                                title="Attachments & Links"
                            />
                            <CardContent>
                                <div>No attachments or links have been added</div>
                            </CardContent>
                        </Card>
                    </Grid> */}
                </Grid>
            </Grid>
        </div>
    );
}
