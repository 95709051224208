import React, { ReactElement } from 'react';

export type SubMenuProps = React.HTMLProps<HTMLUListElement>;

const SubMenu = ({ ...shared }: SubMenuProps): ReactElement => (
    <ul className="subMenu" {...shared}>
        {shared.children}
    </ul>
);

export default SubMenu;
