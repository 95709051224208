import React, { useEffect, useCallback, useState, useContext } from 'react';

import { Chip, Grid } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ScheduleIcon from '@material-ui/icons/Schedule';

import renderDependencies from 'components/utilities/renderDependencies';
import getDateFormat from 'components/utilities/GetDateFormat';
import UserAvatar from 'components/UserAvatar';
import PlanContext from 'pages/plans/PlanContext';
import { TaskWithTaskGroup } from 'components/utilities/sortTaskGroupData';

import './taskcard/TaskCard.scss';

interface TaskCardProps {
    task: TaskWithTaskGroup;
    taskGroupId?: number;
    filterType?: string;
}

const TaskCard = ({ task, filterType = 'Task Group', taskGroupId }: TaskCardProps): React.ReactElement => {
    const { setOffCanvasTask } = useContext(PlanContext);
    const [hasDeps, setHasDeps] = useState(false);
    const [isDateRange, setIsDateRange] = useState(task?.startDate && task?.dueDate);
    const [isMilestone, setIsMilestone] = useState(task?.startDate && task?.dueDate === null);

    const TASK_GROUP_PRIVILEGE = ['Assignee', 'Status'];
    const STATUS_PRIVILEGE = ['Task Group', 'Assignee'];
    const ASSIGNEE_PRIVILEGE = ['Task Group', 'Status'];

    const renderStatusIcon = (status: string) => {
        switch (status) {
            case 'Not Started':
                return <RadioButtonUncheckedIcon />;
            case 'In Progress':
                return <ScheduleIcon />;
            case 'Complete':
                return <CheckCircleOutlineIcon />;
            default:
                return <RadioButtonUncheckedIcon />;
        }
    };

    const fetchDependencies = useCallback(
        () => renderDependencies(task.dependencies, task.dependees),
        [task.dependencies, task.dependees]
    );

    const editTask = () => {
        setOffCanvasTask({ canvas: true, editTask: task, taskGroupId: taskGroupId || task?.taskGroup?.id });
    };

    useEffect(() => {
        if (task.dependees && task.dependencies && task.dependees.length + task.dependencies.length > 0) {
            setHasDeps(true);
        } else setHasDeps(false);

        setIsDateRange(!!task.startDate && !!task.dueDate && task.startDate !== task.dueDate);
        setIsMilestone(
            (!!task.startDate && task.dueDate === null) || (!!task.startDate && task.startDate - task.dueDate === 0)
        );
    }, [task]);

    return (
        <div className="o-taskCard" style={{ borderTop: `0.75em solid ${task?.taskGroup?.color}` }}>
            <Grid container direction="column">
                <Grid className="o-taskCard__titleContainer" onClick={editTask} item xs>
                    <h3 className="o-taskCard__name">{task.name}</h3>
                </Grid>
                {ASSIGNEE_PRIVILEGE.includes(filterType) && (
                    <Grid className="o-taskCard__listItem" container alignItems="center" direction="row">
                        <Grid item justify="flex-end">
                            <span>Assignee</span>
                        </Grid>
                        <Grid xs item>
                            <div className="o-taskCard__separatorLine" />
                        </Grid>
                        <Grid item>
                            <span>
                                {task.users?.map((user) => (
                                    <UserAvatar user={user} />
                                ))}
                            </span>
                        </Grid>
                    </Grid>
                )}
                {TASK_GROUP_PRIVILEGE.includes(filterType) && (
                    <Grid className="o-taskCard__listItem" container alignItems="center" direction="row">
                        <Grid item justify="flex-end">
                            <span>Task Group</span>
                        </Grid>
                        <Grid xs item>
                            <div className="o-taskCard__separatorLine" />
                        </Grid>
                        <Grid item>
                            <div
                                className="o-taskCard__taskGroupPill"
                                style={{ backgroundColor: task?.taskGroup?.color }}
                            >
                                {task?.taskGroup?.name}
                            </div>
                        </Grid>
                    </Grid>
                )}
                {STATUS_PRIVILEGE.includes(filterType) && (
                    <Grid className="o-taskCard__listItem" container alignItems="center" direction="row">
                        <Grid item justify="flex-start">
                            <span>Status</span>
                        </Grid>
                        <Grid xs item>
                            <div className="o-taskCard__separatorLine" />
                        </Grid>
                        <Grid item justify="flex-end">
                            <Chip variant="outlined" icon={renderStatusIcon(task.status || '')} label={task.status} />
                        </Grid>
                    </Grid>
                )}
                {(isDateRange || isMilestone) && (
                    <Grid className="o-taskCard__listItem" container alignItems="center" direction="row">
                        <Grid item justify="flex-start">
                            <span>{isDateRange ? 'Date Range' : 'Milestone'}</span>
                        </Grid>
                        <Grid xs item>
                            <div className="o-taskCard__separatorLine" />
                        </Grid>
                        <Grid item justify="flex-end">
                            <Chip
                                variant="outlined"
                                label={`${getDateFormat(task.startDate)} ${
                                    !isMilestone ? `- ${getDateFormat(task.dueDate)}` : ''
                                }`}
                            />
                        </Grid>
                    </Grid>
                )}
                {hasDeps && (
                    <Grid className="o-taskCard__listItem" container alignItems="center" direction="row">
                        <Grid item justify="flex-start">
                            <span>Dependencies</span>
                        </Grid>
                        <Grid xs item>
                            <div className="o-taskCard__separatorLine" />
                        </Grid>
                        <Grid item justify="flex-end">
                            {fetchDependencies()}
                        </Grid>
                    </Grid>
                )}

                <Grid container justify="flex-end" direction="row">
                    <Grid item>
                        <div className="o-taskCard__viewButtonContainer">
                            <button className="o-taskCard__viewButton" onClick={editTask}>
                                View Details
                            </button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default TaskCard;
