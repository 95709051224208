import React from 'react';
import './colorselector/ColorSelector.scss';

export interface Props extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'> {
    color: string;
    className?: string;
}
const ColorSelector = ({ color = 'white', className = '', ...rest }: Props): React.ReactElement => (
    <button type="button" className={`o-colorcircle ${className}`} style={{ backgroundColor: color }} {...rest}>
        &nbsp;
    </button>
);

export default ColorSelector;
