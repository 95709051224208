import React from 'react';
import { IconButton, Icon } from '@material-ui/core';
import { TaskGroupDataModel, TaskModel } from './Data';
import ganttState from './ganttState';
import SideBarRow from './ganttsidebar/SideBarRow';
import './ganttsidebar/GanttSideBar.scss';

interface SideBarProps {
    isOpen: boolean;
    openHandler: (e: boolean) => void;
    readOnly?: boolean;
}

const GanttSideBar = ({ isOpen, openHandler, readOnly }: SideBarProps): React.ReactElement => {
    const { references, data } = ganttState.Gantt;
    console.log('GanttSideBar', { data });

    return (
        <div className={['m-ganttSideBar', isOpen ? '-open' : '-closed'].join('')} ref={references.ganttSidebarRef}>
            <div className={['stickyHeader', isOpen ? '-open' : '-closed'].join('')}>
                <div className="-expand-icon">
                    {isOpen && (
                        <div>
                            <h4>Task Name</h4>
                        </div>
                    )}
                    <IconButton onClick={() => openHandler(!isOpen)}>
                        <Icon>{isOpen ? 'chevron_left' : 'chevron_right'}</Icon>
                    </IconButton>
                </div>
            </div>
            <div className="m-ganttSideBar__itemsContainer">
                {data
                    .sort((itemA: TaskGroupDataModel, itemB: TaskGroupDataModel) => (itemA.id > itemB.id ? -1 : 1))
                    .map((item: TaskGroupDataModel, i: number) => (
                        <>
                            <SideBarRow
                                key={i}
                                id={`gantt-row-header-${i}`}
                                data={item}
                                groupIndex={ganttState.Gantt.groupBy === 'taskGroupId' ? item.index : 20}
                                readOnly={readOnly}
                                isHeader
                                isOpen={isOpen}
                            />
                            {item.isOpen &&
                                item.children &&
                                item.children.map((task: TaskModel, j: number) => (
                                    <>
                                        <SideBarRow key={`${i}.${j}`} id={`gantt-row-${i}-${j}`} data={{ ...task }} />
                                    </>
                                ))}
                        </>
                    ))}
            </div>
        </div>
    );
};

export default GanttSideBar;
