import React, { ReactNode, useCallback, useState } from 'react';
import { Tabs as MTabs, Tab, TabsProps } from '@material-ui/core';

interface Props extends TabsProps {
    items: {
        label: ReactNode;
        content: ReactNode;
    }[];
    HeaderWrapper?: React.FunctionComponent;
    ContentWrapper?: React.FunctionComponent;
}

function a11yProps(index: any) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

export default function Tabs({
    items,
    HeaderWrapper = undefined,
    ContentWrapper = undefined,
    ...other
}: Props): React.ReactElement {
    const [tabValue, setTabValue] = useState(0);

    const WithHeader = useCallback(
        ({ children }: any) => {
            if (HeaderWrapper) {
                return <HeaderWrapper>{children}</HeaderWrapper>;
            }
            return <>{children}</>;
        },
        [HeaderWrapper]
    );

    const WithContent = useCallback(
        ({ children }: any) => {
            if (ContentWrapper) {
                return <ContentWrapper>{children}</ContentWrapper>;
            }
            return <>{children}</>;
        },
        [ContentWrapper]
    );

    const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <>
            <WithHeader>
                <MTabs onChange={handleChange} value={tabValue} aria-label="Tabs" {...other}>
                    {items.map((item, index) => (
                        <Tab key={index} value={index} label={item.label} wrapped {...a11yProps(index)} />
                    ))}
                </MTabs>
            </WithHeader>
            <WithContent>
                {items.map((item, index) => (
                    <div key={index} role="tabpanel" hidden={tabValue !== index} id={`tabpanel-${index}`}>
                        {item.content}
                    </div>
                ))}
            </WithContent>
        </>
    );
}
