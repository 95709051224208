import React from 'react';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import './planofactionintroduction/PlanOfActionIntroduction.scss';

interface Props {
    closeModal: () => void;
}

const PlanOfActionIntroduction = ({ closeModal }: Props): React.ReactElement => (
    <div className="m-planOfActionIntroduction">
        <Grid container direction="row">
            <Grid item xs={12} sm={6} className="m-planOfActionIntroduction__bods">
                <div className="m-planOfActionIntroduction__head">Plan</div>
                <div className="m-planOfActionIntroduction__headSmall">of Action</div>
                <div className="m-planOfActionIntroduction__sub">Create your path to success</div>
                <p>
                    Now that the fundamentals are established, it&apos;s time to identify the discrete{' '}
                    <b>tasks necessary to get the solution from where it is to where we want it to be</b> (a solved
                    problem or available capability). What do we need to do to mature the technology? Does it need to be
                    flight tested? Will users need training? How do we get it into the supply chain? This thinking will
                    help stakeholders build a comprehensive plan with fewer holes, and therefore lower risk.
                </p>
                <p>
                    After identifying the major task groups, you will need to create tasks. Tasks should describe the
                    activities that need to be accomplished to meet each goal.
                </p>
                <p>
                    Having a solid task list is a huge first step to figuring out who will do what, with what and how
                    many resources, and when. This is not about simply having a plan or document, it&apos;s about{' '}
                    <b>PLANNING</b>. Smart planning will help avoid roadblocks and enable success.
                </p>
            </Grid>
        </Grid>
        <Button
            onClick={() => closeModal()}
            color="primary"
            variant="contained"
            className="m-planOfActionIntroduction__button"
        >
            Start Planning!
        </Button>
    </div>
);

export default PlanOfActionIntroduction;
