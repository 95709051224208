import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { get, has, find } from 'lodash-es';
import { Checkbox, ListItemText, MenuItem } from '@material-ui/core';
import { toast } from 'react-toastify';

import CustomNumberInput from 'components/CustomNumberInput';
import CustomSelect from 'components/CustomSelect';
import CustomMultiSelect from 'components/CustomMultiSelect';
import Permission from 'components/Permission';
import getMoneyFormat from 'components/utilities/GetMoneyFormat';
import { preventEnterSubmit } from 'components/utilities/preventEnterSubmit';
import AlertToast from 'components/AlertToast';

import { qResources, qResourceTypes, qTaskResourcesAssoc, qTasks } from 'api/queries';
import {
    GetResources,
    GetResourcesResources,
    UpdateTaskResourcesVariables,
    CreateTaskResourceVariables,
    DeleteTaskResourcesVariables,
} from 'typings/_graphql';
import TaskFundingSummary from './taskFundingForm/TaskFundingSummary';
import { TaskFundingResource, TaskFundingResourceReadOnly } from './taskFundingForm/TaskFundingResource';
import FormButtons from './form/FormButtons';
import PlanContext from './PlanContext';

import './taskFundingForm/TaskFundingForm.scss';

interface Props {
    readOnly?: boolean;
}

export interface TaskFundingResourceProps {
    resourceName: string;
    resourceAmount: number;
    allocationAmount?: number;
    taskResourceAssocId?: number;
    resourceId: string;
    totalAllocation: number;
}
interface TaskResource {
    id?: number;
    taskId: number;
    resourceId: number;
    taskAmount: number | null;
}

const SELECT_RESOURCE = 'Select Resource';

const TaskFundingForm = ({ readOnly: outerReadOnly = false }: Props): React.ReactElement => {
    const { fundTypes, offCanvasTask, planId, setOffCanvasTask } = useContext(PlanContext);
    const [readOnly, setReadOnly] = useState(true);
    const [title, setTitle] = useState('Add Task Funding');
    const [availableFundingResources, setAvailableFundingResources] = useState<
        readonly GetResourcesResources[] | undefined
    >([]);
    const [selectedFundingResources, setSelectedFundingResources] = useState<TaskFundingResourceProps[]>([]);
    const [isCanvasClosed, setIsCanvasClosed] = useState(false);
    const [taskResourceIdsToDelete, setTaskResourceIdsToDelete] = useState<number[]>([]);

    const {
        control,
        register,
        handleSubmit,
        errors,
        reset,
        watch,
        setValue,
        formState: { isDirty: valuesHaveChanged },
    } = useForm({ mode: 'onSubmit' });

    const [getTask, { data: taskData, loading: getTaskLoading }] = useLazyQuery(qTasks.QUERY_TASK_BY_ID);
    const { data: resourcesData } = useQuery<GetResources>(qResources.QUERY_RESOURCES, {
        variables: { planId },
    });
    const { data: resourceTypesRes } = useQuery(qResourceTypes.QUERY_WITH_RESOURCES, {
        variables: { planId },
    });

    const [createTaskResources] = useMutation<CreateTaskResourceVariables>(
        qTaskResourcesAssoc.CREATE_TASK_RESOURCE_ASSOC
    );
    const [updateTaskResource] = useMutation<UpdateTaskResourcesVariables>(qTaskResourcesAssoc.UPDATE_TASK_RESOURCE);
    const [deleteTaskResources] = useMutation<DeleteTaskResourcesVariables>(qTaskResourcesAssoc.DELETE_TASK_RESOURCES);
    const [updateTaskEstimate] = useMutation(qTasks.UPDATE_TASK_ESTIMATE);

    const defaultFormData = {
        fundType: get(taskData, 'task.fundType.id') || 0,
        estimate: get(taskData, 'task.estimate'),
        allocateFunding: [SELECT_RESOURCE],
    };

    const setDefaultFormData = () => {
        if (offCanvasTask.editFundingTask !== false && taskData?.task) {
            const resourceSelection = taskData?.task?.taskResourceAssoc.map((tr: any) => tr.resource.name);
            const editFormData = {
                fundType: get(taskData, 'task.fundType.id') || 0,
                estimate: get(taskData, 'task.estimate'),
                allocateFunding: resourceSelection.length === 0 ? [SELECT_RESOURCE] : resourceSelection,
            };

            reset(editFormData, { isDirty: false });
        } else {
            reset(defaultFormData);
        }
    };

    // Set dropdown options
    const setResourceOptions = () => {
        const isFundTypeSelected = watch('fundType') === 0;
        if (isFundTypeSelected && resourcesData) {
            setAvailableFundingResources(resourcesData?.resources);
        } else if (!isFundTypeSelected && resourceTypesRes) {
            const { getResourceTypesWithResources } = resourceTypesRes;

            const resourcesByFundtype = getResourceTypesWithResources[0].resources.filter(
                (resourceItem: any) => resourceItem?.fundType?.id === watch('fundType')
            );

            setAvailableFundingResources([
                ...getResourceTypesWithResources[1].resources,
                ...getResourceTypesWithResources[2].resources,
                ...resourcesByFundtype,
            ]);
        }
    };

    const getSelectedFundingResources = () => {
        if (watch('allocateFunding').length > 0 && watch('allocateFunding')[0] !== SELECT_RESOURCE) {
            const taskResourceAssocData: TaskFundingResourceProps[] = [];
            watch('allocateFunding').forEach((resourceSelected: string) => {
                const existingTaskResourceAssoc = taskData?.task?.taskResourceAssoc?.find(
                    (tr: any) => tr.resource.name === resourceSelected
                );

                if (existingTaskResourceAssoc) {
                    const { id, resource, taskAmount } = existingTaskResourceAssoc;
                    const resourceInfo = resourcesData?.resources?.find((res) => res.id === resource.id);
                    taskResourceAssocData.push({
                        resourceName: resource.name,
                        resourceAmount: resource.amount,
                        allocationAmount: taskAmount,
                        taskResourceAssocId: id,
                        resourceId: resource.id,
                        totalAllocation:
                            resourceInfo?.taskResourceAssoc?.reduce(
                                (accumulator, currentValue) => accumulator + (currentValue?.taskAmount || 0),
                                0
                            ) || 0,
                    });
                } else {
                    const resourceInfo = find(availableFundingResources, (fr) => fr.name === resourceSelected);
                    if (resourceInfo) {
                        taskResourceAssocData.push({
                            resourceName: resourceInfo.name,
                            resourceAmount: resourceInfo.amount || 0,
                            resourceId: resourceInfo.id,
                            totalAllocation:
                                resourceInfo?.taskResourceAssoc?.reduce(
                                    (accumulator, currentValue) => accumulator + (currentValue?.taskAmount || 0),
                                    0
                                ) || 0,
                        });
                    }
                }
            });
            setSelectedFundingResources(taskResourceAssocData);
        } else {
            setSelectedFundingResources([]);
        }
    };

    const removeSelectedFundingResources = (resourceId: string, index: number) => {
        const foundIdx = selectedFundingResources.findIndex((tr) => tr.resourceId === resourceId);
        if (foundIdx || foundIdx === 0) {
            const fundingResourceToDelete = selectedFundingResources[foundIdx];

            if (fundingResourceToDelete?.taskResourceAssocId) {
                setTaskResourceIdsToDelete([...taskResourceIdsToDelete, fundingResourceToDelete?.taskResourceAssocId]);
            }

            const updateTaskFundingResources = [
                ...selectedFundingResources.slice(0, foundIdx),
                ...selectedFundingResources.slice(foundIdx + 1),
            ];

            let updateAllocateFunding = [
                ...watch('allocateFunding').slice(0, index),
                ...watch('allocateFunding').slice(index + 1),
            ];

            if (updateAllocateFunding.length === 0) updateAllocateFunding = [SELECT_RESOURCE];

            setValue('allocateFunding', updateAllocateFunding);
            setSelectedFundingResources(updateTaskFundingResources);
        }
    };

    const onEdit = () => {
        if (!outerReadOnly) {
            setReadOnly(false);
        }
    };

    const refetchQueries = [
        {
            query: qResources.QUERY_RESOURCES,
            variables: {
                planId,
            },
        },
    ];

    const addTaskResources = async (taskResources: TaskResource[]) => {
        if (taskResources.length > 0) {
            await createTaskResources({
                refetchQueries,
                variables: { data: { taskResource: taskResources } },
            })
                .then(() => {
                    toast(<AlertToast severity="success" message={'Success allocating resources to tasks.'} />);
                })
                .catch((e) => {
                    toast(
                        <AlertToast severity="error" message={`Error allocating resources to tasks: ${e.message} `} />
                    );
                });
        }
    };

    const editTaskResources = async (taskResources: TaskResource[]) => {
        if (taskResources.length > 0) {
            await updateTaskResource({
                refetchQueries,
                variables: { data: { taskResource: taskResources } },
            }).catch((e) => {
                toast(<AlertToast severity="error" message={`Error updating allocation: ${e.message} `} />);
            });
        }
    };

    const deleteResources = async () => {
        if (taskResourceIdsToDelete.length > 0) {
            deleteTaskResources({
                refetchQueries,
                variables: { taskResourceIds: taskResourceIdsToDelete },
            })
                .then(() => {
                    toast(<AlertToast severity="success" message={'Successfully removed resource from task.'} />);
                })
                .catch((e) => {
                    toast(<AlertToast severity="error" message={`Error removing resource from task: ${e.message} `} />);
                });

            setTaskResourceIdsToDelete([]);
        }
    };

    const onSubmit = handleSubmit((data) => {
        const id = get(offCanvasTask, 'editFundingTask.id');
        const newFundType = data.fundType;
        const newEstimate = data.estimate;
        const newTaskResources: TaskResource[] = [];
        const existingTaskResourceIds: TaskResource[] = [];
        data?.allocateFunding?.forEach((fundingName: string, index: number) => {
            const foundTaskRes = taskData?.task?.taskResourceAssoc.find(
                (tra: any) => tra?.resource?.name === fundingName
            );
            if (foundTaskRes) {
                existingTaskResourceIds.push({
                    id: parseInt(foundTaskRes.id, 10),
                    taskId: taskData?.task?.id,
                    resourceId: parseInt(foundTaskRes.resource.id, 10),
                    taskAmount: data?.taskFundingResources[index]?.floatValue || foundTaskRes.taskAmount,
                });
            } else {
                const foundRes = availableFundingResources?.find((res) => res.name === fundingName);
                if (foundRes) {
                    newTaskResources.push({
                        taskId: taskData?.task?.id,
                        resourceId: parseInt(foundRes?.id, 10),
                        taskAmount: data?.taskFundingResources[index]?.floatValue || 0,
                    });
                }
            }
        });
        addTaskResources(newTaskResources);
        editTaskResources(existingTaskResourceIds);
        deleteResources();

        updateTaskEstimate({
            variables: { id, data: { fundTypeId: newFundType, estimate: newEstimate.floatValue } },
        });

        setOffCanvasTask({ canvas: isCanvasClosed, editFundingTask: false });
    });

    const onCancel = () => {
        setOffCanvasTask({ canvas: false, editFundingTask: false });
        reset(defaultFormData);
        setTaskResourceIdsToDelete([]);
    };

    useEffect(() => {
        if (offCanvasTask.editFundingTask !== false && has(offCanvasTask, 'editFundingTask.id')) {
            getTask({ variables: { id: get(offCanvasTask, 'editFundingTask.id') } });
            setReadOnly(!!offCanvasTask.readonly);
            if (offCanvasTask.editFundingTask?.taskResourceAssoc?.length !== 0) {
                setTitle('Edit Task Funding');
            }
        } else {
            setTitle('Add Task Funding');
            reset(defaultFormData);
            setReadOnly(false);
        }
        if (outerReadOnly) {
            setTitle('');
            setReadOnly(true);
        }
    }, [offCanvasTask]);

    useEffect(() => {
        setResourceOptions();
        setDefaultFormData();
        getSelectedFundingResources();
    }, [taskData, offCanvasTask]);

    useEffect(() => {
        setResourceOptions();
    }, [watch('fundType')]);

    useEffect(() => {
        getSelectedFundingResources();
    }, [watch('allocateFunding', 'taskFundingResources')]);

    useEffect(() => {
        setDefaultFormData();
    }, [readOnly]);

    if (getTaskLoading) {
        return (
            <div className="o-taskFundingForm">
                <h2>{title}</h2>
                <h4>Loading Task...</h4>
            </div>
        );
    }

    const allocated =
        selectedFundingResources?.reduce(
            (accumulator, currentValue) => accumulator + (currentValue?.allocationAmount || 0),
            0
        ) || 0;

    const unallocated = taskData?.task.estimate - allocated;
    const isEstimateError =
        watch('estimate') && typeof watch('estimate') === 'number'
            ? !watch('estimate')
            : !watch('estimate')?.floatValue;

    return (
        <div className={`o-taskFundingForm ${readOnly ? 'readonly' : ''}`}>
            <Permission auth={`${readOnly} === false`} showFail={<h2>Task Funding Details</h2>}>
                <h2>{title}</h2>
            </Permission>

            <TaskFundingSummary task={taskData?.task} allocated={allocated} unallocated={unallocated} />

            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <form autoComplete="off" onKeyPress={preventEnterSubmit}>
                {fundTypes && (
                    <Permission
                        auth={`${readOnly} === false`}
                        showFail={
                            <div className="o-taskFundingForm__readOnlyContainer">
                                <span className="o-taskFundingForm__readOnlyLabel">Gov&apos;t Authorized Category</span>
                                <p className="o-taskform__readOnlyContent">
                                    {taskData?.task?.fundType?.name || 'No category selected'}
                                </p>
                            </div>
                        }
                    >
                        <CustomSelect
                            inputLabel="Gov't Authorized Category"
                            name="fundType"
                            errors={errors}
                            control={control}
                            defaultValue={watch('fundType')}
                        >
                            <MenuItem disabled value={0}>
                                Select Category
                            </MenuItem>
                            {fundTypes.map((fundTypeItem) => (
                                <MenuItem key={fundTypeItem.id} value={fundTypeItem.id}>
                                    {fundTypeItem.name}
                                </MenuItem>
                            ))}
                        </CustomSelect>
                    </Permission>
                )}
                <Permission
                    auth={`${readOnly} === false`}
                    showFail={
                        <div className="o-taskFundingForm__readOnlyContainer">
                            <span className="o-taskFundingForm__readOnlyLabel">Estimate</span>
                            <p className="o-taskFundingForm__readOnlyContent">
                                {taskData?.task?.estimate === null ? (
                                    <em>No Estimate</em>
                                ) : (
                                    getMoneyFormat(taskData?.task?.estimate)
                                )}
                            </p>
                        </div>
                    }
                >
                    <CustomNumberInput
                        defaultValue={watch('estimate')}
                        inputLabel="Estimate"
                        control={control}
                        placeholder="Estimate"
                        errors={errors}
                        name="estimate"
                        inputRef={register}
                        fullWidth
                        customError={isEstimateError}
                        errormessage="An estimate is preferred before allocating resources"
                    />
                </Permission>
                <Permission
                    auth={`${readOnly} === false`}
                    showFail={
                        <div className="o-taskFundingForm__readOnlyContainer">
                            <span className="o-taskFundingForm__readOnlyLabel">Allocate Funding</span>
                            <p className="o-taskFundingForm__readOnlyContent">
                                {watch('allocateFunding') && watch('allocateFunding')[0] !== SELECT_RESOURCE ? (
                                    <>
                                        <p>{watch('allocateFunding').join(', ')}</p>
                                        <TaskFundingResourceReadOnly taskFundingResources={selectedFundingResources} />
                                    </>
                                ) : (
                                    'No funding selected'
                                )}
                            </p>
                        </div>
                    }
                >
                    <CustomMultiSelect
                        inputLabel="Allocate Funding"
                        helperText={
                            readOnly === false
                                ? `Options will only display resources that are commercial or the same resource category`
                                : ''
                        }
                        name="allocateFunding"
                        value={watch('allocateFunding')}
                        defaultValue={watch('allocateFunding')}
                        errors={errors}
                        control={control}
                        onChangeHandler={getSelectedFundingResources}
                        renderValue={(selected) => {
                            if (selected.length > 1 && selected[0] === SELECT_RESOURCE) selected.shift();
                            return selected.join(', ');
                        }}
                    >
                        <MenuItem disabled value={'Select Resource'}>
                            Select Resource
                        </MenuItem>
                        {availableFundingResources
                            ?.filter((resource: GetResourcesResources) => resource?.status?.status !== 'Rejected')
                            ?.map((fundingResource) => {
                                const { name } = fundingResource;
                                return (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox
                                            checked={
                                                selectedFundingResources.findIndex(
                                                    (res: any) => res.resourceName === name
                                                ) > -1
                                            }
                                            inputProps={{ 'aria-label': 'Select funding resources checkbox' }}
                                        />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                );
                            })}
                    </CustomMultiSelect>
                    {selectedFundingResources && (
                        <TaskFundingResource
                            control={control}
                            register={register}
                            watch={watch}
                            taskFundingResources={selectedFundingResources}
                            isTaskOverAllocated={unallocated < 0}
                            onRemove={removeSelectedFundingResources}
                        />
                    )}
                </Permission>
            </form>
            <FormButtons
                readOnly={readOnly}
                isNotEditMode={
                    offCanvasTask.editFundingTask === false ||
                    offCanvasTask.editFundingTask?.taskResourceAssoc?.length === 0
                }
                areValuesChanged={valuesHaveChanged || taskResourceIdsToDelete.length > 0}
                onEditHandler={onEdit}
                onCancelHandler={onCancel}
                onSubmitHandler={onSubmit}
                setIsCanvasClosed={setIsCanvasClosed}
            />
        </div>
    );
};
export default TaskFundingForm;
