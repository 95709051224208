import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.scss';
import Logo from 'assets/images/Techplan-logo.svg';
import UserAvatar from 'components/UserAvatar';
import { Grid, Icon } from '@material-ui/core';

import { useSelector } from 'react-redux';
import { Store } from 'store/reducers';

import ProfileMenu from 'components/layouts/header/ProfileMenu';
import { IUser } from 'typings/User';
import Menu from './header/menu';

const HeaderWrapper = (): React.ReactElement => {
    const userData: IUser = useSelector((storeState: Store) => storeState?.User);
    const [user, setUser] = useState(userData);
    const [underlineMenuItem, setUnderlineMenuItem] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setUnderlineMenuItem(location.pathname === '/');
    }, [location]);

    useEffect(() => {
        setUser(userData);
    }, [userData]);

    return (
        <Grid item sm={12}>
            <header className="o-header" role="navigation" aria-label="Main">
                <div>
                    <Link to="/">
                        <img src={Logo} alt="Techplan Logo" />
                    </Link>
                </div>
                <div className="m-menu">
                    <Menu className="m-menu__list">
                        <Menu.Item className="m-menu__item" link="/">
                            <div className={`m-menu__projects ${underlineMenuItem ? '-underlineMenuItem' : ''}`}>
                                My Projects
                            </div>
                        </Menu.Item>
                        <Menu.Item className="m-menu__item" link="/">
                            <div className="m-menu__notifications">
                                <Icon>notifications_none</Icon>
                            </div>
                        </Menu.Item>
                        <Menu.Item className="m-menu__item -hasSubMenu">
                            <UserAvatar hideTooltip name={user.name} />
                            <ProfileMenu {...user} />
                        </Menu.Item>
                    </Menu>
                </div>
            </header>
        </Grid>
    );
};

const defaultProps = {};

HeaderWrapper.defaultProps = defaultProps;

export default HeaderWrapper;
