import { Plan } from 'typings/Plan';

export const SAVE_PLAN = 'SAVE_NEW_PLAN';
export const UPDATE_PLAN = 'UPDATE_PLAN';
export const DELETE_PLAN = 'DELETE_PLAN';
export const GET_PLAN = 'GET_PLAN';
export const SET_PLAN = 'SET_PLAN';

export interface FunctionProp {
    name: string;
    action?: any;
}

export const saveNewPlan = (data: Plan): Record<string, unknown> => ({
    type: SAVE_PLAN,
    payload: { data },
});

export const updatePlan = (data: Plan): Record<string, unknown> => ({
    type: UPDATE_PLAN,
    payload: { data },
});

export const deletePlan = (data: number): Record<string, unknown> => ({
    type: DELETE_PLAN,
    payload: { data },
});

export const getPlan = (data: Plan): Record<string, unknown> => ({
    type: GET_PLAN,
    payload: { ...data },
});

export const setPlan = (data: Plan): Record<string, unknown> => ({
    type: SET_PLAN,
    payload: { data },
});
