import { gql } from 'apollo-boost';

const GET_INVITEES_BY_PLAN = gql`
    query GetInviteesByPlan($planId: Float!) {
        getInviteesByPlan(planId: $planId) {
            id
            email
            status
            user {
                id
                emailAddress
                name
                phone
                phoneType
                dutyTitle
                organization {
                    name
                }
            }
        }
    }
`;

const DELETE_INVITEE = gql`
    mutation DeleteInvitee($planId: Float!, $email: String!) {
        deleteInvitee(planId: $planId, email: $email)
    }
`;

const REJECT_INVITEE = gql`
    mutation RejectInvitee($planId: Float!, $email: String!) {
        rejectInvitee(planId: $planId, email: $email)
    }
`;

const REINVITE = gql`
    mutation Reinvite($planId: Float!, $email: String!) {
        reinvite(planId: $planId, email: $email)
    }
`;

const ACCEPT_INVITATION = gql`
    mutation AcceptInvite($planId: Float!, $email: String!, $isAccepted: Boolean!) {
        acceptInvite(planId: $planId, email: $email, isAccepted: $isAccepted)
    }
`;

export { GET_INVITEES_BY_PLAN, DELETE_INVITEE, REJECT_INVITEE, REINVITE, ACCEPT_INVITATION };
