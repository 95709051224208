/* eslint no-bitwise: "off" */
declare global {
    interface String {
        createInitials(): string;
        simplifyMoneyTag(): string;
    }
}

type Simplify = boolean | 'k' | 'K' | 'm' | 'M' | 'b' | 'B';

String.prototype.createInitials = function (): string {
    /* eslint no-extend-native: [0] */
    if (typeof this.toString() !== 'string') return '';
    const ws = this.split(' ');
    const len = ws.length - 1;
    return ws
        .filter((j, i) => i === 0 || i === len)
        .map((w) => w.charAt(0).toUpperCase())
        .join('');
};

String.prototype.simplifyMoneyTag = function (forced: Simplify = false): string {
    /* eslint no-extend-native: [0] */
    const convertedNumber: number = parseFloat(`${this}`);
    if (forced) {
        switch (forced) {
            case 'k':
            case 'K':
                return `$${Math.sign(convertedNumber) * parseFloat((Math.abs(convertedNumber) / 1000).toFixed(1))}K`;
            case 'm':
            case 'M':
                return `$${Math.sign(convertedNumber) * parseFloat((Math.abs(convertedNumber) / 1000000).toFixed(1))}M`;
            case 'b':
            case 'B':
                return `$${
                    Math.sign(convertedNumber) * parseFloat((Math.abs(convertedNumber) / 1000000000).toFixed(1))
                }B`;
            default:
                break;
        }
    }
    if (convertedNumber === 0) {
        return `$${convertedNumber}`;
    }
    if (convertedNumber < 1000) {
        return `$${convertedNumber.toFixed(0)}`;
    }
    if (convertedNumber < 1000000) {
        return `$${Math.sign(convertedNumber) * parseFloat((Math.abs(convertedNumber) / 1000).toFixed(1))}K`;
    }
    if (convertedNumber < 1000000000) {
        return `$${Math.sign(convertedNumber) * parseFloat((Math.abs(convertedNumber) / 1000000).toFixed(1))}M`;
    }
    return `$${Math.sign(convertedNumber) * parseFloat((Math.abs(convertedNumber) / 1000000000).toFixed(1))}B`;
};

export {};
