import React, { useState, useCallback, useEffect } from 'react';
import Canvas from './templateItems/Canvas';
import ganttState from './ganttState';
import TimelineScaleScrollbar from './gantttimelinecontainer/TimelineScaleScrollbar';
import './templateItems/Body.scss';

const GanttTimelineContainer = (): React.ReactElement => {
    const { references } = ganttState.Gantt;
    const [sliderValue, sliderValueHandler] = useState(9);

    const RenderTimeline = useCallback(() => {
        const microTimeBucket: string[] = ganttState.Gantt.getDateList();

        return (
            <div
                id="ganttHeaderMonths-container"
                style={{ width: ganttState.Gantt.timelineWidth }}
                ref={references.ganttHeaderMonths}
            >
                <div className="ganttHeaderMonths" style={{ width: ganttState.Gantt.timelineWidth * sliderValue }}>
                    {microTimeBucket.map((d: string) => (
                        <div className="month">{d}</div>
                    ))}
                </div>
            </div>
        );
    }, [references.ganttHeaderMonths, ganttState.Gantt.displayWidth, sliderValue]);

    useEffect(() => {
        if (ganttState.Gantt.isZeroState) {
            sliderValueHandler(3);
            ganttState.Gantt.setDisplayWidth(0);
        }
    }, [ganttState.Gantt.isZeroState]);

    return (
        <div className="body" id="ganttBody" ref={references.ganttTimelineContainerRef}>
            <div className="stickyHeader ganttSlider" style={{ width: ganttState.Gantt.timelineWidth }}>
                <TimelineScaleScrollbar sliderValueHandler={sliderValueHandler} sliderValue={sliderValue} />
                <RenderTimeline />
            </div>
            <Canvas sliderValue={sliderValue} />
        </div>
    );
};

export default GanttTimelineContainer;
