import React, { ReactElement } from 'react';
import { Chip, Icon, Badge } from '@material-ui/core';

import HoverTooltip from 'components/HoverTooltip';

import './CustomFundingChip.scss';
import { GetTaskGroupsTaskGroupsByPlanIdTasksTaskResourceAssoc } from 'typings/_graphql';

interface CustomFundingChipProps {
    data: GetTaskGroupsTaskGroupsByPlanIdTasksTaskResourceAssoc[];
    onDeleteHandler: (data: GetTaskGroupsTaskGroupsByPlanIdTasksTaskResourceAssoc) => void;
    onEditHandler: () => void;
}

const CustomFundingChip = ({ data, onDeleteHandler, onEditHandler }: CustomFundingChipProps): ReactElement => {
    const size = data.length > 1 ? 1 : data.length;
    const remainingResourceAssociations = data.slice(1, data.length);

    return (
        <section className="m-customFundingChip">
            <div>
                {data.slice(0, size).map((resAssociation: GetTaskGroupsTaskGroupsByPlanIdTasksTaskResourceAssoc) => {
                    const isResOverAllocated =
                        resAssociation &&
                        resAssociation.resource.amount &&
                        resAssociation.taskAmount > resAssociation.resource.amount;
                    return (
                        <HoverTooltip
                            message={
                                <div className="m-customFundingChip__hoverItemContainer">
                                    <div className="m-customFundingChip__hoverResources">
                                        <span>{resAssociation.resource.name}</span>
                                        <span className="m-customFundingChip__hoverAmount">
                                            {`$${resAssociation.taskAmount.toLocaleString()}`}
                                        </span>
                                    </div>
                                    <div>
                                        <span>{resAssociation.resource.fundType?.name}</span>
                                    </div>
                                    {isResOverAllocated && resAssociation && resAssociation.resource.amount && (
                                        <div style={{ maxWidth: '200px' }}>
                                            <span>
                                                This resource is overallocated by
                                                <br />
                                                <strong>{`$${Math.abs(
                                                    resAssociation.resource.amount - resAssociation.taskAmount
                                                ).toLocaleString()}`}</strong>
                                            </span>
                                        </div>
                                    )}
                                </div>
                            }
                        >
                            <Chip
                                icon={
                                    isResOverAllocated ? (
                                        <Icon style={{ color: '#F44336' }}>error_outlined</Icon>
                                    ) : undefined
                                }
                                variant={isResOverAllocated ? 'outlined' : undefined}
                                color={isResOverAllocated ? 'secondary' : undefined}
                                label={resAssociation.resource.name}
                                onDelete={() => onDeleteHandler(resAssociation)}
                                onClick={onEditHandler}
                            />
                        </HoverTooltip>
                    );
                })}
            </div>
            {remainingResourceAssociations.length > 0 && (
                <div className="m-customFundingChip__badge">
                    <HoverTooltip
                        message={
                            <ul className="m-customFundingChip__badgeList">
                                {remainingResourceAssociations.map(
                                    (resourceAssociation: GetTaskGroupsTaskGroupsByPlanIdTasksTaskResourceAssoc) => (
                                        <li className="m-customFundingChip__hoverItemContainer">
                                            <div className="m-customFundingChip__hoverResources">
                                                <span>{resourceAssociation.resource.name}</span>
                                                <span className="m-customFundingChip__hoverAmount">
                                                    {`$${resourceAssociation.taskAmount.toLocaleString()}`}
                                                </span>
                                            </div>
                                        </li>
                                    )
                                )}
                            </ul>
                        }
                    >
                        <Badge
                            badgeContent={remainingResourceAssociations.length}
                            max={9}
                            color="primary"
                            onClick={onEditHandler}
                        />
                    </HoverTooltip>
                </div>
            )}
        </section>
    );
};

export default CustomFundingChip;
