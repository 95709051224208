import { gql } from 'apollo-boost';

const QUERY_PLAN_PARAMETERS = gql`
    query GetPlanParameters($id: Float!) {
        plan(id: $id) {
            planParameters {
                id
                details
                label
            }
        }
    }
`;

const UPDATE_PLAN_PARAMETERS = gql`
    mutation updatePlanParametersByLabel($id: Float!, $data: UpdatePlanParameterInput!) {
        updatePlanParametersByLabel(planId: $id, data: $data) {
            details
        }
    }
`;

export { QUERY_PLAN_PARAMETERS, UPDATE_PLAN_PARAMETERS };
