import React from 'react';
import { Icon, IconButton } from '@material-ui/core';
import './modal/Modal.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    closeFunction: () => void;
    className?: string;
}

const Modal = ({ closeFunction, className = '', ...rest }: Props): React.ReactElement => {
    const { children } = rest;

    return (
        <div className={`o-modal ${className}`} {...rest} data-testid="modal">
            <IconButton onClick={closeFunction} className="o-modal__close-icon" data-testid="modal-close-icon">
                <Icon>close</Icon>
            </IconButton>

            {children}
        </div>
    );
};

export default Modal;
