import { gql } from 'apollo-boost';

const QUERY_PLAN = gql`
    query GetPlan($id: Float!) {
        plan(id: $id) {
            id
            code
            description
            name
            scope {
                id
            }
        }
    }
`;

const QUERY_PLANS = gql`
    query GetPlans {
        plans {
            id
            code
            description
            name
            archived
            archivedDate
            invitation {
                status
            }
            createdBy {
                id
                name
            }
        }
    }
`;

const QUERY_PLANFOREDIT = gql`
    query GetPlansForEdit($planId: Float!) {
        plan: planForEdit(planId: $planId) {
            id
            name
            description
            scope {
                id
                scopeRoles {
                    id
                    users {
                        id
                        name
                    }
                    roles {
                        id
                        name
                    }
                }
            }
            planRoles {
                id
                planRole
                user {
                    id
                    name
                }
            }
        }
    }
`;

const CREATE_PLAN = gql`
    mutation CreatePlan($data: CreatePlanInput!) {
        createPlan(data: $data) {
            id
            name
            description
        }
    }
`;

const ADD_STAKEHOLDERS = gql`
    mutation AddStakeholders($planId: Float!, $data: AddStakeholdersInput!) {
        addStakeholders(planId: $planId, data: $data)
    }
`;

const REINVITE_STAKEHOLDERS = gql`
    mutation ReinviteStakeholder($planId: Float!, $data: ReinviteStakeholderInput!) {
        reinviteStakeholder(planId: $planId, data: $data)
    }
`;

const REMOVE_STAKEHOLDER = gql`
    mutation DeleteStakeholder($planId: Float!, $userId: String!) {
        deleteStakeholder(planId: $planId, userId: $userId)
    }
`;

const ARCHIVE_PLAN = gql`
    mutation ArchivePlan($planId: Float!) {
        archivePlan(planId: $planId) {
            id
            code
            name
            archived
            archivedDate
        }
    }
`;

const UNARCHIVE_PLAN = gql`
    mutation UnarchivePlan($planId: Float!) {
        unarchivePlan(planId: $planId) {
            id
            code
            name
            archived
        }
    }
`;

const UPDATE_PLAN = gql`
    mutation updatePlan($planId: Float!, $data: UpdatePlanInput!) {
        updatePlan(planId: $planId, data: $data) {
            id
            name
            description
        }
    }
`;

const QUERY_PLAN_SUMMARY = gql`
    query planSummary($planId: Float!) {
        planSummary(planId: $planId) {
            id
            code
            name
            description
            createdBy {
                id
            }
            scope {
                id
                scopeRoles {
                    id
                    users {
                        id
                        avatar
                        name
                        emailAddress
                        dutyTitle
                    }
                }
            }
            resources {
                id
                resourceTypeId
                status {
                    id
                    status
                }
                name
                fundType {
                    id
                    name
                }
                amount
                taskResourceAssoc {
                    id
                    taskAmount
                    task {
                        id
                        name
                        estimate
                        taskGroup {
                            id
                            name
                        }
                    }
                }
                user {
                    id
                    name
                }
            }
            planParameters {
                id
                label
                details
            }
            taskGroups {
                id
                name
                description
                color
                tasks {
                    id
                    name
                    estimate
                    users {
                        id
                        name
                    }
                }
            }
        }
    }
`;

const QUERY_PLAN_CREATOR = gql`
    query QueryPlanCreator($planId: Float!) {
        planSummary(planId: $planId) {
            id
            name
            createdBy {
                id
                name
                emailAddress
            }
        }
    }
`;

export {
    QUERY_PLAN,
    QUERY_PLANS,
    QUERY_PLANFOREDIT,
    CREATE_PLAN,
    ARCHIVE_PLAN,
    UNARCHIVE_PLAN,
    UPDATE_PLAN,
    QUERY_PLAN_SUMMARY,
    ADD_STAKEHOLDERS,
    REINVITE_STAKEHOLDERS,
    REMOVE_STAKEHOLDER,
    QUERY_PLAN_CREATOR,
};
