import React, { useContext, useState, useEffect, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'store/reducers';
import { IUser } from 'typings/User';
import usePlanFunctions from 'components/utilities/usePlanFunctions';
import NumberFormat from 'react-number-format';
import { get } from 'lodash-es';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PlanContext from '../PlanContext';

interface EstimatesByCategoryProps {
    emptyTasks: boolean;
}
const EstimatesByCategory = ({ emptyTasks }: EstimatesByCategoryProps): ReactElement => {
    const { estimatesByFundType } = usePlanFunctions(useContext(PlanContext));
    const userData: IUser = useSelector((storeState: Store) => storeState?.User);
    const [tableData, setTableData] = useState(estimatesByFundType(userData.id));
    const { taskGroupData } = useContext(PlanContext);

    useEffect(() => {
        setTableData(estimatesByFundType(userData.id));
    }, [taskGroupData]);

    const governmentCategories = Object.keys(tableData).filter((key) => key !== 'Commercial');
    const nonGovernmentCategories = Object.keys(tableData).filter((key) => key === 'Commercial');

    if (emptyTasks)
        return <div className="m-estimatesByCategory__emptyTasks">No resource categories defined to a task</div>;

    return (
        <div className="m-estimatesByCategory">
            {governmentCategories && governmentCategories.length > 0 && (
                <>
                    <div className="m-whatAreCategories__heading">Government Resource Categories</div>
                    <Table size="small">
                        <TableBody>
                            {governmentCategories.map((key) => (
                                <TableRow key={key}>
                                    <TableCell align="right" className="m-estimatesByCategory__name">
                                        {key}
                                    </TableCell>
                                    <TableCell align="left" className="m-estimatesByCategory__numbers">
                                        <div>
                                            My Estimated:{' '}
                                            <span>
                                                <NumberFormat
                                                    value={get(tableData, `${key}.userTotal`, 0)}
                                                    displayType={'text'}
                                                    prefix="$"
                                                    thousandSeparator
                                                />
                                            </span>
                                        </div>
                                        <div>
                                            Plan Estimated:{' '}
                                            <span>
                                                <NumberFormat
                                                    value={get(tableData, `${key}.total`, 0)}
                                                    displayType={'text'}
                                                    prefix="$"
                                                    thousandSeparator
                                                />
                                            </span>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}
            {nonGovernmentCategories && nonGovernmentCategories.length > 0 && (
                <>
                    <div className="m-whatAreCategories__heading">Non-Government Resource Categories</div>
                    <Table size="small">
                        <TableBody>
                            {nonGovernmentCategories.map((key) => (
                                <TableRow key={key}>
                                    <TableCell align="right" className="m-estimatesByCategory__name">
                                        {key}
                                    </TableCell>
                                    <TableCell align="left" className="m-estimatesByCategory__numbers">
                                        <div>
                                            My Estimated:{' '}
                                            <span>
                                                <NumberFormat
                                                    value={get(tableData, `${key}.userTotal`, 0)}
                                                    displayType={'text'}
                                                    prefix="$"
                                                    thousandSeparator
                                                />
                                            </span>
                                        </div>
                                        <div>
                                            Plan Estimated:{' '}
                                            <span>
                                                <NumberFormat
                                                    value={get(tableData, `${key}.total`, 0)}
                                                    displayType={'text'}
                                                    prefix="$"
                                                    thousandSeparator
                                                />
                                            </span>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}
        </div>
    );
};

export default EstimatesByCategory;
