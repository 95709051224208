import React from 'react';
import Switch from '@material-ui/core/Switch';

import './customswitch/CustomSwitch.scss';

interface CustomSwitchProps {
    leftLabel: string;
    rightLabel: string;
    checkedValue: boolean | undefined;
    handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
}

function CustomSwitch({ leftLabel, rightLabel, checkedValue, handleChange }: CustomSwitchProps): React.ReactElement {
    return (
        <section className="o-customSwitch">
            <p className={`${!checkedValue && 'o-customSwitch__active-label'}`}>{leftLabel}</p>
            <Switch checked={checkedValue} onChange={handleChange} color="primary" />
            <p className={`${checkedValue && 'o-customSwitch__active-label'}`}>{rightLabel}</p>
        </section>
    );
}

export default CustomSwitch;
