import React from 'react';

import Permission from 'components/Permission';
import { SearchTaskNameByPlanSearchTaskNameByPlan } from 'typings/_graphql';
import TaskDependencyList from './dependencies/TaskDependencyList';
import AddDependency, { addItemProp } from './AddDependency';
import { removeItemProp } from '../TaskForm';

import './dependencies/Dependencies.scss';

interface Props {
    parentDependencies: SearchTaskNameByPlanSearchTaskNameByPlan[];
    childDependencies: SearchTaskNameByPlanSearchTaskNameByPlan[];
    addTask: (item: addItemProp) => void;
    removeTask: ({ type, taskId }: removeItemProp) => void;
    excludeIds: number[];
    readOnly?: boolean;
    taskId: number;
}

const Dependencies = ({
    parentDependencies,
    childDependencies,
    removeTask,
    taskId,
    readOnly = false,
    excludeIds,
    addTask,
}: Props): React.ReactElement => {
    const dependencyArrayLength = parentDependencies.length + childDependencies.length;
    return (
        <div className={`o-dependencies ${readOnly ? 'readonly' : ''}`}>
            <div className="o-dependencies__header">
                <h4 className="o-dependencies__title">Dependencies</h4>
            </div>
            {parentDependencies.length > 0 ? (
                <h5 className="o-dependencies__parent-title">Dependent On</h5>
            ) : (
                <div>No dependencies</div>
            )}
            <Permission auth={`${readOnly} === false`}>
                <div className="o-dependencies__tasklist">
                    <p>Select tasks that this task is dependent upon.</p>
                </div>
            </Permission>
            <Permission auth={`${readOnly} === false`}>
                <AddDependency taskType="parent" taskId={taskId} excludeIds={excludeIds} addTask={addTask} />
            </Permission>
            <TaskDependencyList
                type="parent"
                removeTask={removeTask}
                items={parentDependencies}
                dependencyArrayLength={dependencyArrayLength}
                readOnly={readOnly}
            />
            {/* <TaskDependencyList
                type="child"
                removeTask={removeTask}
                items={childDependencies}
                dependencyArrayLength={dependencyArrayLength}
                readOnly={readOnly}
            /> */}
        </div>
    );
};

export default Dependencies;
