import React, { ReactElement } from 'react';
import NumberFormat from 'react-number-format';
import { GetTaskGroupsTaskGroupsByPlanIdTasks } from 'typings/_graphql';
import UserAvatar from 'components/UserAvatar';
import { Icon } from '@material-ui/core';

interface TaskFundingSummaryProps {
    task: GetTaskGroupsTaskGroupsByPlanIdTasks;
    allocated: number;
    unallocated: number;
}

const TaskFundingSummary = ({ task, allocated, unallocated }: TaskFundingSummaryProps): ReactElement => {
    const isOverAllocated = unallocated < 0;

    return (
        <>
            <div className="o-taskFundingForm__readOnlyContainer">
                <span className="o-taskFundingForm__readOnlyLabel">Task Selected</span>
                <strong>{task?.name}</strong>
            </div>

            <div className="o-taskFundingForm__readOnlyContainer">
                <span>{task?.fundType?.name}</span>
                <div className="o-taskFundingForm__allocation">
                    <span className="o-taskFundingForm__total">
                        <p>Estimate:</p>
                        <strong className="o-taskFundingForm__dollar">$</strong>
                        <span className="amount">
                            <NumberFormat displayType="text" thousandSeparator value={task?.estimate} />
                        </span>
                    </span>
                    <span className="o-taskFundingForm__total">
                        <p>Allocated:</p>
                        <strong className="o-taskFundingForm__dollar">$</strong>
                        <span className="amount">
                            <NumberFormat displayType="text" thousandSeparator value={allocated} />
                        </span>
                    </span>
                    <span
                        className={`o-taskFundingForm__total ${isOverAllocated && 'o-taskFundingForm__overAllocated'}`}
                    >
                        <div className={`${isOverAllocated && 'o-taskFundingForm__overAllocated-withIcon'}`}>
                            {isOverAllocated && <Icon>error_outlined</Icon>}
                            <p>{`${isOverAllocated ? 'Over Allocated' : 'Unallocated:'}`}</p>
                        </div>
                        <strong className="o-taskFundingForm__dollar">$</strong>
                        <span className="amount">
                            <NumberFormat displayType="text" thousandSeparator value={Math.abs(unallocated)} />
                        </span>
                    </span>
                </div>
            </div>
            <hr className="o-taskFundingForm__hr" />

            {task?.users && task?.users[0] && (
                <div className="o-taskFundingForm__readOnlyContainer">
                    <span className="o-taskFundingForm__readOnlyLabel">Assignee</span>
                    <div className={'a-userDropdown'}>
                        <UserAvatar hideTooltip name={task?.users[0]?.name} />
                        <span key={task?.users[0]?.id}>&nbsp;&nbsp;{task?.users[0]?.name}</span>
                    </div>
                </div>
            )}
        </>
    );
};

export default TaskFundingSummary;
