import React from 'react';
import HoverTooltip from 'components/HoverTooltip';
import {
    GetTaskGroupsTaskGroupsByPlanIdTasksDependees,
    GetTaskGroupsTaskGroupsByPlanIdTasksDependencies,
} from 'typings/_graphql';

const renderDependencies = (
    dependencies: readonly GetTaskGroupsTaskGroupsByPlanIdTasksDependencies[] | null,
    dependees: readonly GetTaskGroupsTaskGroupsByPlanIdTasksDependees[] | null
): React.ReactNode => {
    const dependencyArray: GetTaskGroupsTaskGroupsByPlanIdTasksDependees[] = [];

    if (dependees && dependencies) {
        dependencyArray.push(...dependees, ...dependencies);
    }

    return (
        <HoverTooltip
            message={
                <ul className="o-taskCard__dependencyList">
                    {dependencyArray && dependencyArray.map((dependency) => <li>{dependency.name}</li>)}
                </ul>
            }
        >
            <div className="o-taskCard__dependencies">
                <span>{dependencyArray.length}</span>
            </div>
        </HoverTooltip>
    );
};

export default renderDependencies;
