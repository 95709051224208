import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Container, Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Store } from 'store/reducers';

import UserAvatar from 'components/UserAvatar';

import { qPlans } from 'api/queries';
import { IPlanId } from 'typings/Plan';
import { IUser } from 'typings/User';
import { PlanSummary, PlanSummaryVariables } from 'typings/_graphql';

import './fundamentalsintroduction/FundamentalsIntroduction.scss';

interface Props {
    closeModal: () => void;
}

interface StakeHolder {
    name: string;
    initials: string;
}

const FundamentalsIntroduction = ({ closeModal }: Props): React.ReactElement => {
    const [stakeHolders, stakeHoldersHandler] = useState<StakeHolder[]>();
    const currentUser: IUser = useSelector((storeState: Store) => storeState?.User);
    const { planId } = useParams<IPlanId>();

    const { data: { planSummary } = {} } = useQuery<PlanSummary, PlanSummaryVariables>(qPlans.QUERY_PLAN_SUMMARY, {
        variables: { planId: parseFloat(planId) },
    });

    useEffect(() => {
        if (planSummary?.scope?.scopeRoles) {
            stakeHoldersHandler(
                planSummary.scope.scopeRoles.map(
                    (user): StakeHolder => ({
                        name: user?.users?.name,
                        initials: user?.users?.name?.createInitials(),
                    })
                )
            );
        }
    }, [planSummary]);

    return (
        <Container className="m-fundamentalsIntroduction" maxWidth="xl">
            <Grid container direction="column">
                <Grid item xs={12}>
                    <div className="m-fundamentalsIntroduction__head">Identify</div>
                    <div className="m-fundamentalsIntroduction__headSmall">The Fundamentals</div>
                    <div className="m-fundamentalsIntroduction__sub">
                        A solid foundation, leads to a successful project
                    </div>
                </Grid>
                <Grid container spacing={2} direction="row" justifyContent="space-between">
                    <Grid item xs={12} md={6}>
                        <p>
                            The key to beginning a successful technology commercialization effort is to identify the
                            fundamental ingredients:
                            <b> 1) a problem or desired capability, 2) people who care, and 3) a potential solution.</b>
                        </p>
                        <p>
                            The better we understand the problem or desired capability, the better we can identify and
                            build solutions. Ideally, we define the <b>“as-is” problem</b> with
                            <b> as many details as possible,</b> but it is also important to
                            <b> envision the desired “to-be,” or “end” state,</b> with just as much detail. What
                            parameters can we use to define the problem? How high, fast, light, resistant, or small must
                            the integrated solution be? Details help us zero-in on practical solutions.
                        </p>
                        <p>
                            <b>People who are motivated to solve the problem </b> or achieve a desired capability are
                            the key to successful technology implementation. Without at least one champion, the
                            transition planning and implementation efforts cannot succeed. It’s way more complicated
                            than just putting a name in a book. Your team will succeed when the right stakeholders are
                            engaged. If nobody really cares or drives toward a solution, why bother?
                        </p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <p>
                            We might find that one solution will do the trick, or that several can be combined into a
                            unified capability.{' '}
                            <b>
                                Understanding the current maturity of the technology solution, relative to the problem
                            </b>{' '}
                            or desired capability, is critical to establishing the tasks necessary to mature and
                            implement the solution. Much like envisioning the “solved” problem, it is useful to envision
                            what the final, implemented technology might look like.
                        </p>
                        <p>
                            <b>
                                If any one of these fundamentals are missing, the technology transition/implementation
                                effort cannot succeed.
                            </b>
                        </p>
                        <div className="m-fundamentalsIntroduction__sub">The Current Stakeholder Team:</div>
                        <div className="m-fundamentalsIntroduction__stakeholderContainer">
                            {!!stakeHolders &&
                                stakeHolders.map((stakeholder: StakeHolder) => (
                                    <div className="m-fundamentalsIntroduction__stakeholderList">
                                        <UserAvatar
                                            name={stakeholder.name}
                                            isCurrentUser={stakeholder.name === currentUser.name}
                                        />
                                    </div>
                                ))}
                        </div>
                        <Button
                            onClick={closeModal}
                            color="primary"
                            variant="contained"
                            className="m-fundamentalsIntroduction__button"
                        >
                            Get Started!
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default FundamentalsIntroduction;
