import {
    GetTaskGroupsTaskGroupsByPlanId,
    GetTaskGroupsTaskGroupsByPlanIdTasks,
    GetTaskGroupTaskGroup,
} from 'typings/_graphql';
import { uniq } from 'lodash-es';
import { IUser } from 'typings/User';

export interface TaskWithTaskGroup extends GetTaskGroupsTaskGroupsByPlanIdTasks {
    taskGroup?: GetTaskGroupTaskGroup;
}
export interface CategoryCardProps {
    name: string;
    tasks: TaskWithTaskGroup[];
}

const flattenTasks = (taskGroupData: readonly GetTaskGroupsTaskGroupsByPlanId[]): any[] =>
    taskGroupData.map((taskGroup) => taskGroup?.tasks?.map((task) => ({ ...task, taskGroup }))).flat();

export const sortByAssignee = (
    taskGroupData: readonly GetTaskGroupsTaskGroupsByPlanId[],
    currentUser?: IUser
): CategoryCardProps[] => {
    const assigneeData: CategoryCardProps[] = [];
    const allTasks: any[] = flattenTasks(taskGroupData);
    const assignees = uniq(allTasks.map((task) => task?.users).flat());
    let currentUserData: CategoryCardProps | null = null;

    assignees.forEach((assignee) => {
        const tasks: GetTaskGroupsTaskGroupsByPlanIdTasks[] = allTasks.filter(
            (task: GetTaskGroupsTaskGroupsByPlanIdTasks) => task?.users && task?.users[0]?.name === assignee?.name
        );
        if (assignee.id === currentUser?.id) currentUserData = { name: assignee.name, tasks };
        else if (assignee && tasks) assigneeData.push({ name: assignee.name, tasks });
    });

    if (currentUserData) assigneeData.unshift(currentUserData);

    return assigneeData;
};

export const sortByTaskStatus = (taskGroupData: readonly GetTaskGroupsTaskGroupsByPlanId[]): CategoryCardProps[] => {
    const taskData: CategoryCardProps[] = [];
    const allTasks: any[] = flattenTasks(taskGroupData);
    const statusList = uniq(allTasks.map((task: GetTaskGroupsTaskGroupsByPlanIdTasks) => task?.status).flat());

    statusList.forEach((status) => {
        const tasks: GetTaskGroupsTaskGroupsByPlanIdTasks[] = allTasks.filter((task) => task?.status === status);
        if (status && tasks) taskData.push({ name: status, tasks });
    });
    return taskData;
};
