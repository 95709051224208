import React, { useContext } from 'react';
import { Button, Icon } from '@material-ui/core';
import Gantt from 'components/Gantt';
import PlanContext, { offCanvasProps } from '../PlanContext';

import './boardview/BoardView.scss';

interface RoadMapProps {
    readOnly?: boolean;
    setOffCanvasTaskGroup: (val: offCanvasProps) => void;
}

const RoadMap = ({ readOnly = false, setOffCanvasTaskGroup }: RoadMapProps): React.ReactElement => {
    const { taskGroupData } = useContext(PlanContext);

    return (
        <div className="o-boardView">
            <Gantt taskGroupData={taskGroupData} readOnly={readOnly} />
        </div>
    );
};

export default RoadMap;
