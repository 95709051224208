import React from 'react';
import { isEmpty } from 'lodash-es';
import ScoreRing from './readinesslevels/ScoreRing';
import { ReadinessResultsProps } from '../assesstech/AssessTechProps';

interface ReadinessLevelsProps {
    assessTechData: ReadinessResultsProps;
}

const ReadinessLevels = ({ assessTechData }: ReadinessLevelsProps): React.ReactElement => {
    let readinessLevelsContent = (
        <p className="m-planFundamentals__readinessContent">
            There is no record of a Readiness Assessment for the project.
        </p>
    );

    if (!isEmpty(assessTechData)) {
        readinessLevelsContent = (
            <>
                <ScoreRing
                    score={assessTechData?.trlScore}
                    type="TRL"
                    definition={assessTechData?.trlDefinition || ''}
                />
                <ScoreRing
                    score={assessTechData?.mrlScore}
                    type="MRL"
                    definition={assessTechData?.mrlDefinition || ''}
                />
            </>
        );
    }
    return (
        <>
            <section className="m-planFundamentals__subSection">
                <p>Solution&apos;s Readiness Levels</p>
            </section>
            <section className="m-planFundamentals__readinessContent">{readinessLevelsContent}</section>
        </>
    );
};

export default ReadinessLevels;
