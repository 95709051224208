import React, { ReactElement, useContext } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import NumberFormat from 'react-number-format';

import UserAvatar from 'components/UserAvatar';
import GetMoneyFormat from 'components/utilities/GetMoneyFormat';
import RenderDoubleDatePicker from 'components/table/RenderDoubleDatePicker/RenderDoubleDatePicker';
import { getResourceStatusIcon } from 'components/utilities/getStatusIcon';
import StatusIcon from 'components/StatusIcon';

import { TYPE, ASSIGNEE } from './IdentifyTable';
import ResourceContext from '../ResourceContext';

interface ResourceListItemProps {
    rowId: number | string;
    resource: any;
    isGovFunding: boolean;
    sortedBy: 'Assignee' | 'Type';
}

function ResourceListItem({ resource, isGovFunding, sortedBy, rowId }: ResourceListItemProps): ReactElement {
    const { setOffCanvasResource } = useContext(ResourceContext);

    const editResource = () => {
        const userId = sortedBy === ASSIGNEE ? rowId : resource?.user?.id;
        const resourceTypeId = sortedBy === TYPE ? rowId : resource?.resourceType?.id;
        setOffCanvasResource({
            canvas: true,
            editResource: resource,
            resourceId: resource?.id,
            resourceTypeId,
            userId,
        });
    };

    return (
        <>
            <TableRow key={resource.id} className="a-tBodyRow">
                <TableCell component="td" className="a-tBodyRow__label" onClick={editResource}>
                    <div className="a-tBodyRow__resourceLabel">{resource.name}</div>
                </TableCell>
                {sortedBy === ASSIGNEE && (
                    <TableCell align="center" className="a-tBodyRow__interactive" onClick={editResource}>
                        <div>{resource.resourceType.name}</div>
                    </TableCell>
                )}
                {(isGovFunding || sortedBy === ASSIGNEE) && (
                    <TableCell
                        component="td"
                        align="center"
                        className="a-tBodyRow__interactive "
                        onClick={editResource}
                    >
                        <div>{resource?.fundType?.name || 'N/A'}</div>
                    </TableCell>
                )}
                {sortedBy === TYPE && (
                    <TableCell align="center">
                        {resource.user?.name ? <UserAvatar name={resource.user?.name} /> : <div />}
                    </TableCell>
                )}
                <TableCell align="center" className="a-tBodyRow__interactive" onClick={editResource}>
                    <div className="a-tBodyRow__status">
                        {resource?.status?.status ? (
                            <>
                                <StatusIcon>{getResourceStatusIcon(resource?.status?.status)}</StatusIcon>
                                <span>{resource?.status?.status}</span>
                            </>
                        ) : (
                            <em>No Status</em>
                        )}
                    </div>
                </TableCell>
                <TableCell align="center" className="a-tBodyRow__interactive" width="12%" onClick={editResource}>
                    <strong className="a-tBodyRow__dollar">$</strong>
                    {resource.amount ? (
                        <NumberFormat
                            displayType="text"
                            thousandSeparator
                            value={resource.amount === 0 ? GetMoneyFormat(resource.amount) : resource.amount}
                        />
                    ) : (
                        <div />
                    )}
                </TableCell>
                <TableCell align="center">
                    <RenderDoubleDatePicker data={resource} update="resource" />
                </TableCell>
            </TableRow>
        </>
    );
}

export default ResourceListItem;
