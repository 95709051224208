import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { indexOf, cloneDeep } from 'lodash-es';
import { useMutation } from '@apollo/client';
import { saveUserIntroductions } from '../../store/actions/User';
import { Store } from '../../store/reducers';
import { qPageIntroductions } from '../../api/queries';

interface ReturnType {
    viewIntroduction: (props: any) => React.ReactElement;
    continueButton: (id: number) => void;
}

const useIntroduction = (): ReturnType => {
    const dispatch = useDispatch();
    const user = useSelector((storeState: Store) => storeState.User);
    const [createPageIntroduction] = useMutation(qPageIntroductions.CREACT_PAGE_INTRODUCTION);

    const continueButton = (stepId: number) => {
        createPageIntroduction({ variables: { id: stepId } });
        const newIntroductions = cloneDeep(user?.pageIntroductions);
        newIntroductions.push(stepId);
        dispatch(saveUserIntroductions(newIntroductions));
    };

    const showIntro = (stepId: number) => {
        const toShowIntro = indexOf(user?.pageIntroductions, stepId);
        if (toShowIntro < 0) {
            return true;
        }
        return false;
    };

    function viewIntroduction({ intro, page, stepId }: any): React.ReactElement {
        if (showIntro(stepId) && intro) {
            return (
                <div key="intro" className="o-introductionpage">
                    {user.id.length > 0 && intro}
                </div>
            );
        }
        return <div key="page">{page}</div>;
    }

    return {
        viewIntroduction,
        continueButton,
    };
};

export default useIntroduction;
