import React, { ReactElement, forwardRef } from 'react';
import Popup from 'reactjs-popup';
import { Icon, withStyles } from '@material-ui/core';

import './tooltipmenu/ToolTipMenu.scss';

interface Props {
    trigger?: ReactElement | false;
    icon?: string;
    children: React.ReactElement<HTMLDataListElement> | React.ReactElement<HTMLDataListElement>[];
    position?: Array<
        | 'top left'
        | 'top center'
        | 'top right'
        | 'right top'
        | 'right center'
        | 'right bottom'
        | 'bottom left'
        | 'bottom center'
        | 'bottom right'
        | 'left top'
        | 'left center'
        | 'left bottom'
        | 'center center'
    >;
    open?: boolean;
    disabled?: boolean;
    nested?: boolean;
    defaultOpen?: boolean;
    offsetX?: number;
    offsetY?: number;
    arrow?: boolean;
    modal?: boolean;
    lockScroll?: boolean;
    closeOnDocumentClick?: boolean;
    closeOnEscape?: boolean;
    repositionOnResize?: boolean;
    mouseEnterDelay?: number;
    mouseLeaveDelay?: number;
    onOpen?: () => any;
    onClose?: () => any;
    contentStyle?: React.CSSProperties;
    overlayStyle?: React.CSSProperties;
    arrowStyle?: React.CSSProperties;
    className?: string;
    keepTooltipInside?: boolean | string;
    ref?: any;
    size?: string;
}

const ToolTipMenu = forwardRef(
    (
        {
            position = ['top center'],
            className = 'o-tooltipMenu',
            size = '25px',
            children,
            trigger = false,
            ...rest
        }: Props,
        ref
    ): ReactElement => {
        const TooltipIcon = withStyles(() => ({
            root: {
                fontSize: `${size}`,
            },
        }))(Icon);

        return (
            <Popup
                trigger={<div>{trigger || <TooltipIcon className="o-tooltipMenu__icon">more_vert</TooltipIcon>}</div>}
                position={position}
                closeOnDocumentClick
                className={className}
                nested
                ref={ref}
                {...rest}
            >
                <div>
                    <ul>{children}</ul>
                </div>
            </Popup>
        );
    }
);
export default ToolTipMenu;
