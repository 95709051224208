import React, { ReactElement } from 'react';
import { Button, Icon } from '@material-ui/core';
import './AddRowButton.scss';

interface AddRowButtonProps {
    onClickHandle: () => void;
    className?: string;
    itemToAdd?: string;
}

function AddRowButton({ onClickHandle, className, itemToAdd }: AddRowButtonProps): ReactElement {
    return (
        <div className="o-addRowButton">
            <Button onClick={onClickHandle} className={className}>
                <Icon>add_circle_outline</Icon>&nbsp;&nbsp;
                <span className="o-addRowButton__text">{`Add ${itemToAdd || ''}`}</span>
            </Button>
        </div>
    );
}

export default AddRowButton;
