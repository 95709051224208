import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from 'assets/themes';
import ReactRouter from './ReactRouter';
import Redux from './Redux';
import Apollo from './Apollo';
import Keycloak from './Keycloak';

interface Props {
    children: React.ReactElement;
}

const Providers = ({ children }: Props): React.ReactElement => (
    <Keycloak>
        <MuiThemeProvider theme={theme}>
            <Apollo>
                <Router>
                    <Redux>
                        {children}
                        <ToastContainer />
                        <ReactRouter />
                    </Redux>
                </Router>
            </Apollo>
        </MuiThemeProvider>
    </Keycloak>
);

export default Providers;
