import React, { ReactNode } from 'react';

interface Props {
    auth: string;
    showFail?: ReactNode | false;
    children?: any;
    log?: boolean;
}

const Permission = ({ auth, showFail = false, ...rest }: Props): React.ReactElement => {
    // eslint-disable-next-line no-eval
    const access = eval(`${auth}`);
    if (access) {
        return <>{rest.children}</>;
    }
    if (showFail) {
        return <>{showFail}</>;
    }
    return <></>;
};

export default Permission;
