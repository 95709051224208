/* eslint-disable camelcase */
import { IScopeRoles } from 'typings/User';

interface Props {
    scopes: {
        id: number;
    };
    roles: {
        name: string;
        role_rolecapabilities: RoleCapabilities[];
    };
}

interface RoleCapabilities {
    opperation: number;
    roleCapabilities: {
        functionName: string;
    };
}

export function FormatScopes(scopeRoles: Props[]): IScopeRoles {
    const scopes: IScopeRoles = {};
    for (let i = 0; i < scopeRoles.length; i++) {
        const item = scopeRoles[i];
        const scopeId = item.scopes.id;
        const roles: { [key: string]: number } = {};

        for (let b = 0; b < item.roles.role_rolecapabilities.length; b++) {
            const roleItem = item.roles.role_rolecapabilities[b];
            const { functionName } = roleItem.roleCapabilities;
            const operation = roleItem.opperation;

            roles[functionName] = operation;
        }
        scopes[scopeId] = roles;
    }
    return scopes;
}

interface IntroProps {
    planStepsId: number;
}

export function FormatPageIntroductions(pageIntroductions: IntroProps[]): Array<number> {
    const newArray = [];
    for (let i = 0; i < pageIntroductions.length; i++) {
        newArray.push(pageIntroductions[i].planStepsId);
    }
    return newArray;
}
