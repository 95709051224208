import React, { useContext, useEffect, useState } from 'react';
import { Button, Icon, Grid } from '@material-ui/core';
import { sortBy, indexOf } from 'lodash-es';
import { IUser } from 'typings/User';
import { useSelector } from 'react-redux';
import { Store } from 'store/reducers';

import { sortByAssignee, sortByTaskStatus, CategoryCardProps } from 'components/utilities/sortTaskGroupData';
import AutocompleteDropdown from 'components/AutocompleteDropdown';
import ManageTasksCard from './ManageTasksCard';
import PlanContext, { offCanvasProps } from '../PlanContext';

import './boardview/BoardView.scss';

interface BoardViewProps {
    readOnly?: boolean;
    setOffCanvasTaskGroup: (val: offCanvasProps) => void;
}

const BoardView = ({ readOnly = false, setOffCanvasTaskGroup }: BoardViewProps): React.ReactElement => {
    const { taskGroupData } = useContext(PlanContext);
    const [taskAssigneeData, setTaskAssigneeData] = useState<CategoryCardProps[]>([]);
    const [taskStatusData, setTaskStatusData] = useState<CategoryCardProps[]>([]);
    const [filterType, setFilterType] = useState<string>('Task Group');
    const statusSortOrder = ['Not Started', 'In Progress', 'Complete'];
    const userData: IUser = useSelector((storeState: Store) => storeState?.User);

    const onFilterChange = (value: string) => {
        if (value === null) {
            setFilterType(filterType);
        } else setFilterType(value);
    };

    useEffect(() => {
        setTaskStatusData(sortByTaskStatus(taskGroupData));
        setTaskAssigneeData(sortByAssignee(taskGroupData));
    }, [taskGroupData]);

    if (taskGroupData.length < 1) {
        return (
            <div className="o-boardView__emptyData">
                <h3 className="o-boardView__emptyData -header">There are no defined Task Groups.</h3>
                <p className="o-boardView__emptyData -message">
                    Please add a Task Group in order to begin creating tasks.
                </p>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setOffCanvasTaskGroup({ canvas: true, editTaskGroup: false })}
                >
                    <Icon>add</Icon> &nbsp;&nbsp;Add A Task Group
                </Button>
            </div>
        );
    }

    return (
        <div className="o-boardView">
            <Grid container direction="column">
                <Grid container direction="row" alignItems="flex-end">
                    <Grid item xs={12} sm={6}>
                        <AutocompleteDropdown
                            label="Sort By"
                            defaultValue="Task Group"
                            dropdownOptions={['Task Group', 'Assignee', 'Status']}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>, value: string) => onFilterChange(value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className="o-boardView__buttons">
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => setOffCanvasTaskGroup({ canvas: true, editTaskGroup: false })}
                        >
                            <Icon>add</Icon> &nbsp;&nbsp;Add A Task Group
                        </Button>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <div className="o-boardView__taskGroupsContainer">
                        {filterType === 'Task Group' &&
                            sortBy(taskGroupData, [(taskGroup) => taskGroup.id]).map((taskGroup) => (
                                <ManageTasksCard
                                    key={taskGroup.id}
                                    tasks={taskGroup.tasks}
                                    taskGroup={taskGroup}
                                    readOnly={readOnly}
                                    label={taskGroup.name}
                                    filterType={filterType}
                                />
                            ))}
                        {filterType === 'Assignee' &&
                            sortBy(taskAssigneeData, ({ name }) => (name === userData.name ? -1 : 1)).map(
                                (assignee) => (
                                    <ManageTasksCard
                                        key={assignee.name}
                                        tasks={assignee.tasks}
                                        readOnly={readOnly}
                                        label={assignee.name}
                                        filterType={filterType}
                                    />
                                )
                            )}
                        {filterType === 'Status' &&
                            sortBy(taskStatusData, [(status) => indexOf(statusSortOrder, status.name)]).map(
                                (status) => (
                                    <ManageTasksCard
                                        key={status.name}
                                        tasks={status.tasks}
                                        readOnly={readOnly}
                                        label={status.name}
                                        filterType={filterType}
                                    />
                                )
                            )}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default BoardView;
