/* eslint no-extra-boolean-cast: 0 */
interface ClassNameFactoryProps {
    condition: any;
    class: string;
}

export default function ClassNameFactory(classNames: ClassNameFactoryProps[]): string {
    let returnedName = '';
    for (let i = 0; i < classNames.length; i++) {
        if (!!classNames[i]?.condition) {
            returnedName = `${returnedName} ${classNames[i].class}`;
        }
    }
    return returnedName;
}
