import React, { useState, useEffect, useContext } from 'react';
import Popup from 'reactjs-popup';
import { Button, Select, MenuItem, InputBase } from '@material-ui/core';

import Modal from 'components/Modal';
import ConfirmModal from 'components/ConfirmModal';
import getTasks from 'components/utilities/getTasks';
import { GetTaskGroupsTaskGroupsByPlanIdTasks as Task } from 'typings/_graphql';
import { useMutation } from '@apollo/client';
import { qTasks } from 'api/queries';
import UpdateTaskModalTable from './UpdateTaskModalTable';
import PlanContext from '../../PlanContext';

import './updatetaskmodal/UpdateTaskModal.scss';

interface Props {
    tasks: number[] | undefined;
    open?: boolean;
    afterClick: () => void;
}

const UpdateTaskModal = React.forwardRef(({ tasks = [], open = false, afterClick }: Props, ref: any) => {
    const { taskGroupData, selectedTaskIds, setSelectedTaskIds } = useContext(PlanContext);
    const [selectedTasks, setSelectedTasks] = useState<Task[]>([]);
    const [updateTasks] = useMutation(qTasks.UPDATE_TASKS_STATUS);
    const [statusType, setStatusType] = useState<string>('');
    const statusOptions = ['Not Started', 'In Progress', 'Complete'];
    const [hasSelectionChanged, setHasSelectionChanged] = useState(false);

    const onFilterChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        const selectedStatus = `${e.target.value}`;
        setStatusType(selectedStatus);
        setHasSelectionChanged(true);
    };

    const updateStatus = () => {
        updateTasks({
            variables: { taskIds: selectedTaskIds, status: statusType },
        });
        setSelectedTaskIds([]);
    };

    const closeModal = (close: { (): void }) => {
        close();
        afterClick();
        setHasSelectionChanged(false);
        if (ref) {
            ref?.current?.close();
        }
    };

    useEffect(() => {
        setStatusType('Not Started');
        setSelectedTasks(getTasks(tasks, taskGroupData));
    }, [tasks, taskGroupData]);

    return (
        <Popup
            open={open && selectedTasks.length > 0}
            closeOnDocumentClick
            onClose={afterClick}
            modal
            nested
            contentStyle={{ width: '657px' }}
        >
            {(close: () => void) => (
                <div className="a-updateTaskModal">
                    <Modal closeFunction={() => closeModal(close)}>
                        <h3>Update Status</h3>
                        <p>Update the status of all selected tasks to:</p>
                        <div className="a-updateTaskModal__selectContainer">
                            <span className="a-updateTaskModal__selectLabel">Status</span>
                            <Select
                                input={<InputBase />}
                                label="Status"
                                defaultValue="Not Started"
                                onChange={onFilterChange}
                                className="test"
                            >
                                {statusOptions.map((status: string) => (
                                    <MenuItem id={status} value={status}>
                                        {status}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>

                        <UpdateTaskModalTable status tasks={selectedTasks} />
                        <div className="button-group">
                            <Button color="secondary" variant="outlined" onClick={() => closeModal(close)}>
                                Cancel
                            </Button>
                            <ConfirmModal
                                closeText="Cancel"
                                onConfirm={() => {
                                    updateStatus();
                                    closeModal(close);
                                }}
                                confirmText="Update"
                                message={
                                    <>
                                        Are you you want to update the status of the selected tasks to &quot;
                                        {statusType}&quot;?
                                    </>
                                }
                                title="Update Status"
                            >
                                <Button disabled={!hasSelectionChanged} color="primary" variant="contained">
                                    Save
                                </Button>
                            </ConfirmModal>
                        </div>
                    </Modal>
                </div>
            )}
        </Popup>
    );
});

export default UpdateTaskModal;
