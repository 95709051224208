import { GetTaskGroupsTaskGroupsByPlanIdTasks as Task, GetTaskGroupsTaskGroupsByPlanId } from 'typings/_graphql';
import { find } from 'lodash-es';

export default function getTasks(taskIds: number[], taskGroupData: readonly GetTaskGroupsTaskGroupsByPlanId[]): Task[] {
    const taskArray: Task[] = [];
    taskIds.forEach((taskId) =>
        find(taskGroupData, (taskGroup) =>
            // eslint-disable-next-line array-callback-return
            taskGroup.tasks?.find((task) => {
                if (task.id === taskId) {
                    taskArray.push(task);
                }
            })
        )
    );

    return taskArray;
}
