import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { IPlanId } from 'typings/Plan';

import { useSelector } from 'react-redux';
import { Store } from 'store/reducers';
import { PlanStep, IPlanSteps } from 'typings/PlanStep';
import { Icon } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import ListItem from '@material-ui/core/ListItem';
import Dialog from '@material-ui/core/Dialog';
import GetIntroPage from '../utilities/GetIntroPage';
import './buildsidebar/BuildSideBar.scss';
import HeaderWrapper from './HeaderWrapper';

interface local {
    drawer: boolean;
}

const localStorageKey = 'mile2-techplan';

const stepsWithIntroductions = [
    'Identify Details',
    'Assess TRL / MRL',
    'Manage Tasks',
    'Manage Resources',
    'View Project Report',
];

const BuildSideBar = (): React.ReactElement => {
    const plan = useSelector((storeState: Store) => storeState.Plan) || [];
    const planSteps: IPlanSteps = useSelector((storeState: Store) => storeState.PlanStep) || [];
    const getDrawer: string | null = localStorage.getItem(localStorageKey);
    const initialDrawerState: local = !!getDrawer ? JSON.parse(getDrawer) : { drawer: true };
    const location = useLocation();
    const { planId }: IPlanId = useParams();
    const [isDrawerOpen, setIsDrawerOpen] = useState(initialDrawerState.drawer);
    const [isDrawerOpening, setIsDrawerOpening] = useState(false);
    const [currentPath, setCurrentPath] = useState('');
    const [drawerNavSections, setDrawerNavSections] = useState<PlanStep[]>([]);
    const [parentNavigationPath, setParentNavigationPath] = useState('');
    const [isSplashModalOpen, setIsSplashModalOpen] = useState(false);
    const [currentIntroStep, setCurrentIntroStep] = useState<PlanStep>();

    useEffect(() => {
        localStorage.setItem(localStorageKey, JSON.stringify({ ...initialDrawerState, drawer: isDrawerOpen }));
    }, [isDrawerOpen]);

    const handleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
        if (!isDrawerOpen) {
            setIsDrawerOpening(true);
            setTimeout(() => {
                setIsDrawerOpening(false);
            }, 500);
        }
    };

    const handleClose = () => {
        setIsSplashModalOpen(false);
    };

    const IntroductionPage = () => {
        if (currentIntroStep) {
            const { name } = currentIntroStep;
            return (
                <Dialog
                    fullScreen
                    open={isSplashModalOpen}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            handleClose();
                        }
                    }}
                >
                    <div>
                        <HeaderWrapper />
                        <div className="o-introductionpage">{GetIntroPage(name, handleClose)}</div>
                    </div>
                </Dialog>
            );
        }
        return null;
    };

    const displayIntroPage = (navItem: PlanStep) => {
        setIsSplashModalOpen(true);
        setCurrentIntroStep(navItem);
    };

    useEffect(() => {
        setCurrentPath(location.pathname);
    }, [location.pathname]);

    useEffect(() => {
        const navItemSections: string[] = [];
        if (currentPath && planSteps) {
            if (currentPath.includes('/details')) {
                navItemSections.push('Identify Details', 'Assess TRL / MRL');
                setParentNavigationPath('details');
            }
            if (currentPath.includes('/tasks')) {
                navItemSections.push('Manage Tasks', 'View Timeline');
                setParentNavigationPath('tasks');
            }
            if (currentPath.includes('/resources')) {
                navItemSections.push('Manage Resources');
                setParentNavigationPath('resources');
            }
            if (currentPath.includes('/report')) {
                navItemSections.push('View Project Report');
                setParentNavigationPath('report');
            }
            const stepsOnCurrentNav = planSteps.filter((planStep) => navItemSections.includes(planStep.name));
            if (stepsOnCurrentNav && stepsOnCurrentNav.length > 0) setDrawerNavSections(stepsOnCurrentNav);
        }
    }, [currentPath, planSteps]);

    return (
        <div className="o-planbuildsidebar">
            {isSplashModalOpen && <IntroductionPage />}
            <div className="o-planbuildsidebar__drawerContainer" id="sidebar">
                <Drawer
                    open={isDrawerOpen}
                    variant="permanent"
                    className={`o-planbuildsidebar__drawerPaper ${isDrawerOpen ? '-drawerOpen' : ''}`}
                >
                    <div className={`o-planbuildsidebar__toggleOpenIconContainer ${isDrawerOpen ? '-drawerOpen' : ''}`}>
                        <div className="o-planbuildsidebar__iconContainer">
                            <IconButton onClick={handleDrawer}>
                                <Icon>{isDrawerOpen ? 'chevron_left' : 'chevron_right'}</Icon>
                            </IconButton>
                        </div>
                    </div>
                    <Divider />
                    {isDrawerOpen && (
                        <div
                            data-testid="sidebar-opening"
                            className={`o-planbuildsidebar__container ${isDrawerOpening ? '-drawerOpening' : ''}`}
                        >
                            <div className="o-planbuildsidebar__planDetailsContainer">
                                <span className="o-planbuildsidebar__planName">{plan.name}</span>
                                <span>{plan.code}</span>
                            </div>
                            <Divider />
                            <div className="o-planbuildsidebar__navItemsContainer">
                                {drawerNavSections &&
                                    drawerNavSections.map((drawerNavSection, i) => (
                                        <>
                                            <List>
                                                <div className="o-planbuildsidebar__navItemsSectionTitle">
                                                    {drawerNavSection.name}
                                                    {stepsWithIntroductions.includes(drawerNavSection.name) && (
                                                        <IconButton onClick={() => displayIntroPage(drawerNavSection)}>
                                                            <Icon>help</Icon>
                                                        </IconButton>
                                                    )}
                                                </div>
                                                {drawerNavSection.subSteps &&
                                                    drawerNavSection.subSteps.map((subStep) => (
                                                        <div
                                                            className={`o-planbuildsidebar__navItem ${
                                                                currentPath.includes(subStep.link) ? '-selected' : ''
                                                            }`}
                                                        >
                                                            <Link
                                                                to={`/plan/${planId}/${parentNavigationPath}${subStep.link}`}
                                                            >
                                                                <ListItem>{subStep.name}</ListItem>
                                                            </Link>
                                                        </div>
                                                    ))}
                                            </List>
                                            {drawerNavSections.length - i !== 1 && <Divider />}
                                        </>
                                    ))}
                            </div>
                        </div>
                    )}
                </Drawer>
            </div>
        </div>
    );
};
export default BuildSideBar;
