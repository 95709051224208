import React from 'react';

import { Grid, Card, CardContent, CardHeader } from '@material-ui/core';
import Layouts from 'components/layouts/Layouts';
import PageHeading from 'components/PageHeading';
import CustomInput from 'components/CustomInput';

import FundamentalFormProps from './detailsInterface';

const { Subheader } = Layouts;

export default function IdentifyProblem({
    formController,
    setFocus,
    setBlur,
}: FundamentalFormProps): React.ReactElement {
    const { errors, register } = formController;
    const readOnly = false;

    return (
        <div className="m-fundamentalsForm">
            <Subheader>
                <PageHeading title="Identify the Solution" />
            </Subheader>
            <Grid id="container" container direction="row" justify="space-between">
                <Grid id="leftColumn" container item xs={12} md={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <div className="m-fundamentalsForm__inputTitle">Description of Technology</div>
                                <div className="m-fundamentalsForm__inputDescription">
                                    Describe the benefits of this technology (new capability, improve performance, etc.)
                                    and how it will fulfill the need.
                                </div>
                                <CustomInput
                                    onBlur={setBlur}
                                    onFocus={setFocus}
                                    placeholder={!readOnly ? 'Enter Text' : 'No Content'}
                                    errors={errors}
                                    name="Description of Technology"
                                    inputRef={register()}
                                    disabled={readOnly}
                                    fullWidth
                                    multiline
                                />

                                <div className="m-fundamentalsForm__inputHeading">
                                    <div className="m-fundamentalsForm__inputTitle">
                                        Current State of Technology Relative to the Problem
                                    </div>
                                    <div className="m-fundamentalsForm__inputDescription">
                                        Describe the parameters and requirements that the solution should meet upon
                                        completion to solve the problem.
                                    </div>
                                </div>
                                <CustomInput
                                    onBlur={setBlur}
                                    onFocus={setFocus}
                                    placeholder={!readOnly ? 'Enter Text' : 'No Content'}
                                    errors={errors}
                                    name="Current State of Technology Relative to the Problem"
                                    inputRef={register()}
                                    disabled={readOnly}
                                    fullWidth
                                    multiline
                                />

                                <div className="m-fundamentalsForm__inputHeading">
                                    <div className="m-fundamentalsForm__inputTitle">Risk Mitigation Strategy</div>
                                    <div className="m-fundamentalsForm__inputDescription">
                                        Describe potential risks (ex. How committed is the customer?).
                                    </div>
                                </div>
                                <CustomInput
                                    onBlur={setBlur}
                                    onFocus={setFocus}
                                    placeholder={!readOnly ? 'Enter Text' : 'No Content'}
                                    errors={errors}
                                    name="Risk Mitigation Strategy"
                                    inputRef={register()}
                                    disabled={readOnly}
                                    fullWidth
                                    multiline
                                />

                                <div className="m-fundamentalsForm__inputHeading">
                                    <div className="m-fundamentalsForm__inputTitle">Commercial Value</div>
                                </div>
                                <CustomInput
                                    onBlur={setBlur}
                                    onFocus={setFocus}
                                    placeholder={!readOnly ? 'Enter Text' : 'No Content'}
                                    errors={errors}
                                    name="Commercial Value"
                                    inputRef={register()}
                                    disabled={readOnly}
                                    fullWidth
                                    multiline
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid id="rightColumn" container direction="column" spacing={2} item xs={12} md={6}>
                    <Grid id="card" item>
                        <Card>
                            <CardHeader title="Solution Holder" />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <div className="m-fundamentalsForm__nameContainer">
                                            <div className="m-fundamentalsForm__inputTitle">First Name</div>
                                            <CustomInput
                                                onBlur={setBlur}
                                                onFocus={setFocus}
                                                placeholder={!readOnly ? 'Enter Text' : 'No Content'}
                                                errors={errors}
                                                name="Solution Holder First Name"
                                                inputRef={register()}
                                                disabled={readOnly}
                                                fullWidth
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="m-fundamentalsForm__nameContainer">
                                            <div className="m-fundamentalsForm__inputTitle">Last Name</div>
                                            <CustomInput
                                                onBlur={setBlur}
                                                onFocus={setFocus}
                                                placeholder={!readOnly ? 'Enter Text' : 'No Content'}
                                                errors={errors}
                                                name="Solution Holder Last Name"
                                                inputRef={register()}
                                                disabled={readOnly}
                                                fullWidth
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <div className="m-fundamentalsForm__inputHeading">
                                    <div className="m-fundamentalsForm__inputTitle">Company</div>
                                </div>
                                <CustomInput
                                    onBlur={setBlur}
                                    onFocus={setFocus}
                                    placeholder={!readOnly ? 'Enter Text' : 'No Content'}
                                    errors={errors}
                                    name="Solution Holder Company"
                                    inputRef={register()}
                                    disabled={readOnly}
                                    fullWidth
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* <Grid id="card" item>
                        <Card>
                            <CardHeader
                                action={
                                    <Button variant="contained" color="primary">
                                        Add
                                    </Button>
                                }
                                title="Attachments & Links"
                            />

                            <CardContent>
                                <div>No attachments or links have been added</div>
                            </CardContent>
                        </Card>
                    </Grid> */}
                </Grid>
            </Grid>
        </div>
    );
}
