import React, { useEffect, useState, useContext, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Icon, Input, MenuItem } from '@material-ui/core';
import { find, filter, cloneDeep, get, has } from 'lodash-es';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import { getDurationMinusWeekends } from 'components/utilities/DateUtils';
import PlanContext from 'pages/plans/PlanContext';
import AlertToast from 'components/AlertToast';
import UserDropdown from 'components/UserDropdown';
import Permission from 'components/Permission';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';
import CustomNumberInput from 'components/CustomNumberInput';
import { getTaskStatusIcon } from 'components/utilities/getStatusIcon';
import { Store } from 'store/reducers';
import { preventEnterSubmit } from 'components/utilities/preventEnterSubmit';
import { IUser } from 'typings/User';

import { qTasks, qUsers } from 'api/queries';
import { SearchTaskNameByPlanSearchTaskNameByPlan } from 'typings/_graphql';
import Dependencies from './taskform/Dependencies';
import Dates from './taskform/Dates';

import DeleteModal from './form/DeleteModal';
import './taskform/TaskForm.scss';
import FormButtons from './form/FormButtons';

interface TaskFormProps {
    taskGroupId: number;
    planId: number;
    readOnly?: boolean;
}

interface addItemProp {
    type: 'parent' | 'child';
    task: SearchTaskNameByPlanSearchTaskNameByPlan;
}

const statusOptions = [
    { label: 'Not Started', value: 'Not Started', id: 1, icon: 'radio_button_unchecked' },
    { label: 'In Progress', value: 'In Progress', id: 2, icon: 'schedule' },
    { label: 'Complete', value: 'Complete', id: 3, icon: 'check_circle_outline' },
];

export interface removeItemProp {
    type: 'parent' | 'child';
    taskId: number;
}

const TaskForm = ({ planId, taskGroupId, readOnly: outerReadOnly = false }: TaskFormProps): React.ReactElement => {
    const {
        control,
        register,
        handleSubmit,
        errors,
        reset,
        watch,
        setValue,
        getValues,
        formState: { isDirty: valuesHaveChanged },
    } = useForm({
        mode: 'onSubmit',
    });

    const { setOffCanvasTask, offCanvasTask, taskGroupList, fundTypes } = useContext(PlanContext);
    const [title, setTitle] = useState('Add a Task');
    const userData: IUser = useSelector((storeState: Store) => storeState?.User);
    const [parentDependencies, setParentDependencies] = useState<SearchTaskNameByPlanSearchTaskNameByPlan[]>([]);
    const [childDependencies, setChildDependencies] = useState<SearchTaskNameByPlanSearchTaskNameByPlan[]>([]);
    const [excludeIds, setExcludeIds] = useState<number[]>([]);
    const [editTaskId, setEditTaskId] = useState(0);
    const [, setInnerTaskGroupId] = useState(0);
    const [readOnly, setReadOnly] = useState(true);
    const [dependenciesChanged, setDependenciesChanged] = useState(false);
    const [currentTaskTaskGroupColor, setCurrentTaskTaskGroupColor] = useState();

    const [createTask] = useMutation(qTasks.CREATE_TASK);
    const [updateTask] = useMutation(qTasks.UPDATE_TASK);
    const [getTask, { data: taskData, loading: getTaskLoading }] = useLazyQuery(qTasks.QUERY_TASK_BY_ID);

    const { data: users } = useQuery(qUsers.GET_USERS_BY_PLAN, {
        variables: { planId },
    });
    const [isCanvasClosed, setIsCanvasClosed] = useState(false);

    const ref: any = useRef();

    const defaultFormData = {
        name: '',
        description: '',
        taskGroup: 0,
        users: userData.id,
        status: '',
        estimate: null,
        startDate: null,
        dueDate: null,
        duration: 0,
        isDateRange: false,
    };

    // Clears dependencies
    const clearDependencies = () => {
        setExcludeIds([]);
        setParentDependencies([]);
        setChildDependencies([]);
    };

    // Scrolls to Top
    const scrollToTop = () => {
        const taskForm = document.getElementById('off-canvas --open');
        if (taskForm) taskForm.scrollTop = 0;
    };

    // load default task data
    const loadDefaultTaskData = (reloadDeps = true) => {
        if (offCanvasTask.editTask !== false && taskData?.task) {
            const formatedEditTaskData = cloneDeep(taskData.task);
            setEditTaskId(taskData.task.id);
            formatedEditTaskData.taskGroup = get(taskData, 'task.taskGroup.id');
            formatedEditTaskData.users = get(taskData, 'task.users[0].id');
            formatedEditTaskData.fundType = get(taskData, 'task.fundType.id');
            setCurrentTaskTaskGroupColor(get(taskData, 'task.taskGroup.color'));
            formatedEditTaskData.estimate = get(taskData, 'task.estimate');

            // Date Stuff
            if (!!taskData?.startDate) {
                formatedEditTaskData.startDate = new Date(taskData.startDate);
            }

            if (!!taskData?.dueDate) {
                formatedEditTaskData.dueDate = new Date(taskData.dueDate);
            }

            formatedEditTaskData.duration =
                formatedEditTaskData.startDate && formatedEditTaskData.dueDate
                    ? getDurationMinusWeekends(formatedEditTaskData.startDate, formatedEditTaskData.dueDate)
                    : 0;

            if (reloadDeps) {
                clearDependencies();
                const pDependencies: SearchTaskNameByPlanSearchTaskNameByPlan[] = [];
                const cDependencies: SearchTaskNameByPlanSearchTaskNameByPlan[] = [];
                taskData.task.dependencies.forEach((item: SearchTaskNameByPlanSearchTaskNameByPlan) => {
                    pDependencies.push(item);
                });
                taskData.task.dependees.forEach((item: SearchTaskNameByPlanSearchTaskNameByPlan) => {
                    cDependencies.push(item);
                });
                setParentDependencies(pDependencies);
                setChildDependencies(cDependencies);
            }
            setInnerTaskGroupId(formatedEditTaskData.taskGroup);
            reset(formatedEditTaskData);
        } else {
            setEditTaskId(0);
            reset(defaultFormData);
        }
    };

    // Set default data when task changes.
    useEffect(() => {
        loadDefaultTaskData(readOnly);
    }, [taskData, offCanvasTask, readOnly]);

    // Clear data when offcanvas task info changes
    useEffect(() => {
        if (offCanvasTask.editTask !== false && has(offCanvasTask, 'editTask.id')) {
            setTitle(`Edit Task`);
            getTask({ variables: { id: get(offCanvasTask, 'editTask.id') } });
            setReadOnly(true);
        } else {
            setTitle('Add a Task');
            defaultFormData.taskGroup = taskGroupId;
            defaultFormData.users = '';
            setInnerTaskGroupId(taskGroupId);
            reset(defaultFormData);
            setReadOnly(false);
        }
        clearDependencies();
        if (outerReadOnly) {
            setTitle('');
            setReadOnly(true);
        }
    }, [offCanvasTask]);

    // Set Date Range when start date or end date change.
    useEffect(() => {
        if (getValues('startDate') && getValues('dueDate')) {
            setValue('duration', getDurationMinusWeekends(getValues('startDate'), getValues('dueDate')));
        } else {
            setValue('duration', 0);
        }
    }, [watch('startDate'), watch('dueDate')]);

    // When we submit the data
    const onSubmit = handleSubmit((data) => {
        const dependencies = parentDependencies.map((item) => item.id);
        const dependees = childDependencies.map((item) => item.id);

        const newData = {
            name: data.name,
            description: data.description,
            taskGroupId: parseInt(data.taskGroup, 10),
            users: data.users,
            status: data.status,
            estimate: data?.estimate?.floatValue,
            taskTypeId: data.fundType,
            dependencies,
            dependees,
            startDate: data.startDate,
            dueDate: data.dueDate,
            duration: data.duration,
            isDateRange: data.isDateRange,
        };
        defaultFormData.taskGroup = taskGroupId;

        if (!offCanvasTask.editTask) {
            createTask({
                variables: { data: { ...newData } },
                refetchQueries: [
                    {
                        query: qTasks.GET_BY_PLAN_FUND_TYPE_AND_USER,
                        variables: { planId, getByUser: true },
                    },
                ],
            })
                .then(() => {
                    toast(<AlertToast severity="success" message={`Task Successfully Created`} />);
                    reset(defaultFormData);
                    clearDependencies();
                })
                .catch((e) => {
                    toast(<AlertToast severity="error" message={`Error creating Task: ${e.message}`} />);
                });
        } else {
            updateTask({
                variables: { data: { id: offCanvasTask.editTask.id, ...newData } },
                refetchQueries: [
                    {
                        query: qTasks.GET_BY_PLAN_FUND_TYPE_AND_USER,
                        variables: { planId, getByUser: true },
                    },
                ],
            })
                .then(() => {
                    toast(<AlertToast severity="success" message={`Task Successfully Edited: ${newData.name}`} />);
                    reset(defaultFormData);
                    clearDependencies();
                })
                .catch((e) => {
                    toast(<AlertToast severity="error" message={`Error Editing Task: ${e.message}`} />);
                });
        }
        setOffCanvasTask({ canvas: isCanvasClosed, editTask: false, taskGroupId });
    });

    // Maybe?
    const changeToEdit = () => {
        if (!outerReadOnly) {
            setReadOnly(false);
        }
    };

    // When we click cancel
    const cancelClicked = () => {
        setOffCanvasTask({ canvas: false, editTask: false, taskGroupId: 0 });
        reset(defaultFormData);
        clearDependencies();
    };

    // Add dependency task
    const addTask = (items: addItemProp) => {
        if (items.type === 'child') {
            setChildDependencies(childDependencies.concat(items.task));
        } else {
            setParentDependencies(parentDependencies.concat(items.task));
        }
        setExcludeIds(excludeIds.concat(items.task.id));
        setDependenciesChanged(true);
    };

    // Remove dependency task
    const removeTask = ({ type, taskId }: removeItemProp) => {
        if (type === 'child') {
            const newChildren = filter(childDependencies, (x) => x.id !== taskId);
            setChildDependencies(newChildren);
        } else {
            const newParents = filter(parentDependencies, (x) => x.id !== taskId);
            setParentDependencies(newParents);
        }
        const newExcludeIds = filter(excludeIds, (x) => x !== taskId);
        setExcludeIds(newExcludeIds);
        setDependenciesChanged(true);
    };

    // Whats this?
    const getLabelNameFromValue = (list: any, key: any, value: string) => {
        const item = find(list, (o) => o[key] === value);
        return item?.name || '';
    };

    const ShowMenu = (props: any): React.ReactElement =>
        offCanvasTask.editTask === false ? <></> : <div className="o-taskform__taskMenu">{props.children}</div>;

    const formHooks = { setValue, getValues, watch, register, control };

    if (getTaskLoading) {
        return (
            <div className="o-taskform">
                <h2>{title}</h2>
                <h4>Loading Task...</h4>
            </div>
        );
    }

    return (
        <div className={`o-taskform ${readOnly ? 'readonly' : ''}`}>
            <ShowMenu>
                <DeleteModal
                    objectToDelete={offCanvasTask.editTask}
                    ref={ref}
                    taskGroupId={watch('taskGroup')}
                    afterClick={() => setOffCanvasTask({ canvas: false, editTask: false, taskGroupId: 0 })}
                >
                    <Icon>delete</Icon>
                </DeleteModal>
            </ShowMenu>
            <Permission auth={`${readOnly} === false`} showFail={<h2>Task Details</h2>}>
                <h2>{title}</h2>
            </Permission>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <form autoComplete="off" onKeyPress={preventEnterSubmit} onSubmit={onSubmit}>
                <div className="-hidden">
                    <Controller as={Input} name="startDate" control={control} />
                    <Controller as={Input} name="dueDate" control={control} />
                    <Controller as={Input} name="duration" control={control} />
                    <input ref={register} name="isDateRange" type="checkbox" />
                </div>
                <Permission
                    auth={`${readOnly} === false`}
                    showFail={
                        <div className="o-taskform__readOnlyContainer">
                            <span className="o-taskform__readOnlyLabel">Task Name</span>
                            <p className="o-taskform__readOnlyContent">{watch('name')}</p>
                        </div>
                    }
                >
                    <CustomInput
                        inputLabel="Task Name"
                        errors={errors}
                        maxLength={30}
                        showCharacterCount
                        defaultValue={watch('name')}
                        name="name"
                        inputRef={register({ required: true })}
                        fullWidth
                        required
                    />
                </Permission>
                <Permission
                    auth={`${readOnly} === false`}
                    showFail={
                        <div className="o-taskform__readOnlyContainer">
                            <span className="o-taskform__readOnlyLabel">Task Description</span>
                            <p className="o-taskform__readOnlyContent">
                                {watch('description') || <em>No Description</em>}
                            </p>
                        </div>
                    }
                >
                    <CustomInput
                        inputLabel="Task Description"
                        errors={errors}
                        name="description"
                        inputRef={register()}
                        fullWidth
                        multiline
                    />
                </Permission>
                <UserDropdown
                    control={control}
                    defaultValue={watch('users') || 0}
                    name="users"
                    label="*Assignee"
                    users={users?.usersByPlan || []}
                    readOnly={readOnly}
                />
                <Permission
                    auth={`${readOnly} === false`}
                    showFail={
                        <div className="o-taskform__readOnlyContainer">
                            <span className="o-taskform__readOnlyLabel">Task Group</span>

                            <div className="o-taskform__taskGroupOption">
                                <span
                                    className="o-taskform__taskGroupColor"
                                    style={{ backgroundColor: currentTaskTaskGroupColor }}
                                />
                                {getLabelNameFromValue(taskGroupList, 'id', watch('taskGroup'))}
                            </div>
                        </div>
                    }
                >
                    <CustomSelect
                        errors={errors}
                        required
                        inputLabel="Task Group"
                        name="taskGroup"
                        control={control}
                        watch={watch('taskGroup')}
                    >
                        <MenuItem disabled value={0}>
                            Select Task Group
                        </MenuItem>
                        {taskGroupList.map((taskGroup) => (
                            <MenuItem key={taskGroup.id} value={taskGroup.id}>
                                <div className="o-taskform__taskGroupOption">
                                    <span
                                        className="o-taskform__taskGroupColor"
                                        style={{ backgroundColor: taskGroup?.color }}
                                    />
                                    {taskGroup.name}
                                </div>
                            </MenuItem>
                        ))}
                    </CustomSelect>
                </Permission>
                <Permission
                    auth={`${readOnly} === false`}
                    showFail={
                        <div className="o-taskform__readOnlyContainer">
                            <span className="o-taskform__readOnlyLabel">Status</span>

                            <div className="o-taskform__statusContainer">
                                <Icon>{getTaskStatusIcon(watch('status'))}</Icon>
                                <span>&nbsp;&nbsp;{watch('status')}</span>
                            </div>
                        </div>
                    }
                >
                    <CustomSelect inputLabel="Status" name="status" control={control} watch={watch('status') || 0}>
                        <MenuItem disabled value={0}>
                            Select a Status
                        </MenuItem>
                        {statusOptions.map((statusOption) => (
                            <MenuItem key={statusOption.id} value={statusOption.value}>
                                <div className="o-taskform__statusContainer">
                                    <Icon>{statusOption.icon}</Icon>&nbsp;&nbsp;{statusOption.label}
                                </div>
                            </MenuItem>
                        ))}
                    </CustomSelect>
                </Permission>
                <Permission
                    auth={`${readOnly} === false`}
                    showFail={
                        <div className="o-taskform__readOnlyContainer">
                            <span className="o-taskform__readOnlyLabel">Estimate</span>
                            <p className="o-taskform__readOnlyContent">
                                {!watch('estimate') && watch('estimate') !== 0 ? (
                                    <em>No Estimate</em>
                                ) : (
                                    <>
                                        <strong className="-dollarSign">$</strong>&nbsp;
                                        {watch('estimate').toLocaleString()}
                                    </>
                                )}
                            </p>
                        </div>
                    }
                >
                    <CustomNumberInput
                        defaultValue={watch('estimate')}
                        inputLabel="Estimate"
                        control={control}
                        placeholder="Estimate"
                        errors={errors}
                        name="estimate"
                        inputRef={register}
                        fullWidth
                    />
                </Permission>
                <Permission
                    auth={`${readOnly} === false`}
                    showFail={
                        <div className="o-taskform__readOnlyContainer">
                            <span className="o-taskform__readOnlyLabel">Gov&apos;t Authorized Category</span>
                            <p className="o-taskform__readOnlyContent">
                                {get(taskData, 'task.fundType.name', <em>No Category</em>)}
                            </p>
                        </div>
                    }
                >
                    <CustomSelect
                        inputLabel="Gov't Authorized Category"
                        name="fundType"
                        errors={errors}
                        control={control}
                        watch={watch('fundType.id') || 0}
                    >
                        <MenuItem disabled value={0}>
                            Select a Category
                        </MenuItem>
                        {fundTypes.map((fundTypeItem) => (
                            <MenuItem key={fundTypeItem.id} value={fundTypeItem.id}>
                                {fundTypeItem.name}
                            </MenuItem>
                        ))}
                    </CustomSelect>
                </Permission>
                <hr className="o-taskform__hr" />
                <h4 className="o-taskform__datesTitle">Date</h4>
                <Dates formHooks={formHooks} readOnly={readOnly} />
                <hr className="o-taskform__hr" />
                <Dependencies
                    parentDependencies={parentDependencies}
                    childDependencies={childDependencies}
                    removeTask={removeTask}
                    addTask={addTask}
                    readOnly={readOnly}
                    excludeIds={excludeIds}
                    taskId={editTaskId}
                />
                <FormButtons
                    readOnly={readOnly}
                    isDisabled={watch('taskGroup') < 1}
                    isNotEditMode={offCanvasTask.editTask === false}
                    areValuesChanged={valuesHaveChanged || dependenciesChanged}
                    onEditHandler={changeToEdit}
                    onCancelHandler={cancelClicked}
                    setIsCanvasClosed={setIsCanvasClosed}
                    watch={watch(['name', 'users'])}
                    scrollToTop={scrollToTop}
                />
            </form>
        </div>
    );
};

export default TaskForm;
