import React from 'react';
import { PlanSummaryPlanSummaryTaskGroups as TaskGroup } from 'typings/_graphql';
import ActivityCard from './activitessummary/ActivityCard';

import './activitessummary/ActivitiesSummary.scss';

interface ActivitiesSummaryProps {
    taskGroupsSummary?: readonly TaskGroup[] | null;
}

const ActivitiesSummary = ({ taskGroupsSummary }: ActivitiesSummaryProps): React.ReactElement => (
    <ul className="o-activityCard">
        {taskGroupsSummary && taskGroupsSummary.length > 0 ? (
            taskGroupsSummary?.map((taskGroup) => <ActivityCard key={taskGroup.id} taskGroup={taskGroup} />)
        ) : (
            <p className="o-activityCard__emptyTaskGroups">No task groups or tasks have been added to this project.</p>
        )}
    </ul>
);

export default ActivitiesSummary;
