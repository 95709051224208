import React, { useContext } from 'react';
import { TableCell, TableRow, Icon, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import UserAvatar from 'components/UserAvatar';
import ConfirmModal from 'components/ConfirmModal';
import { getTaskStatusIcon } from 'components/utilities/getStatusIcon';
import { GetTaskGroupsTaskGroupsByPlanIdTasks as Task } from 'typings/_graphql';
import PlanContext from 'pages/plans/PlanContext';

interface Props {
    task: Task;
    assigneeList?: boolean;
    statusList?: boolean;
}

const UpdateTaskModalListItem = ({ task, assigneeList, statusList }: Props): React.ReactElement => {
    const { selectedTaskIds, setSelectedTaskIds } = useContext(PlanContext);

    const assignee = task && task.users ? task?.users[0]?.name : '';
    const ASSIGNEE_PRIVILEGES = assigneeList || statusList;

    const removeTask = (taskId: number) => {
        if (selectedTaskIds.find((id) => id === taskId)) {
            const filteredArray = selectedTaskIds.filter((selectedTaskId) => selectedTaskId !== taskId);
            setSelectedTaskIds(filteredArray);
        }
    };

    const { name, status, dependees, dependencies } = task;
    const numberOfDependencies = dependees && dependencies && dependees?.length + dependencies?.length;
    const confirmMessage = assigneeList ? 'assignee' : 'status';
    const dependenciesLabel = numberOfDependencies === 1 ? 'Dependency' : 'Dependencies';

    const renderModalTitle = (modifierBeingUpdated: string) => {
        switch (modifierBeingUpdated) {
            case 'status':
                return 'Update Status';
            case 'assignee':
                return 'Update Assignee';
            default:
                return 'Shift Dates';
        }
    };

    return (
        <TableRow>
            <TableCell width={`${!assigneeList && !statusList ? '100%' : '50%'}`} align="left">
                <div className={`a-updateTaskModal ${numberOfDependencies === 0 ? '-noDependencies' : ''}`}>
                    <div className="a-updateTaskModal__flexRow">
                        {ASSIGNEE_PRIVILEGES ? (
                            <div className="a-updateTaskModal__flexRow">
                                <ConfirmModal
                                    closeText="Cancel"
                                    onConfirm={() => {
                                        removeTask(task.id);
                                    }}
                                    confirmText="Remove"
                                    message={
                                        <>
                                            {`Are you sure you want to remove "${task.name}" from your update ${confirmMessage} selection?`}
                                        </>
                                    }
                                    title={renderModalTitle(confirmMessage)}
                                >
                                    <Icon>close</Icon>
                                </ConfirmModal>
                                <div>{name}</div>
                            </div>
                        ) : (
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="additional-task-content"
                                    IconButtonProps={{ disabled: true }}
                                >
                                    <ConfirmModal
                                        closeText="Cancel"
                                        onConfirm={() => {
                                            removeTask(task.id);
                                        }}
                                        confirmText="Remove"
                                        message={
                                            <>
                                                {`Are you sure you want to remove "${task.name}" from your shift date selection?`}
                                            </>
                                        }
                                        title="Shift Dates"
                                    >
                                        <Icon>close</Icon>
                                    </ConfirmModal>
                                    <div>{name}</div>
                                    <div className="a-updateTaskModal__dependenciesLabel">
                                        {`${numberOfDependencies} ${dependenciesLabel}`}
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ul className="a-updateTaskModal__dependenciesList">
                                        {dependees?.map((dependee) => (
                                            <li className="a-updateTaskModal__dependenciesList__item">
                                                {dependee.name}
                                            </li>
                                        ))}
                                        {dependencies?.map((dependency) => (
                                            <li className="a-updateTaskModal__dependenciesList__item">
                                                {dependency.name}
                                            </li>
                                        ))}
                                    </ul>
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </div>
                </div>
            </TableCell>
            {ASSIGNEE_PRIVILEGES && (
                <TableCell width="auto" align="left">
                    <UserAvatar name={assignee} />
                </TableCell>
            )}
            {statusList && (
                <TableCell width="auto" align="left">
                    <div className="a-updateTaskModal__flexRow">
                        <Icon>{status && getTaskStatusIcon(status)}</Icon> {status}
                    </div>
                </TableCell>
            )}
        </TableRow>
    );
};

export default UpdateTaskModalListItem;
