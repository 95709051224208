import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { PlanSummaryPlanSummaryTaskGroups as TaskGroup } from 'typings/_graphql';
import { Icon } from '@material-ui/core';

interface ActivityCardProps {
    taskGroup: TaskGroup;
}

const ActivityCard = ({ taskGroup }: ActivityCardProps): React.ReactElement => {
    const [taskGroupEstimate, setTaskGroupEstimate] = useState(0);
    const [stakeholders, setStakeholders] = useState<Array<string>>([]);

    useEffect(() => {
        const newStakeholders: string[] = [];
        let totalEstimate = 0;
        taskGroup.tasks?.forEach((task) => {
            totalEstimate += task.estimate ?? 0;

            if (task.users) {
                task.users.forEach(() => {
                    if (task.users?.[0].name && !newStakeholders.includes(task.users?.[0].name)) {
                        newStakeholders.push(task.users?.[0].name || '');
                    }
                });
            }
        });
        setTaskGroupEstimate(totalEstimate);
        setStakeholders(newStakeholders);
    }, [taskGroup]);

    return (
        <li className="o-activityCard__item">
            <div className="o-activityCard__heading">
                <span className="o-activityCard__color" style={{ backgroundColor: taskGroup.color }} />
                <h4 className="o-activityCard__name">{taskGroup.name}</h4>
            </div>
            <div className="o-activityCard__costContainer">
                <p className="o-activityCard__costTitle">Estimated Cost</p>
                <p className="o-activityCard__costAmount">
                    {taskGroupEstimate > 0 ? (
                        <>
                            <strong>$ </strong>
                            <NumberFormat value={taskGroupEstimate} displayType={'text'} thousandSeparator />{' '}
                        </>
                    ) : (
                        'No tasks estimated'
                    )}
                </p>
            </div>
            <div className="o-activityCard__usersContainer">
                {stakeholders && stakeholders.length > 0 ? (
                    <>
                        <Icon>people</Icon>&nbsp;
                        <p className="o-activityCard__users">{stakeholders.join(', ')}</p>
                    </>
                ) : (
                    'No individuals assigned to tasks.'
                )}
            </div>
            <p className="o-activityCard__desc">{taskGroup.description}</p>
        </li>
    );
};

export default ActivityCard;
