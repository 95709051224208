import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Store } from 'store/reducers';
import { useSelector } from 'react-redux';

import { IUser } from 'typings/User';

import './homepageheader/HomePageHeader.scss';

interface HomeHeaderProps {
    hasActiveProjects: boolean;
    hasArchivedProjects: boolean;
    seeArchived: boolean;
    setSeeArchived: React.Dispatch<React.SetStateAction<boolean>>;
}

const HomePageHeader = ({
    hasArchivedProjects,
    hasActiveProjects,
    seeArchived,
    setSeeArchived,
}: HomeHeaderProps): React.ReactElement => {
    const userData: IUser = useSelector((storeState: Store) => storeState?.User);

    const Header = () =>
        seeArchived ? (
            <Grid item md={4}>
                <div className="m-homePageHeader__activeProjectsButton">
                    <Button
                        onClick={() => {
                            setSeeArchived(false);
                        }}
                        variant="outlined"
                        color="primary"
                    >
                        View Active Projects
                    </Button>
                </div>

                <h3 className="m-homePageHeader__description">Projects On Hold</h3>
                {hasArchivedProjects ? (
                    <p className="m-homePageHeader__subDescription">
                        Below lists all of your projects that were previously placed on hold by yourself or another
                        person on that project.
                    </p>
                ) : (
                    <p className="m-homePageHeader__subDescription">
                        There are no archived projects that you are involved in.
                    </p>
                )}
            </Grid>
        ) : (
            <>
                <Grid item md={7}>
                    <h2 className="m-homePageHeader__name">{`Welcome, ${userData.name}`}</h2>
                    {hasActiveProjects ? (
                        <>
                            <h3 className="m-homePageHeader__description">My Active Projects</h3>
                            <p className="m-homePageHeader__subDescription">
                                Select any project from below that you are currently added to.
                            </p>
                        </>
                    ) : (
                        <>
                            <h4 className="m-homePageHeader__description -light">
                                You are currently not assigned to any Active Projects.
                            </h4>
                            <p className="m-homePageHeader__subDescription -dark">
                                Please create a new project or contact a project owner to be added to an existing
                                project.
                            </p>
                        </>
                    )}
                </Grid>
                <Grid item md={5} className="m-homePageHeader__buttons">
                    <Button
                        color="primary"
                        variant="outlined"
                        disabled={!hasArchivedProjects}
                        onClick={() => {
                            setSeeArchived(true);
                        }}
                    >
                        View Projects On Hold
                    </Button>
                    <Link to="/createPlan">
                        <Button color="primary" variant="contained">
                            Create New Project
                        </Button>
                    </Link>
                </Grid>
            </>
        );
    return <Header />;
};

export default HomePageHeader;
