/* eslint no-unneeded-ternary: 'off' */
import React from 'react';
import { GetTaskByIdTaskUsers, GetTaskByIdTaskUsersPlanRoles } from 'typings/_graphql';
import Tooltip from '../Tooltip';
import './Avatar.scss';

interface AvatarProps {
    assignee?: string;
    users?: GetTaskByIdTaskUsers[];
    absolute?: boolean;
    hideTooltip?: boolean;
    offsetRight?: number;
}
interface AvatarTooltipBodyProps {
    users?: GetTaskByIdTaskUsers[];
}
const AvatarTooltipBody = ({ users }: AvatarTooltipBodyProps): null | React.ReactElement => {
    if (!users || users.length === 0) return null;
    return (
        <>
            <div>{users[0].name}</div>
        </>
    );
};
const isAbsoluteClass = (absolute: boolean) => `avatar${absolute ? ' absolute' : ''}`;
const grabInitials = (users?: GetTaskByIdTaskUsers[], assignee?: string) =>
    String(!!users ? users[0].name : assignee).createInitials();

export default ({
    assignee,
    users,
    offsetRight,
    hideTooltip = false,
    absolute = false,
}: AvatarProps): React.ReactElement =>
    !hideTooltip && (users || assignee) ? (
        <Tooltip body={<AvatarTooltipBody users={users} />}>
            <div className={isAbsoluteClass(absolute)} style={{ right: offsetRight ? offsetRight : -40 }}>
                {grabInitials(users, assignee)}
            </div>
        </Tooltip>
    ) : (
        <div className={isAbsoluteClass(absolute)} style={{ right: offsetRight ? offsetRight : -40 }}>
            {grabInitials(users, assignee)}
        </div>
    );
