export enum Statuses {
    'Active',
    'Pending',
    'Rejected',
    'Inactive',
}

export enum Permissions {
    'Admin',
    'Contributor',
}

export enum PhoneTypes {
    'Home Phone',
    'Work Phone',
    'Mobile Phone',
}
