import { gql } from 'apollo-boost';

const QUERY_TASK_GROUPS_BY_PLAN = gql`
    query GetTaskGroups($id: Float!) {
        taskGroupsByPlanId(planId: $id) {
            id
            name
            description
            color
            tasks {
                id
                isDateRange
                description
                duration
                dependencies {
                    id
                    name
                }
                dependees {
                    id
                    name
                }
                taskGroup {
                    color
                    id
                }
                estimate
                flagged
                flaggedMessage
                name
                status
                dueDate
                startDate
                users {
                    id
                    emailAddress
                    name
                    phone
                    planRoles {
                        planRole
                    }
                    avatar
                }
                fundType {
                    id
                    name
                }
                taskResourceAssoc {
                    id
                    taskAmount
                    resource {
                        id
                        name
                        amount
                        fundType {
                            name
                        }
                    }
                }
            }
        }
    }
`;

const QUERY_TASK_GROUP = gql`
    query GetTaskGroup($taskGroupId: Float!) {
        taskGroup(taskGroupId: $taskGroupId) {
            id
            name
            color
            description
        }
    }
`;
const CREATE_TASK_GROUP = gql`
    mutation CreateTaskGroup($data: CreateTaskGroupInput!) {
        createTaskGroup(data: $data) {
            planId
            name
            description
            color
        }
    }
`;

const DELETE_TASK_GROUP = gql`
    mutation DeleteTaskGroup($taskGroupId: Float!) {
        deleteTaskGroup(taskGroupId: $taskGroupId)
    }
`;

const UPDATE_TASK_GROUP = gql`
    mutation UpdateTaskGroup($taskGroupId: Float!, $data: UpdateTaskGroupInput!) {
        updateTaskGroup(taskGroupId: $taskGroupId, data: $data) {
            planId
            name
            description
            color
        }
    }
`;

export { QUERY_TASK_GROUP, QUERY_TASK_GROUPS_BY_PLAN, CREATE_TASK_GROUP, DELETE_TASK_GROUP, UPDATE_TASK_GROUP };
