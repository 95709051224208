import React from 'react';
import '../planfundamentals/PlanFundamentals.scss';

export interface StakeholderInfoProps {
    name: string;
    lastName: string;
    company: string;
}

interface ProblemSolutionSectionProps {
    subheading: string;
    stakeholderInfo?: StakeholderInfoProps;
    content?: string;
}

const ProblemSolutionSection = ({
    subheading,
    stakeholderInfo,
    content,
}: ProblemSolutionSectionProps): React.ReactElement => {
    const stakeholderName =
        stakeholderInfo?.name && stakeholderInfo?.name !== ''
            ? `${stakeholderInfo?.name} ${stakeholderInfo?.lastName}`
            : `No ${subheading} was identified.`;

    const stakeholderClassName = `m-planFundamentals__stakeholderContent${
        !stakeholderName.includes('identified') ? '-bolder' : ''
    }`;

    const sectionContent = content && content !== '' ? content : 'No description was provided.';

    return (
        <div
            className={`m-planFundamentals__section ${
                stakeholderInfo ? 'm-planFundamentals__stakeholder' : 'm-planFundamentals__separator'
            }`}
        >
            <div className={`${subheading === 'Air Force End User' ? '-sectionDivider' : ''}`}>
                <p className="m-planFundamentals__subheading">{subheading}</p>
                {stakeholderInfo ? (
                    <section className="m-planFundamentals__stakeholderContent">
                        <p className={stakeholderClassName}>{stakeholderName}</p>
                        <p className="m-planFundamentals__stakeholderCompany">{stakeholderInfo.company}</p>
                    </section>
                ) : (
                    <p className="m-planFundamentals__content">{sectionContent}</p>
                )}
            </div>
        </div>
    );
};

export default ProblemSolutionSection;
