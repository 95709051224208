import { gql } from 'apollo-boost';

const QUERY_PLANSTEPS = gql`
    query GetPlanSteps($id: Float!) {
        planSteps(id: $id) {
            id
            status
            isLocked
            typeCode
            order
            name
            link
            icon
            parentStep {
                id
            }
            subSteps {
                id
                name
                order
                status
                link
                typeCode
            }
        }
    }
`;

const QUERY_PLANSTEP = gql`
    query GetPlanStep($id: String!) {
        planStep(id: $id) {
            status
            isLocked
            typeCode
            order
            icon
            name
            parentStep {
                id
                name
            }
            subSteps {
                id
                name
            }
        }
    }
`;

const QUERY_ACTIVE_STEP = gql`
    query GetActiveStep($planId: Float!) {
        activeStep(planId: $planId) {
            id
            name
            icon
            link
            status
        }
    }
`;

const UPDATE_PLAN_STEP_STATUS = gql`
    mutation updatePlanStepStatus($stepId: Float!, $status: String!) {
        updatePlanStepStatus(id: $stepId, status: $status) {
            status
            isLocked
            typeCode
            order
            icon
            name
            parentStep {
                id
                name
            }
            subSteps {
                id
                name
            }
        }
    }
`;

export { QUERY_PLANSTEP, QUERY_PLANSTEPS, QUERY_ACTIVE_STEP, UPDATE_PLAN_STEP_STATUS };
