import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'store/reducers';
import Layouts from 'components/layouts/Layouts';
import PlanDetails from './PlanDetails';

const ProjectDashboard = (): ReactElement => {
    const { Subheader, Content, Sidebar, Navigation } = Layouts;
    const plan = useSelector((storeState: Store) => storeState.Plan);
    return (
        <Layouts className="prevent-col-wrap">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Sidebar />
                <Content>
                    <Navigation />
                    <Subheader>
                        <PlanDetails plan={plan} />
                    </Subheader>
                    <div style={{ paddingTop: '3rem' }}>
                        <h4>Project Dashboard</h4>
                        <p>Dashboard for the plan: show oritenting info like history, blockers, recent status, etc</p>
                        <h4>Project Details</h4>
                        <p>
                            Contains the details that the user entered when creating <br />
                        </p>
                        <p>the plan. Creator has ability to edit.</p>
                    </div>
                </Content>
            </div>
        </Layouts>
    );
};

export default ProjectDashboard;
