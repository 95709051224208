import { defaults } from 'lodash-es';
import {
    GET_USER_STATE,
    SAVE_USER_STATE,
    UPDATE_USER_STATE,
    DELETE_USER_STATE,
    SAVE_USER_INTRODUCTIONS,
} from 'store/actions/User';
import { IScopeRoles } from 'typings/User';

const initialState = {
    id: '',
    name: '',
    emailAddress: '',
    phone: '',
    avatar: '',
    tpSuperAdmin: false,
    isGovernment: false,
    scopeRoles: [],
    pageIntroductions: [],
};

interface Request {
    type: string;
    payload: any;
}

export interface State {
    id: string;
    emailAddress: string;
    name: string;
    phone: string;
    role: string;
    avatar: string;
    isGovernment: boolean;
    orgSymbol: string;
    tpSuperAdmin: boolean;
    scopeRoles: IScopeRoles;
    pageIntroductions: number[];
}

export default (state = initialState, { type, payload }: Request): Record<string, unknown> => {
    switch (type) {
        case GET_USER_STATE: {
            return { ...payload.data, ...state };
        }
        case SAVE_USER_STATE: {
            const { data } = payload;
            const newState = defaults(data, initialState);
            return newState;
        }

        case SAVE_USER_INTRODUCTIONS: {
            const newState = state;
            newState.pageIntroductions = payload.data;
            return newState;
        }

        case UPDATE_USER_STATE: {
            return { ...state, ...payload };
        }
        case DELETE_USER_STATE: {
            return { ...state, user: initialState };
        }
        default:
            return state;
    }
};
