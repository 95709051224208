import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Container, Grid } from '@material-ui/core';
import HorizontalBarchart, { BarchartData } from 'components/charts/HorizontalBarchart';
import GetMoneyFormat from 'components/utilities/GetMoneyFormat';
import { useQuery } from '@apollo/client';
import { qTasks } from 'api/queries';
import { GetTasksByPlanIdFundTypeAndUser } from 'typings/_graphql';
import { IPlanId } from 'typings/Plan';

import './identifyresourcesintroduction/IdentifyResourcesIntroduction.scss';

interface Props {
    closeModal: () => void;
}

const IdentifyResourcesIntroduction = ({ closeModal }: Props): React.ReactElement => {
    const { planId } = useParams<IPlanId>();
    const [chartDataset, setChartDataset] = useState<BarchartData[]>([]);

    const { data: tasks } = useQuery<GetTasksByPlanIdFundTypeAndUser>(qTasks.GET_BY_PLAN_FUND_TYPE_AND_USER, {
        variables: { planId: parseFloat(planId), getByUser: false },
        fetchPolicy: 'network-only',
    });

    const getTotalEstimates = () => {
        let totalEstimated = 0;

        if (tasks?.getTasksByPlanIdFundTypeAndUser) {
            tasks.getTasksByPlanIdFundTypeAndUser.forEach((task) => {
                task.forEach((id) => {
                    totalEstimated += id.estimate || 0;
                });
            });
        }
        return GetMoneyFormat(totalEstimated);
    };

    const getChartDataset = () => {
        const tasksArr: BarchartData[] = [];
        if (tasks) {
            const tasksList = tasks.getTasksByPlanIdFundTypeAndUser.filter((taskArr) => taskArr.length > 0);
            if (tasksList.length > 0) {
                tasksList.forEach((taskList) => {
                    taskList.forEach((task) => {
                        const { fundType } = task;
                        if (fundType) {
                            const taskTypeFound = tasksArr.find((item) => item.label === fundType.name);
                            if (taskTypeFound) {
                                taskTypeFound.value += task.estimate || 0;
                            } else {
                                tasksArr.push({
                                    label: fundType.name,
                                    value: task.estimate || 0,
                                });
                            }
                        }
                    });
                });
            } else {
                setChartDataset([]);
            }

            setChartDataset(tasksArr);
        }
    };

    useEffect(() => {
        getChartDataset();
    }, [tasks]);

    return (
        <Container className="m-identifyResourcesIntroduction" maxWidth="xl">
            <Grid container direction="row" justifyContent="space-between">
                <Grid item xs={12} sm={6}>
                    <div className="m-identifyResourcesIntroduction__head">Resource</div>
                    <div className="m-identifyResourcesIntroduction__headSmall">Identification &amp; allocation</div>
                    <div className="m-identifyResourcesIntroduction__headSub">
                        Realize your vision by applying the right resources the right way
                    </div>

                    <p>
                        In this step, you will <b>identify the resources needed to execute each task in the project</b>{' '}
                        and <b>allocate those resources</b> with means of spending or using the resources.
                    </p>
                    <p>
                        Resources might include government R&D funds, O&M funds, other government (appropriated) funds,
                        commercial investments, labor time, facility time, and/or many other possibilities. Identifying
                        available contract vehicles and conceptualizing new ones will also occur at this step.
                    </p>
                    <Button
                        type="submit"
                        onClick={() => closeModal()}
                        color="primary"
                        variant="contained"
                        className="m-identifyresourcesIntroduction__button"
                    >
                        Identify your Resources!
                    </Button>
                </Grid>

                <Grid item xs={12} sm={6} className="m-identifyResourcesIntroduction__rightColumn">
                    <div className="m-identifyResourcesIntroduction__chartTitle">
                        <div className="m-identifyResourcesIntroduction__chartTitle-head">
                            Total Project Estimated Cost So Far
                        </div>
                        <div className="m-identifyResourcesIntroduction__chartTitle-amount">{getTotalEstimates()}</div>
                    </div>
                    <div className="m-identifyResourcesIntroduction__chart">
                        {chartDataset.length > 0 ? (
                            <HorizontalBarchart dataset={chartDataset} />
                        ) : (
                            <p>No tasks have been estimated</p>
                        )}
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
};

export default IdentifyResourcesIntroduction;
