import React, { ReactElement } from 'react';
import './plandetails/PlanDetails.scss';
import { Plan as PlanType } from 'typings/Plan';

interface Props {
    plan: PlanType;
}
export default function PlanDetails({ plan }: Props): ReactElement {
    return (
        <div className="o-plan">
            <p data-testid="planName" className="o-plan__planName">
                {plan?.name}
            </p>
            <p data-testid="planCode" className="o-plan__planCode">
                {plan?.code}
            </p>
            <p data-testid="planDesc" className="o-plan__description">
                {plan?.description}
            </p>
        </div>
    );
}
