import React, { ReactElement } from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { useKeycloak } from '@react-keycloak/web';

import { createUploadLink } from 'apollo-upload-client';

interface Props {
    children: ReactElement;
}

export const Providers = ({ children }: Props): ReactElement => {
    const isDev = process.env.NODE_ENV === 'development';
    const url = isDev ? 'http://localhost:9000/api' : '/api';
    const { keycloak, initialized } = useKeycloak();
    const token = keycloak?.token || null;
    const apolloClient = new ApolloClient({
        link: createUploadLink({
            uri: url,
            headers: {
                authorization: token ? `Bearer ${token}` : '',
            },
        }),
        cache: new InMemoryCache(),
    });

    if (initialized) {
        return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
    }
    return <div className="screen-initialization">Initializing</div>;
};

export default Providers;
