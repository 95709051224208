import React, { useContext, useEffect, useState } from 'react';
import { Button, Icon, Grid } from '@material-ui/core';
import { sortBy, indexOf } from 'lodash-es';
import { GetTaskGroupsTaskGroupsByPlanIdTasks as Task } from 'typings/_graphql';
import { IUser } from 'typings/User';
import { useSelector } from 'react-redux';
import { Store } from 'store/reducers';
import { sortByAssignee, sortByTaskStatus } from 'components/utilities/sortTaskGroupData';
import AutocompleteDropdown from 'components/AutocompleteDropdown';

import ManageTasksCard from './ManageTasksCard';
import UpdateTaskStatusModal from './listview/UpdateTaskStatusModal';
import UpdateTaskAssigneeModal from './listview/UpdateTaskAssigneeModal';
import UpdateTaskDateModal from './listview/UpdateTaskDateModal';

import PlanContext, { offCanvasProps } from '../PlanContext';

import './listview/ListView.scss';

interface ListViewProps {
    readOnly?: boolean;
    setOffCanvasTaskGroup: (val: offCanvasProps) => void;
}

interface CategoryCardProps {
    name: string;
    tasks: Task[];
}

const ListView = ({ readOnly = false, setOffCanvasTaskGroup }: ListViewProps): React.ReactElement => {
    const { taskGroupData, selectedTaskIds, setSelectedTaskIds } = useContext(PlanContext);
    const [taskAssigneeData, setTaskAssigneeData] = useState<CategoryCardProps[]>([]);
    const [taskStatusData, setTaskStatusData] = useState<CategoryCardProps[]>([]);
    const [filterType, setFilterType] = useState<string>('Task Group');
    const statusSortOrder = ['Not Started', 'In Progress', 'Complete'];
    const [selectedAction, setSelectedAction] = useState<string>('');
    const userData: IUser = useSelector((storeState: Store) => storeState?.User);

    const onFilterChange = (value: string) => {
        if (value === null) {
            setFilterType(filterType);
        } else setFilterType(value);
    };

    useEffect(() => {
        setTaskStatusData(sortByTaskStatus(taskGroupData));
        setTaskAssigneeData(sortByAssignee(taskGroupData));
    }, [taskGroupData]);

    const onTaskSelection = (taskId: number) => {
        if (selectedTaskIds.some((item) => item === taskId)) {
            const filteredArray = selectedTaskIds.filter((item) => item !== taskId);
            setSelectedTaskIds(filteredArray);
        } else {
            setSelectedTaskIds([...selectedTaskIds, taskId]);
        }
    };

    const onBulkActionsSelect = (value: string) => {
        setSelectedAction(value);
    };

    if (taskGroupData.length < 1) {
        return (
            <div className="o-listView__emptyData">
                <h3 className="o-listView__emptyData -header">There are no defined Task Groups.</h3>
                <p className="o-listView__emptyData -message">
                    Please add a Task Group in order to begin creating tasks.
                </p>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setOffCanvasTaskGroup({ canvas: true, editTaskGroup: false })}
                >
                    <Icon>add</Icon> &nbsp;&nbsp;Add A Task Group
                </Button>
            </div>
        );
    }

    return (
        <div className="o-listView">
            <Grid container direction="column">
                <Grid container direction="row" alignItems="flex-end">
                    <Grid item xs={12} sm>
                        <AutocompleteDropdown
                            label="Sort By"
                            defaultValue={'Task Group'}
                            dropdownOptions={['Task Group', 'Assignee', 'Status']}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>, value: string) => onFilterChange(value)}
                        />
                        <UpdateTaskAssigneeModal
                            tasks={selectedTaskIds}
                            open={selectedAction === 'Update Assignee'}
                            afterClick={() => setSelectedAction('')}
                        />
                        <UpdateTaskDateModal
                            tasks={selectedTaskIds}
                            open={selectedAction === 'Date Shift'}
                            afterClick={() => setSelectedAction('')}
                        />
                        <UpdateTaskStatusModal
                            tasks={selectedTaskIds}
                            open={selectedAction === 'Update Status'}
                            afterClick={() => setSelectedAction('')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={`o-listView__bulkActions${selectedTaskIds.length > 1 ? '-active' : ''}`}>
                            <AutocompleteDropdown
                                disabled={selectedTaskIds.length < 2}
                                defaultValue={'Bulk Actions'}
                                value={selectedAction === '' ? 'Bulk Actions' : selectedAction}
                                toolTip={
                                    <div>
                                        Select 2 or more tasks <br /> to use the Bulk Actions
                                    </div>
                                }
                                dropdownOptions={['Update Assignee', 'Date Shift', 'Update Status']}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>, value: string) =>
                                    onBulkActionsSelect(value)
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm className="o-listView__buttons">
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => setOffCanvasTaskGroup({ canvas: true, editTaskGroup: false })}
                        >
                            <Icon>add</Icon> &nbsp;&nbsp;Add A Task Group
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div className="o-listView__taskGroupsContainer">
                        {filterType === 'Task Group' &&
                            sortBy(taskGroupData, [(taskGroup) => taskGroup.id]).map((taskGroup) => (
                                <ManageTasksCard
                                    onTaskSelection={onTaskSelection}
                                    key={taskGroup.id}
                                    tasks={taskGroup.tasks}
                                    taskGroup={taskGroup}
                                    readOnly={readOnly}
                                    label={taskGroup.name}
                                    filterType={filterType}
                                />
                            ))}
                        {filterType === 'Assignee' &&
                            sortBy(taskAssigneeData, ({ name }) => (name === userData.name ? -1 : 1)).map(
                                (assignee) => (
                                    <ManageTasksCard
                                        onTaskSelection={onTaskSelection}
                                        key={assignee.name}
                                        tasks={assignee.tasks}
                                        readOnly={readOnly}
                                        label={assignee.name}
                                        filterType={filterType}
                                    />
                                )
                            )}
                        {filterType === 'Status' &&
                            sortBy(taskStatusData, [(status) => indexOf(statusSortOrder, status.name)]).map(
                                (status) => (
                                    <ManageTasksCard
                                        onTaskSelection={onTaskSelection}
                                        key={status.name}
                                        tasks={status.tasks}
                                        readOnly={readOnly}
                                        label={status.name}
                                        filterType={filterType}
                                    />
                                )
                            )}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default ListView;
