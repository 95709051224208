import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Layouts from 'components/layouts/Layouts';
import PageHeading from 'components/PageHeading';
import { useParams } from 'react-router-dom';
import { IPlanId } from 'typings/Plan';
import { useQuery } from '@apollo/client';
import { qPlans, qAssessTechs } from 'api/queries';
import { PlanSummary, PlanSummaryVariables, GetAssessTechs } from 'typings/_graphql';
// import { PDFDownloadLink } from '@react-pdf/renderer';
// import JustificationPDF from 'components/pdf/JustificationPDF';
import Accordion from './justification/Accordion';
import StakeholderList from './justification/StakeholderList';
import ActivitiesSummary from './justification/ActivitiesSummary';
import FinancialStrategy from './justification/FinancialStrategy';
import IdentifiedDetails from './justification/IdentifiedDetails';

import {
    convertArrayToObject,
    PROBLEM_SUBHEADINGS,
    PROBLEM_STAKEHOLDERS,
    SOLUTION_SUBHEADINGS,
    SOLUTION_HOLDER,
    ParameterArrayObjProps,
} from './justification/JustificationUtils';

import './justification/Justification.scss';

const { Content, Subheader, Sidebar, Navigation } = Layouts;

const Justification = (): React.ReactElement => {
    const [planParametersObj, setPlanParametersObj] = useState<ParameterArrayObjProps>();
    const { planId } = useParams<IPlanId>();

    const {
        data: { planSummary } = {},
        loading,
        error,
        startPolling,
        stopPolling,
    } = useQuery<PlanSummary, PlanSummaryVariables>(qPlans.QUERY_PLAN_SUMMARY, {
        variables: { planId: parseFloat(planId) },
        fetchPolicy: 'no-cache',
    });
    if (error) throw new Error('Could not fetch project summary');

    const { data: assessTechsData, error: assessTechsError } = useQuery<GetAssessTechs>(
        qAssessTechs.QUERY_ASSESS_TECHS,
        {
            variables: { planId: parseFloat(planId) },
        }
    );
    if (assessTechsError) throw new Error("Could not fetch Solution's Readiness Levels");

    // const ExportSummaryButton = () => (
    //     <Button color="primary" variant="outlined">
    //         <PDFDownloadLink document={<JustificationPDF data={planSummary} />} fileName="justification.pdf">
    //             Export Summary
    //         </PDFDownloadLink>
    //     </Button>
    // );

    useEffect(() => {
        startPolling(1000);
        return () => {
            stopPolling();
        };
    }, []);

    useEffect(() => {
        setPlanParametersObj(convertArrayToObject(planSummary?.planParameters));
    }, [planSummary]);

    if (planParametersObj && assessTechsData)
        return (
            <Layouts className="prevent-col-wrap">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Sidebar />
                    <Content>
                        <Navigation />
                        <Subheader>
                            <Grid container>
                                <Grid item xs={9}>
                                    <PageHeading title="Project Summary" />
                                </Grid>
                                {/* <Grid item xs={3} className="o-projectSummary__buttons">
                                <ExportSummaryButton />
                            </Grid> */}
                            </Grid>
                        </Subheader>
                        {loading && 'Plan Summary is loading.'}
                        <Grid className="o-projectSummary__projectDetailsContainer" container direction="column">
                            <Grid item xs={4} md={8}>
                                <p className="o-projectSummary__projectCode">{planSummary?.code}</p>
                            </Grid>
                            <Grid item xs={4} md={8}>
                                <h1>{planSummary?.name}</h1>
                            </Grid>
                            <Grid item xs={4} md={8}>
                                <p className="o-projectSummary__projectDescription">{planSummary?.description}</p>
                            </Grid>
                        </Grid>

                        <Accordion
                            heading="Team Collaborators"
                            icon="people_alt"
                            quantity={planSummary?.scope.scopeRoles.length}
                        >
                            <StakeholderList stakeHolders={planSummary?.scope.scopeRoles} />
                        </Accordion>
                        <Accordion heading="Problem">
                            <IdentifiedDetails
                                planParametersObj={planParametersObj}
                                subheadingList={PROBLEM_SUBHEADINGS}
                                stakeholderList={PROBLEM_STAKEHOLDERS}
                            />
                        </Accordion>
                        <Accordion heading="Solution">
                            <IdentifiedDetails
                                planParametersObj={planParametersObj}
                                subheadingList={SOLUTION_SUBHEADINGS}
                                stakeholderList={[SOLUTION_HOLDER]}
                                assessTechData={assessTechsData?.assessTechs[0]?.currentReadinessResults || {}}
                            />
                        </Accordion>
                        <Accordion heading="Summary of Tasks">
                            <ActivitiesSummary taskGroupsSummary={planSummary?.taskGroups} />
                        </Accordion>
                        <Accordion heading="Financial Strategy">
                            <FinancialStrategy resourceSummary={planSummary?.resources} />
                        </Accordion>
                    </Content>
                </div>
            </Layouts>
        );
    return <div />;
};

export default Justification;
