import { SHOW_MODAL } from '../actions/Modal';
import { Action } from '..';

export interface State {
    show?: boolean;
    title?: string;
    content?: string;
}

const initialState: State = {
    show: false,
    title: '',
    content: '',
};

export default (state = initialState, action: Action): State => {
    const { type, payload } = action;
    switch (type) {
        case SHOW_MODAL: {
            return { ...state, ...payload };
        }
        default:
            return { ...state };
    }
};
