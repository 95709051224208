import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

interface HoverTooltipProps extends React.PropsWithChildren<any> {
    message: React.ReactFragment;
    disableTooltip?: boolean;
}

const HoverTooltip = ({ children, message, disableTooltip = false }: HoverTooltipProps): React.ReactElement => (
    <div className="a-hoverTooltip">
        <Tooltip
            title={message}
            arrow
            placement="top"
            disableHoverListener={disableTooltip}
            disableFocusListener={disableTooltip}
            disableTouchListener={disableTooltip}
        >
            {children}
        </Tooltip>
    </div>
);

export default HoverTooltip;
