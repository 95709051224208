import React from 'react';
import { Route } from 'react-router-dom';
import useIntroduction from 'components/utilities/useIntroduction';
import { useSelector } from 'react-redux';
import { Store } from 'store/reducers';

const PrivateRoute: React.ComponentType<any> = ({
    component: Component,
    introduction: Introduction = false,
    stepId = 0,
    ...rest
}) => {
    const { viewIntroduction } = useIntroduction();
    const introArray = useSelector((storeState: Store) => storeState.User.pageIntroductions) || [];

    if (Introduction) {
        return (
            <>
                {viewIntroduction({
                    introArray,
                    stepId,
                    intro: (
                        <Route {...rest} render={(props) => <Introduction stepId={stepId} {...props} {...rest} />} />
                    ),
                    page: <Route {...rest} render={(props) => <Component {...props} {...rest} />} />,
                })}
            </>
        );
    }
    return (
        <>
            <Route {...rest} render={(props) => <Component {...props} {...rest} />} />
        </>
    );
};

export default PrivateRoute;
