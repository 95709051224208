import React, { useEffect, useState } from 'react';
import Utils, { UtilsProps } from './usegantt/utils';
import Dates, { DatesProps, RangeProps } from './usegantt/dates';
import Views, { ViewsProps } from './usegantt/views';
import Data, { TaskGroupDataModel, TaskModel, DataProps } from './usegantt/data';
import Enums from './utils/enums';

type tGroupBy = 'start' | 'end' | 'taskId' | 'taskGroupId' | 'assignee' | 'progress';
export interface GanttFunctions extends DataProps, UtilsProps, DatesProps, ViewsProps {
    /* Utils */
    groupBy: tGroupBy;
    groupByHandler: (key: 'start' | 'end' | 'taskId' | 'taskGroupId' | 'assignee' | 'progress') => void;
    timeBy: 'weeks' | 'months' | 'years';
    cacheBufferSize: number;
    cacheCeiling: number;
    timeScaleString: string;
    isZeroState: boolean;
    columnIndexObj: { [index: string]: number };
    /* State */
    today: Date;
    dayInMiliseconds: number;
    range: { start: Date; end: Date }; // verified
    data: TaskGroupDataModel[]; // verified
    displayMonthRange: RangeProps; // verified
    milisecondWidth: number; // verified
    scaleData: number; // verified
    displayWidth: number; // verified
    sideBarWidth: number;
    references: { [key: string]: Element | null };
    addReference: (name: string[]) => void;
    tabularData: (string | number)[][];
    tasks: TaskModel[];
    taskGroups: TaskGroupDataModel[];
    timelineWidth: number;
    /* Setters */
    changeItemRange: () => void;
    setTimeScaleString: (str: string) => void;
    setTimeBy: (key: 'weeks' | 'months' | 'years') => void;
    setCacheCeiling: (ceiling: number) => void;
    setRange: (value: { start: Date; end: Date }) => void; // verified
    setRawData: (value: TaskGroupDataModel[]) => void; // verified
    setDisplayMonthRange: (value: RangeProps) => void; // verified
    setMilisecondWidth: (value: number) => void; // verified
    setScaleData: (val: number) => void; // verified
    setDisplayWidth: (val: number) => void; // verified
    setSideBarWidth: (val: number) => void;
    setIsZeroState: (val: boolean) => void;
    /* Getters */
    getTimeList: () => number[];
    getDateList: (timeScaleStringOveride?: string) => string[];
    getBusinessDayCount: (start: string, end: string) => number;
    getMonthOfDays: (mod: number) => number[];
    getMonthLong: (m: number | string) => string;
    getMonYear: (date: string) => string;
    ganttSetup: (newWidth: number, newDisplayMonthRange: RangeProps) => void;
    getMilisecondWidth: () => number;
}

const emptyTask: TaskModel = {
    start: '',
    end: '',
    title: '',
    status: 0,
    id: '',
    parents: [],
};

const useGantt = (): GanttFunctions => {
    const today = new Date();
    const dayInMiliseconds = 86400000;
    const cacheBufferSize = 100;
    const columnIndexObj: { [index: string]: number } = {
        taskId: 0,
        taskGroupId: 1,
        assignee: 2,
        start: 3,
        end: 4,
        progress: 5,
    };
    /*
      rawData is formerly known as data.
      this is, well... the raw data coming in.
      once this is pulled in, it splits out into:
        tasks,
        taskGroups,
        tabularData
      once tabularData is complete, setNewData fires
      data is now set by setNewData
      getNewDataModel is the function that allows us to regroup data
    */
    const [timeBy, setTimeBy] = useState<'weeks' | 'months' | 'years'>('months');
    const [groupBy, groupByHandler] = useState<'start' | 'end' | 'taskId' | 'taskGroupId' | 'assignee' | 'progress'>(
        'taskGroupId'
    );
    const [isAscending /* setIsAscending */] = useState<1 | -1>(1);
    const [rawData, setRawData] = useState<TaskGroupDataModel[]>([]);
    const [data, setNewData] = useState<TaskGroupDataModel[]>([]);
    const [tabularData, setTabularData] = useState<(string | number)[][]>([]);
    const [tasks, setTasks] = useState<TaskModel[]>([]);
    const [taskGroups, setTaskGroups] = useState<TaskGroupDataModel[]>([]);
    const [cacheCeiling, setCacheCeiling] = useState<number>(cacheBufferSize);

    const [isZeroState, setIsZeroState] = useState(false);
    const [earliestDate] = useState(today.setMonth(today.getMonth() - 1));
    const [latestsDate] = useState(today.setMonth(today.getMonth() + 1));
    const [range, setRange] = useState({ start: new Date(earliestDate), end: new Date(latestsDate) });
    const [displayWidth, setDisplayWidth] = useState(1);
    const [sideBarWidth, setSideBarWidth] = useState(250);
    const [timelineWidth, setTimelineWidth] = useState(displayWidth - sideBarWidth);
    const [displayMonthRange, setDisplayMonthRange] = useState<RangeProps>({
        start: new Date(today.getFullYear(), today.getMonth(), 1),
        end: new Date(today.getFullYear(), today.getMonth() + 1, 0),
    });
    const [milisecondWidth, setMilisecondWidth] = useState(0);
    const [scaleData, setScaleDataHandler] = useState(1);
    const [timeScaleString, setTimeScaleString] = useState(Enums.TimeBuckets[1]);
    const [references, referencesHandler] = useState({});

    useEffect(() => {
        setTimelineWidth(displayWidth - sideBarWidth);
    }, [sideBarWidth, displayWidth]);

    const addReference = (names: string[]) => {
        const out: any = {};
        names.forEach((n: string) => {
            out[n] = React.createRef();
        });
        referencesHandler(out);
    };

    const getVectorOfTasks = (): TaskModel[] => {
        const out: TaskModel[] = [];
        rawData.forEach((d: TaskGroupDataModel) => {
            if (d.children.length === 0) {
                out.push({ ...emptyTask, taskGroupId: d.id });
            } else {
                d.children.forEach((task: TaskModel) => out.push({ ...task, taskGroupId: d.id }));
            }
        });

        return out;
    };

    const setScaleData = (val: number) => setScaleDataHandler(milisecondWidth / (val * displayWidth));

    const getTimeList = (): number[] => {
        const out = [];
        const monthInMili = Dates.miliConversions(Enums.TimeBuckets[2]);
        for (
            let i = new Date(displayMonthRange.start).getTime();
            i <= new Date(displayMonthRange.end).getTime();
            i += monthInMili
        ) {
            out.push(new Date(i).getMonth() + 1);
        }
        return out;
    };

    function yearDiff(monthRange: number[]): string[] {
        const dateFrom = new Date(monthRange[0]).getTime();
        const dateTo = new Date(monthRange[1]).getTime();
        const out = [];
        const miliCnt = Dates.miliConversions('day');
        for (let i = dateFrom; i <= dateTo; i += miliCnt) {
            out.push(new Date(i).getFullYear().toString());
        }

        return out.reduce((acc: string[], b: string) => (acc.indexOf(b) === -1 ? [...acc, b] : acc), []);
    }

    function monthDiff(monthRange: number[]): string[] {
        const dateFrom = new Date(monthRange[0]).getTime();
        const dateTo = new Date(monthRange[1]).getTime();
        const out = [];
        for (let i = dateFrom; i <= dateTo; i += 86400000) {
            out.push(new Date(i).toLocaleDateString().replace(/\/.*\//g, '/1/'));
        }
        return out.reduce((acc: string[], b: string) => (acc.indexOf(b) === -1 ? [...acc, b] : acc), []);
    }

    const getDateList = (timeScaleStringOveride?: string): string[] => {
        const out = []; // Output an array of formatted date strings
        const listTimeScaleString = !!timeScaleStringOveride ? timeScaleStringOveride : timeScaleString;
        const monthInMili = Dates.miliConversions(listTimeScaleString);
        switch (listTimeScaleString) {
            case Enums.TimeBuckets[3]: {
                return yearDiff(Object.values(displayMonthRange));
                break;
            }
            case Enums.TimeBuckets[2]: {
                const r = monthDiff(Object.values(displayMonthRange));
                for (const rDiff of r) {
                    let dateStr = '';
                    dateStr = new Date(rDiff)
                        .toLocaleDateString('en', { year: 'numeric', month: 'short' })
                        .split('/')
                        .reverse()
                        .join(' ');
                    out.push(dateStr);
                }
                break;
            }
            case Enums.TimeBuckets[1]:
                for (
                    let i = new Date(displayMonthRange.start).getTime();
                    i < new Date(displayMonthRange.end).getTime();
                    i += monthInMili
                ) {
                    let dateStr = '';
                    dateStr = new Date(i).toLocaleDateString();
                    out.push(dateStr);
                }
                break;
            case Enums.TimeBuckets[0]:
            default:
                for (
                    let i = new Date(displayMonthRange.start).getTime();
                    i < new Date(displayMonthRange.end).getTime();
                    i += monthInMili
                ) {
                    let dateStr = '';
                    dateStr = new Date(i)
                        .toLocaleDateString('en', { day: 'numeric', month: 'short' })
                        .split('/')
                        .reverse()
                        .join(' ');
                    out.push(dateStr);
                }
        }
        return out;
    };

    const getMilisecondWidth = () => milisecondWidth;

    const getBusinessDayCount = (start: string, end: string): number =>
        Math.floor((new Date(end).getTime() - new Date(start).getTime()) / dayInMiliseconds);

    const getMonthOfDays = (mod = 2): number[] => {
        const out = [];
        const tList = getTimeList();
        for (const tListItem of tList) {
            const daysInM = Dates.daysInMonth(tListItem, displayMonthRange.start.getUTCFullYear());
            for (let j = 0; j < daysInM; j++) {
                if (j % mod === 0) {
                    out.push(j + 1);
                }
            }
        }
        return out;
    };

    const getMonthLong = (m: number | string): string =>
        new Date(`${m}/1/1981`).toLocaleDateString('en', { month: 'long' });

    const getMonYear = (date: string): string =>
        new Date(date).toLocaleDateString('en', { month: 'short', year: 'numeric' });

    const ganttSetup = (newWidth: number, newDisplayMonthRange: RangeProps | null = null): void => {
        let newDisplayRange;
        if (isZeroState) {
            setDisplayMonthRange({
                start: new Date(today.getFullYear(), today.getMonth(), 1),
                end: new Date(today.getFullYear(), today.getMonth() + 1, 0),
            });
            return;
        }
        if (!newDisplayMonthRange) {
            newDisplayRange = {
                start: Dates.firstOfTheMonth(new Date(range.start)),
                end: Dates.lastOfTheMonth(new Date(range.end)),
            };
        } else {
            newDisplayRange = {
                start: Dates.firstOfTheMonth(new Date(newDisplayMonthRange.start)),
                end: Dates.lastOfTheMonth(new Date(newDisplayMonthRange.end)),
            };
        }

        const msWidth = Math.abs(newDisplayRange.end.getTime() - newDisplayRange.start.getTime());
        setDisplayMonthRange(newDisplayRange);
        setMilisecondWidth(msWidth);
        setScaleDataHandler(msWidth / newWidth);
        setDisplayWidth(newWidth);
    };

    const changeItemRange = () => {
        // TEST
        setRawData([
            ...rawData,
            {
                ...rawData[0],
                children: [
                    ...rawData[0].children,
                    {
                        ...rawData[0].children[0],
                        end: new Date(
                            new Date(rawData[0].children[0].end)
                                .toLocaleDateString()
                                .split('/')
                                .map((a, i) => (i === 0 ? a + 1 : a))
                                .join('/')
                        ).toLocaleDateString(),
                    },
                ],
            },
        ]);
    };

    useEffect(() => {
        /*
          This basically breaks the tasks out into:
            a Task array
            a TaskGroup array
            a simple Matrix
        */
        setTasks(getVectorOfTasks());
        setTaskGroups(Utils.getVectorOfTaskGroups(rawData));
    }, [rawData]);

    useEffect(() => {
        const k = columnIndexObj[groupBy];

        setTabularData(
            Data.getTabularizeTaskGroupDataModelArr().sort((a: string | number[], b: string | number[]) => {
                /*
                  Using a switch for better sort handling in the future
                */
                switch (groupBy) {
                    case 'taskGroupId':
                    case 'taskId':
                    case 'start':
                    case 'end':
                    case 'assignee':
                    case 'progress':
                    default: {
                        const sortOut = a[k] < b[k] ? -1 : 1;
                        return sortOut * isAscending;
                    }
                }
            })
        );
    }, [taskGroups, groupBy]);

    useEffect(() => {
        const tempData = Data.getNewDataModel(groupBy);
        setNewData(tempData);
    }, [tabularData, groupBy]);

    return {
        /* Utils */
        ...Utils,
        ...Dates,
        ...Views,
        ...Data,
        timeBy,
        groupBy,
        groupByHandler,
        cacheBufferSize,
        cacheCeiling,
        timeScaleString,
        isZeroState,
        columnIndexObj,
        /* State */
        today,
        dayInMiliseconds,
        range,
        data,
        references,
        addReference,
        // rawData,
        displayMonthRange,
        milisecondWidth,
        scaleData,
        displayWidth,
        sideBarWidth,
        tabularData,
        tasks,
        taskGroups,
        timelineWidth,
        /* Getters */
        getTimeList,
        getDateList,
        getBusinessDayCount,
        getMonthOfDays,
        getMonthLong,
        getMonYear,
        ganttSetup,
        getMilisecondWidth,
        /* Setters */
        changeItemRange,
        setTimeScaleString,
        setTimeBy,
        setCacheCeiling,
        setRange,
        setRawData,
        setDisplayMonthRange,
        setMilisecondWidth,
        setScaleData,
        setDisplayWidth,
        setSideBarWidth,
        setIsZeroState,
    };
};

export default useGantt;
