import { gql } from 'apollo-boost';

const GET_USER = gql`
    query getUser {
        user {
            id
            emailAddress
            name
            phone
            planRoles {
                planRole
            }
            avatar
            isGovernment
            orgSymbol
            tpSuperAdmin
            dutyTitle
            organization {
                id
                name
            }
            pageIntroductions {
                planStepsId
            }
            scopeRoles {
                id
                scopes {
                    id
                }
                roles {
                    name
                    role_rolecapabilities {
                        opperation
                        roleCapabilities {
                            functionName
                        }
                    }
                }
            }
        }
    }
`;

const GET_USERS = gql`
    query getUsers {
        users {
            id
            emailAddress
            name
            phone
            planRoles {
                planRole
            }
            avatar
            isGovernment
            orgSymbol
            tpSuperAdmin
        }
    }
`;

const UPDATE_USER = gql`
    mutation UpdateUserById($id: String!, $data: UpdateUserInput!) {
        updateUserById(id: $id, data: $data) {
            id
            emailAddress
            name
            phone
            avatar
            isGovernment
            orgSymbol
            tpSuperAdmin
        }
    }
`;

const GET_USERS_BY_EMAIL = gql`
    query getUsersByEmail($searchString: String!) {
        usersByEmail(searchString: $searchString) {
            id
            emailAddress
            name
            phone
            planRoles {
                planRole
            }
            organization {
                id
                name
            }
            dutyTitle
            avatar
        }
    }
`;

const GET_USERS_BY_PLAN = gql`
    query getUsersByPlan($planId: Float!) {
        usersByPlan(planId: $planId) {
            id
            emailAddress
            name
            planRoles {
                planRole
            }
            organization {
                id
                name
            }
            avatar
            dutyTitle
            phone
            invitation {
                status
            }
            phoneType
            scopeRoles {
                id
                scopes {
                    id
                }
            }
        }
    }
`;

const GET_USERS_BY_ROLE = gql`
    query getUsersByRole($planId: Float!, $role: String!) {
        usersByRole(planId: $planId, role: $role) {
            id
            name
            emailAddress
            avatar
        }
    }
`;

const GET_USERS_AND_RESOURCES = gql`
    query getUsersAndResources($planId: Float!) {
        usersAndResources(planId: $planId) {
            id
            name
            avatar
            resources {
                id
                name
                amount
                startDate
                dueDate
                description
                fundType {
                    id
                    name
                }
                resourceType {
                    id
                    name
                }
                status {
                    id
                    status
                }
                contracts {
                    id
                    title
                    isActive
                    contractNumber
                    TpocFirstName
                    TpocLastName
                }
            }
        }
    }
`;

export {
    UPDATE_USER,
    GET_USER,
    GET_USERS,
    GET_USERS_BY_EMAIL,
    GET_USERS_BY_ROLE,
    GET_USERS_BY_PLAN,
    GET_USERS_AND_RESOURCES,
};
