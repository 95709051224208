import React, { ReactElement, useState } from 'react';
import { Icon } from '@material-ui/core';
import usePalette from 'components/utilities/usePalette';
import Avatar from './avatar/Avatar';
import Tooltip from './Tooltip';
import GanttTTBody from './GanttTTBody';
import { TaskModel } from '../Data';
import Enums from '../utils/enums';
import './GanttItem.scss';

interface GanttItemProps extends TaskModel {
    index: number;
    template?: number;
    groupIndex: number;
    draw: boolean;
    callback?: (taskId: number, status: any) => void;
}

const varient = (template: number) => {
    switch (template) {
        case 0:
            return '-rounded';
            break;
        case 1:
            return '-smoothed';
            break;
        case 2:
            return '-boxed';
            break;
        default:
            return '-transparent';
    }
};

export default ({ index, template = 0, groupIndex, draw, callback, ...props }: GanttItemProps): ReactElement => {
    const [isSmall, smallHandler] = useState<boolean | null | undefined>(false);
    let itemWidth = 100;
    const color = usePalette();
    const templateStyle = varient(template);

    const pos = {
        x: 40,
        y: 0,
        width: 260,
        height: 30,
    };

    const smallChecker = (id: string): void => {
        setTimeout(() => {
            const item = document.getElementById(`${id}`);
            if (!item?.firstElementChild?.scrollWidth) return;

            const elementWidth = item?.firstElementChild?.scrollWidth > item.offsetWidth;

            if (elementWidth !== isSmall) {
                smallHandler(elementWidth);
                itemWidth = item?.firstElementChild?.scrollWidth;
            }
        }, 100);
    };

    return draw ? (
        <button
            className="ganttItem"
            id={`${props.id}-gantt-container`}
            onClick={() =>
                callback ? callback(+props.id, props.progress) : console.log('No callback function provided.')
            }
        >
            {smallChecker(`${props.id}-gantt-container`)}
            {isSmall ? (
                <div className="Milestone__container" id={`${props.id}-gantt-container`}>
                    <Tooltip body={<GanttTTBody index={index} {...props} />}>
                        <svg>
                            <rect
                                x="0"
                                width="40"
                                id="shit"
                                height="30"
                                rx={16}
                                style={{ fill: !!props.color ? props.color : color(groupIndex) }}
                                className="fo_wrapper-rounded"
                            />
                            <foreignObject className="ganttBarForeignObject" {...pos}>
                                <div className="fo_wrapper" style={{ marginLeft: 4 }}>
                                    <div className="fo_wrapper__textwrapper">
                                        <Icon>{Enums.StatusCodesIcons[props.progress]}</Icon>
                                        <div className="fo_wrapper">
                                            {props.progress}
                                            {!!props.users && props.users.length > 0 && (
                                                <div style={{ top: -20, position: 'relative' }}>
                                                    <Avatar
                                                        users={props.users}
                                                        hideTooltip={false}
                                                        offsetRight={-36}
                                                        absolute
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </foreignObject>
                        </svg>
                    </Tooltip>
                </div>
            ) : (
                <>
                    <Tooltip body={<GanttTTBody index={index} {...props} />}>
                        <div
                            className={['ganttItem__item', templateStyle].join(' ')}
                            style={{ background: !!props.color ? props.color : color(groupIndex) }}
                        >
                            <div className="center-align">
                                <Icon>{Enums.StatusCodesIcons[props.progress]}</Icon>
                                <div className="margin-left">{props.progress}</div>
                            </div>
                        </div>
                    </Tooltip>
                    {!!props.users && props.users.length > 0 && (
                        <Avatar users={props.users} hideTooltip={false} absolute />
                    )}
                </>
            )}
        </button>
    ) : (
        <div className={['ganttItem__item_empty', templateStyle].join(' ')} style={{ background: color(groupIndex) }} />
    );
};
