import React, { useEffect, useState } from 'react';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import { LocalizationProvider } from '@material-ui/pickers';
import CustomInput from 'components/CustomInput';
import DoubleDatePicker from 'components/DoubleDatePicker';
import Permission from 'components/Permission';
import getDateFormat from 'components/utilities/GetDateFormat';
import { getDurationMinusWeekends, getDateRangeByDuration } from 'components/utilities/DateUtils';

interface Props {
    readOnly?: boolean;
    hideDuration?: boolean;
    startLabel?: string;
    endLabel?: string;
    formHooks?: any;
}

const Dates = ({
    readOnly = false,
    hideDuration = false,
    startLabel = 'Start Date',
    endLabel = 'End Date',
    formHooks,
}: Props): React.ReactElement => {
    const { watch, setValue } = formHooks;
    const [duration, setDuration] = useState(watch('duration'));
    const label = 'Set Date';

    const changeDuration = (e: any) => {
        if (watch('startDate') === null) return;
        const newDuration = e.target.value < 1 ? 1 : e.target.value;
        if (e.target.value === '') {
            setDuration('');
            return;
        }
        const newEndDate = getDateRangeByDuration(watch('startDate'), newDuration, watch('duration') > newDuration);
        setValue('duration', newDuration, { shouldDirty: true });
        setDuration(newDuration);
        setValue('dueDate', newEndDate, { shouldDity: true });
    };

    useEffect(() => {
        setDuration(getDurationMinusWeekends(watch('startDate'), watch('dueDate')));
    }, [watch('startDate'), watch('dueDate')]);

    const ShowReadOnly = () => (
        <div className="o-taskform__readOnlyContainer">
            <div className="o-taskform__dateContainer">
                {watch('startDate') ? (
                    <>
                        <div>
                            <div className="label">Due Date</div>
                            <div className="value">{getDateFormat(watch('startDate'))}</div>
                        </div>
                        {watch('isDateRange') && (
                            <>
                                <div>
                                    <div className="label">{endLabel}</div>
                                    <div className="value">{getDateFormat(watch('dueDate'))}</div>
                                </div>
                                {!hideDuration && (
                                    <div>
                                        <div className="label">Duration</div>
                                        <div className="value">{watch('duration')} Business Days</div>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <span>No date selected</span>
                )}
            </div>
        </div>
    );

    return (
        <Permission auth={`${readOnly} === false`} showFail={<ShowReadOnly />}>
            <div className="o-taskform__dateContainer">
                <LocalizationProvider dateAdapter={DateFnsAdapter}>
                    <div className="DoubleDatePicker__inputsContainer">
                        <DoubleDatePicker
                            label={label}
                            startLabel={startLabel}
                            endLabel={endLabel}
                            formHooks={formHooks}
                        />
                        {watch('isDateRange') && (
                            <>
                                {!hideDuration && (
                                    <div className="DoubleDatePicker__duration">
                                        <CustomInput
                                            inputLabel="Duration"
                                            name="duration"
                                            type="number"
                                            onChange={changeDuration}
                                            value={duration}
                                            disabled={!watch('startDate')}
                                        />

                                        <div className="DoubleDatePicker__duration__businessDays">Business Days</div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </LocalizationProvider>
            </div>
        </Permission>
    );
};

export default Dates;
