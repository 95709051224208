import React, { useState } from 'react';
import { FormControl, InputBase, InputLabel, Select, StandardTextFieldProps, FormHelperText } from '@material-ui/core';
import { Controller, Control, DeepMap, FieldError } from 'react-hook-form';

import './customselect/CustomSelect.scss';
import './CustomMultiSelect.scss';

export interface CustomSelectProps extends StandardTextFieldProps {
    inputLabel?: string;
    helperText?: string | React.ReactNode;
    name: string;
    className?: string;
    errormessage?: string;
    required?: boolean;
    control: Control;
    errors?: DeepMap<Record<string, any>, FieldError>;
    placeholder?: string;
    defaultValue?: number;
    watch?: any;
    disabled?: boolean;
    renderValue?: (selected: any) => any;
    onChangeHandler?: (event: any) => void;
}

const CustomMultiSelect = ({
    children,
    inputLabel,
    helperText,
    name,
    className,
    errormessage,
    errors = {},
    control,
    defaultValue,
    watch,
    required = false,
    disabled = false,
    renderValue,
    onChangeHandler,
    ...props
}: CustomSelectProps): React.ReactElement => {
    const flagError = errors[name];
    const [isOpen, setIsOpen] = useState(false);

    return (
        <section className={`o-customSelect o-customSelect__multi ${className}`}>
            <FormControl fullWidth variant="outlined" disabled={disabled}>
                <div className="o-customSelect__inputLabel">
                    <InputLabel id="taskGroup" error={!!flagError}>
                        {required && '*'}
                        {inputLabel}
                    </InputLabel>
                </div>
                {helperText && <FormHelperText data-testid="helper-text">{helperText}</FormHelperText>}
                <Controller
                    control={control}
                    name={name}
                    defaultValue={defaultValue}
                    rules={{ required }}
                    disabled={disabled}
                    render={({ value, onChange }) => (
                        <Select
                            disabled={disabled}
                            id={name}
                            defaultValue={defaultValue}
                            label={name}
                            multiple
                            open={isOpen}
                            onOpen={() => setIsOpen(true)}
                            onClose={() => setIsOpen(false)}
                            inputRef={props.inputRef}
                            labelId={name}
                            error={!!flagError}
                            onChange={(e) => {
                                onChange(e);
                                if (onChangeHandler) onChangeHandler(e);
                            }}
                            value={value || watch}
                            input={<InputBase />}
                            renderValue={renderValue}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                            }}
                        >
                            {children}
                        </Select>
                    )}
                />
                {flagError && <FormHelperText error>{errormessage}</FormHelperText>}
            </FormControl>
        </section>
    );
};

export default CustomMultiSelect;
