/* eslint no-param-reassign: 'off' */
/* eslint no-bitwise: 'off' */
/* eslint prefer-destructuring: 'off' */
import React, { useState, useContext, useEffect } from 'react';
import PlanContext from 'pages/plans/PlanContext';
import { GetTaskGroupsTaskGroupsByPlanIdTasks, GetTaskGroupsTaskGroupsByPlanId } from 'typings/_graphql';
import usePalette from '../../utilities/usePalette';
import Avatar from '../templateItems/avatar/Avatar';
import ganttState from '../ganttState';
import { TaskGroupDataModel, TaskModel } from '../Data';
import Enums from '../utils/enums';

interface SideBarRowProps {
    data: TaskModel | TaskGroupDataModel;
    isHeader?: boolean;
    groupIndex?: number;
    readOnly?: boolean;
    isOpen?: boolean;
    id?: string;
}

const typeCast = (data: any): GetTaskGroupsTaskGroupsByPlanIdTasks => ({
    dependees: [],
    dependencies: [],
    description: data.description,
    dueDate: data.end,
    duration: 0,
    estimate: -3,
    flagged: null,
    isDateRange: false,
    flaggedMessage: null,
    fundType: data.fundType,
    id: data.id,
    name: data.title,
    startDate: data.start,
    status: Enums.StatusCodes[data.status],
    taskResourceAssoc: [],
    users: data.users,
});

const SideBarRow = ({
    data,
    isHeader,
    groupIndex = 0,
    readOnly = false,
    isOpen,
    id,
}: SideBarRowProps): React.ReactElement => {
    console.log(data.title, data);
    const { setOffCanvasTask } = useContext(PlanContext);
    const color = usePalette();
    const { taskGroupId } = data;
    const [taskTaskGroup] = useState<GetTaskGroupsTaskGroupsByPlanId>();
    const isEmptyRow = Object.keys(data).length === 7;
    const [isSelected, selectionHandler] = useState(false);

    let background = '';
    if (ganttState.Gantt.isZeroState) {
        background = 'transparent';
    } else if (!!data.color) {
        background = data.color;
    } else {
        background = color(groupIndex);
    }

    const getId = () => {
        if (taskGroupId) return ~~taskGroupId;
        if (taskTaskGroup?.id) return ~~taskTaskGroup.id;
        return 0;
    };

    const editTask = () => {
        setTimeout(() => {
            selectionHandler(true);
        }, 100);
        if (!readOnly) {
            setOffCanvasTask({
                canvas: !isEmptyRow,
                editTask: typeCast(data),
                taskGroupId: getId(),
            });
        }
    };

    const trigger = () => {
        selectionHandler(false);
    };

    useEffect(() => {
        const root = document.getElementById('root');
        if (root) {
            root.addEventListener('click', trigger);
        }
        return () => root?.removeEventListener('click', trigger);
    }, [0]);

    const isDisabled = () => (readOnly ? ' disabled' : '');

    if (ganttState.Gantt.isZeroState) {
        return isHeader ? (
            <div className={'sidebarRow-item -zero'}>
                {isOpen && <div>There are currently no defined Task Groups</div>}
            </div>
        ) : (
            <div />
        );
    }

    return isHeader ? (
        <div className={'sidebarRow__header -vertical-align'} id={id} style={{ background }}>
            {ganttState.Gantt.groupBy === 'assignee' && (
                <button
                    onClick={editTask}
                    className={`-vertical-align${isDisabled()}`}
                    style={{ marginRight: 8, textAlign: 'left' }}
                >
                    {data.id && data.children && data.children[0] && (
                        <Avatar users={data.children[0].users} hideTooltip />
                    )}
                </button>
            )}
            <div>{data.title}</div>
        </div>
    ) : (
        <div className={`sidebarRow-item -vertical-align truncate${isSelected ? ' -selected' : ''}`} id={id}>
            <button onClick={editTask} className={`truncate-child${isDisabled()}`}>
                {data.title}
            </button>
        </div>
    );
};

export default SideBarRow;
