import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, Table, TableCell, TableHead, TableBody } from '@material-ui/core';
import { GetTaskGroupsTaskGroupsByPlanId, GetTaskGroupsTaskGroupsByPlanIdTasks } from 'typings/_graphql';
import { TaskWithTaskGroup } from 'components/utilities/sortTaskGroupData';
import PlanContext from '../../PlanContext';
import TaskListItem from './TaskListItem';

interface Props {
    tasks: readonly TaskWithTaskGroup[] | null;
    taskGroup?: GetTaskGroupsTaskGroupsByPlanId;
    filterType: string;
    onTaskSelection?: any;
}

const TaskGroupTable = ({ tasks, taskGroup, onTaskSelection, filterType }: Props): React.ReactElement => {
    const { selectedTaskIds, setSelectedTaskIds } = useContext(PlanContext);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const statusPrivileges = ['Task Group', 'Assignee'];
    const taskGroupPrivileges = ['Assignee', 'Status'];

    const onSelectAllTasks = () => {
        if (tasks) {
            const taskIds = tasks?.map((task) => task.id);
            if (!isChecked) {
                const filteredTasks = selectedTaskIds.filter((id) => !taskIds.includes(id));
                setSelectedTaskIds([...filteredTasks, ...taskIds]);
                setIsChecked(true);
            } else {
                const filteredTasks = selectedTaskIds.filter((id) => !taskIds.includes(id));
                setSelectedTaskIds([...filteredTasks]);
                setIsChecked(false);
            }
        }
    };

    useEffect(() => {
        if (tasks) {
            const taskIds = tasks.map((task) => task.id);
            const allTasksChecked = taskIds.every((taskId) => selectedTaskIds.includes(taskId));
            setIsChecked(allTasksChecked);
        }
    }, [tasks, selectedTaskIds]);

    return (
        <div className="o-manageTasksCard__taskGroupTable">
            <Table>
                <TableHead>
                    <TableCell width="38%">
                        <Checkbox
                            checked={tasks !== null && tasks.length > 0 && isChecked}
                            inputProps={{ 'aria-label': 'Select all tasks checkbox' }}
                            onClick={onSelectAllTasks}
                        />
                        Task Name
                    </TableCell>
                    <TableCell width="10%" align="center">
                        {filterType === 'Status' && <>Assignee</>}
                        {statusPrivileges.includes(filterType) && <>Status</>}
                    </TableCell>
                    <TableCell width="10%" align="center">
                        {filterType === 'Task Group' && <>Assignee</>}
                        {taskGroupPrivileges.includes(filterType) && <>Task Group</>}
                    </TableCell>
                    <TableCell width="12%" align="center">
                        Estimate
                    </TableCell>
                    <TableCell width="15%" align="center">
                        Date
                    </TableCell>
                    <TableCell width="15%" align="center">
                        Dependent On
                    </TableCell>
                </TableHead>
                <TableBody>
                    {tasks?.map((task: GetTaskGroupsTaskGroupsByPlanIdTasks) => (
                        <TaskListItem
                            onTaskSelection={onTaskSelection}
                            task={task}
                            key={task.id}
                            taskGroup={taskGroup}
                            filterType={filterType}
                        />
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default TaskGroupTable;
