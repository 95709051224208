/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect, createContext } from 'react';
import { GetFundTypes, GetFundTypesFundTypes, GetResourceTypesResourceTypes, GetResourceTypes } from 'typings/_graphql';
import { useQuery } from '@apollo/client';
import { qFundTypes, qResourceTypes } from 'api/queries';
import { useParams } from 'react-router-dom';

export interface offCanvasProps {
    canvas: boolean;
    editResource: GetResourceTypesResourceTypes | false;
    userId?: string | number;
    resourceId?: number;
    resourceTypeId?: string | number;
}

export interface ResourceContextProps {
    offCanvasResource: offCanvasProps;
    setOffCanvasResource: (val: offCanvasProps) => void;
    resourcesData: readonly GetResourceTypesResourceTypes[];
    fundTypes: readonly GetFundTypesFundTypes[];
    resourceTypes: readonly GetResourceTypesResourceTypes[];
    resourceDetails?: GetResourceTypesResourceTypes | null;
}

export const ResourceContext = createContext<ResourceContextProps>({
    offCanvasResource: { canvas: false, editResource: false, resourceTypeId: 0 },
    setOffCanvasResource: () => {},
    resourcesData: [],
    fundTypes: [],
    resourceTypes: [],
    resourceDetails: null,
});

export const ResourceConsumer = ResourceContext.Consumer;

export const ResourceProvider = (props: any): React.ReactElement | any => {
    const [offCanvasResource, setOffCanvasResource] = useState<offCanvasProps>({
        canvas: false,
        editResource: false,
        userId: '',
        resourceId: 0,
        resourceTypeId: 0,
    });

    const [resourceDetails, setResourceDetails] = useState<GetResourceTypesResourceTypes | null | undefined>(null);
    const planId = parseFloat(useParams<{ planId: string }>().planId);

    const { data: resourcesData } = useQuery<GetResourceTypes>(qResourceTypes.QUERY_WITH_RESOURCES, {
        variables: { planId },
    });

    const { data: fundTypes } = useQuery<GetFundTypes>(qFundTypes.QUERY_ALL);

    const { data: resourceTypes } = useQuery<GetResourceTypes>(qResourceTypes.QUERY_ALL);

    useEffect(() => {
        const currentResource = resourcesData?.resourceTypes?.find(
            (resource) => resource.id === offCanvasResource.resourceTypeId
        );
        setResourceDetails(currentResource);
    }, [offCanvasResource?.resourceTypeId]);

    return (
        <ResourceContext.Provider
            value={{
                setOffCanvasResource,
                offCanvasResource,
                resourcesData: resourcesData?.resourceTypes || [],
                fundTypes: fundTypes?.fundTypes || [],
                resourceTypes: resourceTypes?.resourceTypes || [],
                resourceDetails,
            }}
        >
            {props.children}
        </ResourceContext.Provider>
    );
};

export default ResourceContext;
