import { TaskGroupDataModel, TaskModel } from './data';

export interface RangeProps {
    start: Date;
    end: Date;
}

export interface DatesProps {
    compareTimes: (first: string, second: string) => boolean;
    compareDates: (first: string, second: string) => number;
    boundingDates: (data: TaskGroupDataModel[]) => { start: Date; end: Date };
    daysInMonth: (month: number, year: number) => number;
    lastOfTheMonth: (date: Date) => Date;
    firstOfTheMonth: (date: Date) => Date;
    miliConversions: (timeBox: string) => number;
}

export const compareDates = (first: string, second: string): number => {
    const f = new Date(first).getTime();
    const s = new Date(second).getTime();
    if (Number.isNaN(f) || Number.isNaN(s)) return 0;
    return (s - f) / 86400000;
};

export const compareTimes = (first: string, second: string): boolean => {
    const f = new Date(first).getTime();
    const s = new Date(second).getTime();
    if (Number.isNaN(f) || Number.isNaN(s)) return false;
    return f < s;
};

export const boundingDates = (data: TaskGroupDataModel[]): { start: Date; end: Date } => {
    const dates: string[] = [];
    data.forEach((d: TaskGroupDataModel) => d.children.map((t: TaskModel) => dates.push(t.start, t.end)));
    const out = dates.filter((a: string) => !!a).sort((a: string, b: string) => (compareTimes(a, b) ? -1 : 1));

    if (out.length === 0) {
        out[0] = new Date().toLocaleDateString();
        out[1] = new Date().toLocaleDateString();
    }
    return { start: new Date(out[0]), end: new Date(out[out.length - 1]).addDays(7) };
};

export const lastOfTheMonth = (date: Date): Date => new Date(date.getFullYear(), date.getMonth() + 1, 0);

export const daysInMonth = (month: number, year = new Date().getUTCFullYear()): number =>
    parseInt(
        lastOfTheMonth(new Date(`${month}/1/${year}`))
            .toLocaleDateString()
            .split('/')[1],
        10
    );
export const firstOfTheMonth = (date: Date): Date => new Date(date.getFullYear(), date.getMonth(), 1);

export const miliConversions = (timeBox = 'month'): number => {
    const times: { [index: string]: number } = {
        minute: 60000,
        get hour() {
            return this.minute * 60;
        },
        get day() {
            return this.hour * 24;
        },
        get week() {
            return this.day * 7;
        },
        get month() {
            return this.day * 30;
        },
        get year() {
            return this.day * 365;
        },
    };

    return times[timeBox];
};

export function addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export default {
    compareTimes,
    compareDates,
    boundingDates,
    daysInMonth,
    lastOfTheMonth,
    firstOfTheMonth,
    miliConversions,
    addDays,
};
